@use "sass:math";

.program-detail {
    &__campus-wrapper {
        width: 100%;
    }
    .section__description {
        margin-bottom: 0;
        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 -19px math.div($line-height-computed , 2);
            >li {
                flex: 1 0 42%;
                margin: 5px 19px;
            }
        }
    }
    &__loading {
        opacity: 0;
        position: relative;
        margin-left: 15px;
        .loading__wrapper {
            display: none;
            width: 140px;
            margin: 12px 0 12px -10px;
            padding: 0;
            img {
                width: 50px;
                height: auto;
                float: left;
            }
        }
        .loading__text {
            text-align: left;
            margin-top: 0;
            padding: 14px 0;
        }
        &.fadeout {
            transition: opacity 600ms;
            opacity: 0;
        }
        &.fadein {
            transition: opacity 600ms;
            opacity: 1;
            .loading__wrapper {
                display: block;
            }
        }
    }
    &__locations {
        border: 2px solid $umassglobal-lightest-gray;
        background-color: $body-bg;
        width: 100%;
        max-width: 885px;
        .container {
            padding: 40px;
            width: 100%;
        }
        .row {
            margin-left: -40px;
            margin-right: -40px;
        }
        &-header {
            * {
                margin-bottom: 20px;
                margin-top: 0;
            }
        }
        .program-detail__location {
            padding: 0 40px;
            a {
                font-weight: bold;
            }
        }
    }
    &__template {
        display: none;
    }
    &__campus {
        &--notfound {
            display: none;
            p {
                .program-detail__value & {
                    font-weight: normal;
                    color: $umassglobal-gray;
                    line-height: 19px;
                    max-width: 380px;
                }
                a[href^="tel"] {
                    color: $umassglobal-primary-color;
                    font-weight: bold;
                    text-decoration: none;
                    &:hover {
                        color: $link-hover-color;
                    }
                }
            }
            &.active {
                display: block;
            }
        }
        display: none;
        &.active {
            display: block;
        }
    }
    &__scroll {
        max-height: 405px;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        @include media-breakpoint-down(md) {
            max-height: 100vh;
        }
    }
    &__detail {
        margin-bottom: 32px;
        &-window {
            background-color: $body-bg;
            border: 1px solid $umassglobal-lightest-gray;
            .program-detail & {
                padding: 30px 40px;
                @include media-breakpoint-down(md) {
                    padding: 0 15px;
                    .col-md-6 {
                        padding: 0 15px;
                    }
                }
            }
            @include media-breakpoint-down(md) {
                margin-top: 25px;
                .row {
                    display: flex;
                    flex-direction: column;
                }
                [class*="col-md-"] {
                    order: 3;
                    padding: 0;
                    &:first-child {
                        order: 1;
                    }
                    &:last-child {
                        order: 2;
                    }
                }
            }
        }
        .ways__value {
            margin-bottom: 22px;
            @include media-breakpoint-down(md) {
                display: flex;
                justify-content: center;
            }
        }
        .heading__type {
            text-align: left;
            margin-bottom: 9px;
            margin-top: 8px;
            padding-bottom: 1px;
            font-weight: 500;
            h3 {
                font-weight: 500;
                display: inline;
                margin: 0;
            }
        }
    }
    &__value {
        color: $umassglobal-text-gray;
        p {
            font-weight: 700;
            line-height: 18px;
        }
        >.icon {
            font-size: $font-size-maximus;
            top: -8px;
            transform: none;
        }
        .tagline {
            font-size: $font-size-bourgeois;
        }
        &.loading {
            >.icon {
                display: none;
            }
            padding-left: 0;
        }
    }
    &__location {
        .forms__message {
            white-space: nowrap;
            right: 0;
            max-width: none;
            text-align: right;
            @include media-breakpoint-down(md) {
                left: -67px;
                max-width: none;
                text-align: left;
            }
        }
        .program-detail {
            &__detail {
                background: $umassglobal-off-white-color;
                padding: 9px 20px 1px;
                margin-bottom: 12px;
                .heading__type {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: none;
                    @include media-breakpoint-down(md) {
                        margin-bottom: 12px;
                    }
                }
            }
        }
        .location__edit-window {
            display: inline-block;
            line-height: 32px;
            margin: 0;
            text-transform: none;
            border: 1px solid #a9a9a9;
            &.has-error {
                border-color: $umassglobal-primary-color;
                margin-bottom: 16px;
            }
        }
        .location__zipcode-label {
            text-indent: 0;
            position: static;
            display: inline-block;
            font-size: $font-size-smallPica;
            color: $umassglobal-gray;
            font-weight: normal;
            line-height: 2.3;
            margin: 0;
            text-transform: none;
            @include media-breakpoint-down(md) {
                font-size: $font-size-bourgeois;
                .career-impact__location--divider {
                    margin-left: 0;
                }
            }
        }
        .ways__value {
            margin-top: 7px;
            a {
                @include media-breakpoint-down(md) {
                    margin-top: 6px;
                }
            }
        }
        @include media-breakpoint-down(md) {
            .program-detail__value>.icon {
                font-size: $font-size-maximus;
                top: -3px;
            }
        }
    }
    &__info {}
    &__ways {
        .program-detail {
            &__detail {
                margin-bottom: 20px;
            }
        }
    }
}
