@use "sass:math";

$transition-animate-speed: 600ms !default;
$transition-animate: $transition-animate-speed ease-out !default;
$transition-hover-speed: 400ms !default;

%border-top-blue-light {
  border-top: 1px solid #002d5d42;
}

%border-blue-bg {
  border-bottom: 1px solid #ffffff21;
}

@mixin cards-bottom-colors($size) {
  &:hover {
    border-bottom: $size solid $umassglobal-secondary-color !important;
  }
  &.arts-sciences:hover {
    border-bottom: $size solid $umassglobal-arts-and-sciences-color !important;
  }
  &.business:hover {
    border-bottom: $size solid $umassglobal-business-color !important;
  }
  &.education:hover {
    border-bottom: $size solid $umassglobal-education-color !important;
  }
  &.nursing:hover {
    border-bottom: $size solid $umassglobal-nursing-and-health-professions-color !important;
  }
  &.ext-education:hover {
    border-bottom: $size solid $umassglobal-extended-education-color !important;
  }
}

@mixin cards-paid-colors($size) {
  &:hover {
    border-bottom: none !important;
  }
  &.arts-sciences {
    border-left: $size solid $umassglobal-arts-and-sciences-color !important;
    @media screen and (max-width: 767px) {
      border-top: $size solid $umassglobal-arts-and-sciences-color !important;
    }
  }
  &.business {
    border-left: $size solid $umassglobal-business-color !important;
    @media screen and (max-width: 767px) {
      border-top: $size solid $umassglobal-business-color!important;
    }
  }
  &.education {
    border-left: $size solid $umassglobal-education-color !important;
    @media screen and (max-width: 767px) {
      border-top: $size solid $umassglobal-education-color !important;
    }
  }
  &.nursing {
    border-left: $size solid $umassglobal-nursing-and-health-professions-color !important;
    @media screen and (max-width: 767px) {
      border-top: $size solid $umassglobal-nursing-and-health-professions-color !important;
    }
  }
  &.ext-education {
    border-left: $size solid $umassglobal-extended-education-color !important;
    @media screen and (max-width: 767px) {
      border-top: $size solid $umassglobal-extended-education-color !important;
    }
  }
}

@mixin darklink {
  color: $umassglobal-text-gray;
  &:hover {
    color: $umassglobal-primary-color;
  }
}

@mixin list-inline {
  @include list-unstyled;
  >li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@mixin panel-variant(
  $border,
  $heading-text-color,
  $heading-bg-color,
  $heading-border
) {
  border-color: $border;
  & > .panel-heading {
    color: $heading-text-color;
    background-color: $heading-bg-color;
    border-color: $heading-border;
    + .panel-collapse > .panel-body {
      border-top-color: $border;
    }
    .badge {
      color: $heading-bg-color;
      background-color: $heading-text-color;
    }
  }
  & > .panel-footer {
    + .panel-collapse > .panel-body {
      border-bottom-color: $border;
    }
  }
}

@mixin panel-hover {
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
  transition: box-shadow $transition-hover-speed;
  &:hover {
    //box-shadow: 0 0 10px #00000080;
    text-decoration: none;
  }
}

@mixin panel-hover-image {
  cursor: pointer;
  .card__image {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
    transition: box-shadow $transition-hover-speed;
  }
  &:hover {
    .card__image {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    }
    a {
      color: $umassglobal-primary-color;
      text-decoration: underline;
    }
  }
}

@mixin make-sm-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  math.div($gutter , 2);
  padding-right: math.div($gutter , 2);

  @media (min-width: $screen-sm-min) {
    float: left;
    width: math.percentage(math.div($columns , $grid-columns));
  }
}
