body {
    .modal--open & {
        overflow: hidden;
        position: relative;
    }
}

.body {
    .modal--open & {
        position: absolute;
        width: 100vw;
    }
}

#at-custom-sidebar {
    &.at-custom-sidebar {
        box-sizing: border-box;
        border: 1px solid $umassglobal-extraLight-gray;
        border-radius: 0;
        box-shadow: none;
        width: 85px !important;
        .at-share-btn {
            line-height: 0 !important;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .at-icon-wrapper {
            line-height: 45px !important;
            height: 45px !important;
            width: 45px !important;
            margin: 0 !important;
            .at-icon {
                margin: 11px !important;
                height: 24px !important;
                width: 24px !important;
            }
        }
        .at-custom-sidebar-btns {
            padding: 19px;
            width: 83px !important;
            box-sizing: border-box;
            &::before {
                content: "SHARE";
                color: $umassglobal-dark-gray;
                display: block;
                font-family: $font-family-sans-serif;
                font-size: $font-size-small;
                font-weight: bold;
                line-height: 26px;
                position: relative;
                margin-bottom: 20px;
                padding: 5px 0 0;
                text-align: center;
                border-bottom: 1px solid $umassglobal-extraLight-gray;
            }
        }
    }
}

.at-resp-share-element.at-mobile {
    border: 1px solid $umassglobal-extraLight-gray;
    margin-top: 20px;
    .at-share-btn-elements {
        padding: 44px 14px 14px;
        display: flex;
        justify-content: space-between;
        &::before {
            content: "SHARE";
            color: $umassglobal-dark-gray;
            display: block;
            font-family: $font-family-sans-serif;
            font-size: $font-size-small;
            font-weight: bold;
            line-height: 16px;
            position: absolute;
            margin-bottom: 15px;
            padding: 0 0 0 3px;
            text-align: left;
            top: 14px;
        }
    }
    .at-icon-wrapper.at-share-btn {
        margin-right: 24px;
        flex: 1 1 25%;
        text-align: center;
        &:last-child {
            margin-right: 0;
        }
        .at-icon-wrapper {
            float: none;
        }
    }
}

.container {
    .section .container {
        width: auto;
        padding: 12px 0;
        .row {
            margin-left: -5px;
            margin-right: -5px;
        }
        .col-sm-4 {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

.leftnav__content,
.program-detail,
.admission-req,
.readmore,
.curriculum {
    .col-sm-8,
    .col-sm-6 {
        &:first-child {
            @include media-breakpoint-up(md) {
                padding-right: 70px;
            }
        }
    }
    .section {
        &__header {

        }
        &__description,
        &__header {
            text-align: left;
            max-width: none;
        }
    }
}

.section {
    background-size: cover;
    background-position: center top !important;
    .container {
        padding-bottom: 60px;
        padding-top: 60px;
        z-index: 1;
        position: relative;
    }
    @include media-breakpoint-up(xl) {
        .container {
            padding: 50px 25px;
            &__snippet {
                padding: 50px 25px;
            }
        }
    }
    .acrobat__download {
        color: $umassglobal-gray;
        font-size: $font-size-small;
        line-height: $line-height-small;
        font-weight: normal;
        font-style: normal;
    }
    &--short {
        .container {
            padding-bottom: 0;
        }
        @include media-breakpoint-up(xl) {
            .container, .container__snippet {
                padding: 50px 25px 0;
            }
        }
    }
    &__content {
        .section {
            &__description,
            &__header {
                text-align: left;
            }
        }
    }
    &__content,
    &__description {
        >h2,
        >h3 {
            &:first-child {
                margin-top: 0;
                margin-bottom: 24px;
            }
        }
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
        .checks {
            .icon__checkoutline {
                &::after {
                    color: $umassglobal-secondary-color;
                    font-weight: bold;
                }
            }
        }
    }
    &__divider {
        .container {
            border-bottom: 1px solid $umassglobal-extraLight-gray;
        }
    }
    &__header {
        &:first-child {
            * {
                margin-top: 0;
            }
        }
    }
    &__description,
    &__header {
        text-align: center;
        @include media-breakpoint-down(md) {
            .btn {
                width: 100%;
            }
        }
        .section__dark-text & {
            * {
                color: $umassglobal-text-gray;
            }
        }
    }
    &__pattern {
        background-position: 50% 50%;
        background-size: 100% auto;
        @include media-breakpoint-down(md) {
            background-size: initial;
            background-position: 100% 0;
        }
        &-left {
            background-position: 50% 50%;
            background-size: 100% auto;
            @include media-breakpoint-down(md) {
                background-size: initial;
                background-position: 0 0;
            }
        }
        &-right {
            background-position: 50% 0;
            background-size: 100% auto;
            @include media-breakpoint-down(md) {
                background-size: initial;
                background-position: 100% 0;
            }
        }
        &-full {
            background-position: 50% 50%;
            background-size: 100% auto;
            @include media-breakpoint-down(md) {
                background-size: initial;
                background-position: 0 0;
            }
        }
    }
    &__yellowback {
        background-color: $umassglobal-tertiary-color;
    }
    &__blueback {
        background-color: $umassglobal-secondary-color;
    }
    &__redback {
        background-color: $umassglobal-primary-color;
        color: white;
        h2,
        p {
            color: white;
        }
    }
    &__lightgrayback {
        background-color: $umassglobal-off-white-color;
    }
    &__grayback {
        background-color: $umassglobal-lightest-gray;
    }
    &__darkgrayback {
        background-color: $umassglobal-off-white-color-alternate;
    }
    &__darkback {
        background-color: $umassglobal-dark-gray;
    }
    &__short {
        .container {
            padding-bottom: 50px;
            padding-top: 17px;
            &__snippet {
                padding-bottom: 50px;
                padding-top: 17px;
            }
        }
    }
    &__noback {
        background: none;
    }
    &__subsection {
        .section__header {
            overflow: hidden;
            * {
                display: inline-block;
                font-size: $font-size-maximus;
                line-height: 1;
                margin: 0 -20px;
                position: relative;
                padding: 0 20px;
                @include media-breakpoint-down(md) {
                    margin: 0 -5px 30px;
                    padding: 0 5px;
                }
                &:after,
                &:before {
                    border-bottom: 1px solid $umassglobal-extraLight-gray;
                    content: " ";
                    display: block;
                    position: absolute;
                    top: 50%;
                    width: 50vw;
                }
                &:after {
                    left: 100%;
                }
                &:before {
                    right: 100%;
                }
            }
        }
        &.section__light-text {
            .section__header {
                * {
                    &:after,
                    &:before {
                        border-bottom: 1px solid #6e6e6e;
                    }
                }
            }
        }
    }
    &__description,
    &__content {
        img {
            height: auto;
            max-width: 100%;
        }
    }
}

.link {
    &__icon-text {
        display: none;
    }
}

.error-message {
    position: relative;
    background: $umassglobal-secondary-color;
    min-height: 680px;
    display: flex;
    align-items: center;
    color: white;
    @include media-breakpoint-down(md) {
        min-height: 0;
    }
    &__header {
        * {
            color: white;
            font-family: $font-family-sans-serif;
            font-size: 10rem;
            font-weight: normal;
            line-height: .8;
            margin-bottom: 40px;
            @include media-breakpoint-down(md) {
                font-size: 6.5rem;
                line-height: $line-height-halved;
            }
        }
    }
    p {
        font-size: 2rem;
        line-height: $line-height-base;
        margin: 0;
    }
    &__cta {
        margin-top: 70px;
    }
    .img-overlay {
        background: $umassglobal-secondary-color;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;
    }
}

.overlay {
    height: 100vh;
    opacity: 0;
    position: fixed;
    left: 10000px;
    visibility: hidden;
    top: 0;
    // transition: left 0s linear 1s, opacity 1s;
    width: 100vw;
    z-index: 1051;
    &.open {
        left: 0;
        opacity: 1;
        visibility: visible;
        // transition: opacity 1s;
    }
    &__back {
        height: 100%;
        background: #000;
        opacity: 0.85;
        width: 100%;
    }
    &__form {
        display: none;
        &-active & {
            display: block;
        }
        &-active {
            .overlay {
                &__content {
                    border-radius: 8px;
                    background: $umassglobal-white;
                    flex: 1 0 100%;
                    width: 100%;
                    position: relative;
                    max-width: 750px;
                    &-scroll {}
                    @media (max-height: 800px) {
                        border-radius: 0;
                        height: auto;
                        max-height: none;
                        margin: 0 auto -100px;
                        &-wrapper {
                            display: block;
                            padding: 0;
                            overflow-x: hidden;
                            overflow-y: scroll;
                        }
                        &-scroll {
                            max-width: 750px;
                            margin: 0 auto;
                        }
                    }
                    @include media-breakpoint-down(md) {
                        border-radius: 0;
                        max-height: none;
                    }
                }
            }
        }
    }
    &__video {
        &-wrapper {
            display: none;
            padding-top: 56.25%;
            position: relative;
            @include media-breakpoint-down(md) {
                margin-top: 75px;
            }
        }
        &-active &-wrapper {
            display: block;
        }
        &-window {
            max-width: 960px;
        }
        &-container {
            display: none;
            &.active {
                display: inherit;
            }
        }
    }
    &__content {
        width: 100%;
        position: relative;
        max-width: 750px;
        .overlay__video-active & {
            max-width: 950px;
        }
        @include media-breakpoint-down(md) {
            max-height: 100%;
        }
        &-wrapper {
            align-items: center;
            display: flex;
            height: 100%;
            left: 0;
            justify-content: center;
            position: absolute;
            padding: 50px 0;
            top: 0;
            width: 100%;
            @include media-breakpoint-down(md) {
                padding: 0;
            }
        }
        iframe {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
        }
    }
    &__close {
        &-button {
            &:hover {
                color: $umassglobal-primary-color;
            }
            position: absolute;
            right: 12px;
            //top: -34px;
            top: 3px;
            z-index: 100;
            @include media-breakpoint-up(lg) {
                font-size: 2rem;
            }
            @media screen and (max-width: 1024px) {
                right: 12px;
                top: 1.5px;
            }
            @include media-breakpoint-down(md) {
                right: 15px;
            }
            @include media-breakpoint-down(md) {
                .program-detail &,
                .overlay__form-active & {
                    .icon {
                        color: $umassglobal-text-gray;
                        &:hover {
                            color: $umassglobal-primary-color;
                        }
                    }
                }
                right: 25px;
                top: 25px;
            }
        }
    }
}

.loading {
    &__wrapper {
        text-align: center;
        margin: 50px auto;
        padding: 10px 0 20px;
        width: 98px;
    }
    &__text {
        font-size: $font-size-small;
        line-height: $line-height-small;
        color: $umassglobal-gray;
        text-align: center;
        font-weight: bold;
        margin-top: -12px;
    }
}

.nav-pills__wrapper--scrollable {
    &.scrollable {
        position: relative;
    }
    >.nav-pills {
        flex-wrap: nowrap;
        white-space: nowrap;
    }
    >.icon__rightmorearrow {
        display: none;
        &:after {
            vertical-align: baseline;
            line-height: 10px;
        }
    }
    @include media-breakpoint-down(md) {
        overflow: hidden;
        &.scrollable {
            padding-top: 18px;
            margin: -15px -25px;
            .nav-pills {
                border-bottom: none;
                padding-left: 25px;
                padding-bottom: 15px;
                position: relative;
                >li {
                    &:last-child {
                        padding-right: 30px;
                    }
                }
                &:after {
                    bottom: 15px;
                    position: absolute;
                    left: 30px;
                    width: calc(100% + 130px);
                }
            }
            >.icon__rightmorearrow {
                color: $umassglobal-gray;
                display: block;
                font-size: 2rem;
                line-height: .5;
                top: 0;
                position: absolute;
                right: 12px;
            }
        }
    }
}


.related-information {
    .tabs {
        &__content {
            &.active {
                @media screen and (max-width: 992px) {
                    display: flex;
                    justify-content: center;
                }
                @media screen and (min-width: 992px) {
                    display: block;
                }
            }
        }
    }
}

.tabs {
    &__container {
        @include clearfix;
        padding: 30px 0 0;
        .related-information & {
            padding: 60px 0 0;
            position: relative;
            @include media-breakpoint-down(md) {
                padding: 30px 0 0;
            }
        }
        .owl-stage {
            display: flex;
        }
        .owl-nav {
            z-index: 99999999;
            color: $umassglobal-dark-gray;
            font-size: 5rem;
            width: 100%;
            height: 25px;
            @media screen and (min-width: 992px) {
                position: absolute;
                top: 30%;
                font-size: 10rem;
                height: auto;
            }
            button {
                line-height: 50px !important;
                @media screen and (min-width: 992px) {
                    line-height: 100px !important;
                }
            }
            .owl-prev {
                position: absolute;
                left: 0;
                @media screen and (min-width: 992px) {
                    left: -40px;
                }
            }
            .owl-next {
                position: absolute;
                right: 0;
                @media screen and (min-width: 992px) {
                    right: -40px;
                }
            }
        }
        .card__event {
            .card__event-content {
                .card__text {
                    .card__subtitle {
                        font: normal normal bold 14px/19px $font-family-sans-serif;
                        letter-spacing: 0;
                    }
                    .card__title {
                        h3,
                        a {
                            font-size: 2rem;
                            line-height: 1.5;
                        }
                    }
                    .card__copy {
                        font: normal normal normal 1.6rem/1.4 $font-family-sans-serif;
                        letter-spacing: 0;
                        color: $umassglobal-dark-gray;
                    }
                }
                .card__information {
                    .card__date,
                    .card__time {
                        font: normal normal normal 1.6rem/1.4 $font-family-sans-serif;
                        letter-spacing: 0;
                        color: $umassglobal-dark-gray;
                    }
                    .card__addlink .addeventatc {
                        font: normal normal bold 1.5rem/1.3 $font-family-sans-serif;
                        letter-spacing: 0;
                    }
                }
            }
        }
    }
    &__wrapper {
        margin-top: 30px;
        &.single-tab {
            .nav-tabs {
                display: none;
            }
            .tabs__container {
                padding-top: 0;
            }
        }
        &.scrollable {
            position: relative;
        }
        >.icon__rightmorearrow {
            display: none;
            &:after {
                vertical-align: baseline;
                line-height: 10px;
            }
        }
        @include media-breakpoint-down(md) {
            overflow: hidden;
            &.scrollable {
                margin-top: 24px;
                margin-left: -25px;
                margin-right: -25px;
                padding-top: 18px;
                .nav-tabs {
                    border-bottom: none;
                    padding-left: 30px;
                    padding-bottom: 15px;
                    position: relative;
                    >li {
                        &:last-child {
                            margin-right: 30px;
                        }
                    }
                    &:after {
                        bottom: 15px;
                        position: absolute;
                        border-bottom: 1px solid $umassglobal-text-gray;
                        left: 30px;
                        width: calc(100% + 130px);
                    }
                }
                >.icon__rightmorearrow {
                    color: $umassglobal-gray;
                    display: block;
                    font-size: 2rem;
                    line-height: .5;
                    top: 0;
                    position: absolute;
                    right: 12px;
                }
                .tabs__content {
                    @include media-breakpoint-down(md) {
                        padding: 0 30px;
                    }
                }
                .tabs__container {
                    padding: 15px 0 0;
                }
            }
        }
    }
    &__content {
        top: 0;
        left: -10000px;
        opacity: 0;
        position: absolute;
        transition: opacity 600ms;
        display: none;
        &.active {
            top: auto;
            left: auto;
            opacity: 1;
            position: relative;
            display: block;
        }
        &.last {
            top: auto;
            left: 0;
            opacity: 0;
            position: absolute;
            visibility: inherit;
        }
        .row {
            display: flex;
            @include media-breakpoint-down(md) {
                display: block;
            }
        }
    }
}

.location {
    &__edit {
        color: $umassglobal-primary-color;
        padding-left: 5px;
        &-window {
            cursor: pointer;
            display: inline-block;
            padding: 0 6px;
            text-align: left;
            &.has-error {
                input,
                .location__zipcode {
                    color: $umassglobal-primary-color;
                }
            }
            &:hover {
                .location {
                    &__zipcode {
                        text-decoration: underline;
                        text-decoration-color: $umassglobal-darker-gray;
                    }
                }
            }
            &.active {
                background-color: $umassglobal-off-white-color;
                cursor: inherit;
                display: inline-block;
                .location {
                    &__zipcode {
                        display: none;
                    }
                    &__edit {}
                    &__field {
                        display: inline-block;
                    }
                }
            }
        }
    }
    &__zipcode {
        color: $umassglobal-darker-gray;
        display: inline-block;
        font-weight: bold;
        width: 48px;
        &-label,
        &--reader {
            text-align: left;
            text-indent: -10000px;
            position: absolute;
        }
    }
    &__field {
        border: none;
        background: none;
        display: none;
        font-size: $font-size-small;
        color: $umassglobal-darker-gray;
        font-weight: bold;
        letter-spacing: inherit;
        line-height: 28px;
        margin: 0;
        padding: 0;
        height: 28px;
        width: 48px;
    }
}

.ways {
    &__value {
        align-items: flex-start;
        color: $umassglobal-gray;
        display: flex;
        flex-direction: column;
        padding-left: 35px;
        position: relative;
        text-align: left;
        font-size: $font-size-small;
        line-height: 1.2;
        .readmore button,
        a {
            font-size: $font-size-small;
            font-weight: 700;
            text-decoration: underline;
            text-decoration-color: $umassglobal-extraLight-gray;
            margin-top: 12px;
        }
        p {
            font-weight: 500;
            margin: 0;
        }
        >.icon {
            color: $umassglobal-light-gray;
            font-size: 1.9em;
            line-height: 1;
            left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            text-decoration: none;
        }
        .tagline {
            font-size: 1rem;
            line-height: 1.5;
        }
    }
}

.program-detail__info {
    .ways__value {
        a {
            font: normal normal bold 13px/18px $font-family-sans-serif;
            letter-spacing: 0.2px;
        }
    }
}

.sidebyside {
    overflow: hidden;
    .container-fluid {
        max-width: 2000px;
    }
    .container {
        padding-bottom: 40px;
    }
    &__container.container {
        padding: 0;
    }
    .row {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }
    &__image {
        width: 100%;
        height: 100%;

        &.image__video-link {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }
        &-wrapper {
            min-height: 300px;
            position: relative;
        }
    }
    &__right {
        .row {
            flex-direction: row;
            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
        }
        .sidebyside {
            &__column-image {
                padding-left: 0;
                justify-content: flex-end;
                @include media-breakpoint-down(md) {
                    padding: 0;
                }
            }
            &__text {
                text-align: left;
            }
        }
    }
    &__left {
    }
    &__column {
        width: 100%;
        img {
            width: 100%;
            max-width: 100%;
            @include media-breakpoint-down(md) {
                height: auto;
            }
        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
            height: 350px;
        }
        @media screen and (min-width: 768px) {
            width: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
    &__text {
        text-align: right;
        flex: auto;
        @include media-breakpoint-down(md) {
            padding: 30px;
            text-align: left;
        }
        h3 {
            &:first-child {
                margin-top: 0;
            }
        }
        p {}
        @media screen and (min-width: 768px) and (max-width: 1024px) {
            padding: 15px;
        }
    }
    &__divider {
        .container {
            border-bottom: 1px solid $umassglobal-extraLight-gray;
        }
    }
}

.image {
    &__caption {
        display: block;
        background-color: $umassglobal-off-white-color;
        font-size: $font-size-small;
        line-height: $line-height-small;
        padding: 20px 15px 8px;
        width: 100%;
    }
    &__full {
        img {
            height: auto;
            width: 100%;
        }
        margin: 0 0 12px;
    }
    &__left {
        align-items: center;
        display: flex;
        flex-direction: row;
        @include media-breakpoint-down(md) {
            align-items: flex-start;
        }
        a {
            display: inline-block;
            max-width: 40%;
            margin-right: 10%;
            flex: 1 0 auto;
            @include media-breakpoint-down(md) {
                width: 70px;
            }
        }
        img {
            height: auto;
            max-width: 40%;
            margin-right: 10%;
            flex: 1 0 auto;
            @include media-breakpoint-down(md) {
                width: 30%;
            }
        }
        a img {
            margin: 0;
            max-width: none;
            width: 100%;
        }
    }
    &__right {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        img {
            height: auto;
            max-width: 40%;
            margin-left: 10%;
        }
    }
    &__floatleft,
    &__floatright {
        position: relative;
        float: right;
        margin-left: 25px;
        margin-bottom: 25px;
        width: 360px;
        @include media-breakpoint-down(md) {
            margin-left: 0;
            margin-bottom: 12px;
            width: 100%;
        }
        img {
            height: auto;
            width: 100%;
        }
        &.no-triangle {
            &:after {
                content: "";
                display: none;
            }
        }
    }
    &__floatleft {
        float: left;
        margin-left: 0;
        margin-right: 25px;
    }
    &__video-link a {
        cursor: pointer;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        @include standard-icons("play");
        &:after {
            content: " ";
            background-image: url("../../../fonts/play-icon.png");
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
            position: absolute;
        }
        &:hover {
            &:after {
                background-image: url("../../../fonts/play-icon.png");
                background-position: 0 -24px;
                background-repeat: no-repeat;
            }
        }
    }
}

.card {
    &__addlink {
        a {
            font-size: $font-size-smallPica;
            font-weight: 700;
            line-height: 1.3;
            display: inline-block;
            padding-left: 30px;
            position: relative;
        }
        .icon {
            color: $umassglobal-light-gray;
            font-size: $font-size-maximus;
            line-height: .75;
            position: absolute;
            left: 0;
            text-decoration: none;
        }
    }
    &__eventlisting {
        padding: 50px 0;
        border-bottom: 1px solid $umassglobal-extraLight-gray;
        @include media-breakpoint-down(md) {
            padding: 35px 0;
        }
        &:last-child {
            border-bottom: none;
        }
        .row {
            display: flex;
            margin: 0 -20px;
            flex-wrap: nowrap;
        }
        &-link {
            display: block;
            text-decoration: none;
            color: $umassglobal-text-gray;
            &:hover {
                text-decoration: none;
                color: $link-hover-color;
                .card__eventlisting-description {
                    color: $umassglobal-text-gray;
                }
                .card__eventlisting-name {
                    text-decoration: underline;
                }
                .card__eventlisting-location {
                    color: $link-hover-color;
                }
            }
        }
        &-when {
            border-right: 1px solid $umassglobal-extraLight-gray;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 0 20px;
            &--desktop {
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
            &--mobile {
                @include media-breakpoint-up(md) {
                    display: none;
                }
                background-color: $umassglobal-off-white-color;
                border: none;
                padding: 20px;
                margin-bottom: 12px;
            }
        }
        &-text {
            padding-right: 15px;
        }
        &-what {
            display: flex;
            flex-direction: row;
            padding: 0 20px;
            align-items: flex-start;
            @include media-breakpoint-down(md) {
                flex-direction: column-reverse;
                padding: 0 45px;
            }
        }
        &-date {
            font-weight: bold;
        }
        &-through {
            color: $umassglobal-gray;
            font-size: $font-size-small;
            text-align: center;
            overflow: hidden;
            max-width: 170px;
            span {
                display: inline-block;
                position: relative;
                padding: 0 7px;
                &:after,
                &:before {
                    border-bottom: 1px solid $table-border-color;
                    content: " ";
                    display: block;
                    position: absolute;
                    top: 50%;
                    width: 50vw;
                }
                &:after {
                    left: 100%;
                }
                &:before {
                    right: 100%;
                }
            }
        }
        &-type {
            @include subheading($umassglobal-primary-color,
            $font-size-tiny);
        }
        &-name {
            font-size: $font-size-h3;
            line-height: $line-height-h3;
            margin-bottom: 12px;
        }
        &-location {
            font-size: $font-size-small;
            color: $umassglobal-darkest-gray;
            margin-bottom: 12px;
        }
        &-description {
            margin-bottom: 30px;
        }
        &-image {
            flex: 1 0 auto;
            position: relative;
            img {
                height: auto;
                width: 100%;
            }
            @include media-breakpoint-down(md) {
                margin-bottom: 24px;
            }
        }
        &-ctas {
            white-space: nowrap;
            @include media-breakpoint-down(md) {
                white-space: normal;
                display: flex;
                flex-direction: column-reverse;
                align-items: flex-start;
            }
            .btn {
                font-size: $font-size-small;
                margin-right: 18px;
                overflow: auto;
                &:last-child {
                    margin-right: 0;
                }
                @include media-breakpoint-down(md) {
                    margin-right: 0;
                    margin-top: 18px;
                    width: auto;
                    &:last-child {
                        margin-top: 0;
                    }
                }
                @include media-breakpoint-down(md) {
                    font-size: $font-size-bourgeois;
                    text-transform: none;
                    min-width: 0;
                }
            }
            .icon {
                font-size: $font-size-english;
                line-height: $line-height-halved;
                vertical-align: middle;
                text-decoration: none;
            }
            .btn-link {
                color: $umassglobal-primary-color;
                &:hover {
                    color: hoverColor($umassglobal-primary-color);
                }
                @include media-breakpoint-down(md) {
                    margin-left: -1px;
                }
            }
        }
    }
    &__subtitle {
        @include subheading($umassglobal-primary-color,
        $font-size-bourgeois);
        line-height: $line-height-base;
        margin-bottom: 5px;
    }
    &__title {
        font-size: $font-size-columbian;
        line-height: $line-height-single;
        h3 {
            font-size: $font-size-english;
            line-height: $line-height-single;
            font-family: $font-family-sans-serif;
        }
        a {
            color: $umassglobal-text-gray;
        }
    }
    &__school {
        border-radius: $border-radius-large;
        background: $umassglobal-text-gray;
        color: #fff;
    }
    &__faculty {
        background-color: $body-bg;
        display: block;
        font-size: $font-size-tiny;
        text-decoration: none;
        @include media-breakpoint-up(md) {
            @include panel-hover;
            display: flex;
            flex-direction: column;
            height: 100%;
            &:hover {
                .card__faculty-name * {
                    text-decoration: none;
                }
            }
            .card__image {
                overflow: hidden;
            }
        }
        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: row;
            .card__image {
                width: 116px;
                margin-right: 16px;
                flex: 0 0 116px;
            }
        }
        &:focus {
            text-decoration: none;
        }
        &-column {
            margin-bottom: 24px;
            padding: 0 12px;
            @include media-breakpoint-down(md) {
                margin-bottom: 0;
                padding: 25px;
                border-bottom: 1px solid $umassglobal-lightest-gray;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &-text {
            border: 1px solid $umassglobal-lightest-gray;
            border-top: 0;
            padding: 18px 12px;
            position: relative;
            flex-grow: 1;
            @include media-breakpoint-down(md) {
                border: none;
                &:after {
                    content: none;
                }
            }
        }
        &-label {
            * {
                color: $umassglobal-gray;
                font-size: $font-size-bourgeois;
                font-family: $font-family-sans-serif;
                font-weight: 500;
                line-height: 1.9;
                letter-spacing: 0.03em;
                text-transform: uppercase;
                margin: 0 0 8px;
                @include media-breakpoint-down(md) {
                    margin: 0 0 4px;
                }
            }
        }
        &-name {
            * {
                color: $umassglobal-primary-color;
                font-size: $font-size-pica;
                font-family: $font-family-sans-serif;
                line-height: $line-height-halved;
                margin: 12px 0;
                text-decoration: none;
                @include media-breakpoint-down(md) {
                    margin: 8px 0 0;
                }
            }
        }
        &-title {
            color: $umassglobal-gray;
            font-size: $font-size-smallPica;
            line-height: $line-height-extra;
            font-style: italic;
        }
        &-specialty {
            .card__faculty-label {
                * {
                    font-weight: bold;
                }
            }
            border-top: 1px solid $umassglobal-lightest-gray;
            color: $umassglobal-text-gray;
            font-size: $font-size-longPrimer;
            line-height: $line-height-single;
            margin-top: 10px;
            padding-top: 15px;
            @include media-breakpoint-down(md) {
                border-top: none;
                margin-top: 0;
            }
            p {
                margin: 0;
                font-size: $font-size-bourgeois;
            }
        }
    }
    &__program,
    &__event {
        &-content {
            height: 100%;
            background-color: $body-bg;
            border: 1px solid $umassglobal-lightest-gray;
            padding: 5px 30px;
            position: relative;
            &.arts-sciences {
                &:hover {
                    border-bottom-color: $umassglobal-arts-and-sciences-color;
                }
            }
            &.business {
                &:hover {
                    border-bottom-color: $umassglobal-business-color;
                }
            }
            &.education {
                &:hover {
                    border-bottom-color: $umassglobal-education-color;
                }
            }
            &.nursing {
                &:hover {
                    border-bottom-color: $umassglobal-nursing-and-health-professions-color;
                }
            }
            &.ext-education {
                &:hover {
                    border-bottom-color: $umassglobal-extended-education-color;
                }
            }
        }
        &-ways {
            margin: 0 -12px;
        }
        .ways__value {
            display: inline-flex;
            height: 31px;
            margin: 0 12px 8px;
            .icon {
                top: 0;
                transform: none;
            }
        }
        .card {
            &__subtitle {
                @include subheading($umassglobal-primary-color);
                font-size: $font-size-bourgeois;
                line-height: 2;
            }
            &__title {
                * {
                    font-size: $font-size-english;
                    line-height: 1.6;
                    margin: 0;
                    text-decoration: none;
                    &:hover {
                        color: $umassglobal-primary-color;
                        text-decoration: underline;
                    }
                }
                margin-bottom: 16px;
            }
            &__text {
                border-bottom: 1px solid $umassglobal-lightest-gray;
            }
            &__date,
            &__time,
            &__copy {
                @include subheading($umassglobal-gray,
                $font-size-small);
                font-weight: 500;
            }
            &__copy {
                margin-bottom: 16px;
            }
            &__description {
                font-size: $font-size-pica;
                line-height: 1.6;
            }
            &__information {
                margin: 20px 0;
            }
            &__addlink,
            &__cta {
                margin-top: 20px;
            }
        }
    }
    &__news {
        background-color: $body-bg;
        border: 2px solid $umassglobal-lighter-gray;
        
        a.card__news-link {
            display: block;
            text-decoration: none;
            &:hover {
                text-decoration: none;
                .card__subtitle {
                    text-decoration: none;
                }
                .card__title {
                    * {
                        text-decoration: underline;
                    }
                }
            }
        }
        @include panel-hover-image;
        @include media-breakpoint-down(md) {
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .card__title {
            * {
                text-decoration: none;
                font-weight: bold;
                margin: 0;
                font-size: $font-size-english;
                line-height: $line-height-single;
            }
            h3 {
                font-size: $font-size-columbian;
                line-height: $line-height-minimal;
            }
        }
        .card_description {
            color: $umassglobal-darker-gray;
            font-size: $font-size-pica;
            margin: 10px 0;
        }
        .card__image {
            overflow: hidden;
            &.placeholder {
                background: $umassglobal-off-white-color url("/images/UMassGlobal-placeholder.png") no-repeat 50% 50%;
                width: 100%;
                padding-top: 58%;
                overflow: hidden;
            }
            .icon {
                color: #fff;
                position: absolute;
                top: 10px;
                left: 10px;
                font-size: 3rem;
                text-shadow: 0 0 4px rgba(0, 0, 0, 0.75);
                text-decoration: none;
                line-height: $line-height-straight;
            }
        }
    }
    &__image {
        border-radius: $border-radius-base;
        position: relative;
        // height: 16rem;
        align-self: center;
        align-content: center;
        img {
            @extend %resImg-2cols;
            height: 300px;
            @include media-breakpoint-up(md) {
                height: 170px;
            }
            @include media-breakpoint-up(lg) {
                height: 201px;
            }
        }
        &-link {
            display: block;
            text-decoration: none;
            @include panel-hover;
            &:hover {
                text-decoration: none;
                width: 100%;
                z-index: 999;
            }
        }
    }
    &__text {
        padding: 20px;
        background: white;
    }
}

.faq-item-accordion {
    margin: 50px 0;
}

.accordionV2 {
    @extend .list-group;
    >li {
        @extend .list-group-item;
        border: 1px solid $umassglobal-lightest-gray;
        padding: 0;
        transition: all 0.5s ease-in;
        &:first-child {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        &:last-child {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        button {
            border: none;
            cursor: pointer;
            font-weight: 700;
            outline: none;
            position: relative;
            text-align: left;
            width: 100%;
            overflow: hidden;
            padding: 16px;
            height: auto;
            text-overflow: unset;
            white-space: normal;
            display: flex;
            justify-content: space-between;
            background: transparent;
            .icon {
                background: transparent;
                font-size: $font-size-pica;
                position: inherit;
                align-self: center;
                width: auto;
                height: auto;
                line-height: initial;
            }
            &:hover {
                background: $umassglobal-lightest-gray;
                .icon {
                    background: transparent;
                }
            }
        }
        &.open {
            display: block;
            .icon {
                transform: scale(1, -1);
            }
            &:first-of-type button {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            button {
                background: $umassglobal-lightest-gray;
            }
        }
    }
    &__section {
        &-wrapper {
            display: none;
            padding: 30px;
            @media print {
                display: block !important;
                padding: 0 30px;
            }
        }
    }
}

.icon {
    &__large {
        .icon {
            font-size: 2em;
            margin-right: 8px;
        }
    }
}

.skip {
    position: absolute;
    top: -1000px;
    left: -1000px;
    height: 1px;
    width: 1px;
    text-align: left;
    overflow: hidden;
    &:focus,
    &:hover {
        left: 0;
        top: 0;
        height: auto;
        overflow: visible;
        position: static;
        width: 100%;
    }
    @include media-breakpoint-down(md) {
        display: none;
    }
}

// TODO: Please remove from templates and use bootstrap utility classes m-0 and/or p-0
//.reset-margin {
//    margin: 0 !important;
//}

//.reset-padding {
//    padding: 0 !important;
//}

.font-12 {
    font-size: $font-size-bourgeois;
}
// TODO: Please remove from templates and use bootstrap utility class clearfix
//.clear-both {
//    clear: both;
//}

.people-finder__result {
    &.card__faculty-column {
        background: white;
        margin: 0;
        padding: 0;
        position: relative;
        border: 1px solid $umassglobal-extraLight-gray;
        .card__faculty {
            background: transparent;
            display: flex;
            height: 100%;
            flex-wrap: wrap;
            align-content: flex-start;
            .card__image {
                width: 100%;
                margin: 0;
                flex: none;
                height: auto;
            }
        }
        .card__faculty-text {
            border: none;
            border-bottom: 2px solid transparent;
            &:after {
                display: none;
            }
        }
        &.BusinessandProfessionalStudies {
            &:hover {
                .card__faculty .card__faculty-text {
                    border-bottom: 2px solid $umassglobal-business-color;
                }
            }
        }
        &.ArtsandSciences {
            &:hover {
                .card__faculty .card__faculty-text {
                    border-bottom: 2px solid $umassglobal-arts-and-sciences-color;
                }
            }
        }
        &.NursingandHealthProfessions {
            &:hover {
                .card__faculty .card__faculty-text {
                    border-bottom: 2px solid $umassglobal-nursing-and-health-professions-color;
                }
            }
        }
        &.Education {
            &:hover {
                .card__faculty .card__faculty-text {
                    border-bottom: 2px solid $umassglobal-education-color;
                }
            }
        }
        &.ExtendedEducation {
            &:hover {
                .card__faculty .card__faculty-text {
                    border-bottom: 2px solid $umassglobal-extended-education-color;
                }
            }
        }
    }
}

#annBar {
    background: $umassglobal-lightest-gray;
    color: $umassglobal-primary-color;
    font-weight: bold;
    padding: 10px 0;
    @include media-breakpoint-down(md) {
        padding: 30px 10px 10px;
        position: relative;
    }
    .container {
        display: flex;
        align-items: center;
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }
    #close {
        font-size: $font-size-columbian;
        font-weight: bold;
        padding: 0 20px;
        margin-right: 20px;
        border-right: 1px solid $umassglobal-lighter-gray;
        color: black;
        cursor: pointer;
        @include media-breakpoint-down(md) {
            position: absolute;
            right: 0;
            border: none;
            margin: 0;
            top: 0;
        }
    }
    p,
    a {
        margin: 0;
        padding: 0;
        font-size: $font-size-longPrimer;
        @include media-breakpoint-up(md) {
            font-size: $font-size-pica;
        }
    }
    p {
        >i {
            margin-right: 10px;
        }
        @include media-breakpoint-down(md) {
            text-align: center;
            margin-bottom: 8px;
        }
    }
    a {
        margin-left: 10px;
        i {
            padding-left: 5px;
            color: black;
        }
    }
}

%hoverActiveState {
    background: hoverColor($umassglobal-secondary-color);
    color: #fff;
    border-bottom: 2px solid #fff;
}

//SIDE BY SIDE TABS
div#sbs-tabs {
    div#tabs-wrapper {
        background: $umassglobal-darker-gray;
        width: 100%;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        @media screen and (max-width: 959px) {
            overflow-x: scroll;
        }
        .item {
            padding: 10px;
            color: $umassglobal-white;
            border-bottom: 2px solid $umassglobal-darker-gray;
            border-right: 1px solid $umassglobal-lighter-gray;
            box-sizing: border-box;
            cursor: pointer;
            display: flex;
            align-items: center;
            min-width: 178px;
            justify-content: center;
            text-align: center;
            &:hover {
                @extend %hoverActiveState;
            }
            &:last-child {
                border-right: none;
            }
            @media screen and (min-width: 1024px) {
                padding: 50px 0;
                font-size: $font-size-pica;
            }
            &.active {
                @extend %hoverActiveState;
            }
        }
    }
}

#sbs-content {
    background: #103869;
    overflow: hidden;
    position: relative;
    .content-item {
        box-sizing: border-box;
        display: none;
        &.active {
            display: block;
            @media screen and (min-width: 1024px) {
                display: grid;
                grid-template-columns: 50% 50%;
            }
        }
        .content-text {
            box-sizing: border-box;
            padding: 30px;
            h3 {
                margin-bottom: 30px;
            }
            h3,
            p {
                color: #fff;
            }
            a {
                color: #fff;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    div.content-img {
        width: 100%;
        text-align: center;
        @media screen and (min-width: 1024px) {
            padding-bottom: 0;
        }
        @media screen and (max-width: 768px) {
            padding-bottom: 0;
        }
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;

        }
    }
}

.faculty__row--few {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .people-finder__result.card__faculty-column {
        width: 23%;
        margin: 0 22px 30px 0;
        border: 1px solid $umassglobal-extraLight-gray;
        @include media-breakpoint-down(md) {
            margin: 0 0 30px 0;
            width: 100%;
        }
    }
}

.section.related-information .tabs__wrapper.related-information__tabs.single-tab .tabs__content.active {
    overflow: visible;
}

section.section.schools {
    .subheading.schools__container-header {
        h3 {
            color: $umassglobal-primary-color !important;
        }
    }
}

.section__content {
    h3 {
        &.subheading {
            color: $umassglobal-primary-color !important;
        }
    }
}

.current-students-resources {
    border-top: 1px solid $umassglobal-extraLight-gray;
}

img.my-umass-global {
    width: 24px;
    margin-right: 5px;
}

.cta-snippet {
    .steps__links {
        li {
            a.btn {
                text-align: center;
            }
        }
    }
}

.article {
    img {
        display: block !important;
        max-width: 100% !important;
        height: auto !important;
    }
}

.linkcollection__links {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    span {
        &:after {
            font-size: $font-size-english;
        }
    }
    @include media-breakpoint-down(md) {
        width: 100%;
        flex-wrap: wrap;
    }
    .item {
        margin-right: 20px;
        display: flex;
        img {
            width: 24px;
            height: 20px;
            margin: 5px 5px 0 0;
        }
        &:last-child {
            margin-right: 0;
        }
        span {
            margin-right: 5px;
        }
    }
}

/* PROGRAMS 2 COLS*/

.programs-2-col,
.programs-1-col {
    margin: 30px 0;
    @media only screen and (min-width: 812px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 50px;
    }
    .col {
        @media only screen and (max-width: 375px) {
            margin-bottom: 50px;
        }
        @media only screen and (min-width: 1024px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
        h6 {
            @media only screen and (min-width: 1024px) {
                margin-top: 0;
            }
        }
        .image {
            margin-right: 20px;
            width: 100%;
            height: auto;
            @media only screen and (min-width: 1024px) {
                width: 252px;
                height: 175px;
            }
            img {
                object-fit: cover;
                width: 100% !important;
                max-width: 100%;
                height: 100% !important;
            }
        }
    }
}

.programs-1-col {
    h5 {
        font-size: $font-size-columbian;
        line-height: 1.2;
    }
    @media only screen and (min-width: 812px) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 50px;
    }
    .col {
        .image {
            @media only screen and (min-width: 1024px) {
                width: 500px;
                height: auto;
            }
        }
    }
}


/* PROGRAMS 2 COLS*/


/* loading dots */

.stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 2rem 0;
    margin: 0 -5%;
    overflow: hidden;
}

div.icon__loading {
    display: block;
    padding: 30px 0;
    width: 100%;
    p {
        font-family: $font-family-sans-serif;
        font-weight: 900;
        text-align: center;
        padding: 0;
        margin: 0;
    }
    &.fadeout {
        display: none;
    }
}


/**
 * ==============================================
 * Dot Falling
 * ==============================================
 */




 @mixin dotsFalling($color, $name) {
    .dot-falling {
        position: relative;
        left: -9999px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: $color;
        color: $color;
        box-shadow: 10px 10px 10px 10px black;
        animation: dotFalling#{$name} 1s infinite linear;
        animation-delay: .1s;
    }
    
    .dot-falling::before,
    .dot-falling::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
    }
    
    .dot-falling::before {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: $color;
        color: $color;
        animation: dotFallingBefore#{$name} 1s infinite linear;
        animation-delay: 0s;
    }
    
    .dot-falling::after {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: $color;
        color: $color;
        animation: dotFallingAfter#{$name} 1s infinite linear;
        animation-delay: .2s;
    }
    
    @keyframes dotFalling#{$name} {
        0% {
            box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
        }
        25%,
        50%,
        75% {
            box-shadow: 9999px 0 0 0 $color;
        }
        100% {
            box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
        }
    }
    
    @keyframes dotFallingBefore#{$name} {
        0% {
            box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
        }
        25%,
        50%,
        75% {
            box-shadow: 9984px 0 0 0 $color;
        }
        100% {
            box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
        }
    }
    
    @keyframes dotFallingAfter#{$name} {
        0% {
            box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
        }
        25%,
        50%,
        75% {
            box-shadow: 10014px 0 0 0 $color;
        }
        100% {
            box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
        }
    }
}


.degree-finder{
    .icon__loading{
        @include dotsFalling($umassglobal-secondary-color, one);
    }
}

.hero-program-finder{
    .icon__loading{
        @include dotsFalling(white, two);
     }
}


.video-responsive {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    position: relative;
    margin: 30px 0;
}

.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

//PARTERNS PORTAL FORM AND LOGOS FIXES
.partner_portal_main_content {
    .benefits {
        .beneficts__mention {
            margin: 0 auto;
        }
        .beneficts__logo {
            img {
                display: block;
                width: 50%;
                margin: 0 auto;
                padding-top: 30px;
            }
        }
    }
}

//img[src*="https://secure.adnxs.com"] {
//    display: none;
//}



.be-ix-link-block .be-related-link-container{margin-bottom:20px}
.be-ix-link-block .be-related-link-container .be-label{margin:0;color:#4b4f54!important;font-weight:700;font-family:"Open Sans",sans-serif;font-size:14px;letter-spacing:.03em;text-transform:uppercase}
.be-ix-link-block .be-related-link-container .be-list{display:inline-block;list-style:none;margin:0;padding:0;font-size:14px}
.be-ix-link-block .be-related-link-container .be-list .be-list-item{display:inline-block;margin-right:20px}
.be-ix-link-block .be-related-link-container .be-list .be-list-item:last-child{margin-right:0}
@media (max-width: 767px) {
.be-ix-link-block .be-related-link-container .be-label{width:100%;margin-bottom:8px;line-height:1.45}
.be-ix-link-block .be-related-link-container .be-list{display:block;width:100%;margin-bottom:13px}
.be-ix-link-block .be-related-link-container .be-list .be-list-item{display:block;margin-right:0;font-size:16px;line-height:50px}
}
@media (min-width: 768px) {
.be-ix-link-block .be-related-link-container{display:flex}
.be-ix-link-block .be-related-link-container .be-label{display:inline-block;margin-right:20px;flex-basis:125px;flex-grow:0;flex-shrink:0}
.be-ix-link-block .be-related-link-container .be-list{width:auto}
}

ul.checks {
    list-style-type: none;
}

//Time Cost Calculator

.tcc-header-image {
    max-width: 130px;
    max-height: 130px;
    align-self: center;
    margin-right: 60px;
}

.tcc-header p {
    font-size: $font-size-pica;
}

.tcc-title {
    font-size: 4.1rem;
}

//Lightbox for YT Videos
.newLightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}   

.newLightbox-content {
    width: 100%;
    max-width: 964px;
    height: 58%;
}

.newLightbox iframe {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.newLightbox iframe:focus {
    outline: none;
}

.newLightbox-content .lightbox-close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 1.5em;
    cursor: pointer;
    color: $umassglobal-white;
}

.forms__request-divider {
    overflow: hidden;
}

@media (max-width: 769px) {
    .forms__request-divider .tcpa_statement *:not(.mobile-only) {
        display: none;
    }
}

.ex-tab-pane {
    display: none;
}

.ex-tab-pane.active {
    display: block;
}