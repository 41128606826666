.thumbnails {
    background-position: 50% 0;
    background-repeat: repeat-y;
    padding-bottom: 30px;
    .section__description {
        margin-bottom: 50px;
    }
    &__description {
        text-align: center;
        max-width: 780px;
        margin: 0 auto 45px;
    }
    &__angle {
        filter: drop-shadow( 0 0 5px rgba(0, 0, 0, .25));
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: auto;
    }
    &__thumb {
        padding-bottom: 10px;
        padding-top: 10px;
        height: 360px;
        margin-bottom: 15px;
        @media screen and (max-width: 767px) {
            // height: 300px;
            padding-bottom: 0;
        }
    }
    &__image {
        color: $umassglobal-text-gray;
        display: block;
        // overflow: hidden;
        position: relative;
        @include panel-hover;
        width: 100%;
        height: 360px;
        margin: 0 auto;
        @include media-breakpoint-down(md) {
            height: 350px;
            margin: 0 auto;
        }
        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 360px;
            @media screen and (max-width: 767px) {
                height: 350px;
            }
        }
        &--shadow {
            fill: $umassglobal-white;
            opacity: .9;
        }
        &--cta {
            transition: fill $transition-hover-speed;
            fill: $umassglobal-tertiary-color;
        }
        .icon {
            bottom: 1px;
            font-size: $font-size-maximus;
            line-height: 1;
            position: absolute;
            right: 22px;
            text-decoration: none;
        }
        &:hover {
            text-decoration: none;
            .thumbnails__image--cta {
                fill: $umassglobal-tertiary-color-d50;
            }
            .icon {
                color: $umassglobal-text-gray;
            }
            .thumbnails {
                &__copy * {
                    color: $umassglobal-white;
                }
                &__title * {
                    color: $umassglobal-white;
                    text-decoration: none;
                }
            }
        }
        //--------------------------
        &.card {
            top: 50%;
            left: 50%;
            width: 360px;
            height: 360px;
            margin: -150px;
            border: none;
            @media screen and (max-width: 767px) {
                width: 100%;
                height: 100%;
                position: relative;
                top: 0;
                left: 0;
                margin: 0;
                float: none;
                perspective: none;
            }
            .content {
                position: absolute;
                width: 100%;
                height: 100%;
                //box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
                transition: transform 1s;
                transform-style: preserve-3d;
            }
            &:hover {
                .content {
                    transform: rotateY( 180deg);
                    transition: transform 1.25s; //0.5s;
                    .front {
                        .thumbnails__text {
                            display: none;
                        }
                    }
                }
            }
            .front,
            .back {
                position: absolute;
                height: 100%;
                width: 100%;
                background: white;
                line-height: 5;
                text-align: center;
                font-size: 6rem;
                border-radius: 5px;
                backface-visibility: hidden;
                .thumbnails__text {
                    width: 100%;
                    height: 35%;
                    background: transparent linear-gradient(0deg, #000000BF 0, #10101082 57%, #80808000 100%) 0 0 no-repeat padding-box;
                    top: auto;
                    padding: 45px 15px 25px;
                    @media screen and (max-width: 767px) {
                        bottom: 0 !important;
                    }
                }
            }
            .back {
                //background: #03446A;
                color: white;
                transform: rotateY( 180deg);
                .thumbnails__text {
                    width: 100%;
                    height: 100%;
                    background: #00000081 0% 0% no-repeat padding-box;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    padding: 45px 30px 25px;
                }
            }
        }
        //-------------------------------
    }
    &__container {}
    &__link {
        bottom: 0;
        content: " ";
        border-bottom: 800px solid $umassglobal-tertiary-color;
        border-right: 800px solid transparent;
        height: 0;
        right: 30px;
        opacity: .5;
        position: absolute;
        width: 0;
    }
    &__title {
        * {
            color: $umassglobal-white;
            font-size: $font-size-h3;
            font-weight: normal;
            line-height: 1.1;
            margin-bottom: 10px;
            font-family: $font-family-serif;
            text-decoration: none;
            text-align: left;
            padding: 0.5rem 0;

            @include media-breakpoint-only(lg) {
                line-height: 22px;
                margin-bottom: 12px;
            }
        }
    }
    &__shape {}
    &__text {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        padding: 45px 15px 25px;
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: $font-size-small;
        line-height: 1.4;
        width: 70%;
        text-align: left;
        color: $umassglobal-white;
        word-break: break-word;
        @include media-breakpoint-up(xl) {
            padding: 0 15px;
        }
        @include media-breakpoint-only(lg) {
            &:before {
                content: ' ';
                shape-outside: polygon(0 0, 100% 0, 100% 100%);
                height: 230px;
                width: 273px;
                float: right;
            }
        }
    }
    &__copy p {
        overflow: auto;
    }
}