.campus-detail {


    .container {

        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
        }
    }

    &__image {
        @include media-breakpoint-down(md) {
            order: -1;
        }
    }

    .linkcollection {
        @include media-breakpoint-down(md) {
            &:after {
                content: none;
            }
        }
    }

}