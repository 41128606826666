.search-results {
    .container {
        max-width: 1005px;
        @include media-breakpoint-up(md) {
            padding: 0;
        }
        @include media-breakpoint-up(md) {
            padding: 0 30px;
        }
        .search-results__search-field {
            color: white;
        }
    }
    &__notfound {
        display: block;
        max-width: none;
        margin-bottom: 50px;
        opacity: 1;
        position: relative;
        hr {
            margin: 45px 0;
        }
        .degree-finder__header {
            * {
                font-size: 3.2rem;
                font-weight: normal;
                line-height: $line-height-straight;
                margin-bottom: 50px;
                .searchterm {
                    font-weight: bold;
                }
                strong {
                    font-weight: bold;
                }
            }
        }
        p strong {
            color: $umassglobal-gray;
        }
        &-message {
            max-width: 750px;
        }
    }
    &__popular {
        margin: 50px 0;
        &-row {
            margin: 20px -8px;
        }
    }
    &__popularterm {
        color: $body-bg;
        text-decoration: none;
        padding: 8px;
        img {
            transition: opacity $transition-hover-speed;
            opacity: .4;
        }
        .card__image {
            background: $umassglobal-text-gray;
            position: relative;
            &-link {
                &:hover {
                    img {
                        opacity: .2;
                    }
                }
            }
        }
        p {
            display: flex;
            color: $body-bg;
            font-weight: bold;
            font-size: $font-size-columbian;
            line-height: $line-height-base;
            text-align: center;
            position: absolute;
            text-decoration: none;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            padding: 0 20px;
            @include media-breakpoint-only(md) {
                font-size: $font-size-english;
                line-height: $line-height-base;
            }
            @include media-breakpoint-down(md) {
                font-size: $font-size-pica;
                line-height: $line-height-base;
            }
        }
    }
    &__search {
        &-wrapper {
            background-color: $umassglobal-secondary-color;
            color: white;
            @include media-breakpoint-up(md) {
                .container {
                    padding-bottom: 32px;
                    padding-top: 32px;
                }
            }
            @include media-breakpoint-down(md) {
                .container {
                    padding: 25px;
                }
            }
        }
        &-field {
            appearance: none;
            box-shadow: none;
            border-radius: 0;
            border: none;
            border-bottom: 1px solid white;
            background-color: transparent;
            font-size: 3.2rem;
            font-weight: normal;
            line-height: $line-height-base;
            padding: 0 0 0 38px;
            height: 46px;
            @include media-breakpoint-down(md) {
                font-size: $font-size-columbian;
                line-height: $line-height-base;
                height: 29px;
                padding: 0 0 0 22px;
            }
            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: white;
                opacity: 1;
                /* Firefox */
            }
        }
        button {
            border: none;
            color: white;
            position: absolute;
            left: 0;
            top: 0;
            height: 46px;
            font-size: $font-size-maximus;
            padding: 0;
            line-height: $line-height-base;
            .icon {
                line-height: $line-height-base;
            }
            @include media-breakpoint-down(md) {
                font-size: $font-size-english;
                height: 29px;
                line-height: $line-height-base;
            }
        }
        label {
            position: absolute;
            left: -1000px;
        }
        .form-group {
            position: relative;
        }
    }
    &__filter {
        .select2-container {
            width: 100%;
        }
        @include media-breakpoint-down(md) {
            margin: 15px 0;
        }
        .nav-tabs {
            display: inline-block;
        }
        @include media-breakpoint-up(md) {
            display: inline-block;
            flex: 1 1 auto;
            margin-left: 40px;
            &:first-child {
                margin-left: 0;
            }
        }
        @include media-breakpoint-only(md) {
            margin: 10px 20px;
            &:first-child {
                margin: 10px 20px;
            }
        }
        &-container {
            background-color: $umassglobal-off-white-color;
            border-bottom: 1px solid $umassglobal-lightest-gray;
            @include media-breakpoint-down(md) {
                border-bottom: none;
                margin-bottom: 5px;
                .container {
                    padding: 25px;
                }
            }
        }
        &-wrapper {
            min-height: 45px;
            position: relative;
            max-width: 810px;
            width: 100%;
            margin: 0 auto;
            @include media-breakpoint-down(md) {
                position: relative;
            }
            .container {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        .nav-pills {
            display: flex;
            height: 52px;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            @include media-breakpoint-only(md) {
                justify-content: flex-start;
            }
        }
        label {
            display: block;
            margin-bottom: 12px;
        }
        @include media-breakpoint-down(md) {
            margin-right: 0;
        }
    }
    &__filters {
        padding: 30px 0;
        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
            flex-direction: row;
        }
        @include media-breakpoint-only(md) {
            margin: 0 -20px;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: 20px 0;
        }
        @include media-breakpoint-down(md) {
            padding: 0;
            label {
                margin-bottom: 0;
            }
        }
    }
    &__header {
        position: absolute;
        text-indent: -10000px;
    }
    &__label {
        color: $umassglobal-text-gray;
        font-family: $font-family-sans-serif;
        font-size: $font-size-small;
        font-weight: normal;
        line-height: $line-height-small;
        padding: 20px 0;
        strong {
            color: $umassglobal-primary-color;
            font-weight: bold;
        }
    }
    &__name {
        font-weight: normal;
        margin-top: 0;
        margin-bottom: 12px;
        line-height: 30px;
        text-decoration: none;
        .highlight {
            display: inline-block;
            background: rgba(255, 206, 7, .5);
            padding: 0 2px;
        }
    }
    &__description {
        color: $umassglobal-gray;
        margin-bottom: 12px;
        .highlight {
            display: inline-block;
            background: rgba(255, 206, 7, .5);
            padding: 0 2px;
        }
    }
    &__url {
        color: $umassglobal-primary-color;
        font-size: $font-size-small;
        line-height: $line-height-small;
        text-decoration: underline;
    }
    &__result {
        text-decoration: none;
        .search-results__name {
            text-decoration: none;
        }
        &:hover {
            text-decoration: none;
            .search-results__name {
                color: $umassglobal-text-gray;
                text-decoration: none;
            }
        }
        background-color: $umassglobal-white;
        border: 1px solid transparent;
        border-radius: $panel-border-radius;
        @include panel-variant($umassglobal-extraLight-gray, $umassglobal-darker-gray, $umassglobal-off-white-color, $umassglobal-extraLight-gray);
        @include panel-hover;
        cursor: pointer;
        display: flex;
        position: relative;
        flex-direction: row;
        margin-bottom: 30px;
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
        &.arts-sciences {
            &:after {
                border-bottom-color: $umassglobal-arts-and-sciences-color;
                border-right-color: $umassglobal-arts-and-sciences-color;
            }
        }
        &.business {
            &:after {
                border-bottom-color: $umassglobal-business-color;
                border-right-color: $umassglobal-business-color;
            }
        }
        &.education {
            &:after {
                border-bottom-color: $umassglobal-education-color;
                border-right-color: $umassglobal-education-color;
            }
        }
        &.nursing {
            &:after {
                border-bottom-color: $umassglobal-nursing-and-health-professions-color;
                border-right-color: $umassglobal-nursing-and-health-professions-color;
            }
        }
        &.ext-education {
            &:after {
                border-bottom-color: $umassglobal-extended-education-color;
                border-right-color: $umassglobal-extended-education-color;
            }
        }
        &-content {
            height: 100%;
            padding: 30px;
        }
    }
}