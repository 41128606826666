// TODO:  ̬ NO CLUE WHAT THIS IS. PLEASE NOTIFY ME IF NECESSARY - C. Wilden
//iframe[src^="https://secure.img-cdn.mediaplex.com/"] {
//    margin-top: -10000px;
//    z-index: -9;
//    display: block;
//}
// ^ NO CLUE WHAT THIS IS. PLEASE NOTIFY ME IF NECESSARY - C. Wilden

.footer {
    border-top: 1px solid #002d5d42;
    background: white;
    color: $umassglobal-gray;
    font-size: $font-size-smallPica;
    overflow: hidden;
    padding: 70px 0 0 0;
    @media print {
        display: none;
    }
    @include media-breakpoint-down(md) {
        padding: 40px 0 0 0;
    }
    @include media-breakpoint-up(xl) {
        .container {
            padding: 0 25px;
            width: 100%;
        }
    }
    .subheading {
        color: $umassglobal-white;
        font-weight: 700;
        font-size: $font-size-smallPica;
        a {
            color: $umassglobal-white;
        }
    }
    a {
        color: $umassglobal-secondary-color-d50;
        text-decoration: none;
        &:hover {
            color: $umassglobal-primary-color;
            font-weight: bold;
            text-decoration: none;
        }
    }
    .btn-link {
        text-decoration: underline;
        text-decoration-color: $umassglobal-primary-color;
    }
    h3 {
        color: $umassglobal-gray;
        font-size: $font-size-longPrimer;
        font-weight: 700;
        line-height: 23px;
        margin-top: 0;
        margin-bottom: 5px;
    }
    address {
        color: $umassglobal-darker-gray;
        font-size: $font-size-longPrimer;
        letter-spacing: 0.03em;
        line-height: 23px;
        margin-bottom: 30px;
        h3 {
            margin-bottom: 0;
        }
        @include media-breakpoint-down(md) {
            width: 48%;
            margin: 0 0 12px;
            letter-spacing: 0;
        }
        @include media-breakpoint-up(lg) {
            max-width: 200px;
        }
    }
    &__logo {
        display: block;
        margin: 15px 0;
        img {
            height: auto;
            width: 100%;
            max-width: 200px;
        }
        @include media-breakpoint-down(md) {
            margin: 25px 0;
            width: 45%;
        }
        @include media-breakpoint-only(md) {
            margin: 35px 0;
            width: 45%;
        }
    }
    &__social {
        &-link {
            font-size: $font-size-maximus;
            &-text {
                display: none;
            }
            .footer & {
                color: $umassglobal-secondary-color-d50;
                &:hover {
                    color: $umassglobal-primary-color;
                }
            }
        }
        @include media-breakpoint-down(md) {
            margin-bottom: 20px;
            width: 100%;
        }
        @media screen and (min-width: 960px) {
            margin-bottom: 75px;
            padding-bottom: 40px;
        }
    }
    &__contact {
        .subheading {
            color: $umassglobal-darker-gray;
            font-size: $font-size-longPrimer;
            text-transform: none;
            margin: 0;
            font-weight: bold;
            overflow: auto;
        }
        &-links {
            background: $umassglobal-extraLight-gray;
        }
    }
    .container {
        position: relative;
        @include media-breakpoint-down(md) {
            padding: 0 30px;
        }
    }
    &__divider {
        border-left: 2px solid #002d5d1f;
        left: -72px;
        height: calc(100% - 8px);
        position: absolute;
        top: 8px;
        width: 0;
        @include media-breakpoint-down(md) {
            border-left: 0;
            border-top: 2px solid #002d5d42;
            height: 0;
            left: 0;
            width: 100vw;
            top: 0;
        }
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
    &__contact {
        position: relative;
        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 30px;
            padding-top: 30px;
            flex-wrap: wrap;
        }
    }
    &__landing {
        color: #959595;
        border: none;
        @include media-breakpoint-only(md) {
            padding: 30px 0;
        }
        p {
            margin-bottom: 0;
        }
        address {
            max-width: none;
            margin-bottom: 15px;
            @include media-breakpoint-down(md) {
                font-size: $font-size-smallPica;
                line-height: $line-height-halved;
                width: auto;
                margin: 0;
                letter-spacing: 0;
            }
        }
        &-address {
            margin-left: 90px;
            color: $umassglobal-text-gray;
            margin-bottom: 50px;
            @include media-breakpoint-down(md) {
                flex: 1 1 50%;
                font-size: $font-size-smallPica;
                line-height: $line-height-halved;
                margin-left: 25px;
            }
        }
        &-copyright {
            color: $umassglobal-text-gray;
            font-weight: bold;
            @include media-breakpoint-down(md) {
                margin-bottom: 12px;
                font-size: $font-size-longPrimer;
                line-height: $line-height-base;
                white-space: nowrap;
            }
        }
        .footer__logo {
            max-width: 270px;
            @include media-breakpoint-down(md) {
                flex: 1 1 50%;
                max-width: 100%;
            }
            @include media-breakpoint-only(md) {
                width: 50%;
                margin: 0 auto;
            }
        }
        &-left {
            display: flex;
            @media screen and (max-width: 768px) {
                flex-wrap: wrap;
                .footer__logo {
                    width: 34%;
                }
            }
            @media screen and (min-width: 1024px) {
                flex-wrap: wrap;
                .footer__logo {
                    width: 50%;
                }
            }
        }
        &-right {
            align-items: center;
            border-left: 2px solid $umassglobal-darker-gray;
            display: flex;
            height: 66px;
            justify-content: flex-end;
            @include media-breakpoint-down(md) {
                border-left: none;
                display: block;
                height: auto;
                .nav__contact {
                    border-top: 2px solid $umassglobal-darker-gray;
                    margin-top: 20px;
                    padding-top: 12px;
                }
            }
            @include media-breakpoint-only(md) {
                height: auto;
                align-items: flex-start;
                justify-content: center;
                .nav__contact {
                    text-align: left;
                    height: auto;
                    line-height: 30px;
                    li {
                        display: block;
                    }
                }
            }
        }
    }
}

.partner_footer {
    @include media-breakpoint-down(md) {
        padding: 0;
        .container {
            padding: 0;
            .footer__landing-left {
                padding: 0 30px;
            }
        }
    }
    .login_box {
        border: 1px solid $umassglobal-extraDark-gray;
        background-color: $umassglobal-extraDark-gray;
        padding: 22px 28px;
        margin-top: 20px;
        @include media-breakpoint-down(md) {
            padding: 15px;
            margin: 40px 15px;
            height: 90px;
        }
        @media (max-width: 340px) {
            padding: 16px;
            margin: 20px 5px;
            p {
                font-size: 1rem;
            }
        }
        p {
            float: left;
            width: 55%;
            @include media-breakpoint-down(md) {
                width: 60%;
            }
        }
        .btn-default {
            background-color: $umassglobal-lightest-gray;
            color: black;
            font-weight: bold;
            float: right;
            padding: 2px 20px;
            height: 35px;
            @include media-breakpoint-down(md) {
                min-width: 95px;
            }
        }
    }
    .nav__contact {
        float: left;
        >li {
            &:first-child {
                margin: 0 6px 0 0;
                padding-left: 0;
            }
        }
        a {
            color: $umassglobal-text-gray;
            &:hover {
                color: black;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .footer__logo {
            width: 100%;
            padding: 0 12vw;
            max-width: 100%;
            margin: 50px 0 30px 0;
            flex: auto;
        }
        .footer__landing-address,
        .footer__landing-copyright {
            text-align: center;
            width: 100%;
            margin-left: 0;
        }
        .footer_links {
            padding: 0;
            background-color: black;
        }
        .nav__contact {
            width: 100%;
            text-align: center;
            margin: 25px 0;
            line-height: initial;
            height: initial;
            flex-wrap: wrap;
            li {
                text-align: center;
                margin-bottom: 10px;
                padding-right: 15px;
                width: 100%;
                &:first-child {
                    margin-bottom: 10px;
                }
            }
        }
        .partner_footer .nav__contact {
            width: 100%;
            float: none;
        }
    }
}

.header__landing .header {
    box-shadow: 0 0 6px #00000063;
    border-bottom: 1px solid $umassglobal-lightest-gray;
}