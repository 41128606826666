.sticky-nav {
    position: sticky;
    top: 130px;
    z-index: 1000;
}

.scrollspy-section {
    position: relative;
}

.affix~.container-fluid {
    position: relative;
    top: 150px;
}

.affix {
    top: 60px;
    width: 100%;
    z-index: 9999 !important;
}

.nav-link {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 768px) {
    .sticky-nav {
        top: 95px;
    }

    #current-section-title {
        font-size: 1.5rem;
        font-weight: normal;
        margin-left: 15px;
        margin: 5px 0px;
        justify-content: center;
    }

    .scrollspy-dynamic {
        height: 250px;
    }

    .nav-pills {
        flex-direction: column;
        width: 100%;
    }

    .nav-link {
        width: 100%;
    }
    
    .dropdown-menu.show {
        width: 320px !important;
    }

    .dropdown-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        color: #ffffff;

        width: 100%;
    }

    .progress-container {
        position: relative;
        width: 100%;
        height: 10px;
        background-color: #e0e0e0;
    }

    .progress-bar {
        height: 100%;
        background-color: #007bff;
        width: 0;
    }

    #progress-points-container {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        pointer-events: none;
    }

    .progress-point {
        width: 10px;
        height: 10px;
        background-color: #ffffff;
        border: 2px solid #007bff;
        border-radius: 50%;
        pointer-events: none;
    }
}