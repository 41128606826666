//@use "colors";

nav {
    ul,
    ol {
        @extend .list-unstyled;
    }
}

a#navPhoneButton {
    .icon {
        &:after {
            font-size: $font-size-pica;
        }
    }
}

.nav {
    @media print {
        display: none;
    }
    a {
        text-decoration: none;
    }
    .btn-link {
        text-decoration: underline;
        text-decoration-color: $umassglobal-primary-color;
    }
    &__dropdown {
        &-menu {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: $zindex-dropdown;
            display: none;
            float: left;
            list-style: none;
            font-size: $font-size-base;
            text-align: left;
            background-color: $dropdown-bg;
            background-clip: padding-box;
            @include media-breakpoint-up(xl) {
                height: calc(100vh - 119px);
                left: 100%;
                top: 0;
                width: 100%;
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch;
            }
            @include media-breakpoint-up(lg) {
                border: 1px solid $table-border-color;
                @include box-shadow(0 0 12px rgba(0, 0, 0, 0.25));
            }
        }
    }
    &__global {
        @media print {
            display: none;
        }
        &-wrapper {
            @include media-breakpoint-up(xl) {
                margin-top: 0;
                transition: left 0.5s;
                position: relative;
                left: 0;
            }
        }
        @include media-breakpoint-up(xl) {
            @include box-shadow(0 0 12px rgba(0, 0, 0, 0.25));
            &.nav__subnav--open &-wrapper {
                left: -100%;
                @include media-breakpoint-down(md) {
                    left: -100%;
                }
            }
            background: $umassglobal-off-white-color;
            height: calc(100vh - 113px);
            margin-top: 1px;
            overflow: hidden;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            position: absolute;
            right: 0;
            top: 100%;
            width: 375px;
            z-index: -1;
            transition: all 0.5s !important;
            .navbar-nav {
                margin: 0;
            }
            .keyboard & {
                height: 100vh;
            }
        }
        @include media-breakpoint-down(md) {
            left: auto;
            right: auto;
            width: 100%;
        }
        @include media-breakpoint-up(lg) {
            float: right;
            &.nav__search--open {
                position: relative;
            }
        }
        .nav>li {
            font-size: $font-size-med;
            position: static;
            @include media-breakpoint-up(md) {
                font-size: $font-size-large;
            }
            &.mobile {
                display: none;
                @include media-breakpoint-up(xl) {
                    display: inherit;
                }
            }
            >button,
            >a {
                &:hover {
                    color: $umassglobal-primary-color;
                }
                @include media-breakpoint-up(xl) {
                    padding: 14px 46px 14px 20px;
                    &:focus {
                        color: $umassglobal-text-gray;
                    }
                }
                @include media-breakpoint-up(md) {
                    padding: 25px 76px 25px 44px;
                }
                >.icon {
                    display: none;
                    @include media-breakpoint-up(xl) {
                        display: inline-block;
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                        text-decoration: none;
                    }
                    @include media-breakpoint-up(md) {
                        font-size: $font-size-maximus;
                        right: 36px;
                    }
                }
            }
        }
    }
    &__dropdown {
        padding: 30px;
        width: 100vw;
        max-width: 1140px;
    }
    &__overview-image {
        @include media-breakpoint-up(xl) {
            display: none;
        }
        img {
            object-fit: cover;
            width: 100%;
            height: 120px;
            clip-path: polygon(100% 0%, 0% 0%, 0% 86%, 50% 100%, 100% 86%);
        }
        /*@include media-breakpoint-up(lg) {
            overflow: hidden;
            position: relative;
            &:before,
            &:after {
                bottom: 0;
                content: " ";
                position: absolute;
                border-bottom: 10px solid $prim-bg;
                border-top: 10px solid transparent;
                z-index: 3;
            }
            &:before {
                right: 50%;
                border-left: 149px solid $prim-bg;
                border-right: 149px solid transparent;
            }
            &:after {
                left: 50%;
                border-left: 149px solid transparent;
                border-right: 149px solid $prim-bg;
            }
        }*/
        .nav__subnav-overview--small & {
            @include media-breakpoint-up(lg) {
                &:before {
                    border-left: 71px solid $umassglobal-off-white-color;
                    border-right: 71px solid transparent;
                }
                &:after {
                    border-left: 71px solid transparent;
                    border-right: 71px solid $umassglobal-off-white-color;
                }
            }
        }
    }
    &__subnav {
        padding: 0;
        .open>& {
            display: block;
        }
        .container {
            @include media-breakpoint-up(xl) {
                width: auto;
            }
            @include media-breakpoint-up(lg) {
                padding: 0;
                position: relative;
            }
        }
        &-description {
            font-size: $font-size-smallPica;
            font-weight: 300;
            line-height: 1.7;
            padding: 22px 48px;
            @include media-breakpoint-up(xl) {
                display: none;
                padding: 22px;
            }
        }
        &-overview {
            @include media-breakpoint-up(lg) {
                background-color: $umassglobal-off-white-color;
                float: right;
                width: 594px;
                padding-bottom: 100px;
                margin-bottom: -100px;
                .btn-primary-carat,
                .btn-primary {
                    display: none;
                }
            }
            @include media-breakpoint-up(xl) {
                padding: 20px 40px 100px 65px;
            }
            @include media-breakpoint-down(md) {
                padding: 15px 25px 75px 25px;
            }
            &--small {
                @include media-breakpoint-up(lg) {
                    width: 282px;
                }
            }
        }
        &-header {
            margin-bottom: 6px;
            padding: 0;
            a {
                color: $umassglobal-text-gray;
                &:hover {
                    color: $umassglobal-primary-color;
                }
            }
            .subheading {
                color: $umassglobal-text-gray;
                margin-top: 0;
            }
        }
        &-section {
            display: inline-block;
            vertical-align: top;
        }
        .footer & {
            &-section {
                width: 30%;
                &:last-child {
                    margin-right: 0;
                }
                @include media-breakpoint-down(md) {
                    display: block;
                    margin-right: 0;
                    margin-bottom: 30px;
                    width: 100%;
                }
                @media screen and (min-width: 1188px) {
                    margin-right: 17px;
                    width: 23%;
                }
            }
            &-header {
                margin-bottom: 8px;
                * {
                    //color: $umassglobal-white;
                    line-height: $line-height-base;
                    margin-bottom: 0;
                    a {
                        font-weight: 700;
                        //color: $umassglobal-white;
                        &:hover {
                            color: $umassglobal-primary-color;
                        }
                    }
                }
            }
        }
        &-mobiletitle {
            background: $umassglobal-off-white-color;
            cursor: pointer;
            padding: 10px 30px;
            @include media-breakpoint-down(md) {
                font-size: $font-size-longPrimer;
                padding: 16px 22px;
                line-height: $line-height-extra;
                .icon {
                    font-size: $font-size-columbian;
                    line-height: .9;
                    vertical-align: bottom;
                    margin-right: 9px;
                    &:after {
                        vertical-align: bottom;
                    }
                }
            }
            @include media-breakpoint-up(md) {
                border-bottom: 1px solid $umassglobal-extraLight-gray;
                padding: 28px 23px 23px 23px;
                font-size: $font-size-english;
                line-height: $line-height-base;
                .icon {
                    font-size: $font-size-columbian;
                    line-height: $line-height-straight;
                    vertical-align: bottom;
                    margin-right: 14px;
                    &:after {
                        vertical-align: bottom;
                    }
                }
            }
        }
        &-link {
            font-size: $font-size-smallPica;
            font-weight: 500;
            line-height: 2.5;
            @include media-breakpoint-down(md) {
                font-size: $font-size-pica;
                line-height: 3.1;
            }
        }
        .nav__global & {
            @include media-breakpoint-up(lg) {
                //background: $body-bg url("/images/pattern_back_left--transparent.svg") 50% 50%/100% auto no-repeat;
                left: 0;
                overflow: hidden;
                right: 0;
                z-index: -1;
            }
            &-header {
                border-bottom: 1px solid $umassglobal-lightest-gray;
                * {
                    font-size: $font-size-pica;
                    font-weight: 700;
                    text-transform: none;
                    letter-spacing: 0;
                    @include media-breakpoint-down(md) {
                        font-size: $font-size-bourgeois;
                        text-transform: uppercase;
                        margin-bottom: 6px;
                    }
                    @include media-breakpoint-up(md) {
                        font-size: $font-size-longPrimer;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                        line-height: $line-height-extra;
                    }
                }
            }
            &-sections {
                @include media-breakpoint-up(lg) {
                    padding: 30px 30px 30px 285px;
                    display: inline-block;
                }
                @include media-breakpoint-up(xl) {
                    padding: 14px 40px 0 65px;
                }
                @include media-breakpoint-down(md) {
                    padding: 15px 25px 0 25px;
                }
            }
            &-section {
                display: block;
                @include media-breakpoint-up(xl) {
                    padding: 20px 0;
                    &:first-child {
                        padding-top: 10px;
                    }
                }
                @include media-breakpoint-down(md) {
                    padding: 13px 0;
                    margin-right: 0;
                    width: auto;
                }
                @include media-breakpoint-up(md) {
                    margin-right: 0;
                    width: auto;
                }
                @include media-breakpoint-up(lg) {
                    display: inline-block;
                    margin-right: 25px;
                    width: 218px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            &-link {
                @include media-breakpoint-down(md) {
                    font-size: $font-size-pica;
                    line-height: $line-height-base;
                    margin: 25px 0;
                    &:first-child {
                        margin-top: 14px;
                    }
                    &:last-child {
                        margin-bottom: 15px;
                    }
                }
                @include media-breakpoint-up(md) {
                    font-size: $font-size-english;
                    line-height: $line-height-base;
                    letter-spacing: 0.03em;
                    margin: 30px 0;
                    &:first-child {
                        margin-top: 24px;
                    }
                    &:last-child {
                        margin-bottom: 12px;
                    }
                }
            }
            ul>li {
                >a {
                    color: $umassglobal-text-gray;
                    &:hover {
                        color: $umassglobal-primary-color;
                    }
                }
            }
        }
    }
    &__link {
        &.dropdown.open {}
        &.open {
            >a {
                color: $umassglobal-primary-color;
                font-weight: bold;
            }
        }
    }
    &__mobilebutton {
        display: none;
    }
    @include media-breakpoint-up(xl) {
        &__mobilebutton {
            border: 2px solid $umassglobal-lightest-gray;
            border-radius: 4px;
            display: block;
            font-size: $font-size-pica;
            position: absolute;
            right: 25px;
            top: 15px;
            line-height: $line-height-base;
            min-width: 0;
            padding: 6px 12px;
            height: 45px;
            width: 120px;
            .icon {
                color: $umassglobal-text-gray;
            }
            @include media-breakpoint-up(md) {
                height: 53px;
                width: 136px;
                right: 39px;
                top: 19px;
            }
            &.open {
                background: $umassglobal-gray;
                color: $umassglobal-white;
                .icon {
                    color: $umassglobal-white;
                    transform: scale(1, -1);
                }
            }
        }
        &__link {
            border-bottom: 1px solid $umassglobal-extraLight-gray;

        }
    }
    &__topnav {
        @media print {
            display: none;
        }
        a {
            text-decoration: none;
        }
        &-header {
            display: inline-block;
            vertical-align: bottom;
            * {
                color: $umassglobal-dark-gray;
                font-size: $font-size-longPrimer;
                font-weight: normal;
                line-height: 2.9;
                margin: 0;
            }
        }
        ul {
            @include list-unstyled;
            font-size: 0;
            margin: 0;
            display: inline-block;
            vertical-align: bottom;
            @include media-breakpoint-down(xl) {
                display: inline-flex;
            }
            >li {
                display: inline-block;
                padding: 0 5px;
                font-size: $font-size-smallPica;
                vertical-align: bottom;
                @include media-breakpoint-down(xl) {
                    border: none;
                    text-align: center;
                    padding: 0 5px;
                    flex: 1 1 auto;
                }
                @include media-breakpoint-down(md) {
                    flex: 1 1 50%;
                    padding: 0;
                }
                >a {
                    color: $umassglobal-text-gray;
                    &:hover {
                        color: $umassglobal-primary-color;
                    }
                    @include media-breakpoint-down(xl) {
                        display: block;
                    }
                    white-space: nowrap;
                }
            }
        }
        &-wrapper {
            background: $umassglobal-lightest-gray;
            @include media-breakpoint-up(xl) {
                .container {
                    padding: 0;
                }
            }
            .container {
                width: 100%;
            }
        }
        &-contactlinks {
            display: inline-block;
            text-align: right;
            flex: 3 0 auto;
            z-index: 999;
            #navPhoneButtonMobile {
                @include media-breakpoint-down(md) {
                    border-bottom: 1px solid $umassglobal-lightest-gray;
                    color: $umassglobal-primary-color;
                }
            }
        }
        .chat__link {
            @include media-breakpoint-down(md) {
                font-size: $font-size-bourgeois;
            }
        }
        .phone-btn {
            background: white;
            @include media-breakpoint-down(md) {
                font-size: 2rem;
                line-height: 2.2;
            }
            @include media-breakpoint-up(xl) {
                .icon {
                    margin-right: 0;
                }
            }
            @include media-breakpoint-up(lg) {
                font-size: $font-size-pica;
            }
            .icon {
                text-decoration: none;
            }
        }
        .request-btn,
        .apply-btn {
            padding: 0;
            a {
                color: $umassglobal-white;
                &:hover {
                    color: $umassglobal-white;
                }
            }
        }
        &-left {
            float: left;
            font-size: 0;
            line-height: 45px;
            @include media-breakpoint-up(xl) {
                line-height: 38px;
                display: block;
            }
            ul>li {
                font-size: $font-size-tiny;
                padding: 0 8px;
                >a {
                    color: $umassglobal-dark-gray;
                }
            }
        }
        &-mobile {
            display: none;
            @include media-breakpoint-down(md) {
                display: flex;
                flex-direction: row;
                float: none;
                text-align: right;
                font-size: $font-size-small;
                line-height: 45px;
            }
            a {
                font-weight: 700;
                text-transform: uppercase;
                &.apply-btn {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        &-right {
            flex-direction: row;
            float: none;
            text-align: right;
            font-size: $font-size-small;
            line-height: 45px;
            a {
                font-weight: 700;
                text-transform: uppercase;
            }
            @include media-breakpoint-between(md, xl) {
                display: flex;
                position: absolute;
                right: 30px;
                top: 0;
            }
            @include media-breakpoint-up(xl) {
                display: flex;
                line-height: 38px;
            }
            &.onPageHeader {
                position: relative;
                @media (min-width: 769px) {
                    display: block;
                }
            }
        }
        &-request {
            @include media-breakpoint-up(xl) {
                flex: 1 0 auto;
            }
            @include media-breakpoint-up(lg) {
                order: inherit;
            }
        }
    }
    &__search {
        &-label {
            left: -10000px;
            top: auto;
            width: 1px;
            height: 1px;
            overflow: hidden;
            position: absolute;
        }
        &-open {
            @include media-breakpoint-up(lg) {
                transition: opacity 600ms;
                visibility: hidden;
                height: 56px;
                left: -10000px;
                line-height: 56px;
                opacity: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
            .nav__search--open & {
                left: 0;
                visibility: visible;
                opacity: 1;
            }

            .form-group{
                position:relative;
                div.icon__loading {
                    padding: 30px 0;
                    width: 100%;
                    position: absolute;
                    z-index: 999;
                    right: -300px;
                    top: -28px;
                }
                .search__suggestions{
                    max-height: 230px;
                    width: 100%;
                    overflow-y: auto;
                    position: absolute;
                    right: 0;
                    z-index: 9;
                    background: white;
                    border:1px solid darken(#F5F4F1, 10%);
                    border-top:none;
                    border-bottom-right-radius: 10px;
                    border-bottom-left-radius: 10px;
                    box-shadow: 0 0 15px 0 #00000033;
                    display: none;
                    &.mobile{
                        top:55px;
                    }
                    a{
                        width: 100%;
                        display: block;
                        margin: 0;
                        padding: 10px 15px !important;
                        border: none;
                        text-align: left;
                        cursor: pointer;
                        font-size: $font-size-smallPica;
                        line-height: 1.3;
                        text-decoration: none;
                        &:hover{
                            background: $umassglobal-off-white-color;
                        }
                    }

                }
            }


            .form-group,
            .input-group {
                width: 100%;
            }
            input {
                border-color: $umassglobal-text-gray;
                border-right: 0;
                border-left: 0;
                height: 56px;
                line-height: 42px;
                @include media-breakpoint-up(xl) {
                    border: 1px solid $umassglobal-lightest-gray;
                }
            }
            .form-inline .input-group .input-group-addon,
            .form-inline .input-group .input-group-btn {
                padding: 0;
                width: 56px;
                @include media-breakpoint-up(xl) {
                    position: absolute;
                    right: 0;
                    text-align: center;
                    top: 0;
                    z-index: 4;
                }
            }
            .btn-default {
                background-color: $umassglobal-extraLight-gray;
                border-color: $umassglobal-text-gray;
                height: 56px;
                line-height: 54px;
                min-width: 0;
                width: 58px;
                padding: 0;
                border-radius: 4px;
                .icon {
                    color: $umassglobal-text-gray;
                    margin: 0;
                }
                &:hover {
                    color: $umassglobal-text-gray;
                    .icon {
                        color: $umassglobal-primary-color;
                    }
                }
                @include media-breakpoint-up(lg) {
                    &:hover {
                        background-color: $umassglobal-primary-color;
                        color: $umassglobal-white;
                        .icon {
                            color: $umassglobal-white;
                        }
                    }
                }
                @include media-breakpoint-up(xl) {
                    border: none;
                    background: none;
                    padding: 0;
                    width: auto;
                }
            }
            form {
                @include media-breakpoint-up(xl) {
                    position: relative;
                }
            }
            .input-group-addon {
                background-color: $body-bg;
                border-color: $umassglobal-text-gray;
            }
            @include media-breakpoint-up(xl) {
                display: block;
                height: auto;
                padding: 15px 15px 100px;
                position: static;
            }
            @include media-breakpoint-up(md) {
                padding: 34px 40px 150px;
            }
        }
        &-close {
            @include darklink;
        }
        &-closegroup {
            @include media-breakpoint-up(xl) {
                display: none;
            }
        }
        &-button {
            @include darklink;
            display: inline-block;
            padding: 10px $nav-link-padding;
            line-height: $line-height-computed;
            @include media-breakpoint-up(lg) {
                padding: $navbar-padding-vertical 0 $navbar-padding-vertical 6px;
            }
            @include media-breakpoint-up(xl) {
                display: none;
            }
            .icon {
                line-height: $line-height-computed;
            }
        }
    }
    &__footer {
        &-wrapper {
            @media screen and (min-width: 960px) {
                padding-right: 50px;
            }
            @include media-breakpoint-up(lg) {
                padding-top: 10px;
            }
        }
    }
    &__contact {
        @include list-inline;
        height: 48px;
        line-height: 48px;
        margin: 0;
        text-align: center;
        @include media-breakpoint-down(md) {
            height: auto;
            width: 100%;
            display: flex;
            justify-content: center;
        }
        >li {
            margin: 0 6px;
            a {
                color: #000000DE;
                &:hover {
                    color: black;
                }
            }
            @include media-breakpoint-down(md) {
                display: block;
                margin: 0;
                text-align: left;
            }
            a {
                color: #000000DE;
                &:hover {
                    color: $umassglobal-primary-color;
                }
            }
        }
    }
    &__social {
        @include list-inline;
        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: stretch;
        }
        margin-left: -9px;
        margin-top: 9px;
        display: flex;
        flex-wrap: wrap;
        //justify-content: space-between;
        >li {
            padding-left: 0;
            padding-right: 20px;
            padding-bottom: 15px;
            @media screen and (min-width: 812px) {
                padding-right: 10px;
            }
            a {
                .icon {
                    text-decoration: none;
                }
            }
        }
    }
}

//BRAN-1633
.appear-from-right {
    left: auto !important;
    right: 0 !important;
    visibility: visible !important;
    transition: all 0.5s !important;
    z-index: 1 !important;
    &.search__global {
        right: 375px !important;
        @include media-breakpoint-down(md) {
            left: 0;
            right: 100% !important;
            width: 100%;
        }
    }
    &.nav__subnav--open {
        overflow: hidden;
    }
}

//ADDED HEADER ELEMENTS
.header-root__elements {
    align-content: center;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: auto;
    @include media-breakpoint-down(md) {
        width: 100%;
    }
    @include media-breakpoint-up(md) {
        width: 100%;
    }
    @include media-breakpoint-up(lg) {
        width: auto;
    }
}

.header-root__ctas {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    margin-right: -10px;
}

//SEARCH BUTTON
.search-button {
    padding: 10px;
    transition: all 250ms ease-in-out;
}

.search-button--open {
    background-color: transparent;
}

.search-button--close {
    background-color: transparent;
    display: none;
}

.search-button:focus,
.search-button:hover {
    background-color: transparent;
}

.search-button--open .svg-icon,
.search-button--close .svg-icon {
    align-content: center;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
}

.search-button--close {
    display: none;
}

//SEARCH OFF-CANVAS
.search__global {
    background: $umassglobal-lightest-gray;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
    height: 100vh;
    left: auto;
    position: absolute;
    right: 0;
    top: 67px;
    transform: translateX(100%);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    visibility: hidden;
    width: 100%;
    z-index: 0;
}

.search__global .nav__search-open {
    padding: 15px;
}

.search__global .nav__search-open .form-group {
    margin-bottom: 0;
}

.search__global .search__suggestions {
    color: $umassglobal-dark-gray;
    padding: 0 15px 15px 15px;
}

.search__global .search__suggestions p {
    margin-bottom: 4px;
}

.search__global .no-bullet {
    list-style: none;
    margin: 0;
    padding: 0;
}

//MOBILE MENU BUTTON
.mobile-menu-button {
    padding: 10px;
    transition: all 250ms ease-in-out;
}

.mobile-menu-button--open {
    background-color: transparent;
}

.mobile-menu-button--close {
    background-color: transparent;
    display: none;
}

.mobile-menu-button:focus,
.mobile-menu-button:hover {
    background-color: transparent;
}

.mobile-menu-button--open .svg-icon,
.mobile-menu-button--close .svg-icon {
    align-content: center;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
}

.mobile-menu-button--close {
    display: none;
}

.svg-icon__text {
    display: none;
}

//MOBILE MENU
.nav__global {
    -webkit-transition: all;
    transition: all 0.5s;
    visibility: hidden;
    z-index: 0;
}

//GENERAL

.overlay__close-button {
    right: 24px;
    top: 24px;
}

.simple-icons-svg {
    height: 20px;
    width: 20px;
}

.search-results__search-wrapper {
    padding-top: 23px;
    @include media-breakpoint-up(xl) {
        padding-top: 50px;
    }
}

@media screen and (min-width: 768px) {
    //TABLET VIEWS
    .header-root__ctas {
        margin-right: 0;
    }
    .svg-icon__text {
        display: block;
        font-weight: bold;
        margin-left: 4px;
        text-transform: uppercase;
    }
    .search__global {
        max-width: 375px;
    }
}

@media screen and (max-width: 959px) {
    .header {
        padding: 15px 25px;
    }
}

@media screen and (min-width: 960px) {
    .search__global {
        top: 91px;
    }
}

@media screen and (max-width: 1199px) {
    .nav__global-wrapper .nav__search-open {
        display: none;
    }
    .nav__global {
        right: -375px;
    }
}

@media screen and (min-width: 1200px) {
    .header-root__elements {
        display: inline-block;
    }
    .header-root__ctas {
        display: none;
    }
    .search__global {
        display: none;
    }
    .nav__global {
        height: auto;
        visibility: visible;
    }
}