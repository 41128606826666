.partner_hero{
    
    @media (max-width: 960px){
        .sidebyside__image {
            width: 100%;
        }

        .sidebyside__column img {
            width: 100%;
        }

        .sidebyside__text {
            padding: 30px;
            text-align: left;
        }
    }

    @media (min-width: 960px){

        .sidebyside__column-image {
            display: -ms-flexbox;
        }
        

        .float-left{
            float: left;
        }

        .float-right{
            float: right;
        }
    
        .sidebyside__image-wrapper{
            width: 50%;
        }
    }

    h2{
        color:black;
        @media (min-width: 992px){  font-size: 30px; }
       
    }

    .sidebyside__text {
        text-align: left;

        margin: 10px 0;

        @include media-breakpoint-up(xs) {
            margin: 10px 10%;
        }

        @include media-breakpoint-up(md) {
            margin: 10px 10%;
        }

        @include media-breakpoint-up(lg) {
            margin: 20% 8% 0 1%;
        }

        @media screen and (min-width: 768px) and (orientation: portrait){
            margin: 20px 10% 10px;
        }

        @media screen and (min-width: 1600px){
            margin: 30% 8% 0 2%;
        }
        
    }

    &.sidebyside__right{
        .sidebyside__text {
            text-align: left;
    
            margin: 10px 0;
    
            @include media-breakpoint-up(xs) {
                margin: 10px 10%;
            }
    
            @include media-breakpoint-up(md) {
                margin: 10px 10%;
            }
    
            @include media-breakpoint-up(lg) {
                margin: 20% 1% 0 8%;
            }
    
            @media screen and (min-width: 768px) and (orientation: portrait){
                margin: 20px 10% 10px;
            }
    
            @media screen and (min-width: 1600px){
                margin: 30% 1% 0 8%;
            }
            
        }
    }

    .testimonials{ 

        .testimonials__image{
            margin: auto;
        }

        margin: 20px 0;

        @include media-breakpoint-up(md) {
            margin: 40px 10% 0;
        }
        
        @include media-breakpoint-up(lg) {
            margin: 10% 45px;
        }

        
        .testimonials__quote {

            blockquote{

                @include media-breakpoint-up(xs) {                    
                    line-height: 22pt;
                    font-size: 1.5em; 
                }

                @include media-breakpoint-up(md) {                    
                    line-height: 16pt;
                    font-size: 0.9em;
                }

                @include media-breakpoint-up(lg) {                    
                    font-size: 1.2em;
                    line-height: 24pt;
                }

                @media screen and (min-width: 768px) and (orientation: portrait){
                    line-height: 17pt;
                    font-size: 0.9em;
                }
            }

            @include media-breakpoint-up(xs) {
                padding: 12px 12px 60px 12px;
            }

            @include media-breakpoint-up(md) {
                padding: 12px 12px 30px 12px;
            }
            
            @include media-breakpoint-up(lg) {
                padding: 10px;
            }

            @media screen and (min-width: 768px) and (max-width: 960px) and (orientation: portrait){
                padding-bottom: 60px;
            }
        }


        .testimonials__image img {
            border-radius: 50%;
            height: auto;
            width: 18vw;
            @media screen and (max-width: 767px){
                height: 18vw; //TODO: What is this supposed to do. Why set the height to 18% of viewport width?
                width: 18vw;
            }
        }

        .testimonials__image .photo_testimonial{
            border-radius: 50%;
            margin: auto;
            background-size: 100%;

            height: 24vw;
            width: 24vw;

            @include media-breakpoint-up(xs) {
                height: 18vw;
                width: 18vw;
            }

            @include media-breakpoint-up(md) {
                height: 8vw;
                width: 8vw;
            }

            @include media-breakpoint-up(lg) {
                height: 8vw;
                width: 8vw;
            }

            @media screen and (min-width: 768px) and (max-width: 960px) and (orientation: portrait){
                height: 16vw;
                width: 16vw;
            }
            
        
        }

    }

    
    

    .texture_overlay{
        background-image: url(/images/pattern_back_full--transparent.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
        @include media-breakpoint-down(md) {
            background-position: 155% top;
        }
    }  
    
}

.variant_red{
    h2{
        color:white;
    }
    .testimonials__quote{
        color:$umassglobal-white;
        blockquote{
            color:$umassglobal-white;
            &:before, &:after{
                color:$umassglobal-white;
            }
        }
    }
    background-color: $umassglobal-primary-color-d25;
}

.variant_yellow{
    h2{
        color:black;
    }
    .testimonials__quote{
        color: $umassglobal-extraDark-gray;
        blockquote{
            color: $umassglobal-extraDark-gray;
        }
    }
    background-color: $umassglobal-tertiary-color;
}

.variant_black{
    h2{
        color: silver;
    }
    .testimonials__quote{
        color: silver;
        blockquote{
            color: silver;
        }
    }
    background: $umassglobal-extraDark-gray;
}

.variant_grey{
    h2{
        color: $umassglobal-extraDark-gray;
    }
    .testimonials__quote{
        color: $umassglobal-extraDark-gray;
        blockquote{
            color: $umassglobal-extraDark-gray;
        }
    }
    background: $umassglobal-off-white-color;
}


