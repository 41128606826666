.people-finder {
    background: $umassglobal-business-color;
    background-image: none !important;
    @include media-breakpoint-down(md) {
        background-size: initial;
        background-position: 0% 100%;
    }
    .section__header {
        * {
            color: $umassglobal-white;
        }
    }
    .container {
        @include media-breakpoint-up(lg) {
            padding: 0 30px;
        }
    }
    &__result {
        @include cards-bottom-colors(2px);
        &.card__faculty {
            box-shadow: 0 0 0 rgba(0, 0, 0, .75);
            &:hover {
                box-shadow: 0 0 20px rgba(0, 0, 0, .75);
            }
            &-title {
                @include media-breakpoint-down(md) {
                    color: $umassglobal-text-gray;
                }
            }
            &-column {
                border: none;
            }
        }
    }
    .degree-finder {
        &__header {
            &-container.container {
                padding: 50px 30px 0;
                @include media-breakpoint-down(md) {
                    padding: 50px 25px 0;
                }
            }
        }
        &__loading {
            .loading__wrapper {
                background-color: $umassglobal-white;
            }
        }
        &__notfound {
            max-width: none;
            padding-left: 12px;
            @include media-breakpoint-down(md) {
                padding-left: 0;
            }
            * {
                color: $umassglobal-white;
            }
            hr {
                border-top: 1px solid #9e5b64;
            }
            .degree-finder__header {
                * {
                    color: $umassglobal-white;
                }
            }
            li {
                .list__icon {
                    color: $umassglobal-tertiary-color;
                }
            }
        }
        &__filters {
            flex-wrap: wrap;
            margin: 0 -7px;
            padding: 0 0 30px;            

            @include media-breakpoint-up(md) {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 2fr;
            }   
            
            @include media-breakpoint-down(md) {
                padding: 15px;
                margin: 0;
            }
        }
        &__filter {
            margin-left: 0;
            padding: 30px 7px 0;
            @include media-breakpoint-down(md) {
                padding: 0;
                margin: 15px 0;
            }
            label {
                color: #fff !important;
                @include media-breakpoint-down(md) {
                    color: #4b4f54 !important;
                }
            }
            &-wrapper {
                @extend %border-blue-bg;
                max-width: none;
                .degree-finder__mobilebutton {
                    background: white;
                    color: #000;
                }
            }
            .input-group-addon,
            .input-group-btn {
                position: absolute;
                right: 0;
                top: .1rem;
                z-index: 4;
            }
            &-container {
                border: none;
            }
        }
        &__results-container {
            padding: 0 30px 50px;
            @include media-breakpoint-down(md) {
                padding: 0 15px 50px;
            }
        }
        &__results {
            .card__faculty-column {
                @include media-breakpoint-down(md) {
                    margin-bottom: 15px;
                    padding: 0;
                    border-bottom: none;
                }
            }
            .card__faculty {
                &:hover {
                    box-shadow: 0 0 10px #ffffff63;
                }
                @include media-breakpoint-down(md) {
                    padding: 18px 12px;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .form-group,
    .input-group {
        width: 100%;
        margin-bottom: 0;
        position: relative;
    }
    .input-group-addon {
        background-color: $body-bg;
        border-color: $umassglobal-text-gray;
    }
    .readmore {
        &__button {
            color: $umassglobal-white;
            @include media-breakpoint-down(md) {
                margin-left: -15px;
                margin-right: -15px;
            }
            button {
                &:hover {
                    color: white;
                    text-decoration: underline;
                }
                &:after,
                &:before {
                    @extend %border-blue-bg;
                }
            }
        }
    }
}

.select2-container.select2-container--filter.select2-container--open {
    @media screen and (max-width: 767px) {
        left: 3% !important;
        width: 94% !important;
    }
    .select2-dropdown.select2-dropdown--below,
    .select2-dropdown.select2-dropdown--above,
    .select2-dropdown.select2-dropdown__columns.select2-dropdown--above,
    .select2-dropdown.select2-dropdown--below.select2-dropdown__columns {
        @media screen and (max-width: 767px) {
            width: 100% !important;
        }
    }
}

.select2.select2-container.select2-container--filter.select2-container--below.select2-container--open,
.select2.select2-container.select2-container--filter.select2-container--above.select2-container--open {
    @media screen and (max-width: 767px) {
        left: 0 !important;
        width: 100% !important;
    }
}