.career-impact {
    overflow: hidden;
    @include media-breakpoint-down(md) {
        background: none;
    }
    .section__header {
        text-align: left;
        @include media-breakpoint-down(md) {
            max-width: 230px;
            * {
                margin-bottom: 18px;
            }
        }
        span {
            color: $umassglobal-primary-color;
        }
    }
    &__loading {
        pointer-events: none;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        jusify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: opacity 600ms;
        .loaded &,
        .onscreen & {
            opacity: 0;
        }
    }
    &__detail {
        text-align: center;
        &-header {
            * {
                color: $umassglobal-text-gray;
                font-size: $font-size-english;
                font-family: $font-family-sans-serif;
                font-weight: bold;
                line-height: 20px;
                margin-top: 0;
            }
        }
        opacity: 0;
        transform: translateY(30px);
        .onscreen & {
            transition: transform $transition-animate, opacity 600ms;
            transition-delay: 1s;
            opacity: 1;
            transform: translateY(0);
        }
        @include media-breakpoint-only(lg) {
            margin-top: 40px;
        }
    }
    &__data {
        position: relative;
        >div {
            padding: 25px 15px;
            @include media-breakpoint-down(md) {
                padding: 25px 0;
            }
        }
        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: center;
            margin: 0 -50px;
            margin-top: 50px;
            >div {
                padding: 0 50px;
                &.career-impact__organizations-wrapper {
                    padding: 0 0 0 50px;
                }
            }
        }
        @include media-breakpoint-down(md) {
            max-width: 400px;
            margin: 10px auto;
        }
    }
    &__subhead {
        color: $umassglobal-darker-gray;
        font-size: $font-size-smallPica;
        font-weight: bold;
        line-height: $line-height-base;
        text-transform: uppercase;
        margin-bottom: 25px;
    }
    &__table {
        text-align: left;
        .table>thead>tr>th {
            color: $umassglobal-white;
            background-color: $umassglobal-primary-color;
        }
        .table {
            >thead,
            >tbody {
                >tr>th {
                    font-size: 1rem;
                    line-height: $line-height-extra;
                    padding: 7px 20px;
                    @include media-breakpoint-up(md) {
                        font-size: $font-size-bourgeois;
                        padding: 7px 10px;
                        &:first-child {
                            padding-left: 30px;
                        }
                    }
                }
                >tr>td {
                    font-size: 1rem;
                    line-height: $line-height-extra;
                    padding: 7px 20px;
                    white-space: nowrap;
                    &:first-child {
                        white-space: normal;
                    }
                    @include media-breakpoint-up(md) {
                        font-size: $font-size-bourgeois;
                        padding: 7px 10px;
                        &:first-child {
                            padding-left: 30px;
                        }
                    }
                }
            }
            margin-bottom: 0;
        }
        .icon {
            color: $umassglobal-tertiary-color;
            vertical-align: baseline;
            &:after {
                vertical-align: baseline;
            }
        }
    }
    &__location {
        @include media-breakpoint-up(lg) {
            text-align: right;
        }
        .forms__message {
            white-space: nowrap;
            right: 0;
            max-width: none;
            text-align: right;
            @include media-breakpoint-down(md) {
                left: -67px;
                max-width: none;
                text-align: left;
            }
        }
        &-heading {
            display: inline-block;
            font-size: $font-size-smallPica;
            color: $umassglobal-gray;
            font-weight: normal;
            line-height: $line-height-double;
            margin: 0;
            text-transform: none;
            @include media-breakpoint-down(md) {
                font-size: $font-size-bourgeois;
            }
        }
        .location__field,
        .subheading {
            @include media-breakpoint-down(md) {
                font-size: $font-size-bourgeois;
            }
        }
        .location__edit-window {
            display: inline-block;
            line-height: 32px;
            margin: 0;
            text-transform: none;
            border: 1px solid $umassglobal-lightest-gray;
            &.has-error {
                border-color: $umassglobal-primary-color;
            }
        }
        .location__zipcode-label {
            text-indent: 0;
            position: static;
            display: inline-block;
            font-size: $font-size-smallPica;
            color: $umassglobal-gray;
            font-weight: normal;
            line-height: $line-height-double;
            margin: 0;
            text-transform: none;
            @include media-breakpoint-down(md) {
                font-size: $font-size-bourgeois;
            }
        }
        &--name {
            color: $umassglobal-text-gray;
            font-weight: bold;
        }
        &--divider {
            color: $umassglobal-primary-color;
            display: inline-block;
            margin: 0 8px;
        }
    }
    &__openings {
        &--tagline {
            color: $umassglobal-gray;
            display: block;
        }
    }
    &__organizations {
        &-container {
            opacity: 0;
            transform: translateY(30px);
            .onscreen & {
                transition: transform $transition-animate, opacity 600ms;
                transition-delay: 0s;
                opacity: 1;
                transform: translateY(0);
            }
            .career-impact__detail-header * {
                margin-bottom: 30px;
            }
            @include media-breakpoint-down(md) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }
        &-wrapper {
            @include media-breakpoint-only(lg) {
                display: flex;
                align-items: center;
                height: 45vw;
            }
        }
        >li {
            font-size: $font-size-longPrimer;
            line-height: $line-height-extra;
            margin-bottom: 16px;
        }
    }
    &__jobs {
        &-wrapper {
            opacity: 0;
            transform: translateY(30px);
            .onscreen & {
                transition: transform $transition-animate, opacity 600ms;
                transition-delay: 0.5s;
                opacity: 1;
                transform: translateY(0);
            }
        }
        &-container {
            padding-top: 100%;
            position: relative;
        }
        &-percent {
            background-color: $umassglobal-secondary-color;
            position: absolute;
            width: 100%;
            height: 0;
            left: 0;
            bottom: 0;
            .animate & {
                height: 100%;
                transition: height 2s cubic-bezier(0.445, 0.050, 0.550, 0.950);
            }
        }
        &-inner {
            background-color: $umassglobal-white;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            position: relative;
        }
        &-outer {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            background-color: $umassglobal-off-white-color;
            position: absolute;
            overflow: hidden;
            top: 0;
            left: 0;
            padding: 20px;
            @include media-breakpoint-down(md) {
                padding: 12px;
            }
        }
        &-total {
            color: #000;
            font-size: 6rem;
            font-weight: bold;
            line-height: $line-height-base;
            margin-bottom: 5px;
            width: 100%;
        }
        &-data {
            font-size: $font-size-smallPica;
            line-height: $line-height-halved;
            text-align: center;
            position: absolute;
            top: 50%;
            width: 100%;
            transform: translateY(-50%);
        }
    }
    &__percent {}
    &__source {
        color: $umassglobal-gray;
        font-style: italic;
        font-size: $font-size-small;
        line-height: $line-height-small;
        margin-top: 40px;
        @include media-breakpoint-down(md) {
            max-width: 400px;
            margin: 0 auto;
        }
        opacity: 0;
        transform: translateY(30px);
        .onscreen & {
            transition: transform $transition-animate, opacity 600ms;
            transition-delay: 1.5s;
            opacity: 1;
            transform: translateY(0);
        }
    }
}