:root {
  --font-size-h1: 2.6rem;
  --font-size-h2: 2rem;
  --font-size-h3: 1.8rem;
  --font-size-p: 1.4rem;
  --border-color: black;
}

@media print {
  #onetrust-banner-sdk,
  #onetrust-consent-sdk,
  br {
    display: none;
  }

  body {
    margin: 0.1rem !important;
    padding: 0px !important;
    background: transparent;
    font-family: Arial, sans-serif;
    line-height: 1 !important;
    font-size: var(--font-size-p) !important;
  }

  h1,
  h2,
  h3,
  p,
  a,
  ul,
  li,
  span {
    padding: 0px !important;
    margin: 0px !important;
  }

  h1 {
    font-size: var(--font-size-h1) !important;
  }

  h2 {
    font-size: var(--font-size-h2) !important;
  }

  h3 {
    font-size: var(--font-size-h3) !important;
  }

  p,
  ul,
  em,
  a,
  div {
    font-size: var(--font-size-p) !important;
  }

  a {
    color: #000;
    text-decoration: none !important;
  }

  .section__darkgrayback,
  .section__grayback {
    background-color: white;
  }

  .container {
    margin: 0px !important;
    padding: 0px !important;
  }

  section,
  .container {
    max-width: 100% !important;
  }

  div.ivy-bot,
  section.faqs,
  section.related-information,
  section.faculty,
  section.career-impact,
  section.bubbles,
  .nav__topnav-wrapper,
  #navbarSupportedContent,
  .navbar-toggler,
  .readmore__button,
  section.steps,
  section.p-0 {
    display: none !important;
  }

  .header__global {
    position: fixed !important;
    top: 0;
  }

  .header {
    padding: 0px !important;
  }

  #hero-wrap {
    margin-top: 65px !important;
  }

  #hero-wrap #heroInterior .hero__background picture > source {
    display: none;
  }

  #hero-wrap
    #heroInterior
    .hero__background
    picture
    > source[media='(max-width:959px)'] {
    display: block !important;
  }

  .hero {
    min-height: 0 !important;
    border-top: none !important;
  }

  .hero__background {
    position: static;
  }

  .hero__background--overlay,
  .hero__background picture {
    height: 100% !important;
    background: none !important;
  }

  #heroInterior {
    padding: 0px !important;
    clip-path: none !important;
  }

  .hero__container {
    max-width: 100% !important;
    display: grid;
  }

  .hero__description {
    color: $umassglobal-text-gray;
    text-shadow: none !important;
    margin-bottom: 0px !important;

    p {
      font-size: 1.4rem !important;
      line-height: 1 !important;
    }

    p:empty {
      display: none;
    }

    div {
      display: none;
    }
  }

  #heroInterior .container .hero__container .hero__header {
    order: 1 !important;
    position: fixed;
    width: 75%;
    margin-top: -220px;
    justify-self: end;

    h1 {
      margin-bottom: 0px !important;
      color: $umassglobal-red !important;
      text-shadow: none !important;
      text-align: end;
      line-height: 1;
    }
  }

  #heroInterior .hero__background {
    order: 2 !important;
    margin-top: 10px;
    height: 150px;
    max-height: 175px;
  }

  #heroInterior .container .hero__container .hero__description {
    order: 3 !important;
    margin-top: 10px;
  }

  section.program-detail {
    .row {
      flex-direction: column-reverse !important;

      > div {
        width: 100% !important;
        padding: 0px 8px !important;
      }

      .program-detail__detail-window {
        padding: 0px !important;
        margin: var(--margin-padding-0) !important;
        border-top: 2px solid var(--border-color);
        border-bottom: 2px solid var(--border-color);
        border-left: none;
        border-right: none;
        margin-top: 5px !important;

        .row {
          display: grid;
          grid-template-areas: 'left right';

          .program-detail__detail {
            margin: 0px !important;
          }

          .program-detail__info {
            grid-area: left;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            a {
              display: none;
            }
          }

          .program-detail__info .program-detail__detail {
            margin-bottom: 0px !important;
          }

          .program-detail__info .program-detail__detail .heading__type {
            margin-top: 0px !important;
            padding-bottom: 0px !important;
            margin-bottom: 8px !important;
          }

          .program-detail__info .program-detail__detail .ways__value {
            margin-bottom: 0px !important;
          }

          .program-detail__ways {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            grid-area: right;
          }

          .program-detail__ways .program-detail__detail {
            margin-bottom: 0px !important;
          }

          .program-detail__ways .program-detail__detail .heading__type {
            margin-top: 0px !important;
            padding-bottom: 0px !important;
            margin-bottom: 2px !important;
          }

          .program-detail__ways .program-detail__detail .ways__value {
            margin-bottom: 0px !important;
          }
        }
      }
    }
    .program-detail__value > .icon,
    .ways__value > .icon {
      font-size: 2.2rem !important;
    }

    .section__description {
      margin: 15px 0;

      p {
        margin-bottom: 15pt !important;
      }

      ul > li {
        margin: 0px 0px 15px 30px !important;
        list-style: circle !important;
      }
    }
  }

  .section__lightgrayback {
    background-color: transparent !important;
    margin: 0px;
    border-top: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
    padding: 0px !important;
    position: fixed;
    bottom: 75px;
    width: 100% !important;
    max-width: 100% !important;
  }

  .tabs__wrapper {
    margin: 0px !important;
  }

  .tabs__container .tabs__content .row {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    width: 75% !important;
    max-width: 75% !important;
  }

  .tabs__container .tabs__content .row > div {
    width: 45% !important;
    max-width: 45% !important;
  }

  .columns__content {
    padding: 10px 0px !important;
    width: 100%;
    max-width: 100%;

    p {
      font-size: 0.8rem !important;
      line-height: 1;
      padding: 0px !important;
    }
  }
 

  .tabs__content .row img,
  .tabs__content .row a > img {
    width: 35% !important;
    max-width: 35% !important;
  }

  section.testimonials {
    background-image: none !important;
    padding: 0px !important;
  }

  .testimonials .container {
    padding-bottom: 0px;
    padding-top: 0px;
    background-color: white;
  }

  .testimonials__continer {
    padding: 0px !important;
  }

  .testimonials__previous,
  .testimonials__next,
  .testimonials__image,
  .testimonials__slide .row .col-md-4 {
    display: none !important;
  }

  .testimonials__slide {
    display: none !important;
    padding: 0px !important;
  }

  .testimonials__slide:first-child {
    display: block !important;
    padding: 0px !important;
  }

  .testimonials__quote {
    margin: 0;
    margin-top: 10px !important;
    padding: 0px 56px;
  }

  .testimonials blockquote {
    font-style: normal !important;
    line-height: 1.1 !important;
    font-size: 1.4rem !important;
    margin: 0px !important;
  }

  .testimonials__name {
    margin-top: 0 !important;
  }

  .section.admission-req {
    page-break-before: always !important;
    margin-top: 55px !important;

    .section__description .row {
      display: var(--display-none) !important;
    }
  }

  .admission-req__additional {
    width: 100% !important;
    margin: 0px !important;
  }

  .admission-req__additional-container {
    background: none !important;
    margin: 0px !important;

    .container p > strong {
      font-size: var(--font-size-h3);

      .section__description p strong {
        font-size: var(--font-size-h3);
      }
    }

    p,
    ul {
      margin-bottom: 10pt !important;
    }
  }

  .section__description {
    .admission-req__requirements li,
    ul li {
      margin: 0px 0px 15px 30px !important;
      list-style: circle !important;
    }
  }

  .admission-req__additional-text {
    padding: var(--padding-admission);
    font-size: var(--font-size-additional-text);
    height: fit-content !important;
    border: none;

    .admission-req__title {
      margin-bottom: 0px;
      font-size: var(--font-size-h3);
      color: inherit !important;
    }
  }

  .admission-req__additional-container p[style='text-align: center;'] {
    display: none;
  }

  .accordion__section-content > p:nth-of-type(1),
  .accordion__section-content > p:nth-of-type(2),
  .accordion__section-content + .accordion__section-header,
  .accordion__section-header,
  .icon__downarrow {
    display: none !important;
  }

  .section.curriculum {
    page-break-before: always !important;
    margin-top: 50px !important;
  }

  .accordionV2__section {
    margin-top: 5px !important;

    .accordionV2__section-wrapper {
      padding: 0px;
    }
    .accordion__section-content + p {
      display: none;
    }
  }

  .accordionV2__section:nth-child(3) {
    page-break-before: always;
    margin-top: 50px !important;
  }

  .accordionV2__section:nth-child(5) {
    page-break-before: always;
    margin-top: 50px !important;
  }

  .accordionV2 > li button {
    order: 0 !important;
    border-top-width: 0px;
    border-top-style: none;
    border-top-color: currentcolor;
    border-top: 1px solid #000 !important;
    pointer-events: none !important;
    cursor: not-allowed !important;
    padding: 5px 0px !important;
    border-radius: 0px !important;
    background: white !important;
  }

  .accordion__section-content {
    display: block !important;
  }

  .curriculum__wrapper {
    max-width: 100% !important;
    width: 100% !important;

    table {
      width: 100% !important;

      th {
        padding: 0px !important;
      }
    }
  }

  .accordionV2 > li,
  .list-group-item {
    border: none !important;
  }

  table.sc_courselist {
    width: 100%;
    border-collapse: collapse;
  }

  table.sc_courselist td {
    padding: 0px !important;
    border: none;
  }

  table.sc_courselist {
    border-collapse: collapse;
  }

  table.sc_courselist tbody tr {
    border-top: none;
    border-bottom: none;
  }

  table.sc_courselist thead tr {
    border-top: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
  }

  table.sc_courselist tbody th:first-child,
  table.sc_courselist tbody td:first-child,
  table.sc_courselist tbody th:last-child,
  table.sc_courselist tbody td:last-child {
    font-family: 'Noto Mono', monospace;
  }

  table.sc_courselist tbody th:first-child,
  table.sc_courselist tbody td:first-child {
    width: 25%;
  }

  table.sc_courselist tbody th:last-child,
  table.sc_courselist tbody td:last-child {
    width: 5%;
  }

  .sc_courselist caption.hidden,
  .program-detail__info .ways__value a {
    display: none;
  }

  table > span,
  .codecol span,
  td p span {
    line-height: 0 !important;
  }

  // cta footer

  .section.cta-snippet {
    display: none !important;
  }

  .section.cta-snippet.section__dark-text[aria-label='Ways to get started'] {
    display: block !important;
    background-color: white !important;
    position: fixed;
    border-bottom: 1px solid #000 !important;
    bottom: 0 !important;
    width: 100%;
    padding-bottom: 5px;

    .section__header > h2,
    .section__header > p,
    .steps__links li {
      display: none;
    }

    .container,
    .steps__links,
    .section__header {
      display: block;
      text-align: left !important;
    }

    .container::before {
      content: 'Become the Teacher Who Inspired You';
      font-size: 1.4rem;
      font-weight: bold;
      margin-bottom: 1.5px;
    }

    .container .section__header::after {
      content: '\2709  Request Info: https://www.umassglobal.edu';
      font-size: 1.4rem;
      line-height: 0;
    }

    .container .steps__links::before {
      content: '\2713  Apply Now: https://www.umassglobal.edu/apply';
      font-size: 1.4rem;
      line-height: 0;
    }

    .container::after {
      content: '\260E  Call us at: 800.746.0082';
      font-size: 1.4rem;
      line-height: 0;
    }
  }
}
