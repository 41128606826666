.news-finder {

    .container {
        @include media-breakpoint-up(lg) {
            padding: 0 30px;
        }
    }

    &__result {

    }

    .degree-finder {

        &__header {

            &-container.container {
                padding: 50px 30px 0;
                @include media-breakpoint-down(md) {
                    padding: 50px 25px 0;
                }
            }
        }


        &__notfound {
            max-width: none;

            padding-left: 12px;
            @include media-breakpoint-down(md) {
                padding-left: 0;
            }

        }

        // &__filters {
        //     flex-wrap: wrap;
        //     margin: 0 -7px;
        //     padding: 0 0 30px;
        // }

        &__filter {
            margin-left: 0;
            padding: 30px 7px 0;


            .input-group-addon,
            .input-group-btn {
                width: 52px;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
                z-index: 4;
            }


            input {
                height: 52px;
                line-height: 38px;
                border: 1px solid $table-border-color;
                min-width: 330px;
                border-radius: 4px;
                @include media-breakpoint-down(md) {
                    min-width: 0;
                }
            }

            

            button {
                height: 52px;
                line-height: 52px;
                min-width: 0;
                border: none;
                background: none;
                padding: 0;
                width: 52px;
                .icon {
                    color: $umassglobal-text-gray;
                    margin: 0;
                }

                &:hover {
                    .icon {
                        color: $umassglobal-primary-color;
                    }
                }
            }
        }
        &__results-container {
            padding: 0 30px 50px;
            @include media-breakpoint-down(md) {
                padding: 0;
                .container {
                    padding-bottom: 30px;
                }
            }

            .container {
                position: relative;
            }
        }

        &__results {

        }
    }


    .form-group,
    .input-group {
        width: 100%;
        margin-bottom: 0;
        position: relative;
    }

    .input-group-addon {
        background-color: $body-bg;
        border-color: $umassglobal-text-gray;
    }
}