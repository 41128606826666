/* AddEvent theme CSS
---------------------------------------------------------------------- */


/* The button */

.addeventatc {
    cursor: pointer;
    color: $umassglobal-primary-color;
    border-radius: 0;
    text-decoration: underline;
    white-space: normal;
    text-align: left;
    vertical-align: baseline;
    transition: color $transition-hover-speed;
    font-size: $font-size-smallPica;
    font-weight: 700;
    line-height: 1.3;
    display: inline-block;
    padding: 0 0 0 30px;
    position: relative;
    .icon {
        transition: color $transition-hover-speed;
        color: $umassglobal-secondary-color;
        font-size: $font-size-columbian;
        line-height: .8;
        position: absolute;
        left: 0;
        text-decoration: none;
    }
}

.addeventatc:hover {
    color: $link-hover-color;
}

.addeventatc .start,
.addeventatc .end,
.addeventatc .timezone,
.addeventatc .title,
.addeventatc .description,
.addeventatc .location,
.addeventatc .organizer,
.addeventatc .organizer_email,
.addeventatc .facebook_event,
.addeventatc .all_day_event,
.addeventatc .date_format,
.addeventatc .alarm_reminder,
.addeventatc .recurring,
.addeventatc .attendees,
.addeventatc .calname,
.addeventatc .uid,
.addeventatc .status,
.addeventatc .method,
.addeventatc .client {
    display: none!important;
}


/* The drop down */

.addeventatc_dropdown {
    width: 200px;
    position: absolute;
    z-index: 99999;
    padding: 6px 0 0 0;
    background: $umassglobal-white;
    text-align: left;
    display: none;
    margin-top: 4px;
    margin-left: -1px;
    border: 1px solid $umassglobal-lightest-gray;
    border-radius: 2px;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
}

.addeventatc_dropdown span {
    display: block;
    line-height: 100%;
    background: $umassglobal-white;
    text-decoration: none;
    font-size: $font-size-smallPica;
    color: #333;
    padding: 9px 10px 9px 40px;
}

.addeventatc_dropdown span:hover {
    background-color: $umassglobal-off-white-color;
    color: $umassglobal-text-gray;
    text-decoration: none;
    font-size: $font-size-smallPica;
}

.addeventatc_dropdown em {
    color: $umassglobal-lighter-gray;
    font-size: $font-size-bourgeois;
}

.addeventatc_dropdown .frs {
    background: $umassglobal-white;
    color: $umassglobal-extraLight-gray!important;
    cursor: pointer;
    font-size: 1rem;
    font-style: normal!important;
    font-weight: normal!important;
    line-height: 110%!important;
    padding-left: 10px;
    position: absolute;
    right: 10px;
    text-align: right;
    text-decoration: none;
    top: 5px;
    z-index: 101;
}

.addeventatc_dropdown .frs:hover {
    color: #999!important;
}

.addeventatc_dropdown .ateappleical {
    background-image: url(/images/gfx/icon-apple-t1.svg);
    background-position: 13px 50%;
    background-repeat: no-repeat;
    background-size: 14px auto;
}

.addeventatc_dropdown .ateoutlook {
    background-image: url(/images/gfx/icon-outlook-t1.svg);
    background-position: 12px 50%;
    background-repeat: no-repeat;
    background-size: 16px auto;
}

.addeventatc_dropdown .ateoutlookcom {
    background-image: url(/images/gfx/icon-outlook-t1.svg);
    background-position: 12px 50%;
    background-repeat: no-repeat;
    background-size: 16px auto;
}

.addeventatc_dropdown .ategoogle {
    background-image: url(/images/gfx/icon-google-t1.svg);
    background-position: 12px 50%;
    background-repeat: no-repeat;
    background-size: 16px auto;
}

.addeventatc_dropdown .ateyahoo {
    background-image: url(/images/gfx/icon-yahoo-t1.svg);
    background-position: 12px 50%;
    background-repeat: no-repeat;
    background-size: 16px auto;
}

.addeventatc_dropdown .atefacebook {
    background-image: url(/images/gfx/icon-facebook-t1.svg);
    background-position: 12px 50%;
    background-repeat: no-repeat;
    background-size: 16px auto;
}

.addeventatc_dropdown .copyx {
    height: 21px;
    display: block;
    position: relative;
    cursor: default;
}

.addeventatc_dropdown .brx {
    height: 1px;
    overflow: hidden;
    background: #e0e0e0;
    position: absolute;
    z-index: 100;
    left: 10px;
    right: 10px;
    top: 9px;
}