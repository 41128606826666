.location-finder {
    background: $umassglobal-off-white-color url("/images/pattern_back_left--transparent.svg") 50% 100%/100% auto no-repeat;
    background-image: none !important;
    @include media-breakpoint-down(md) {
        background-size: initial;
        background-position: 0 100%;
    }
    .container {
        @include media-breakpoint-up(lg) {
            padding: 0 30px;
        }
    }
    &__result {
        .card {
            &__link {
                background-color: $body-bg;
                display: flex;
                flex-direction: column;
                height: 100%;
                margin-bottom: 27px;
                position: relative;
                @include panel-hover;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                    .card__text {
                        border-bottom: 1px solid $umassglobal-secondary-color;
                    }
                }
                .card__text {
                    border-left: 1px solid $umassglobal-extraLight-gray;
                    border-right: 1px solid $umassglobal-extraLight-gray;
                    border-bottom: 1px solid $umassglobal-extraLight-gray;
                    flex-grow: 100;
                }
            }
            &__title {
                border-bottom: 1px solid #e2e2e2;
                padding-bottom: 15px;
                margin-bottom: 15px;
                * {
                    color: $umassglobal-secondary-color;
                    font-size: $font-size-small;
                    line-height: 19px;
                    margin: 0;
                    text-decoration: none;
                }
            }
            &__text {
                color: $umassglobal-text-gray;
                margin: 0;
                padding: 30px;
                .icon {
                    color: $umassglobal-primary-color;
                    float: left;
                    font-size: 3rem;
                    line-height: .66;
                    margin-right: 10px;
                    text-decoration: none;
                    margin-bottom: 26px;
                }
            }
            &__distance {
                padding: 0 40px;
                margin: 0;
                position: relative;
                bottom: 10px;
                font-size: $font-size-smallPica;
                line-height: $line-height-base;
            }
        }
    }
    .degree-finder {
        &__results {
            min-height: 500px;
            .location-finder__result {
                display: none;
            }
            &.fadeout {
                .location-finder__result {
                    display: block;
                }
            }
            &.fadein {
                .location-finder__result {
                    display: block;
                }
            }
        }
        &__header {
            &-container.container {
                padding: 50px 30px 0;
                @include media-breakpoint-down(md) {
                    padding: 50px 25px 0;
                }
            }
        }
        &__distance {
            width: 270px;
        }
        &__filters {
            @include media-breakpoint-down(md) {
                visibility: visible;
                position: static;
                left: auto;
                opacity: 1;
                padding: 0;
                border: none;
                background: none;
                display: flex;
                flex-wrap: wrap;
                justify-content: stretch;
            }
            &-wrapper {
                margin: 0 auto;
                max-width: 475px;
            }
        }
        &__distance {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
        &__filter {
            margin-left: 0;
            @include media-breakpoint-down(md) {
                flex: 1 1 auto;
                display: block;
            }
            &-container {
                border-bottom: none;
                margin-bottom: 0;
                .degree-finder__filter {
                    label {
                        color: $umassglobal-primary-color !important;
                    }
                }
            }
            &-wrapper {
                border-bottom: 1px solid $umassglobal-extraLight-gray;
                margin-bottom: 30px;
                max-width: none;
                @include media-breakpoint-down(md) {
                    border-bottom: none;
                    margin-bottom: 0;
                }
            }
            .input-group-addon,
            .input-group-btn {
                width: 52px;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
                z-index: 4;
            }
            input {
                height: 52px;
                line-height: 38px;
                border: 1px solid $umassglobal-lightest-gray;
                width: 140px;
                padding-left: 20px;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    appearance: none;
                    border: none;
                }
            }
            .has-error input {
                border: 1px solid $umassglobal-primary-color;
            }
            &-text {
                display: block;
                margin-bottom: 0;
                @include subheading;
                text-transform: none;
                height: 79px;
                line-height: 52px;
                padding: 27px 25px 0;
                text-align: center;
                @include media-breakpoint-down(md) {
                    padding-left: 0;
                    padding-top: 34px;
                    margin: 15px 0;
                }
            }
            .forms__message {
                bottom: -18px;
                white-space: nowrap;
            }
            button {
                height: 52px;
                line-height: 52px;
                min-width: 0;
                border: none;
                background: none;
                padding: 0;
                width: 52px;
                .icon {
                    color: $umassglobal-primary-color;
                    margin: 0;
                }
                &:hover {
                    .icon {
                        color: $umassglobal-primary-color;
                    }
                }
            }
            label {
                font-weight: bold;
                color: $umassglobal-primary-color;
            }
        }
        &__results-container {
            padding: 0 30px 50px;
            @include media-breakpoint-down(md) {
                padding: 0 25px 50px;
                .container {
                    padding: 0;
                }
            }
        }
    }
    .form-group,
    .input-group {
        width: 100%;
        margin-bottom: 0;
        position: relative;
    }
    .input-group-addon {
        background-color: $body-bg;
        border-color: $umassglobal-text-gray;
    }
}