
.section {
  &__description {
    margin: 0 auto 30px;
    max-width: 775px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__light-text {
    color: $umassglobal-white;
    h1, h2, h3, h4, h5, h6 {
      color: $umassglobal-white;
    }
    p {
      color: $umassglobal-white;
    }
    .section__description {
      p {
        color: $umassglobal-white;
      }
    }
  }
}

.bio {
  &__school {
    color: $umassglobal-gray;
    font-size: $font-size-bourgeois;
    font-weight: normal;
    line-height: 1.1;
    margin-bottom: 4px
  }

  &__name {
    * {
      color: $umassglobal-text-gray;
      font-size: $font-size-h3;
      line-height: $line-height-h3;
      font-family: $font-family-sans-serif;
      font-weight: bold;
      margin: 0 0 4px;
    }
  }

  &__title {
    font-size: $font-size-small;
    color: $umassglobal-gray;
    font-style: italic;
    line-height: $line-height-h3;
  }

  &__header {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;

    &-connect {
      border-left: 1px solid $umassglobal-lightest-gray;
      padding-left: 30px;
      margin-left: 30px;

      .linkcollection__header {
        margin-bottom: 2px;
        * {
          font-size: $font-size-bourgeois;
          line-height: 1.6;
          margin: 0;
        }
      }

      .linkcollection__connect {
        font-size: 3.5rem;
        line-height: 1;
      }
    }
  }

  &__blog {
    hr {
      margin-bottom: 0;
      margin-top: 50px;
    }
  }
}

.callout {
  background-color: $umassglobal-off-white-color;
  color: $umassglobal-gray;
  margin-bottom: 20px;
  padding: 25px;

  &:last-child {
    margin-bottom: 0;
  }
}

.datablock {
  margin-bottom: 25px;
  .subheading {
    color: $umassglobal-gray;
    font-size: $font-size-smallPica;
    font-weight: normal;
    line-height: 2.14;
    @include media-breakpoint-down(md) {
      font-size: $font-size-pica;
      line-height: 1.5;
    }
  }

  &__title {
    font-weight: bold;
  }

  &__description {
    font-family: $font-family-sans-serif;
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: 0.03em;

    p {
      margin-bottom: 0;
    }


    @include media-breakpoint-down(md) {
      color: $umassglobal-gray;
      line-height: 1.6;

      em {
        color: $umassglobal-text-gray;
      }
    }
  }

  &__tagline {
    margin-top: 12px;
  }

  &__column {
    display: inline-block;
    width: 40%;
    margin-right: 30px;
    vertical-align: top;
    @include media-breakpoint-down(md) {
      width: auto;
      margin-right: 0;
    }
  }

  .readmore {
    margin: 12px 0;
  }
}

.readmore {
  &__text {
    display: none;
    .readmore__open & {
      display: block;
    }
  }

  &__link {

    button {
      color: $umassglobal-primary-color;
      text-decoration: underline;
      font-weight: bold;
      margin: 12px 0;
      position: relative;

      .icon {
        position: absolute;
        right: -19px;
        top: 0;
      }
    }

    &--misc {
      button {
        color: $umassglobal-primary-color;
        text-decoration: underline;
        font-weight: bold;
        margin: 12px 0;
        position: relative;

        .icon {
          position: absolute;
          right: -19px;
          top: 0;
        }
      }
    }
  }

  &__button {
    color: $umassglobal-primary-color;
    display: block;
    text-align: center;
    overflow: hidden;
    padding-bottom: 7px;

    button {
      display: inline-block;
      font-size: $font-size-med;
      font-weight: 700;
      position: relative;
      padding: 10px 40px;
      text-decoration: none;

      &:hover {
        color: $umassglobal-primary-color;
        text-decoration: underline;
      }

      &:after,
      &:before {
        border-bottom: 1px solid $umassglobal-lightest-gray;
        content: " ";
        display: block;
        position: absolute;
        top: 50%;
        width: 50vw;
      }
      &:after {
        left: 100%;
      }
      &:before {
        right: 100%;
      }
    }

    &-icon {
      bottom: -7px;
      left: 0;
      position: absolute;
      width: 100%;
      display: block;
      top: auto;
    }
  }

  &__open {
    button .icon {
      transform: scale(1, -1);
    }
  }
}

.red {
  color: $umassglobal-primary-color;
}

.icon {
  text-decoration: none;
}

.icons {

  .anchors__header {
    margin: 10px 0;
  }
}

.responsivetable {

  &__container {

    @include media-breakpoint-up(lg) {
      padding-right: 20px;
    }

    .responsivetable {
      border-right: 1px solid $umassglobal-lightest-gray;
      padding-right: 35px;
      position: relative;
      @include media-breakpoint-down(md) {
        border-right: none;
        border-bottom: 1px solid $umassglobal-lightest-gray;
        padding-right: 0;
        margin-bottom: 30px;
      }

      .section__content & {
        border: none;
        padding-right: 0;
        margin-bottom: 0;
      }

      &.scrollable {
        @include standard-icons(rightmorearrow, before);
      }

      @include media-breakpoint-down(md) {

        &.scrollable {
          margin-top: 24px;
          margin-left: -25px;
          margin-right: -25px;
          padding: 18px 0 0;

          .table-responsive {
            padding: 12px 25px;
          }


          &:before {
            color: $umassglobal-gray;
            font-size: 2rem;
            line-height: .5;
            top: 0;
            position: absolute;
            right: 12px;
          }
        }
      }

      @include media-breakpoint-up(lg) {

        &.scrollable {
          &:before {
            display: none;
          }
        }
      }
    }
  }

  &__block {
    @include media-breakpoint-down(md) {
      border: none;
    }
  }

  &__tagline {
    color: $umassglobal-gray;
    font-size: $font-size-small;
    line-height: $line-height-small;
    font-weight: normal;
    font-style: italic;

    @include media-breakpoint-down(md) {
      padding-top: 22px;
    }

    p {
      @include media-breakpoint-up(lg) {
        margin: 0;
      }
    }

    .row {
      .container .section & {
        margin-left: -15px;
        margin-right: -15px;
      }
    }
  }

}
