@use "sass:math";

@mixin subheading($color: $umassglobal-darker-gray, $size: $font-size-small) {
    color: $color !important;
    font-family: $font-family-sans-serif;
    font-size: $size;
    font-weight: bold;
    letter-spacing: 0.03em;
    text-transform: uppercase;
}


$headings-font-family:    inherit;
$headings-font-weight:    500;
$headings-line-height:    1.1;
$headings-color:          inherit;

// 3. Line Height
$line-height-large-mobile: 1.667;
$line-height-large: 1.667;
$line-height-small: 1.42;
$line-height-h1: 62px;
$line-height-h1-alt: 45px;
$line-height-h3: 28px;
$line-height-h2: 38px;
$line-height-mobile-h1: 43px;
$line-height-mobile-h1-alt: 35px;

// 4. Font Color
$font-color-h3: $umassglobal-text-gray;

:root{
    font-size: $font-size-root;
    line-height: $line-height-base;

}

body{
    font-size: $font-size-base!important;
    font-family: $font-family-base;
    line-height: $line-height-base;
}

.heading {
    &__type {
        text-align: center;
        border-bottom: 1px solid $umassglobal-lightest-gray;
    }
}

code, kbd, pre, samp {
    font-family: $font-family-monospace;
    padding: 15px 25px;
    background-color: $umassglobal-light-gray;
    margin-bottom: 0;
}

.subheading {
    @include subheading;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
    small,
    .small {
        font-weight: normal;
        line-height: $line-height-straight;
        color: $headings-small-color;
    }
    .subheading,
    &.subheading {
        @extend .subheading;
    }
}

h1,
.h1,
h2,
.h2 {
    font-family: $main-headings-font-family;
   // font-weight: $main-headings-font-weight;
}

body {
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $umassglobal-text-gray;
    background-color: $body-bg;
}

h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 22px;
    margin-bottom: 12px;
}

h1,
.h1 {
    color: $font-color-h1;
    font-size: $font-size-h1;
    line-height: $line-height-h1;
}

h2,
.h2 {
    color: $font-color-h2;
    font-size: $font-size-h2;
    line-height: $line-height-h2;
}

h3,
.h3 {
    color: $font-color-h3;
    font-size: $font-size-h3;
    line-height: $line-height-h3;
    a {
        text-decoration: underline;
        text-decoration-color: $umassglobal-primary-color;
    }
    a &,
    a {
        color: $umassglobal-text-gray;
        text-decoration: underline;
        text-decoration-color: $umassglobal-primary-color;
    }
}

.h3, .h5, .h6, h3, h5, h6{
    font-weight: bold;
}