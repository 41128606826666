.horizontal_form{     
    .forms__wrapper{
        margin-left: 15px;
    }

    @media (max-width: 992px){  .col-md-12{  width: 100%; } }

    h5{
        font-size: $font-size-smallPica;
        line-height: $line-height-base;
        text-align: center;
        color: $umassglobal-dark-gray;
        font-family: $font-family-serif;
    }

    .wrapper{
        padding: 50px 5px;
        text-align: center;
        @media (max-width: 992px){  padding: 20px 5px; }
        img {
            height: auto;
            width: auto;
            max-width: 80%;
        }
    }

    .sidebyside__column img {
        height: auto;
        width: auto;
        max-width: 80%;
    }

    .forms__request-header * {
        max-width: 100%;
    }

    .form-arrows.nav-tabs li a:hover {
        background-color: #dfdfdf;
        color: #822433;
        &:after{
            border-left-color: #dfdfdf;
        }
    }
    .form-arrows.nav-tabs li.active a:hover {
        background-color: $umassglobal-dark-gray;
        color: white;
        &:after{
            border-left-color: $umassglobal-dark-gray;
        }
    }
    
    .student-type--question{
        position: relative!important;
    }
    .forms__message--international{
        display:none;
    }
    .student-type--question .form-inline{
        padding-top: 15px;
    }
    .student-type {
        position: absolute;
        width: 100%;
        padding-left: 0;
        @include media-breakpoint-down(md) {
            position:inherit;
        }
    }

    // .form-arrows.nav-tabs{
    //     @media (min-width: 992px){ width: 40%; }        
    // }  
    .radio-inline, .checkbox-inline{
        padding: 0;
    }
    .form-inline .radio label{
        font-size: $font-size-bourgeois;
    }
    .nurseRequestHorizontal .forms__message{
        bottom: -20px!important;
    }
    .tcpa_statement{
        margin-top: 20px;
        padding:10px;
        display: inline-table;
        @include media-breakpoint-down(md) {
            // margin-top: 0;
            padding:0!important;
        }
        &.education, &.about{
            padding:20px;
        }
    }
    @include media-breakpoint-down(md) {
        .forms__request-wrapper{
            padding: 15px;
        }
    }
    .form-arrows.nav-tabs li a{
        cursor: pointer;
    }
    .form-arrows.nav-tabs{
        pointer-events: all;
    }
    .form-inline {
        .form-group {
            display: inline-block;
            vertical-align: middle;
        }

        @media (max-width: 991px){  .form-group {
            margin-bottom: 8px;
            } 
        }

        select.form-control {
            background: transparent url(/images/downarrow-line.png) no-repeat 100% 50%;
            background-size: auto 100%;
            color: $umassglobal-darker-gray;
            padding-right: 46px;
            appearance: none;
        }
    }

    @media (min-width: 992px){
        .brd-col-lg-3 {
            width: 22.5%;
        }
        .brd-col-lg-2 {
            width: 12%;
        }
    }

    .submit__tabs{
        float: right;

        .icon {
            color: $umassglobal-darker-gray;
        }
    }

    .horizontal_form_divider{
        margin-top: 50px;
        border-top: 2px solid $umassglobal-extraLight-gray;
        @include media-breakpoint-down(md) {
            display: none; 
        }

        @include media-breakpoint-only(md) {
            display: none; 
        }
    }

    // .form-arrows.nav-tabs li a:hover{
    //     background-color: #dfdfdf;
    // }

    li.disabled{
        pointer-events: none;
    }
    .tab-second.disabled a{
        color: #606060!important;
    }
}

.osh{
    margin: 0;
}


@media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1199px) 
    and (orientation : landscape) { 
        .hidden_for_large{
            display: none;    
        }
    }


    @include media-breakpoint-down(md) {
        .hidden_for_small{
            display: none;    
        }
    }

    @include media-breakpoint-only(md) {
        .hidden_for_medium{
            display: none;    
        }
    }

    @include media-breakpoint-up(xl) {
        .hidden_for_large{
            display: none;    
        }
    }



    .horizontal_form.full_width{
        @include media-breakpoint-down(md) {
            .forms__wrapper{
                padding: 0 25px;
            }
        }
        .tcpa_statement{
            margin-top: 78px;
            @include media-breakpoint-down(md) {
                    margin: 20px 0;
            }
            p{
                font-size: $font-size-longPrimer;
            }
        }
    }

