/*! Gray v1.6.0 (https://github.com/karlhorky/gray) | MIT */
.grayscale {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.grayscale.grayscale-fade {
  transition: filter .5s;
}

/* Webkit hack until filter is unprefixed */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .grayscale.grayscale-fade {
    -webkit-transition: -webkit-filter .5s;
    transition:         -webkit-filter .5s;
  }
}

.grayscale.grayscale-off,
.grayscale.grayscale-fade:hover {
  -webkit-filter: grayscale(0);
  filter:         grayscale(0);
}

/* Background element */
.grayscale.grayscale-replaced {
  -webkit-filter: none;
  filter:         none;
}

.grayscale.grayscale-replaced > svg {
  -webkit-transition: opacity .5s ease;
  transition:         opacity .5s ease;
  opacity: 1;
}

.grayscale.grayscale-replaced.grayscale-off > svg,
.grayscale.grayscale-replaced.grayscale-fade:hover > svg {
  opacity: 0;
}
