.faqs {
    background-color: $umassglobal-lightest-gray;
    .leftnav__container &,
    .section__content & {
        background-color: transparent;
        .section__header {
            * {
                color: $umassglobal-text-gray;
                font-size: $font-size-h3;
                line-height: $line-height-h3;
                font-family: $font-family-sans-serif;
                font-weight: bold;
            }
        }
    }
    .tabs__wrapper {
        margin-top: 50px;
    }
    .tabs__container {
        padding-top: 30px;
    }
    &__category {
        &-header {
            * {
                color: $umassglobal-primary-color;
                margin-bottom: 0;
                font: normal normal bold 2.1rem/1.66 $font-family-sans-serif;
                letter-spacing: 0;
                text-transform: uppercase;
            }
            border-bottom: 1px solid $table-border-color;
            margin-bottom: 2rem;

        }
        margin-bottom: 30px;
    }
    &__question {
        &-header {
            * {
                color: $umassglobal-text-gray;
                font: normal normal bold 1.7rem/1.35 $font-family-sans-serif;
            }
        }
        margin-bottom: 40px;
    }
    &__container {
        &.section__text {
            ul li {
                list-style: none;
            }
        }
    }
}