//TODO: Move all buttons to this file and componentize properly;

@each $color, $value in $theme-colors {
  .btn-#{$color}-carat {
    @include button-variant($value, $value);
  &:after {
    content: "\f108";
    margin-left: 4px;
    font-family: 'standard-icons';
   }
  }
}



