@charset "UTF-8";
button {
  background: none;
  border: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
}

/*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #005EB8;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #FFCE00;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #A32035;
  --bs-secondary: #005EB8;
  --bs-primary-blue: #005EB8;
  --bs-tertiary: #FFCE00;
  --bs-primary-yellow: #FFCE00;
  --bs-success: hsl(121, 100%, 26%);
  --bs-info: #0dcaf0;
  --bs-warning: #ff8307;
  --bs-danger: hsl(0, 95%, 45%);
  --bs-light: #dbdbdb;
  --bs-dark: #1c1c1c;
  --bs-default: #fff;
  --bs-disabled: #545454;
  --bs-arts-and-sciences: #009739;
  --bs-business: #002E5D;
  --bs-red: #A32035;
  --bs-font-sans-serif: "Noto Sans", sans-serif;
  --bs-font-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: 10px;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  font-size: calc(1.285rem + 0.42vw);
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media (min-width: 1200px) {
  body {
    font-size: 1.6rem;
  }
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.525rem + 3.3vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 4rem;
  }
}

h2, .h2 {
  font-size: calc(1.445rem + 2.34vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 3.2rem;
  }
}

h3, .h3 {
  font-size: calc(1.405rem + 1.86vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 2.8rem;
  }
}

h4, .h4 {
  font-size: calc(1.365rem + 1.38vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 2.4rem;
  }
}

h5, .h5 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h5, .h5 {
    font-size: 2rem;
  }
}

h6, .h6 {
  font-size: calc(1.285rem + 0.42vw);
}
@media (min-width: 1200px) {
  h6, .h6 {
    font-size: 1.6rem;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #A32035;
  text-decoration: underline;
}
a:hover {
  color: #821a2a;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 300;
}
@media (min-width: 1200px) {
  .lead {
    font-size: 2rem;
  }
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, nav ul,
nav ol {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .blockquote {
    font-size: 2rem;
  }
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xl,
.container-old-lg-bs3 {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 960px) {
  .container-old-lg-bs3, .container-md, .container-sm, .container {
    max-width: 970px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-old-lg-bs3, .container-md, .container-sm, .container {
    max-width: 1170px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 960px) {
  .col-old-lg-bs3 {
    flex: 1 0 0%;
  }
  .row-cols-old-lg-bs3-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-old-lg-bs3-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-old-lg-bs3-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-old-lg-bs3-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-old-lg-bs3-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-old-lg-bs3-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-old-lg-bs3-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 960px) {
  .col-old-lg-bs3-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-old-lg-bs3-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-old-lg-bs3-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-old-lg-bs3-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-old-lg-bs3-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-old-lg-bs3-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-old-lg-bs3-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-old-lg-bs3-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-old-lg-bs3-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-old-lg-bs3-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-old-lg-bs3-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-old-lg-bs3-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-old-lg-bs3-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-old-lg-bs3-0 {
    margin-left: 0;
  }
  .offset-old-lg-bs3-1 {
    margin-left: 8.33333333%;
  }
  .offset-old-lg-bs3-2 {
    margin-left: 16.66666667%;
  }
  .offset-old-lg-bs3-3 {
    margin-left: 25%;
  }
  .offset-old-lg-bs3-4 {
    margin-left: 33.33333333%;
  }
  .offset-old-lg-bs3-5 {
    margin-left: 41.66666667%;
  }
  .offset-old-lg-bs3-6 {
    margin-left: 50%;
  }
  .offset-old-lg-bs3-7 {
    margin-left: 58.33333333%;
  }
  .offset-old-lg-bs3-8 {
    margin-left: 66.66666667%;
  }
  .offset-old-lg-bs3-9 {
    margin-left: 75%;
  }
  .offset-old-lg-bs3-10 {
    margin-left: 83.33333333%;
  }
  .offset-old-lg-bs3-11 {
    margin-left: 91.66666667%;
  }
  .g-old-lg-bs3-0,
  .gx-old-lg-bs3-0 {
    --bs-gutter-x: 0;
  }
  .g-old-lg-bs3-0,
  .gy-old-lg-bs3-0 {
    --bs-gutter-y: 0;
  }
  .g-old-lg-bs3-1,
  .gx-old-lg-bs3-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-old-lg-bs3-1,
  .gy-old-lg-bs3-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-old-lg-bs3-2,
  .gx-old-lg-bs3-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-old-lg-bs3-2,
  .gy-old-lg-bs3-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-old-lg-bs3-3,
  .gx-old-lg-bs3-3 {
    --bs-gutter-x: 1rem;
  }
  .g-old-lg-bs3-3,
  .gy-old-lg-bs3-3 {
    --bs-gutter-y: 1rem;
  }
  .g-old-lg-bs3-4,
  .gx-old-lg-bs3-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-old-lg-bs3-4,
  .gy-old-lg-bs3-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-old-lg-bs3-5,
  .gx-old-lg-bs3-5 {
    --bs-gutter-x: 3rem;
  }
  .g-old-lg-bs3-5,
  .gy-old-lg-bs3-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #edd2d7;
  --bs-table-striped-bg: #e1c8cc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d5bdc2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #dbc2c7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #d5bdc2;
}

.table-secondary {
  --bs-table-bg: #ccdff1;
  --bs-table-striped-bg: #c2d4e5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8c9d9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdcedf;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8c9d9;
}

.table-success {
  --bs-table-bg: #cce7cc;
  --bs-table-striped-bg: #c2dbc2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8d0b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdd6bd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8d0b8;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #ffe6cd;
  --bs-table-striped-bg: #f2dbc3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6cfb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecd5be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6cfb9;
}

.table-danger {
  --bs-table-bg: #f9cdcd;
  --bs-table-striped-bg: #edc3c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e0b9b9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e6bebe;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e0b9b9;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 959.98px) {
  .table-responsive-old-lg-bs3 {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .col-form-label-lg {
    font-size: 2rem;
  }
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: calc(1.265rem + 0.18vw);
}
@media (min-width: 1200px) {
  .col-form-label-sm {
    font-size: 1.4rem;
  }
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: calc(1.285rem + 0.42vw);
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 1200px) {
  .form-control {
    font-size: 1.6rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #d1909a;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(163, 32, 53, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  font-size: calc(1.265rem + 0.18vw);
  border-radius: 0.2rem;
}
@media (min-width: 1200px) {
  .form-control-sm {
    font-size: 1.4rem;
  }
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: calc(1.325rem + 0.9vw);
  border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .form-control-lg {
    font-size: 2rem;
  }
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + (0.75rem + 2px));
}
textarea.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
}
textarea.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: calc(1.285rem + 0.42vw);
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (min-width: 1200px) {
  .form-select {
    font-size: 1.6rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #d1909a;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(163, 32, 53, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: calc(1.265rem + 0.18vw);
}
@media (min-width: 1200px) {
  .form-select-sm {
    font-size: 1.4rem;
  }
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .form-select-lg {
    font-size: 2rem;
  }
}

.form-check {
  display: block;
  min-height: 2.4rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #d1909a;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(163, 32, 53, 0.25);
}
.form-check-input:checked {
  background-color: #A32035;
  border-color: #A32035;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #A32035;
  border-color: #A32035;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23d1909a'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(163, 32, 53, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(163, 32, 53, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #A32035;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #e3bcc2;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #A32035;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #e3bcc2;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc((3.5rem + 2px) * 1.4);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: calc(1.285rem + 0.42vw);
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
@media (min-width: 1200px) {
  .input-group-text {
    font-size: 1.6rem;
  }
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.325rem + 0.9vw);
  border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn {
    font-size: 2rem;
  }
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: calc(1.265rem + 0.18vw);
  border-radius: 0.2rem;
}
@media (min-width: 1200px) {
  .input-group-sm > .form-control,
  .input-group-sm > .form-select,
  .input-group-sm > .input-group-text,
  .input-group-sm > .btn {
    font-size: 1.4rem;
  }
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: hsl(121, 100%, 26%);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: calc(1.265rem + 0.18vw);
  color: #fff;
  background-color: rgba(0, 133, 2, 0.9);
  border-radius: 0.25rem;
}
@media (min-width: 1200px) {
  .valid-tooltip {
    font-size: 1.4rem;
  }
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: hsl(121, 100%, 26%);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='hsl%28121, 100%, 26%%29' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: hsl(121, 100%, 26%);
  box-shadow: 0 0 0 0.25rem rgba(0, 133, 2, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: hsl(121, 100%, 26%);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='hsl%28121, 100%, 26%%29' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: hsl(121, 100%, 26%);
  box-shadow: 0 0 0 0.25rem rgba(0, 133, 2, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: hsl(121, 100%, 26%);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: hsl(121, 100%, 26%);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 133, 2, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: hsl(121, 100%, 26%);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: hsl(0, 95%, 45%);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: calc(1.265rem + 0.18vw);
  color: #fff;
  background-color: rgba(224, 6, 6, 0.9);
  border-radius: 0.25rem;
}
@media (min-width: 1200px) {
  .invalid-tooltip {
    font-size: 1.4rem;
  }
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: hsl(0, 95%, 45%);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='hsl%280, 95%, 45%%29'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='hsl%280, 95%, 45%%29' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: hsl(0, 95%, 45%);
  box-shadow: 0 0 0 0.25rem rgba(224, 6, 6, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: hsl(0, 95%, 45%);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='hsl%280, 95%, 45%%29'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='hsl%280, 95%, 45%%29' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: hsl(0, 95%, 45%);
  box-shadow: 0 0 0 0.25rem rgba(224, 6, 6, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: hsl(0, 95%, 45%);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: hsl(0, 95%, 45%);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(224, 6, 6, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: hsl(0, 95%, 45%);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: calc(1.285rem + 0.42vw);
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 1200px) {
  .btn {
    font-size: 1.6rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(163, 32, 53, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #A32035;
  border-color: #A32035;
}
.btn-primary:hover {
  color: #fff;
  background-color: #8b1b2d;
  border-color: #821a2a;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #8b1b2d;
  border-color: #821a2a;
  box-shadow: 0 0 0 0.25rem rgba(177, 65, 83, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #821a2a;
  border-color: #7a1828;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(177, 65, 83, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #A32035;
  border-color: #A32035;
}

.btn-secondary {
  color: #fff;
  background-color: #005EB8;
  border-color: #005EB8;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #00509c;
  border-color: #004b93;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #00509c;
  border-color: #004b93;
  box-shadow: 0 0 0 0.25rem rgba(38, 118, 195, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #004b93;
  border-color: #00478a;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 118, 195, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #005EB8;
  border-color: #005EB8;
}

.btn-primary-blue {
  color: #fff;
  background-color: #005EB8;
  border-color: #005EB8;
}
.btn-primary-blue:hover {
  color: #fff;
  background-color: #00509c;
  border-color: #004b93;
}
.btn-check:focus + .btn-primary-blue, .btn-primary-blue:focus {
  color: #fff;
  background-color: #00509c;
  border-color: #004b93;
  box-shadow: 0 0 0 0.25rem rgba(38, 118, 195, 0.5);
}
.btn-check:checked + .btn-primary-blue, .btn-check:active + .btn-primary-blue, .btn-primary-blue:active, .btn-primary-blue.active, .show > .btn-primary-blue.dropdown-toggle {
  color: #fff;
  background-color: #004b93;
  border-color: #00478a;
}
.btn-check:checked + .btn-primary-blue:focus, .btn-check:active + .btn-primary-blue:focus, .btn-primary-blue:active:focus, .btn-primary-blue.active:focus, .show > .btn-primary-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 118, 195, 0.5);
}
.btn-primary-blue:disabled, .btn-primary-blue.disabled {
  color: #fff;
  background-color: #005EB8;
  border-color: #005EB8;
}

.btn-tertiary {
  color: #000;
  background-color: #FFCE00;
  border-color: #FFCE00;
}
.btn-tertiary:hover {
  color: #000;
  background-color: #ffd526;
  border-color: #ffd31a;
}
.btn-check:focus + .btn-tertiary, .btn-tertiary:focus {
  color: #000;
  background-color: #ffd526;
  border-color: #ffd31a;
  box-shadow: 0 0 0 0.25rem rgba(217, 175, 0, 0.5);
}
.btn-check:checked + .btn-tertiary, .btn-check:active + .btn-tertiary, .btn-tertiary:active, .btn-tertiary.active, .show > .btn-tertiary.dropdown-toggle {
  color: #000;
  background-color: #ffd833;
  border-color: #ffd31a;
}
.btn-check:checked + .btn-tertiary:focus, .btn-check:active + .btn-tertiary:focus, .btn-tertiary:active:focus, .btn-tertiary.active:focus, .show > .btn-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 175, 0, 0.5);
}
.btn-tertiary:disabled, .btn-tertiary.disabled {
  color: #000;
  background-color: #FFCE00;
  border-color: #FFCE00;
}

.btn-primary-yellow {
  color: #000;
  background-color: #FFCE00;
  border-color: #FFCE00;
}
.btn-primary-yellow:hover {
  color: #000;
  background-color: #ffd526;
  border-color: #ffd31a;
}
.btn-check:focus + .btn-primary-yellow, .btn-primary-yellow:focus {
  color: #000;
  background-color: #ffd526;
  border-color: #ffd31a;
  box-shadow: 0 0 0 0.25rem rgba(217, 175, 0, 0.5);
}
.btn-check:checked + .btn-primary-yellow, .btn-check:active + .btn-primary-yellow, .btn-primary-yellow:active, .btn-primary-yellow.active, .show > .btn-primary-yellow.dropdown-toggle {
  color: #000;
  background-color: #ffd833;
  border-color: #ffd31a;
}
.btn-check:checked + .btn-primary-yellow:focus, .btn-check:active + .btn-primary-yellow:focus, .btn-primary-yellow:active:focus, .btn-primary-yellow.active:focus, .show > .btn-primary-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 175, 0, 0.5);
}
.btn-primary-yellow:disabled, .btn-primary-yellow.disabled {
  color: #000;
  background-color: #FFCE00;
  border-color: #FFCE00;
}

.btn-success {
  color: #fff;
  background-color: hsl(121, 100%, 26%);
  border-color: hsl(121, 100%, 26%);
}
.btn-success:hover {
  color: #fff;
  background-color: #007102;
  border-color: #006a02;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #007102;
  border-color: #006a02;
  box-shadow: 0 0 0 0.25rem rgba(38, 151, 40, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #006a02;
  border-color: #006402;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 151, 40, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: hsl(121, 100%, 26%);
  border-color: hsl(121, 100%, 26%);
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ff8307;
  border-color: #ff8307;
}
.btn-warning:hover {
  color: #000;
  background-color: #ff962c;
  border-color: #ff8f20;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ff962c;
  border-color: #ff8f20;
  box-shadow: 0 0 0 0.25rem rgba(217, 111, 6, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ff9c39;
  border-color: #ff8f20;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 111, 6, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ff8307;
  border-color: #ff8307;
}

.btn-danger {
  color: #fff;
  background-color: hsl(0, 95%, 45%);
  border-color: hsl(0, 95%, 45%);
}
.btn-danger:hover {
  color: #fff;
  background-color: #be0505;
  border-color: #b30505;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #be0505;
  border-color: #b30505;
  box-shadow: 0 0 0 0.25rem rgba(229, 43, 43, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b30505;
  border-color: #a80505;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(229, 43, 43, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: hsl(0, 95%, 45%);
  border-color: hsl(0, 95%, 45%);
}

.btn-light {
  color: #000;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}
.btn-light:hover {
  color: #000;
  background-color: #e0e0e0;
  border-color: #dfdfdf;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #e0e0e0;
  border-color: #dfdfdf;
  box-shadow: 0 0 0 0.25rem rgba(186, 186, 186, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #e2e2e2;
  border-color: #dfdfdf;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(186, 186, 186, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}

.btn-dark {
  color: #fff;
  background-color: #1c1c1c;
  border-color: #1c1c1c;
}
.btn-dark:hover {
  color: #fff;
  background-color: #181818;
  border-color: #161616;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #181818;
  border-color: #161616;
  box-shadow: 0 0 0 0.25rem rgba(62, 62, 62, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #161616;
  border-color: #151515;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(62, 62, 62, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #1c1c1c;
  border-color: #1c1c1c;
}

.btn-default {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-default:hover {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:focus + .btn-default, .btn-default:focus {
  color: #000;
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-check:checked + .btn-default, .btn-check:active + .btn-default, .btn-default:active, .btn-default.active, .show > .btn-default.dropdown-toggle {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-default:focus, .btn-check:active + .btn-default:focus, .btn-default:active:focus, .btn-default.active:focus, .show > .btn-default.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-default:disabled, .btn-default.disabled {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-disabled {
  color: #fff;
  background-color: #545454;
  border-color: #545454;
}
.btn-disabled:hover {
  color: #fff;
  background-color: #474747;
  border-color: #434343;
}
.btn-check:focus + .btn-disabled, .btn-disabled:focus {
  color: #fff;
  background-color: #474747;
  border-color: #434343;
  box-shadow: 0 0 0 0.25rem rgba(110, 110, 110, 0.5);
}
.btn-check:checked + .btn-disabled, .btn-check:active + .btn-disabled, .btn-disabled:active, .btn-disabled.active, .show > .btn-disabled.dropdown-toggle {
  color: #fff;
  background-color: #434343;
  border-color: #3f3f3f;
}
.btn-check:checked + .btn-disabled:focus, .btn-check:active + .btn-disabled:focus, .btn-disabled:active:focus, .btn-disabled.active:focus, .show > .btn-disabled.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(110, 110, 110, 0.5);
}
.btn-disabled:disabled, .btn-disabled.disabled {
  color: #fff;
  background-color: #545454;
  border-color: #545454;
}

.btn-arts-and-sciences {
  color: #000;
  background-color: #009739;
  border-color: #009739;
}
.btn-arts-and-sciences:hover {
  color: #000;
  background-color: #26a757;
  border-color: #1aa14d;
}
.btn-check:focus + .btn-arts-and-sciences, .btn-arts-and-sciences:focus {
  color: #000;
  background-color: #26a757;
  border-color: #1aa14d;
  box-shadow: 0 0 0 0.25rem rgba(0, 128, 48, 0.5);
}
.btn-check:checked + .btn-arts-and-sciences, .btn-check:active + .btn-arts-and-sciences, .btn-arts-and-sciences:active, .btn-arts-and-sciences.active, .show > .btn-arts-and-sciences.dropdown-toggle {
  color: #000;
  background-color: #33ac61;
  border-color: #1aa14d;
}
.btn-check:checked + .btn-arts-and-sciences:focus, .btn-check:active + .btn-arts-and-sciences:focus, .btn-arts-and-sciences:active:focus, .btn-arts-and-sciences.active:focus, .show > .btn-arts-and-sciences.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 128, 48, 0.5);
}
.btn-arts-and-sciences:disabled, .btn-arts-and-sciences.disabled {
  color: #000;
  background-color: #009739;
  border-color: #009739;
}

.btn-business {
  color: #fff;
  background-color: #002E5D;
  border-color: #002E5D;
}
.btn-business:hover {
  color: #fff;
  background-color: #00274f;
  border-color: #00254a;
}
.btn-check:focus + .btn-business, .btn-business:focus {
  color: #fff;
  background-color: #00274f;
  border-color: #00254a;
  box-shadow: 0 0 0 0.25rem rgba(38, 77, 117, 0.5);
}
.btn-check:checked + .btn-business, .btn-check:active + .btn-business, .btn-business:active, .btn-business.active, .show > .btn-business.dropdown-toggle {
  color: #fff;
  background-color: #00254a;
  border-color: #002346;
}
.btn-check:checked + .btn-business:focus, .btn-check:active + .btn-business:focus, .btn-business:active:focus, .btn-business.active:focus, .show > .btn-business.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 77, 117, 0.5);
}
.btn-business:disabled, .btn-business.disabled {
  color: #fff;
  background-color: #002E5D;
  border-color: #002E5D;
}

.btn-red {
  color: #fff;
  background-color: #A32035;
  border-color: #A32035;
}
.btn-red:hover {
  color: #fff;
  background-color: #8b1b2d;
  border-color: #821a2a;
}
.btn-check:focus + .btn-red, .btn-red:focus {
  color: #fff;
  background-color: #8b1b2d;
  border-color: #821a2a;
  box-shadow: 0 0 0 0.25rem rgba(177, 65, 83, 0.5);
}
.btn-check:checked + .btn-red, .btn-check:active + .btn-red, .btn-red:active, .btn-red.active, .show > .btn-red.dropdown-toggle {
  color: #fff;
  background-color: #821a2a;
  border-color: #7a1828;
}
.btn-check:checked + .btn-red:focus, .btn-check:active + .btn-red:focus, .btn-red:active:focus, .btn-red.active:focus, .show > .btn-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(177, 65, 83, 0.5);
}
.btn-red:disabled, .btn-red.disabled {
  color: #fff;
  background-color: #A32035;
  border-color: #A32035;
}

.btn-outline-primary {
  color: #A32035;
  border-color: #A32035;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #A32035;
  border-color: #A32035;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(163, 32, 53, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #A32035;
  border-color: #A32035;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(163, 32, 53, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #A32035;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #005EB8;
  border-color: #005EB8;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #005EB8;
  border-color: #005EB8;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 94, 184, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #005EB8;
  border-color: #005EB8;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 94, 184, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #005EB8;
  background-color: transparent;
}

.btn-outline-primary-blue {
  color: #005EB8;
  border-color: #005EB8;
}
.btn-outline-primary-blue:hover {
  color: #fff;
  background-color: #005EB8;
  border-color: #005EB8;
}
.btn-check:focus + .btn-outline-primary-blue, .btn-outline-primary-blue:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 94, 184, 0.5);
}
.btn-check:checked + .btn-outline-primary-blue, .btn-check:active + .btn-outline-primary-blue, .btn-outline-primary-blue:active, .btn-outline-primary-blue.active, .btn-outline-primary-blue.dropdown-toggle.show {
  color: #fff;
  background-color: #005EB8;
  border-color: #005EB8;
}
.btn-check:checked + .btn-outline-primary-blue:focus, .btn-check:active + .btn-outline-primary-blue:focus, .btn-outline-primary-blue:active:focus, .btn-outline-primary-blue.active:focus, .btn-outline-primary-blue.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 94, 184, 0.5);
}
.btn-outline-primary-blue:disabled, .btn-outline-primary-blue.disabled {
  color: #005EB8;
  background-color: transparent;
}

.btn-outline-tertiary {
  color: #FFCE00;
  border-color: #FFCE00;
}
.btn-outline-tertiary:hover {
  color: #000;
  background-color: #FFCE00;
  border-color: #FFCE00;
}
.btn-check:focus + .btn-outline-tertiary, .btn-outline-tertiary:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 206, 0, 0.5);
}
.btn-check:checked + .btn-outline-tertiary, .btn-check:active + .btn-outline-tertiary, .btn-outline-tertiary:active, .btn-outline-tertiary.active, .btn-outline-tertiary.dropdown-toggle.show {
  color: #000;
  background-color: #FFCE00;
  border-color: #FFCE00;
}
.btn-check:checked + .btn-outline-tertiary:focus, .btn-check:active + .btn-outline-tertiary:focus, .btn-outline-tertiary:active:focus, .btn-outline-tertiary.active:focus, .btn-outline-tertiary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 206, 0, 0.5);
}
.btn-outline-tertiary:disabled, .btn-outline-tertiary.disabled {
  color: #FFCE00;
  background-color: transparent;
}

.btn-outline-primary-yellow {
  color: #FFCE00;
  border-color: #FFCE00;
}
.btn-outline-primary-yellow:hover {
  color: #000;
  background-color: #FFCE00;
  border-color: #FFCE00;
}
.btn-check:focus + .btn-outline-primary-yellow, .btn-outline-primary-yellow:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 206, 0, 0.5);
}
.btn-check:checked + .btn-outline-primary-yellow, .btn-check:active + .btn-outline-primary-yellow, .btn-outline-primary-yellow:active, .btn-outline-primary-yellow.active, .btn-outline-primary-yellow.dropdown-toggle.show {
  color: #000;
  background-color: #FFCE00;
  border-color: #FFCE00;
}
.btn-check:checked + .btn-outline-primary-yellow:focus, .btn-check:active + .btn-outline-primary-yellow:focus, .btn-outline-primary-yellow:active:focus, .btn-outline-primary-yellow.active:focus, .btn-outline-primary-yellow.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 206, 0, 0.5);
}
.btn-outline-primary-yellow:disabled, .btn-outline-primary-yellow.disabled {
  color: #FFCE00;
  background-color: transparent;
}

.btn-outline-success {
  color: hsl(121, 100%, 26%);
  border-color: hsl(121, 100%, 26%);
}
.btn-outline-success:hover {
  color: #fff;
  background-color: hsl(121, 100%, 26%);
  border-color: hsl(121, 100%, 26%);
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 133, 2, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: hsl(121, 100%, 26%);
  border-color: hsl(121, 100%, 26%);
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 133, 2, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: hsl(121, 100%, 26%);
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ff8307;
  border-color: #ff8307;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ff8307;
  border-color: #ff8307;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 131, 7, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ff8307;
  border-color: #ff8307;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 131, 7, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ff8307;
  background-color: transparent;
}

.btn-outline-danger {
  color: hsl(0, 95%, 45%);
  border-color: hsl(0, 95%, 45%);
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: hsl(0, 95%, 45%);
  border-color: hsl(0, 95%, 45%);
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(224, 6, 6, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: hsl(0, 95%, 45%);
  border-color: hsl(0, 95%, 45%);
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(224, 6, 6, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: hsl(0, 95%, 45%);
  background-color: transparent;
}

.btn-outline-light {
  color: #dbdbdb;
  border-color: #dbdbdb;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(219, 219, 219, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(219, 219, 219, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #dbdbdb;
  background-color: transparent;
}

.btn-outline-dark {
  color: #1c1c1c;
  border-color: #1c1c1c;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #1c1c1c;
  border-color: #1c1c1c;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(28, 28, 28, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #1c1c1c;
  border-color: #1c1c1c;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(28, 28, 28, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #1c1c1c;
  background-color: transparent;
}

.btn-outline-default {
  color: #fff;
  border-color: #fff;
}
.btn-outline-default:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:focus + .btn-outline-default, .btn-outline-default:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-outline-default, .btn-check:active + .btn-outline-default, .btn-outline-default:active, .btn-outline-default.active, .btn-outline-default.dropdown-toggle.show {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:checked + .btn-outline-default:focus, .btn-check:active + .btn-outline-default:focus, .btn-outline-default:active:focus, .btn-outline-default.active:focus, .btn-outline-default.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-outline-default:disabled, .btn-outline-default.disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-disabled {
  color: #545454;
  border-color: #545454;
}
.btn-outline-disabled:hover {
  color: #fff;
  background-color: #545454;
  border-color: #545454;
}
.btn-check:focus + .btn-outline-disabled, .btn-outline-disabled:focus {
  box-shadow: 0 0 0 0.25rem rgba(84, 84, 84, 0.5);
}
.btn-check:checked + .btn-outline-disabled, .btn-check:active + .btn-outline-disabled, .btn-outline-disabled:active, .btn-outline-disabled.active, .btn-outline-disabled.dropdown-toggle.show {
  color: #fff;
  background-color: #545454;
  border-color: #545454;
}
.btn-check:checked + .btn-outline-disabled:focus, .btn-check:active + .btn-outline-disabled:focus, .btn-outline-disabled:active:focus, .btn-outline-disabled.active:focus, .btn-outline-disabled.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(84, 84, 84, 0.5);
}
.btn-outline-disabled:disabled, .btn-outline-disabled.disabled {
  color: #545454;
  background-color: transparent;
}

.btn-outline-arts-and-sciences {
  color: #009739;
  border-color: #009739;
}
.btn-outline-arts-and-sciences:hover {
  color: #000;
  background-color: #009739;
  border-color: #009739;
}
.btn-check:focus + .btn-outline-arts-and-sciences, .btn-outline-arts-and-sciences:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 151, 57, 0.5);
}
.btn-check:checked + .btn-outline-arts-and-sciences, .btn-check:active + .btn-outline-arts-and-sciences, .btn-outline-arts-and-sciences:active, .btn-outline-arts-and-sciences.active, .btn-outline-arts-and-sciences.dropdown-toggle.show {
  color: #000;
  background-color: #009739;
  border-color: #009739;
}
.btn-check:checked + .btn-outline-arts-and-sciences:focus, .btn-check:active + .btn-outline-arts-and-sciences:focus, .btn-outline-arts-and-sciences:active:focus, .btn-outline-arts-and-sciences.active:focus, .btn-outline-arts-and-sciences.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 151, 57, 0.5);
}
.btn-outline-arts-and-sciences:disabled, .btn-outline-arts-and-sciences.disabled {
  color: #009739;
  background-color: transparent;
}

.btn-outline-business {
  color: #002E5D;
  border-color: #002E5D;
}
.btn-outline-business:hover {
  color: #fff;
  background-color: #002E5D;
  border-color: #002E5D;
}
.btn-check:focus + .btn-outline-business, .btn-outline-business:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 46, 93, 0.5);
}
.btn-check:checked + .btn-outline-business, .btn-check:active + .btn-outline-business, .btn-outline-business:active, .btn-outline-business.active, .btn-outline-business.dropdown-toggle.show {
  color: #fff;
  background-color: #002E5D;
  border-color: #002E5D;
}
.btn-check:checked + .btn-outline-business:focus, .btn-check:active + .btn-outline-business:focus, .btn-outline-business:active:focus, .btn-outline-business.active:focus, .btn-outline-business.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 46, 93, 0.5);
}
.btn-outline-business:disabled, .btn-outline-business.disabled {
  color: #002E5D;
  background-color: transparent;
}

.btn-outline-red {
  color: #A32035;
  border-color: #A32035;
}
.btn-outline-red:hover {
  color: #fff;
  background-color: #A32035;
  border-color: #A32035;
}
.btn-check:focus + .btn-outline-red, .btn-outline-red:focus {
  box-shadow: 0 0 0 0.25rem rgba(163, 32, 53, 0.5);
}
.btn-check:checked + .btn-outline-red, .btn-check:active + .btn-outline-red, .btn-outline-red:active, .btn-outline-red.active, .btn-outline-red.dropdown-toggle.show {
  color: #fff;
  background-color: #A32035;
  border-color: #A32035;
}
.btn-check:checked + .btn-outline-red:focus, .btn-check:active + .btn-outline-red:focus, .btn-outline-red:active:focus, .btn-outline-red.active:focus, .btn-outline-red.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(163, 32, 53, 0.5);
}
.btn-outline-red:disabled, .btn-outline-red.disabled {
  color: #A32035;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #A32035;
  text-decoration: underline;
}
.btn-link:hover {
  color: #821a2a;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.325rem + 0.9vw);
  border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .btn-lg, .btn-group-lg > .btn {
    font-size: 2rem;
  }
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: calc(1.265rem + 0.18vw);
  border-radius: 0.2rem;
}
@media (min-width: 1200px) {
  .btn-sm, .btn-group-sm > .btn {
    font-size: 1.4rem;
  }
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: calc(1.285rem + 0.42vw);
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
@media (min-width: 1200px) {
  .dropdown-menu {
    font-size: 1.6rem;
  }
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 960px) {
  .dropdown-menu-old-lg-bs3-start {
    --bs-position: start;
  }
  .dropdown-menu-old-lg-bs3-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-old-lg-bs3-end {
    --bs-position: end;
  }
  .dropdown-menu-old-lg-bs3-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #A32035;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: calc(1.265rem + 0.18vw);
  color: #6c757d;
  white-space: nowrap;
}
@media (min-width: 1200px) {
  .dropdown-header {
    font-size: 1.4rem;
  }
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #A32035;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #A32035;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #821a2a;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #A32035;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-old-lg-bs3,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin-right: 1rem;
  font-size: calc(1.325rem + 0.9vw);
  text-decoration: none;
  white-space: nowrap;
}
@media (min-width: 1200px) {
  .navbar-brand {
    font-size: 2rem;
  }
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: calc(1.325rem + 0.9vw);
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (min-width: 1200px) {
  .navbar-toggler {
    font-size: 2rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (min-width: 960px) {
  .navbar-expand-old-lg-bs3 {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-old-lg-bs3 .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-old-lg-bs3 .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-old-lg-bs3 .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-old-lg-bs3 .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-old-lg-bs3 .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-old-lg-bs3 .navbar-toggler {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group, .card > .accordionV2 {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child, .card > .accordionV2:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child, .card > .accordionV2:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group, .card > .card-header + .accordionV2,
.card > .list-group + .card-footer,
.card > .accordionV2 + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: calc(1.285rem + 0.42vw);
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (min-width: 1200px) {
  .accordion-button {
    font-size: 1.6rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #931d30;
  background-color: #f6e9eb;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23931d30'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #d1909a;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(163, 32, 53, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
}

.page-link {
  position: relative;
  display: block;
  color: #A32035;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #821a2a;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #821a2a;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(163, 32, 53, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #A32035;
  border-color: #A32035;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .pagination-lg .page-link {
    font-size: 2rem;
  }
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: calc(1.265rem + 0.18vw);
}
@media (min-width: 1200px) {
  .pagination-sm .page-link {
    font-size: 1.4rem;
  }
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #621320;
  background-color: #edd2d7;
  border-color: #e3bcc2;
}
.alert-primary .alert-link {
  color: #4e0f1a;
}

.alert-secondary {
  color: #00386e;
  background-color: #ccdff1;
  border-color: #b3cfea;
}
.alert-secondary .alert-link {
  color: #002d58;
}

.alert-primary-blue {
  color: #00386e;
  background-color: #ccdff1;
  border-color: #b3cfea;
}
.alert-primary-blue .alert-link {
  color: #002d58;
}

.alert-tertiary {
  color: #665200;
  background-color: #fff5cc;
  border-color: #fff0b3;
}
.alert-tertiary .alert-link {
  color: #524200;
}

.alert-primary-yellow {
  color: #665200;
  background-color: #fff5cc;
  border-color: #fff0b3;
}
.alert-primary-yellow .alert-link {
  color: #524200;
}

.alert-success {
  color: #005001;
  background-color: #cce7cc;
  border-color: #b3dab3;
}
.alert-success .alert-link {
  color: #004001;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #994f04;
  background-color: #ffe6cd;
  border-color: #ffdab5;
}
.alert-warning .alert-link {
  color: #7a3f03;
}

.alert-danger {
  color: #860404;
  background-color: #f9cdcd;
  border-color: #f6b4b4;
}
.alert-danger .alert-link {
  color: #6b0303;
}

.alert-light {
  color: #585858;
  background-color: #f8f8f8;
  border-color: #f4f4f4;
}
.alert-light .alert-link {
  color: #464646;
}

.alert-dark {
  color: #111111;
  background-color: #d2d2d2;
  border-color: #bbbbbb;
}
.alert-dark .alert-link {
  color: #0e0e0e;
}

.alert-default {
  color: #666666;
  background-color: white;
  border-color: white;
}
.alert-default .alert-link {
  color: #525252;
}

.alert-disabled {
  color: #323232;
  background-color: #dddddd;
  border-color: #cccccc;
}
.alert-disabled .alert-link {
  color: #282828;
}

.alert-arts-and-sciences {
  color: #005b22;
  background-color: #ccead7;
  border-color: #b3e0c4;
}
.alert-arts-and-sciences .alert-link {
  color: #00491b;
}

.alert-business {
  color: #001c38;
  background-color: #ccd5df;
  border-color: #b3c0ce;
}
.alert-business .alert-link {
  color: #00162d;
}

.alert-red {
  color: #621320;
  background-color: #edd2d7;
  border-color: #e3bcc2;
}
.alert-red .alert-link {
  color: #4e0f1a;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 1.2rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #A32035;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group, .accordionV2 {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item, .accordionV2 > li {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child, .accordionV2 > li:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child, .accordionV2 > li:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .accordionV2 > li.disabled, .list-group-item:disabled, .accordionV2 > li:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active, .accordionV2 > li.active {
  z-index: 2;
  color: #fff;
  background-color: #A32035;
  border-color: #A32035;
}
.list-group-item + .list-group-item, .accordionV2 > li + .list-group-item, .accordionV2 > .list-group-item + li, .accordionV2 > li + li {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active, .accordionV2 > li + .list-group-item.active, .accordionV2 > .list-group-item + li.active, .accordionV2 > li + li.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child, .accordionV2.list-group-horizontal > li:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child, .accordionV2.list-group-horizontal > li:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active, .accordionV2.list-group-horizontal > li.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item, .accordionV2.list-group-horizontal > li + .list-group-item, .accordionV2.list-group-horizontal > .list-group-item + li, .accordionV2.list-group-horizontal > li + li {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active, .accordionV2.list-group-horizontal > li + .list-group-item.active, .accordionV2.list-group-horizontal > .list-group-item + li.active, .accordionV2.list-group-horizontal > li + li.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child, .accordionV2.list-group-horizontal-sm > li:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child, .accordionV2.list-group-horizontal-sm > li:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active, .accordionV2.list-group-horizontal-sm > li.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item, .accordionV2.list-group-horizontal-sm > li + .list-group-item, .accordionV2.list-group-horizontal-sm > .list-group-item + li, .accordionV2.list-group-horizontal-sm > li + li {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active, .accordionV2.list-group-horizontal-sm > li + .list-group-item.active, .accordionV2.list-group-horizontal-sm > .list-group-item + li.active, .accordionV2.list-group-horizontal-sm > li + li.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child, .accordionV2.list-group-horizontal-md > li:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child, .accordionV2.list-group-horizontal-md > li:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active, .accordionV2.list-group-horizontal-md > li.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item, .accordionV2.list-group-horizontal-md > li + .list-group-item, .accordionV2.list-group-horizontal-md > .list-group-item + li, .accordionV2.list-group-horizontal-md > li + li {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active, .accordionV2.list-group-horizontal-md > li + .list-group-item.active, .accordionV2.list-group-horizontal-md > .list-group-item + li.active, .accordionV2.list-group-horizontal-md > li + li.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 960px) {
  .list-group-horizontal-old-lg-bs3 {
    flex-direction: row;
  }
  .list-group-horizontal-old-lg-bs3 > .list-group-item:first-child, .accordionV2.list-group-horizontal-old-lg-bs3 > li:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-old-lg-bs3 > .list-group-item:last-child, .accordionV2.list-group-horizontal-old-lg-bs3 > li:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-old-lg-bs3 > .list-group-item.active, .accordionV2.list-group-horizontal-old-lg-bs3 > li.active {
    margin-top: 0;
  }
  .list-group-horizontal-old-lg-bs3 > .list-group-item + .list-group-item, .accordionV2.list-group-horizontal-old-lg-bs3 > li + .list-group-item, .accordionV2.list-group-horizontal-old-lg-bs3 > .list-group-item + li, .accordionV2.list-group-horizontal-old-lg-bs3 > li + li {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-old-lg-bs3 > .list-group-item + .list-group-item.active, .accordionV2.list-group-horizontal-old-lg-bs3 > li + .list-group-item.active, .accordionV2.list-group-horizontal-old-lg-bs3 > .list-group-item + li.active, .accordionV2.list-group-horizontal-old-lg-bs3 > li + li.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child, .accordionV2.list-group-horizontal-lg > li:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child, .accordionV2.list-group-horizontal-lg > li:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active, .accordionV2.list-group-horizontal-lg > li.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item, .accordionV2.list-group-horizontal-lg > li + .list-group-item, .accordionV2.list-group-horizontal-lg > .list-group-item + li, .accordionV2.list-group-horizontal-lg > li + li {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active, .accordionV2.list-group-horizontal-lg > li + .list-group-item.active, .accordionV2.list-group-horizontal-lg > .list-group-item + li.active, .accordionV2.list-group-horizontal-lg > li + li.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child, .accordionV2.list-group-horizontal-xl > li:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child, .accordionV2.list-group-horizontal-xl > li:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active, .accordionV2.list-group-horizontal-xl > li.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item, .accordionV2.list-group-horizontal-xl > li + .list-group-item, .accordionV2.list-group-horizontal-xl > .list-group-item + li, .accordionV2.list-group-horizontal-xl > li + li {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active, .accordionV2.list-group-horizontal-xl > li + .list-group-item.active, .accordionV2.list-group-horizontal-xl > .list-group-item + li.active, .accordionV2.list-group-horizontal-xl > li + li.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child, .accordionV2.list-group-horizontal-xxl > li:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child, .accordionV2.list-group-horizontal-xxl > li:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active, .accordionV2.list-group-horizontal-xxl > li.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item, .accordionV2.list-group-horizontal-xxl > li + .list-group-item, .accordionV2.list-group-horizontal-xxl > .list-group-item + li, .accordionV2.list-group-horizontal-xxl > li + li {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active, .accordionV2.list-group-horizontal-xxl > li + .list-group-item.active, .accordionV2.list-group-horizontal-xxl > .list-group-item + li.active, .accordionV2.list-group-horizontal-xxl > li + li.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item, .accordionV2.list-group-flush > li {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child, .accordionV2.list-group-flush > li:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #621320;
  background-color: #edd2d7;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #621320;
  background-color: #d5bdc2;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #621320;
  border-color: #621320;
}

.list-group-item-secondary {
  color: #00386e;
  background-color: #ccdff1;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #00386e;
  background-color: #b8c9d9;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #00386e;
  border-color: #00386e;
}

.list-group-item-primary-blue {
  color: #00386e;
  background-color: #ccdff1;
}
.list-group-item-primary-blue.list-group-item-action:hover, .list-group-item-primary-blue.list-group-item-action:focus {
  color: #00386e;
  background-color: #b8c9d9;
}
.list-group-item-primary-blue.list-group-item-action.active {
  color: #fff;
  background-color: #00386e;
  border-color: #00386e;
}

.list-group-item-tertiary {
  color: #665200;
  background-color: #fff5cc;
}
.list-group-item-tertiary.list-group-item-action:hover, .list-group-item-tertiary.list-group-item-action:focus {
  color: #665200;
  background-color: #e6ddb8;
}
.list-group-item-tertiary.list-group-item-action.active {
  color: #fff;
  background-color: #665200;
  border-color: #665200;
}

.list-group-item-primary-yellow {
  color: #665200;
  background-color: #fff5cc;
}
.list-group-item-primary-yellow.list-group-item-action:hover, .list-group-item-primary-yellow.list-group-item-action:focus {
  color: #665200;
  background-color: #e6ddb8;
}
.list-group-item-primary-yellow.list-group-item-action.active {
  color: #fff;
  background-color: #665200;
  border-color: #665200;
}

.list-group-item-success {
  color: #005001;
  background-color: #cce7cc;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #005001;
  background-color: #b8d0b8;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #005001;
  border-color: #005001;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #994f04;
  background-color: #ffe6cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #994f04;
  background-color: #e6cfb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #994f04;
  border-color: #994f04;
}

.list-group-item-danger {
  color: #860404;
  background-color: #f9cdcd;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #860404;
  background-color: #e0b9b9;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #860404;
  border-color: #860404;
}

.list-group-item-light {
  color: #585858;
  background-color: #f8f8f8;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #585858;
  background-color: #dfdfdf;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #585858;
  border-color: #585858;
}

.list-group-item-dark {
  color: #111111;
  background-color: #d2d2d2;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #111111;
  background-color: #bdbdbd;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #111111;
  border-color: #111111;
}

.list-group-item-default {
  color: #666666;
  background-color: white;
}
.list-group-item-default.list-group-item-action:hover, .list-group-item-default.list-group-item-action:focus {
  color: #666666;
  background-color: #e6e6e6;
}
.list-group-item-default.list-group-item-action.active {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.list-group-item-disabled {
  color: #323232;
  background-color: #dddddd;
}
.list-group-item-disabled.list-group-item-action:hover, .list-group-item-disabled.list-group-item-action:focus {
  color: #323232;
  background-color: #c7c7c7;
}
.list-group-item-disabled.list-group-item-action.active {
  color: #fff;
  background-color: #323232;
  border-color: #323232;
}

.list-group-item-arts-and-sciences {
  color: #005b22;
  background-color: #ccead7;
}
.list-group-item-arts-and-sciences.list-group-item-action:hover, .list-group-item-arts-and-sciences.list-group-item-action:focus {
  color: #005b22;
  background-color: #b8d3c2;
}
.list-group-item-arts-and-sciences.list-group-item-action.active {
  color: #fff;
  background-color: #005b22;
  border-color: #005b22;
}

.list-group-item-business {
  color: #001c38;
  background-color: #ccd5df;
}
.list-group-item-business.list-group-item-action:hover, .list-group-item-business.list-group-item-action:focus {
  color: #001c38;
  background-color: #b8c0c9;
}
.list-group-item-business.list-group-item-action.active {
  color: #fff;
  background-color: #001c38;
  border-color: #001c38;
}

.list-group-item-red {
  color: #621320;
  background-color: #edd2d7;
}
.list-group-item-red.list-group-item-action:hover, .list-group-item-red.list-group-item-action:focus {
  color: #621320;
  background-color: #d5bdc2;
}
.list-group-item-red.list-group-item-action.active {
  color: #fff;
  background-color: #621320;
  border-color: #621320;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(163, 32, 53, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast:not(.showing):not(.show) {
  opacity: 0;
}
.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 959.98px) {
  .modal-fullscreen-old-lg-bs3-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-old-lg-bs3-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-old-lg-bs3-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-old-lg-bs3-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-old-lg-bs3-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: calc(1.265rem + 0.18vw);
  word-wrap: break-word;
  opacity: 0;
}
@media (min-width: 1200px) {
  .tooltip {
    font-size: 1.4rem;
  }
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: calc(1.265rem + 0.18vw);
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .popover {
    font-size: 1.4rem;
  }
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: calc(1.285rem + 0.42vw);
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
@media (min-width: 1200px) {
  .popover-header {
    font-size: 1.6rem;
  }
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #A32035;
}
.link-primary:hover, .link-primary:focus {
  color: #821a2a;
}

.link-secondary {
  color: #005EB8;
}
.link-secondary:hover, .link-secondary:focus {
  color: #004b93;
}

.link-primary-blue {
  color: #005EB8;
}
.link-primary-blue:hover, .link-primary-blue:focus {
  color: #004b93;
}

.link-tertiary {
  color: #FFCE00;
}
.link-tertiary:hover, .link-tertiary:focus {
  color: #ffd833;
}

.link-primary-yellow {
  color: #FFCE00;
}
.link-primary-yellow:hover, .link-primary-yellow:focus {
  color: #ffd833;
}

.link-success {
  color: hsl(121, 100%, 26%);
}
.link-success:hover, .link-success:focus {
  color: #006a02;
}

.link-info {
  color: #0dcaf0;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ff8307;
}
.link-warning:hover, .link-warning:focus {
  color: #ff9c39;
}

.link-danger {
  color: hsl(0, 95%, 45%);
}
.link-danger:hover, .link-danger:focus {
  color: #b30505;
}

.link-light {
  color: #dbdbdb;
}
.link-light:hover, .link-light:focus {
  color: #e2e2e2;
}

.link-dark {
  color: #1c1c1c;
}
.link-dark:hover, .link-dark:focus {
  color: #161616;
}

.link-default {
  color: #fff;
}
.link-default:hover, .link-default:focus {
  color: white;
}

.link-disabled {
  color: #545454;
}
.link-disabled:hover, .link-disabled:focus {
  color: #434343;
}

.link-arts-and-sciences {
  color: #009739;
}
.link-arts-and-sciences:hover, .link-arts-and-sciences:focus {
  color: #33ac61;
}

.link-business {
  color: #002E5D;
}
.link-business:hover, .link-business:focus {
  color: #00254a;
}

.link-red {
  color: #A32035;
}
.link-red:hover, .link-red:focus {
  color: #821a2a;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 960px) {
  .sticky-old-lg-bs3-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #A32035 !important;
}

.border-secondary {
  border-color: #005EB8 !important;
}

.border-primary-blue {
  border-color: #005EB8 !important;
}

.border-tertiary {
  border-color: #FFCE00 !important;
}

.border-primary-yellow {
  border-color: #FFCE00 !important;
}

.border-success {
  border-color: hsl(121, 100%, 26%) !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ff8307 !important;
}

.border-danger {
  border-color: hsl(0, 95%, 45%) !important;
}

.border-light {
  border-color: #dbdbdb !important;
}

.border-dark {
  border-color: #1c1c1c !important;
}

.border-default {
  border-color: #fff !important;
}

.border-disabled {
  border-color: #545454 !important;
}

.border-arts-and-sciences {
  border-color: #009739 !important;
}

.border-business {
  border-color: #002E5D !important;
}

.border-red {
  border-color: #A32035 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.525rem + 3.3vw) !important;
}

.fs-2 {
  font-size: calc(1.445rem + 2.34vw) !important;
}

.fs-3 {
  font-size: calc(1.405rem + 1.86vw) !important;
}

.fs-4 {
  font-size: calc(1.365rem + 1.38vw) !important;
}

.fs-5 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-6 {
  font-size: calc(1.285rem + 0.42vw) !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #A32035 !important;
}

.text-secondary {
  color: #005EB8 !important;
}

.text-primary-blue {
  color: #005EB8 !important;
}

.text-tertiary {
  color: #FFCE00 !important;
}

.text-primary-yellow {
  color: #FFCE00 !important;
}

.text-success {
  color: hsl(121, 100%, 26%) !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-warning {
  color: #ff8307 !important;
}

.text-danger {
  color: hsl(0, 95%, 45%) !important;
}

.text-light {
  color: #dbdbdb !important;
}

.text-dark {
  color: #1c1c1c !important;
}

.text-default {
  color: #fff !important;
}

.text-disabled {
  color: #545454 !important;
}

.text-arts-and-sciences {
  color: #009739 !important;
}

.text-business {
  color: #002E5D !important;
}

.text-red {
  color: #A32035 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #A32035 !important;
}

.bg-secondary {
  background-color: #005EB8 !important;
}

.bg-primary-blue {
  background-color: #005EB8 !important;
}

.bg-tertiary {
  background-color: #FFCE00 !important;
}

.bg-primary-yellow {
  background-color: #FFCE00 !important;
}

.bg-success {
  background-color: hsl(121, 100%, 26%) !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: #ff8307 !important;
}

.bg-danger {
  background-color: hsl(0, 95%, 45%) !important;
}

.bg-light {
  background-color: #dbdbdb !important;
}

.bg-dark {
  background-color: #1c1c1c !important;
}

.bg-default {
  background-color: #fff !important;
}

.bg-disabled {
  background-color: #545454 !important;
}

.bg-arts-and-sciences {
  background-color: #009739 !important;
}

.bg-business {
  background-color: #002E5D !important;
}

.bg-red {
  background-color: #A32035 !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 960px) {
  .float-old-lg-bs3-start {
    float: left !important;
  }
  .float-old-lg-bs3-end {
    float: right !important;
  }
  .float-old-lg-bs3-none {
    float: none !important;
  }
  .d-old-lg-bs3-inline {
    display: inline !important;
  }
  .d-old-lg-bs3-inline-block {
    display: inline-block !important;
  }
  .d-old-lg-bs3-block {
    display: block !important;
  }
  .d-old-lg-bs3-grid {
    display: grid !important;
  }
  .d-old-lg-bs3-table {
    display: table !important;
  }
  .d-old-lg-bs3-table-row {
    display: table-row !important;
  }
  .d-old-lg-bs3-table-cell {
    display: table-cell !important;
  }
  .d-old-lg-bs3-flex {
    display: flex !important;
  }
  .d-old-lg-bs3-inline-flex {
    display: inline-flex !important;
  }
  .d-old-lg-bs3-none {
    display: none !important;
  }
  .flex-old-lg-bs3-fill {
    flex: 1 1 auto !important;
  }
  .flex-old-lg-bs3-row {
    flex-direction: row !important;
  }
  .flex-old-lg-bs3-column {
    flex-direction: column !important;
  }
  .flex-old-lg-bs3-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-old-lg-bs3-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-old-lg-bs3-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-old-lg-bs3-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-old-lg-bs3-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-old-lg-bs3-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-old-lg-bs3-wrap {
    flex-wrap: wrap !important;
  }
  .flex-old-lg-bs3-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-old-lg-bs3-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-old-lg-bs3-0 {
    gap: 0 !important;
  }
  .gap-old-lg-bs3-1 {
    gap: 0.25rem !important;
  }
  .gap-old-lg-bs3-2 {
    gap: 0.5rem !important;
  }
  .gap-old-lg-bs3-3 {
    gap: 1rem !important;
  }
  .gap-old-lg-bs3-4 {
    gap: 1.5rem !important;
  }
  .gap-old-lg-bs3-5 {
    gap: 3rem !important;
  }
  .justify-content-old-lg-bs3-start {
    justify-content: flex-start !important;
  }
  .justify-content-old-lg-bs3-end {
    justify-content: flex-end !important;
  }
  .justify-content-old-lg-bs3-center {
    justify-content: center !important;
  }
  .justify-content-old-lg-bs3-between {
    justify-content: space-between !important;
  }
  .justify-content-old-lg-bs3-around {
    justify-content: space-around !important;
  }
  .justify-content-old-lg-bs3-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-old-lg-bs3-start {
    align-items: flex-start !important;
  }
  .align-items-old-lg-bs3-end {
    align-items: flex-end !important;
  }
  .align-items-old-lg-bs3-center {
    align-items: center !important;
  }
  .align-items-old-lg-bs3-baseline {
    align-items: baseline !important;
  }
  .align-items-old-lg-bs3-stretch {
    align-items: stretch !important;
  }
  .align-content-old-lg-bs3-start {
    align-content: flex-start !important;
  }
  .align-content-old-lg-bs3-end {
    align-content: flex-end !important;
  }
  .align-content-old-lg-bs3-center {
    align-content: center !important;
  }
  .align-content-old-lg-bs3-between {
    align-content: space-between !important;
  }
  .align-content-old-lg-bs3-around {
    align-content: space-around !important;
  }
  .align-content-old-lg-bs3-stretch {
    align-content: stretch !important;
  }
  .align-self-old-lg-bs3-auto {
    align-self: auto !important;
  }
  .align-self-old-lg-bs3-start {
    align-self: flex-start !important;
  }
  .align-self-old-lg-bs3-end {
    align-self: flex-end !important;
  }
  .align-self-old-lg-bs3-center {
    align-self: center !important;
  }
  .align-self-old-lg-bs3-baseline {
    align-self: baseline !important;
  }
  .align-self-old-lg-bs3-stretch {
    align-self: stretch !important;
  }
  .order-old-lg-bs3-first {
    order: -1 !important;
  }
  .order-old-lg-bs3-0 {
    order: 0 !important;
  }
  .order-old-lg-bs3-1 {
    order: 1 !important;
  }
  .order-old-lg-bs3-2 {
    order: 2 !important;
  }
  .order-old-lg-bs3-3 {
    order: 3 !important;
  }
  .order-old-lg-bs3-4 {
    order: 4 !important;
  }
  .order-old-lg-bs3-5 {
    order: 5 !important;
  }
  .order-old-lg-bs3-last {
    order: 6 !important;
  }
  .m-old-lg-bs3-0 {
    margin: 0 !important;
  }
  .m-old-lg-bs3-1 {
    margin: 0.25rem !important;
  }
  .m-old-lg-bs3-2 {
    margin: 0.5rem !important;
  }
  .m-old-lg-bs3-3 {
    margin: 1rem !important;
  }
  .m-old-lg-bs3-4 {
    margin: 1.5rem !important;
  }
  .m-old-lg-bs3-5 {
    margin: 3rem !important;
  }
  .m-old-lg-bs3-auto {
    margin: auto !important;
  }
  .mx-old-lg-bs3-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-old-lg-bs3-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-old-lg-bs3-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-old-lg-bs3-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-old-lg-bs3-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-old-lg-bs3-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-old-lg-bs3-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-old-lg-bs3-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-old-lg-bs3-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-old-lg-bs3-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-old-lg-bs3-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-old-lg-bs3-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-old-lg-bs3-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-old-lg-bs3-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-old-lg-bs3-0 {
    margin-top: 0 !important;
  }
  .mt-old-lg-bs3-1 {
    margin-top: 0.25rem !important;
  }
  .mt-old-lg-bs3-2 {
    margin-top: 0.5rem !important;
  }
  .mt-old-lg-bs3-3 {
    margin-top: 1rem !important;
  }
  .mt-old-lg-bs3-4 {
    margin-top: 1.5rem !important;
  }
  .mt-old-lg-bs3-5 {
    margin-top: 3rem !important;
  }
  .mt-old-lg-bs3-auto {
    margin-top: auto !important;
  }
  .me-old-lg-bs3-0 {
    margin-right: 0 !important;
  }
  .me-old-lg-bs3-1 {
    margin-right: 0.25rem !important;
  }
  .me-old-lg-bs3-2 {
    margin-right: 0.5rem !important;
  }
  .me-old-lg-bs3-3 {
    margin-right: 1rem !important;
  }
  .me-old-lg-bs3-4 {
    margin-right: 1.5rem !important;
  }
  .me-old-lg-bs3-5 {
    margin-right: 3rem !important;
  }
  .me-old-lg-bs3-auto {
    margin-right: auto !important;
  }
  .mb-old-lg-bs3-0 {
    margin-bottom: 0 !important;
  }
  .mb-old-lg-bs3-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-old-lg-bs3-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-old-lg-bs3-3 {
    margin-bottom: 1rem !important;
  }
  .mb-old-lg-bs3-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-old-lg-bs3-5 {
    margin-bottom: 3rem !important;
  }
  .mb-old-lg-bs3-auto {
    margin-bottom: auto !important;
  }
  .ms-old-lg-bs3-0 {
    margin-left: 0 !important;
  }
  .ms-old-lg-bs3-1 {
    margin-left: 0.25rem !important;
  }
  .ms-old-lg-bs3-2 {
    margin-left: 0.5rem !important;
  }
  .ms-old-lg-bs3-3 {
    margin-left: 1rem !important;
  }
  .ms-old-lg-bs3-4 {
    margin-left: 1.5rem !important;
  }
  .ms-old-lg-bs3-5 {
    margin-left: 3rem !important;
  }
  .ms-old-lg-bs3-auto {
    margin-left: auto !important;
  }
  .p-old-lg-bs3-0 {
    padding: 0 !important;
  }
  .p-old-lg-bs3-1 {
    padding: 0.25rem !important;
  }
  .p-old-lg-bs3-2 {
    padding: 0.5rem !important;
  }
  .p-old-lg-bs3-3 {
    padding: 1rem !important;
  }
  .p-old-lg-bs3-4 {
    padding: 1.5rem !important;
  }
  .p-old-lg-bs3-5 {
    padding: 3rem !important;
  }
  .px-old-lg-bs3-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-old-lg-bs3-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-old-lg-bs3-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-old-lg-bs3-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-old-lg-bs3-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-old-lg-bs3-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-old-lg-bs3-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-old-lg-bs3-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-old-lg-bs3-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-old-lg-bs3-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-old-lg-bs3-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-old-lg-bs3-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-old-lg-bs3-0 {
    padding-top: 0 !important;
  }
  .pt-old-lg-bs3-1 {
    padding-top: 0.25rem !important;
  }
  .pt-old-lg-bs3-2 {
    padding-top: 0.5rem !important;
  }
  .pt-old-lg-bs3-3 {
    padding-top: 1rem !important;
  }
  .pt-old-lg-bs3-4 {
    padding-top: 1.5rem !important;
  }
  .pt-old-lg-bs3-5 {
    padding-top: 3rem !important;
  }
  .pe-old-lg-bs3-0 {
    padding-right: 0 !important;
  }
  .pe-old-lg-bs3-1 {
    padding-right: 0.25rem !important;
  }
  .pe-old-lg-bs3-2 {
    padding-right: 0.5rem !important;
  }
  .pe-old-lg-bs3-3 {
    padding-right: 1rem !important;
  }
  .pe-old-lg-bs3-4 {
    padding-right: 1.5rem !important;
  }
  .pe-old-lg-bs3-5 {
    padding-right: 3rem !important;
  }
  .pb-old-lg-bs3-0 {
    padding-bottom: 0 !important;
  }
  .pb-old-lg-bs3-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-old-lg-bs3-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-old-lg-bs3-3 {
    padding-bottom: 1rem !important;
  }
  .pb-old-lg-bs3-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-old-lg-bs3-5 {
    padding-bottom: 3rem !important;
  }
  .ps-old-lg-bs3-0 {
    padding-left: 0 !important;
  }
  .ps-old-lg-bs3-1 {
    padding-left: 0.25rem !important;
  }
  .ps-old-lg-bs3-2 {
    padding-left: 0.5rem !important;
  }
  .ps-old-lg-bs3-3 {
    padding-left: 1rem !important;
  }
  .ps-old-lg-bs3-4 {
    padding-left: 1.5rem !important;
  }
  .ps-old-lg-bs3-5 {
    padding-left: 3rem !important;
  }
  .text-old-lg-bs3-start {
    text-align: left !important;
  }
  .text-old-lg-bs3-end {
    text-align: right !important;
  }
  .text-old-lg-bs3-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 4rem !important;
  }
  .fs-2 {
    font-size: 3.2rem !important;
  }
  .fs-3 {
    font-size: 2.8rem !important;
  }
  .fs-4 {
    font-size: 2.4rem !important;
  }
  .fs-5 {
    font-size: 2rem !important;
  }
  .fs-6 {
    font-size: 1.6rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
:root {
  font-size: 10px;
  line-height: 1.5;
}

body {
  font-size: 1.6rem !important;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.5;
}

.heading__type {
  text-align: center;
  border-bottom: 1px solid #eeeeee;
}

code, kbd, pre, samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  padding: 15px 25px;
  background-color: #949494;
  margin-bottom: 0;
}

.subheading, h1 .subheading, h1.subheading, h2 .subheading, h2.subheading, h3 .subheading, h3.subheading, h4 .subheading, h4.subheading, h5 .subheading, h5.subheading, h6 .subheading, h6.subheading, .h1 .subheading, .h1.subheading, .h2 .subheading, .h2.subheading, .h3 .subheading, .h3.subheading, .h4 .subheading, .h4.subheading, .h5 .subheading, .h5.subheading, .h6 .subheading, .h6.subheading {
  color: #383838 !important;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
h1 small,
h1 .small, h2 small,
h2 .small, h3 small,
h3 .small, h4 small,
h4 .small, h5 small,
h5 .small, h6 small,
h6 .small, .h1 small,
.h1 .small, .h2 small,
.h2 .small, .h3 small,
.h3 .small, .h4 small,
.h4 .small, .h5 small,
.h5 .small, .h6 small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #545454;
}
h1,
.h1,
h2,
.h2 {
  font-family: "Noto Serif", serif;
}

body {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
  background-color: #fff;
}

h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 22px;
  margin-bottom: 12px;
}

h1,
.h1 {
  color: #fff;
  font-size: 5.2rem;
  line-height: 62px;
}

h2,
.h2 {
  color: #A32035;
  font-size: 3.2rem;
  line-height: 38px;
}

h3,
.h3 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 2.1rem;
  line-height: 28px;
}
h3 a,
.h3 a {
  text-decoration: underline;
  text-decoration-color: #A32035;
}
a h3,
h3 a,
a .h3,
.h3 a {
  color: rgba(0, 0, 0, 0.8);
  text-decoration: underline;
  text-decoration-color: #A32035;
}

.h3, .h5, .h6, h3, h5, h6 {
  font-weight: bold;
}

@font-face {
  font-family: "standard-icons";
  src: url("../../../fonts/standard-icons.eot?c331768050b06dedf63880bf151d6dde");
  src: url("../../../fonts/standard-icons.eot?#iefix") format("embedded-opentype"), url("../../../fonts/standard-icons.woff2?c331768050b06dedf63880bf151d6dde") format("woff2"), url("../../../fonts/standard-icons.woff?c331768050b06dedf63880bf151d6dde") format("woff"), url("../../../fonts/standard-icons.ttf?c331768050b06dedf63880bf151d6dde") format("truetype"), url("../../../fonts/standard-icons.svg?c331768050b06dedf63880bf151d6dde#standard-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.checkbox input:checked + .checkbox-button:after, .image__video-link a:after {
  font-family: "standard-icons";
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.responsivetable__container .responsivetable.scrollable:before {
  font-family: "standard-icons";
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon:after {
  font-family: "standard-icons";
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon__blackboard:after {
  content: "\f101";
}

.icon__board:after {
  content: "\f102";
}

.icon__book:after {
  content: "\f103";
}

.icon__boxarrow:after {
  content: "\f104";
}

.icon__brochure:after {
  content: "\f105";
}

.icon__calculator:after {
  content: "\f106";
}

.icon__calendar:after {
  content: "\f107";
}

.icon__carat:after {
  content: "\f108";
}

.icon__chat:after {
  content: "\f109";
}

.icon__check:after {
  content: "\f10a";
}

.icon__checkbox:after {
  content: "\f10b";
}

.icon__checkoutline:after {
  content: "\f10c";
}

.icon__close:after {
  content: "\f10d";
}

.icon__cost:after {
  content: "\f10e";
}

.icon__credit:after {
  content: "\f10f";
}

.icon__curvearrow:after {
  content: "\f110";
}

.icon__downarrow:after {
  content: "\f111";
}

.icon__downchevrons:after {
  content: "\f112";
}

.icon__download:after {
  content: "\f113";
}

.icon__email:after {
  content: "\f114";
}

.icon__facebook:after {
  content: "\f115";
}

.icon__faculty:after {
  content: "\f116";
}

.icon__growth:after {
  content: "\f117";
}

.icon__growth.down:after {
  color: #A32035;
  transform: rotate(180deg);
}

.icon__hybrid:after {
  content: "\f118";
}

.icon__inperson:after {
  content: "\f119";
}

.icon__inpersonutility:after {
  content: "\f11a";
}

.icon__instagram:after {
  content: "\f11b";
}

.icon__largeleftarrow:after {
  content: "\f11c";
}

.icon__largerightarrow:after {
  content: "\f11d";
}

.icon__leadership:after {
  content: "\f11e";
}

.icon__leftarrow:after {
  content: "\f11f";
}

.icon__linkedin:after {
  content: "\f120";
}

.icon__linkedinfull:after {
  content: "\f121";
}

.icon__mybrandman:after {
  content: "\f122";
}

.icon__online:after {
  content: "\f123";
}

.icon__onlineself:after {
  content: "\f124";
}

.icon__pencil:after {
  content: "\f125";
}

.icon__personcheck:after {
  content: "\f126";
}

.icon__phone:after {
  content: "\f127";
}

.icon__pin:after {
  content: "\f128";
}

.icon__play:after {
  content: "\f129";
}

.icon__podcast:after {
  content: "\f12a";
}

.icon__print:after {
  content: "\f12b";
}

.icon__request:after {
  content: "";
  background-image: url("../../../fonts/refresh.svg");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}

.icon__rightarrow:after {
  content: "\f12d";
}

.icon__rightmorearrow:after {
  content: "\f12e";
}

.icon__search:after {
  content: "\f12f";
}

.icon__selfservice:after {
  content: "\f130";
}

.icon__staff:after {
  content: "\f131";
}

.icon__twitter:after {
  content: "\f132";
}

.icon__uparrow:after {
  content: "\f133";
}

.icon__video:after {
  content: "\f134";
}

.icon__youtube:after {
  content: "\f135";
}

.section__description {
  margin: 0 auto 30px;
  max-width: 775px;
}
.section__description:last-child {
  margin-bottom: 0;
}
.section__light-text {
  color: #fff;
}
.section__light-text h1, .section__light-text .h1, .section__light-text h2, .section__light-text .h2, .section__light-text h3, .section__light-text .h3, .section__light-text h4, .section__light-text .h4, .section__light-text h5, .section__light-text .h5, .section__light-text h6, .section__light-text .h6 {
  color: #fff;
}
.section__light-text p {
  color: #fff;
}
.section__light-text .section__description p {
  color: #fff;
}

.bio__school {
  color: #707070;
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.1;
  margin-bottom: 4px;
}
.bio__name * {
  color: rgba(0, 0, 0, 0.8);
  font-size: 2.1rem;
  line-height: 28px;
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  margin: 0 0 4px;
}
.bio__title {
  font-size: 1.4rem;
  color: #707070;
  font-style: italic;
  line-height: 28px;
}
.bio__header {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}
.bio__header-connect {
  border-left: 1px solid #eeeeee;
  padding-left: 30px;
  margin-left: 30px;
}
.bio__header-connect .linkcollection__header {
  margin-bottom: 2px;
}
.bio__header-connect .linkcollection__header * {
  font-size: 1.2rem;
  line-height: 1.6;
  margin: 0;
}
.bio__header-connect .linkcollection__connect {
  font-size: 3.5rem;
  line-height: 1;
}
.bio__blog hr {
  margin-bottom: 0;
  margin-top: 50px;
}

.callout {
  background-color: #fafafa;
  color: #707070;
  margin-bottom: 20px;
  padding: 25px;
}
.callout:last-child {
  margin-bottom: 0;
}

.datablock {
  margin-bottom: 25px;
}
.datablock .subheading {
  color: #707070;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 2.14;
}
@media (max-width: 767.98px) {
  .datablock .subheading {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
.datablock__title {
  font-weight: bold;
}
.datablock__description {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.4rem;
  line-height: 1.42;
  letter-spacing: 0.03em;
}
.datablock__description p {
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .datablock__description {
    color: #707070;
    line-height: 1.6;
  }
  .datablock__description em {
    color: rgba(0, 0, 0, 0.8);
  }
}
.datablock__tagline {
  margin-top: 12px;
}
.datablock__column {
  display: inline-block;
  width: 40%;
  margin-right: 30px;
  vertical-align: top;
}
@media (max-width: 767.98px) {
  .datablock__column {
    width: auto;
    margin-right: 0;
  }
}
.datablock .readmore {
  margin: 12px 0;
}

.readmore__text {
  display: none;
}
.readmore__open .readmore__text {
  display: block;
}
.readmore__link button {
  color: #A32035;
  text-decoration: underline;
  font-weight: bold;
  margin: 12px 0;
  position: relative;
}
.readmore__link button .icon {
  position: absolute;
  right: -19px;
  top: 0;
}
.readmore__link--misc button {
  color: #A32035;
  text-decoration: underline;
  font-weight: bold;
  margin: 12px 0;
  position: relative;
}
.readmore__link--misc button .icon {
  position: absolute;
  right: -19px;
  top: 0;
}
.readmore__button {
  color: #A32035;
  display: block;
  text-align: center;
  overflow: hidden;
  padding-bottom: 7px;
}
.readmore__button button {
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 700;
  position: relative;
  padding: 10px 40px;
  text-decoration: none;
}
.readmore__button button:hover {
  color: #A32035;
  text-decoration: underline;
}
.readmore__button button:after, .readmore__button button:before {
  border-bottom: 1px solid #eeeeee;
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  width: 50vw;
}
.readmore__button button:after {
  left: 100%;
}
.readmore__button button:before {
  right: 100%;
}
.readmore__button-icon {
  bottom: -7px;
  left: 0;
  position: absolute;
  width: 100%;
  display: block;
  top: auto;
}
.readmore__open button .icon {
  transform: scale(1, -1);
}

.red {
  color: #A32035;
}

.icon {
  text-decoration: none;
}

.icons .anchors__header {
  margin: 10px 0;
}

@media (min-width: 992px) {
  .responsivetable__container {
    padding-right: 20px;
  }
}
.responsivetable__container .responsivetable {
  border-right: 1px solid #eeeeee;
  padding-right: 35px;
  position: relative;
}
@media (max-width: 767.98px) {
  .responsivetable__container .responsivetable {
    border-right: none;
    border-bottom: 1px solid #eeeeee;
    padding-right: 0;
    margin-bottom: 30px;
  }
}
.section__content .responsivetable__container .responsivetable {
  border: none;
  padding-right: 0;
  margin-bottom: 0;
}
.responsivetable__container .responsivetable.scrollable:before {
  content: "\f12e";
}
@media (max-width: 767.98px) {
  .responsivetable__container .responsivetable.scrollable {
    margin-top: 24px;
    margin-left: -25px;
    margin-right: -25px;
    padding: 18px 0 0;
  }
  .responsivetable__container .responsivetable.scrollable .table-responsive {
    padding: 12px 25px;
  }
  .responsivetable__container .responsivetable.scrollable:before {
    color: #707070;
    font-size: 2rem;
    line-height: 0.5;
    top: 0;
    position: absolute;
    right: 12px;
  }
}
@media (min-width: 992px) {
  .responsivetable__container .responsivetable.scrollable:before {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .responsivetable__block {
    border: none;
  }
}
.responsivetable__tagline {
  color: #707070;
  font-size: 1.4rem;
  line-height: 1.42;
  font-weight: normal;
  font-style: italic;
}
@media (max-width: 767.98px) {
  .responsivetable__tagline {
    padding-top: 22px;
  }
}
@media (min-width: 992px) {
  .responsivetable__tagline p {
    margin: 0;
  }
}
.container .section .responsivetable__tagline .row {
  margin-left: -15px;
  margin-right: -15px;
}

.people-finder .readmore__button button:after, .people-finder .readmore__button button:before, .people-finder .degree-finder__filter-wrapper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1294117647);
}

.header {
  background: #fff;
  padding: 16px 0;
  position: relative;
}
.keyboard .header__global {
  top: -138px;
}
@media (min-width: 1200px) {
  .keyboard .header__global {
    top: -119px;
  }
}
@media print {
  .header__global {
    position: relative;
    left: auto;
    top: auto;
  }
}
.header__global .mobile {
  display: none;
}
@media (min-width: 1200px) {
  .header__global .mobile {
    display: initial;
  }
}
.header__global .desktop {
  display: none;
}
@media (min-width: 992px) {
  .header__global .desktop {
    display: initial;
  }
}
@media (max-width: 767.98px) {
  .header {
    padding: 16px 25px;
  }
}
@media (min-width: 768px) {
  .header {
    padding: 16px 40px;
  }
}
.header__logo {
  display: inline-block;
  width: 250px;
}
@media (max-width: 767.98px) {
  .header__logo {
    width: 180px;
  }
}
@media print {
  .header__logo:after {
    display: none;
  }
}
.header__container:after, .header__container:before {
  display: none;
}
@media (min-width: 1200px) {
  .header__container {
    position: static;
    padding: 0;
  }
}
.header__landing .header__logo {
  display: block;
  margin-right: 1.5rem;
  max-width: 6rem;
}

.breadcrumb__nav {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}
@media print {
  .breadcrumb__nav {
    display: none;
  }
}
@media (min-width: 1200px) {
  .breadcrumb__nav .container {
    padding: 0 25px;
  }
}
.breadcrumb nav {
  background: none;
}

.partner__logo {
  float: left;
  margin-left: 1.5rem;
  padding-left: 1.5rem;
  border-left: 1px solid #dbdbdb;
  color: #737373;
  font-size: 16pt;
  text-decoration: none;
  text-align: center;
  height: 75px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  overflow: hidden;
}
.partner__logo h5, .partner__logo .h5 {
  display: table-cell;
  vertical-align: middle;
  color: #737373;
}
@media (min-width: 768px) {
  .partner__logo h5, .partner__logo .h5 {
    font-size: 16pt;
    font-weight: 500;
    line-height: 1.2;
  }
}
.partner__logo img {
  max-height: 100%;
  width: auto;
  margin-top: 0;
  height: auto;
  max-width: 100%;
}
@media (min-width: 960px) {
  .partner__logo img {
    /*height: 60px;
    width: auto;
    max-width: 20vw;
    margin-top: 8px;*/
    width: auto;
    margin-top: 0;
    height: auto;
    max-width: 100%;
  }
}
@media (max-width: 960px) {
  .partner__logo {
    width: 100%;
    /*padding: 0 28px;
    margin: 12px 0;
    font-size: 8pt;
    width: 100%;
    max-width: 100%;*/
  }
  .partner__logo p {
    margin: auto;
  }
  .partner__logo img {
    /*width: auto;
    margin-top: 3px;
    height: 90%;
    max-width: 100%;*/
  }
}

/*.partner_portal_logo {
    //padding: 15px 20px 0;
    //padding-top: 25px !important;
    max-height: 90px;
    @include media-breakpoint-down(md) {
        width: 111%;
        padding: 0;
        margin: 0 45px;
    }
    @include media-breakpoint-only(md) {
        width: 100%;
        margin-right: auto !important;
        margin-left: auto !important;
        margin: 0 auto;
        text-align: center;
        display: block;
    }
}*/
.left-bar {
  border-left: 1px solid #dbdbdb;
}
@media (min-width: 992px) {
  .left-bar {
    width: 12% !important;
  }
}

.pre_header {
  font-size: 1rem;
  text-align: center;
  background-color: #fafafa;
  height: 38px;
  padding: 10px;
  color: #545454;
}
.pre_header .link {
  font-weight: bold;
  color: #545454;
}

@media (max-width: 992px) {
  .no_padding {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.btn-grey {
  background-color: #b3b3b3 !important;
  color: black;
  width: 100%;
}

.btn-light {
  width: 100%;
  background-color: #fafafa !important;
}

.no_border {
  border: none;
}

.footer {
  border-top: 1px solid rgba(0, 45, 93, 0.2588235294);
  background: white;
  color: #707070;
  font-size: 1.4rem;
  overflow: hidden;
  padding: 70px 0 0 0;
}
@media print {
  .footer {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .footer {
    padding: 40px 0 0 0;
  }
}
@media (min-width: 1200px) {
  .footer .container {
    padding: 0 25px;
    width: 100%;
  }
}
.footer .subheading {
  color: #fff;
  font-weight: 700;
  font-size: 1.4rem;
}
.footer .subheading a {
  color: #fff;
}
.footer a {
  color: #002f5c;
  text-decoration: none;
}
.footer a:hover {
  color: #A32035;
  font-weight: bold;
  text-decoration: none;
}
.footer .btn-link {
  text-decoration: underline;
  text-decoration-color: #A32035;
}
.footer h3, .footer .h3 {
  color: #707070;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 23px;
  margin-top: 0;
  margin-bottom: 5px;
}
.footer address {
  color: #383838;
  font-size: 1.3rem;
  letter-spacing: 0.03em;
  line-height: 23px;
  margin-bottom: 30px;
}
.footer address h3, .footer address .h3 {
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .footer address {
    width: 48%;
    margin: 0 0 12px;
    letter-spacing: 0;
  }
}
@media (min-width: 992px) {
  .footer address {
    max-width: 200px;
  }
}
.footer__logo {
  display: block;
  margin: 15px 0;
}
.footer__logo img {
  height: auto;
  width: 100%;
  max-width: 200px;
}
@media (max-width: 767.98px) {
  .footer__logo {
    margin: 25px 0;
    width: 45%;
  }
}
@media (min-width: 768px) and (max-width: 959.98px) {
  .footer__logo {
    margin: 35px 0;
    width: 45%;
  }
}
.footer__social-link {
  font-size: 2.5rem;
}
.footer__social-link-text {
  display: none;
}
.footer .footer__social-link {
  color: #002f5c;
}
.footer .footer__social-link:hover {
  color: #A32035;
}
@media (max-width: 767.98px) {
  .footer__social {
    margin-bottom: 20px;
    width: 100%;
  }
}
@media screen and (min-width: 960px) {
  .footer__social {
    margin-bottom: 75px;
    padding-bottom: 40px;
  }
}
.footer__contact .subheading {
  color: #383838;
  font-size: 1.3rem;
  text-transform: none;
  margin: 0;
  font-weight: bold;
  overflow: auto;
}
.footer__contact-links {
  background: #dbdbdb;
}
.footer .container {
  position: relative;
}
@media (max-width: 767.98px) {
  .footer .container {
    padding: 0 30px;
  }
}
.footer__divider {
  border-left: 2px solid rgba(0, 45, 93, 0.1215686275);
  left: -72px;
  height: calc(100% - 8px);
  position: absolute;
  top: 8px;
  width: 0;
}
@media (max-width: 767.98px) {
  .footer__divider {
    border-left: 0;
    border-top: 2px solid rgba(0, 45, 93, 0.2588235294);
    height: 0;
    left: 0;
    width: 100vw;
    top: 0;
  }
}
@media (max-width: 767.98px) {
  .footer__divider {
    width: 100%;
  }
}
.footer__contact {
  position: relative;
}
@media (max-width: 767.98px) {
  .footer__contact {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    padding-top: 30px;
    flex-wrap: wrap;
  }
}
.footer__landing {
  color: #959595;
  border: none;
}
@media (min-width: 768px) and (max-width: 959.98px) {
  .footer__landing {
    padding: 30px 0;
  }
}
.footer__landing p {
  margin-bottom: 0;
}
.footer__landing address {
  max-width: none;
  margin-bottom: 15px;
}
@media (max-width: 767.98px) {
  .footer__landing address {
    font-size: 1.4rem;
    line-height: 1.25;
    width: auto;
    margin: 0;
    letter-spacing: 0;
  }
}
.footer__landing-address {
  margin-left: 90px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 50px;
}
@media (max-width: 767.98px) {
  .footer__landing-address {
    flex: 1 1 50%;
    font-size: 1.4rem;
    line-height: 1.25;
    margin-left: 25px;
  }
}
.footer__landing-copyright {
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
}
@media (max-width: 767.98px) {
  .footer__landing-copyright {
    margin-bottom: 12px;
    font-size: 1.3rem;
    line-height: 1.5;
    white-space: nowrap;
  }
}
.footer__landing .footer__logo {
  max-width: 270px;
}
@media (max-width: 767.98px) {
  .footer__landing .footer__logo {
    flex: 1 1 50%;
    max-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 959.98px) {
  .footer__landing .footer__logo {
    width: 50%;
    margin: 0 auto;
  }
}
.footer__landing-left {
  display: flex;
}
@media screen and (max-width: 768px) {
  .footer__landing-left {
    flex-wrap: wrap;
  }
  .footer__landing-left .footer__logo {
    width: 34%;
  }
}
@media screen and (min-width: 1024px) {
  .footer__landing-left {
    flex-wrap: wrap;
  }
  .footer__landing-left .footer__logo {
    width: 50%;
  }
}
.footer__landing-right {
  align-items: center;
  border-left: 2px solid #383838;
  display: flex;
  height: 66px;
  justify-content: flex-end;
}
@media (max-width: 767.98px) {
  .footer__landing-right {
    border-left: none;
    display: block;
    height: auto;
  }
  .footer__landing-right .nav__contact {
    border-top: 2px solid #383838;
    margin-top: 20px;
    padding-top: 12px;
  }
}
@media (min-width: 768px) and (max-width: 959.98px) {
  .footer__landing-right {
    height: auto;
    align-items: flex-start;
    justify-content: center;
  }
  .footer__landing-right .nav__contact {
    text-align: left;
    height: auto;
    line-height: 30px;
  }
  .footer__landing-right .nav__contact li {
    display: block;
  }
}

@media (max-width: 767.98px) {
  .partner_footer {
    padding: 0;
  }
  .partner_footer .container {
    padding: 0;
  }
  .partner_footer .container .footer__landing-left {
    padding: 0 30px;
  }
}
.partner_footer .login_box {
  border: 1px solid #1c1c1c;
  background-color: #1c1c1c;
  padding: 22px 28px;
  margin-top: 20px;
}
@media (max-width: 767.98px) {
  .partner_footer .login_box {
    padding: 15px;
    margin: 40px 15px;
    height: 90px;
  }
}
@media (max-width: 340px) {
  .partner_footer .login_box {
    padding: 16px;
    margin: 20px 5px;
  }
  .partner_footer .login_box p {
    font-size: 1rem;
  }
}
.partner_footer .login_box p {
  float: left;
  width: 55%;
}
@media (max-width: 767.98px) {
  .partner_footer .login_box p {
    width: 60%;
  }
}
.partner_footer .login_box .btn-default {
  background-color: #eeeeee;
  color: black;
  font-weight: bold;
  float: right;
  padding: 2px 20px;
  height: 35px;
}
@media (max-width: 767.98px) {
  .partner_footer .login_box .btn-default {
    min-width: 95px;
  }
}
.partner_footer .nav__contact {
  float: left;
}
.partner_footer .nav__contact > li:first-child {
  margin: 0 6px 0 0;
  padding-left: 0;
}
.partner_footer .nav__contact a {
  color: rgba(0, 0, 0, 0.8);
}
.partner_footer .nav__contact a:hover {
  color: black;
}
@media screen and (max-width: 767px) {
  .partner_footer .footer__logo {
    width: 100%;
    padding: 0 12vw;
    max-width: 100%;
    margin: 50px 0 30px 0;
    flex: auto;
  }
  .partner_footer .footer__landing-address,
  .partner_footer .footer__landing-copyright {
    text-align: center;
    width: 100%;
    margin-left: 0;
  }
  .partner_footer .footer_links {
    padding: 0;
    background-color: black;
  }
  .partner_footer .nav__contact {
    width: 100%;
    text-align: center;
    margin: 25px 0;
    line-height: initial;
    height: initial;
    flex-wrap: wrap;
  }
  .partner_footer .nav__contact li {
    text-align: center;
    margin-bottom: 10px;
    padding-right: 15px;
    width: 100%;
  }
  .partner_footer .nav__contact li:first-child {
    margin-bottom: 10px;
  }
  .partner_footer .partner_footer .nav__contact {
    width: 100%;
    float: none;
  }
}

.header__landing .header {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3882352941);
  border-bottom: 1px solid #eeeeee;
}

a#navPhoneButton .icon:after {
  font-size: 1.6rem;
}

@media print {
  .nav {
    display: none;
  }
}
.nav a {
  text-decoration: none;
}
.nav .btn-link {
  text-decoration: underline;
  text-decoration-color: #A32035;
}
.nav__dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  list-style: none;
  font-size: 1.6rem;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
}
@media (min-width: 1200px) {
  .nav__dropdown-menu {
    height: calc(100vh - 119px);
    left: 100%;
    top: 0;
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
@media (min-width: 992px) {
  .nav__dropdown-menu {
    border: 1px solid #dee2e6;
  }
}
@media print {
  .nav__global {
    display: none;
  }
}
@media (min-width: 1200px) {
  .nav__global-wrapper {
    margin-top: 0;
    transition: left 0.5s;
    position: relative;
    left: 0;
  }
}
@media (min-width: 1200px) {
  .nav__global {
    background: #fafafa;
    height: calc(100vh - 113px);
    margin-top: 1px;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: absolute;
    right: 0;
    top: 100%;
    width: 375px;
    z-index: -1;
    transition: all 0.5s !important;
  }
  .nav__global.nav__subnav--open .nav__global-wrapper {
    left: -100%;
  }
}
@media (min-width: 1200px) and (max-width: 767.98px) {
  .nav__global.nav__subnav--open .nav__global-wrapper {
    left: -100%;
  }
}
@media (min-width: 1200px) {
  .nav__global .navbar-nav {
    margin: 0;
  }
  .keyboard .nav__global {
    height: 100vh;
  }
}
@media (max-width: 767.98px) {
  .nav__global {
    left: auto;
    right: auto;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .nav__global {
    float: right;
  }
  .nav__global.nav__search--open {
    position: relative;
  }
}
.nav__global .nav > li {
  font-size: 1.6rem;
  position: static;
}
@media (min-width: 768px) {
  .nav__global .nav > li {
    font-size: 1.8rem;
  }
}
.nav__global .nav > li.mobile {
  display: none;
}
@media (min-width: 1200px) {
  .nav__global .nav > li.mobile {
    display: inherit;
  }
}
.nav__global .nav > li > button:hover,
.nav__global .nav > li > a:hover {
  color: #A32035;
}
@media (min-width: 1200px) {
  .nav__global .nav > li > button,
  .nav__global .nav > li > a {
    padding: 14px 46px 14px 20px;
  }
  .nav__global .nav > li > button:focus,
  .nav__global .nav > li > a:focus {
    color: rgba(0, 0, 0, 0.8);
  }
}
@media (min-width: 768px) {
  .nav__global .nav > li > button,
  .nav__global .nav > li > a {
    padding: 25px 76px 25px 44px;
  }
}
.nav__global .nav > li > button > .icon,
.nav__global .nav > li > a > .icon {
  display: none;
}
@media (min-width: 1200px) {
  .nav__global .nav > li > button > .icon,
  .nav__global .nav > li > a > .icon {
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    text-decoration: none;
  }
}
@media (min-width: 768px) {
  .nav__global .nav > li > button > .icon,
  .nav__global .nav > li > a > .icon {
    font-size: 2.5rem;
    right: 36px;
  }
}
.nav__dropdown {
  padding: 30px;
  width: 100vw;
  max-width: 1140px;
}
.nav__overview-image {
  /*@include media-breakpoint-up(lg) {
      overflow: hidden;
      position: relative;
      &:before,
      &:after {
          bottom: 0;
          content: " ";
          position: absolute;
          border-bottom: 10px solid $prim-bg;
          border-top: 10px solid transparent;
          z-index: 3;
      }
      &:before {
          right: 50%;
          border-left: 149px solid $prim-bg;
          border-right: 149px solid transparent;
      }
      &:after {
          left: 50%;
          border-left: 149px solid transparent;
          border-right: 149px solid $prim-bg;
      }
  }*/
}
@media (min-width: 1200px) {
  .nav__overview-image {
    display: none;
  }
}
.nav__overview-image img {
  object-fit: cover;
  width: 100%;
  height: 120px;
  clip-path: polygon(100% 0%, 0% 0%, 0% 86%, 50% 100%, 100% 86%);
}
@media (min-width: 992px) {
  .nav__subnav-overview--small .nav__overview-image:before {
    border-left: 71px solid #fafafa;
    border-right: 71px solid transparent;
  }
  .nav__subnav-overview--small .nav__overview-image:after {
    border-left: 71px solid transparent;
    border-right: 71px solid #fafafa;
  }
}
.nav__subnav {
  padding: 0;
}
.open > .nav__subnav {
  display: block;
}
@media (min-width: 1200px) {
  .nav__subnav .container {
    width: auto;
  }
}
@media (min-width: 992px) {
  .nav__subnav .container {
    padding: 0;
    position: relative;
  }
}
.nav__subnav-description {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.7;
  padding: 22px 48px;
}
@media (min-width: 1200px) {
  .nav__subnav-description {
    display: none;
    padding: 22px;
  }
}
@media (min-width: 992px) {
  .nav__subnav-overview {
    background-color: #fafafa;
    float: right;
    width: 594px;
    padding-bottom: 100px;
    margin-bottom: -100px;
  }
  .nav__subnav-overview .btn-primary-carat,
  .nav__subnav-overview .btn-primary {
    display: none;
  }
}
@media (min-width: 1200px) {
  .nav__subnav-overview {
    padding: 20px 40px 100px 65px;
  }
}
@media (max-width: 767.98px) {
  .nav__subnav-overview {
    padding: 15px 25px 75px 25px;
  }
}
@media (min-width: 992px) {
  .nav__subnav-overview--small {
    width: 282px;
  }
}
.nav__subnav-header {
  margin-bottom: 6px;
  padding: 0;
}
.nav__subnav-header a {
  color: rgba(0, 0, 0, 0.8);
}
.nav__subnav-header a:hover {
  color: #A32035;
}
.nav__subnav-header .subheading {
  color: rgba(0, 0, 0, 0.8);
  margin-top: 0;
}
.nav__subnav-section {
  display: inline-block;
  vertical-align: top;
}
.footer .nav__subnav-section {
  width: 30%;
}
.footer .nav__subnav-section:last-child {
  margin-right: 0;
}
@media (max-width: 767.98px) {
  .footer .nav__subnav-section {
    display: block;
    margin-right: 0;
    margin-bottom: 30px;
    width: 100%;
  }
}
@media screen and (min-width: 1188px) {
  .footer .nav__subnav-section {
    margin-right: 17px;
    width: 23%;
  }
}
.footer .nav__subnav-header {
  margin-bottom: 8px;
}
.footer .nav__subnav-header * {
  line-height: 1.5;
  margin-bottom: 0;
}
.footer .nav__subnav-header * a {
  font-weight: 700;
}
.footer .nav__subnav-header * a:hover {
  color: #A32035;
}
.nav__subnav-mobiletitle {
  background: #fafafa;
  cursor: pointer;
  padding: 10px 30px;
}
@media (max-width: 767.98px) {
  .nav__subnav-mobiletitle {
    font-size: 1.3rem;
    padding: 16px 22px;
    line-height: 1.75;
  }
  .nav__subnav-mobiletitle .icon {
    font-size: 2.1rem;
    line-height: 0.9;
    vertical-align: bottom;
    margin-right: 9px;
  }
  .nav__subnav-mobiletitle .icon:after {
    vertical-align: bottom;
  }
}
@media (min-width: 768px) {
  .nav__subnav-mobiletitle {
    border-bottom: 1px solid #dbdbdb;
    padding: 28px 23px 23px 23px;
    font-size: 1.8rem;
    line-height: 1.5;
  }
  .nav__subnav-mobiletitle .icon {
    font-size: 2.1rem;
    line-height: 1;
    vertical-align: bottom;
    margin-right: 14px;
  }
  .nav__subnav-mobiletitle .icon:after {
    vertical-align: bottom;
  }
}
.nav__subnav-link {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.5;
}
@media (max-width: 767.98px) {
  .nav__subnav-link {
    font-size: 1.6rem;
    line-height: 3.1;
  }
}
@media (min-width: 992px) {
  .nav__global .nav__subnav {
    left: 0;
    overflow: hidden;
    right: 0;
    z-index: -1;
  }
}
.nav__global .nav__subnav-header {
  border-bottom: 1px solid #eeeeee;
}
.nav__global .nav__subnav-header * {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 0;
}
@media (max-width: 767.98px) {
  .nav__global .nav__subnav-header * {
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-bottom: 6px;
  }
}
@media (min-width: 768px) {
  .nav__global .nav__subnav-header * {
    font-size: 1.3rem;
    text-transform: uppercase;
    margin-bottom: 10px;
    line-height: 1.75;
  }
}
@media (min-width: 992px) {
  .nav__global .nav__subnav-sections {
    padding: 30px 30px 30px 285px;
    display: inline-block;
  }
}
@media (min-width: 1200px) {
  .nav__global .nav__subnav-sections {
    padding: 14px 40px 0 65px;
  }
}
@media (max-width: 767.98px) {
  .nav__global .nav__subnav-sections {
    padding: 15px 25px 0 25px;
  }
}
.nav__global .nav__subnav-section {
  display: block;
}
@media (min-width: 1200px) {
  .nav__global .nav__subnav-section {
    padding: 20px 0;
  }
  .nav__global .nav__subnav-section:first-child {
    padding-top: 10px;
  }
}
@media (max-width: 767.98px) {
  .nav__global .nav__subnav-section {
    padding: 13px 0;
    margin-right: 0;
    width: auto;
  }
}
@media (min-width: 768px) {
  .nav__global .nav__subnav-section {
    margin-right: 0;
    width: auto;
  }
}
@media (min-width: 992px) {
  .nav__global .nav__subnav-section {
    display: inline-block;
    margin-right: 25px;
    width: 218px;
  }
  .nav__global .nav__subnav-section:last-child {
    margin-right: 0;
  }
}
@media (max-width: 767.98px) {
  .nav__global .nav__subnav-link {
    font-size: 1.6rem;
    line-height: 1.5;
    margin: 25px 0;
  }
  .nav__global .nav__subnav-link:first-child {
    margin-top: 14px;
  }
  .nav__global .nav__subnav-link:last-child {
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) {
  .nav__global .nav__subnav-link {
    font-size: 1.8rem;
    line-height: 1.5;
    letter-spacing: 0.03em;
    margin: 30px 0;
  }
  .nav__global .nav__subnav-link:first-child {
    margin-top: 24px;
  }
  .nav__global .nav__subnav-link:last-child {
    margin-bottom: 12px;
  }
}
.nav__global .nav__subnav ul > li > a {
  color: rgba(0, 0, 0, 0.8);
}
.nav__global .nav__subnav ul > li > a:hover {
  color: #A32035;
}
.nav__link.open > a {
  color: #A32035;
  font-weight: bold;
}
.nav__mobilebutton {
  display: none;
}
@media (min-width: 1200px) {
  .nav__mobilebutton {
    border: 2px solid #eeeeee;
    border-radius: 4px;
    display: block;
    font-size: 1.6rem;
    position: absolute;
    right: 25px;
    top: 15px;
    line-height: 1.5;
    min-width: 0;
    padding: 6px 12px;
    height: 45px;
    width: 120px;
  }
  .nav__mobilebutton .icon {
    color: rgba(0, 0, 0, 0.8);
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .nav__mobilebutton {
    height: 53px;
    width: 136px;
    right: 39px;
    top: 19px;
  }
}
@media (min-width: 1200px) {
  .nav__mobilebutton.open {
    background: #707070;
    color: #fff;
  }
  .nav__mobilebutton.open .icon {
    color: #fff;
    transform: scale(1, -1);
  }
  .nav__link {
    border-bottom: 1px solid #dbdbdb;
  }
}
@media print {
  .nav__topnav {
    display: none;
  }
}
.nav__topnav a {
  text-decoration: none;
}
.nav__topnav-header {
  display: inline-block;
  vertical-align: bottom;
}
.nav__topnav-header * {
  color: #545454;
  font-size: 1.3rem;
  font-weight: normal;
  line-height: 2.9;
  margin: 0;
}
.nav__topnav ul {
  padding-left: 0;
  list-style: none;
  font-size: 0;
  margin: 0;
  display: inline-block;
  vertical-align: bottom;
}
@media (max-width: 1199.98px) {
  .nav__topnav ul {
    display: inline-flex;
  }
}
.nav__topnav ul > li {
  display: inline-block;
  padding: 0 5px;
  font-size: 1.4rem;
  vertical-align: bottom;
}
@media (max-width: 1199.98px) {
  .nav__topnav ul > li {
    border: none;
    text-align: center;
    padding: 0 5px;
    flex: 1 1 auto;
  }
}
@media (max-width: 767.98px) {
  .nav__topnav ul > li {
    flex: 1 1 50%;
    padding: 0;
  }
}
.nav__topnav ul > li > a {
  color: rgba(0, 0, 0, 0.8);
  white-space: nowrap;
}
.nav__topnav ul > li > a:hover {
  color: #A32035;
}
@media (max-width: 1199.98px) {
  .nav__topnav ul > li > a {
    display: block;
  }
}
.nav__topnav-wrapper {
  background: #eeeeee;
}
@media (min-width: 1200px) {
  .nav__topnav-wrapper .container {
    padding: 0;
  }
}
.nav__topnav-wrapper .container {
  width: 100%;
}
.nav__topnav-contactlinks {
  display: inline-block;
  text-align: right;
  flex: 3 0 auto;
  z-index: 999;
}
@media (max-width: 767.98px) {
  .nav__topnav-contactlinks #navPhoneButtonMobile {
    border-bottom: 1px solid #eeeeee;
    color: #A32035;
  }
}
@media (max-width: 767.98px) {
  .nav__topnav .chat__link {
    font-size: 1.2rem;
  }
}
.nav__topnav .phone-btn {
  background: white;
}
@media (max-width: 767.98px) {
  .nav__topnav .phone-btn {
    font-size: 2rem;
    line-height: 2.2;
  }
}
@media (min-width: 1200px) {
  .nav__topnav .phone-btn .icon {
    margin-right: 0;
  }
}
@media (min-width: 992px) {
  .nav__topnav .phone-btn {
    font-size: 1.6rem;
  }
}
.nav__topnav .phone-btn .icon {
  text-decoration: none;
}
.nav__topnav .request-btn,
.nav__topnav .apply-btn {
  padding: 0;
}
.nav__topnav .request-btn a,
.nav__topnav .apply-btn a {
  color: #fff;
}
.nav__topnav .request-btn a:hover,
.nav__topnav .apply-btn a:hover {
  color: #fff;
}
.nav__topnav-left {
  float: left;
  font-size: 0;
  line-height: 45px;
}
@media (min-width: 1200px) {
  .nav__topnav-left {
    line-height: 38px;
    display: block;
  }
}
.nav__topnav-left ul > li {
  font-size: 1.2rem;
  padding: 0 8px;
}
.nav__topnav-left ul > li > a {
  color: #545454;
}
.nav__topnav-mobile {
  display: none;
}
@media (max-width: 767.98px) {
  .nav__topnav-mobile {
    display: flex;
    flex-direction: row;
    float: none;
    text-align: right;
    font-size: 1.4rem;
    line-height: 45px;
  }
}
.nav__topnav-mobile a {
  font-weight: 700;
  text-transform: uppercase;
}
.nav__topnav-mobile a.apply-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.nav__topnav-right {
  flex-direction: row;
  float: none;
  text-align: right;
  font-size: 1.4rem;
  line-height: 45px;
}
.nav__topnav-right a {
  font-weight: 700;
  text-transform: uppercase;
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .nav__topnav-right {
    display: flex;
    position: absolute;
    right: 30px;
    top: 0;
  }
}
@media (min-width: 1200px) {
  .nav__topnav-right {
    display: flex;
    line-height: 38px;
  }
}
.nav__topnav-right.onPageHeader {
  position: relative;
}
@media (min-width: 769px) {
  .nav__topnav-right.onPageHeader {
    display: block;
  }
}
@media (min-width: 1200px) {
  .nav__topnav-request {
    flex: 1 0 auto;
  }
}
@media (min-width: 992px) {
  .nav__topnav-request {
    order: inherit;
  }
}
.nav__search-label {
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
}
@media (min-width: 992px) {
  .nav__search-open {
    transition: opacity 600ms;
    visibility: hidden;
    height: 56px;
    left: -10000px;
    line-height: 56px;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
.nav__search--open .nav__search-open {
  left: 0;
  visibility: visible;
  opacity: 1;
}
.nav__search-open .form-group {
  position: relative;
}
.nav__search-open .form-group div.icon__loading {
  padding: 30px 0;
  width: 100%;
  position: absolute;
  z-index: 999;
  right: -300px;
  top: -28px;
}
.nav__search-open .form-group .search__suggestions {
  max-height: 230px;
  width: 100%;
  overflow-y: auto;
  position: absolute;
  right: 0;
  z-index: 9;
  background: white;
  border: 1px solid #e0ddd3;
  border-top: none;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  display: none;
}
.nav__search-open .form-group .search__suggestions.mobile {
  top: 55px;
}
.nav__search-open .form-group .search__suggestions a {
  width: 100%;
  display: block;
  margin: 0;
  padding: 10px 15px !important;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.3;
  text-decoration: none;
}
.nav__search-open .form-group .search__suggestions a:hover {
  background: #fafafa;
}
.nav__search-open .form-group,
.nav__search-open .input-group {
  width: 100%;
}
.nav__search-open input {
  border-color: rgba(0, 0, 0, 0.8);
  border-right: 0;
  border-left: 0;
  height: 56px;
  line-height: 42px;
}
@media (min-width: 1200px) {
  .nav__search-open input {
    border: 1px solid #eeeeee;
  }
}
.nav__search-open .form-inline .input-group .input-group-addon,
.nav__search-open .form-inline .input-group .input-group-btn {
  padding: 0;
  width: 56px;
}
@media (min-width: 1200px) {
  .nav__search-open .form-inline .input-group .input-group-addon,
  .nav__search-open .form-inline .input-group .input-group-btn {
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 4;
  }
}
.nav__search-open .btn-default {
  background-color: #dbdbdb;
  border-color: rgba(0, 0, 0, 0.8);
  height: 56px;
  line-height: 54px;
  min-width: 0;
  width: 58px;
  padding: 0;
  border-radius: 4px;
}
.nav__search-open .btn-default .icon {
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
}
.nav__search-open .btn-default:hover {
  color: rgba(0, 0, 0, 0.8);
}
.nav__search-open .btn-default:hover .icon {
  color: #A32035;
}
@media (min-width: 992px) {
  .nav__search-open .btn-default:hover {
    background-color: #A32035;
    color: #fff;
  }
  .nav__search-open .btn-default:hover .icon {
    color: #fff;
  }
}
@media (min-width: 1200px) {
  .nav__search-open .btn-default {
    border: none;
    background: none;
    padding: 0;
    width: auto;
  }
}
@media (min-width: 1200px) {
  .nav__search-open form {
    position: relative;
  }
}
.nav__search-open .input-group-addon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.8);
}
@media (min-width: 1200px) {
  .nav__search-open {
    display: block;
    height: auto;
    padding: 15px 15px 100px;
    position: static;
  }
}
@media (min-width: 768px) {
  .nav__search-open {
    padding: 34px 40px 150px;
  }
}
.nav__search-close {
  color: rgba(0, 0, 0, 0.8);
}
.nav__search-close:hover {
  color: #A32035;
}
@media (min-width: 1200px) {
  .nav__search-closegroup {
    display: none;
  }
}
.nav__search-button {
  color: rgba(0, 0, 0, 0.8);
  display: inline-block;
  padding: 10px 6px 13px;
  line-height: 2rem;
}
.nav__search-button:hover {
  color: #A32035;
}
@media (min-width: 992px) {
  .nav__search-button {
    padding: 1.5rem 0 1.5rem 6px;
  }
}
@media (min-width: 1200px) {
  .nav__search-button {
    display: none;
  }
}
.nav__search-button .icon {
  line-height: 2rem;
}
@media screen and (min-width: 960px) {
  .nav__footer-wrapper {
    padding-right: 50px;
  }
}
@media (min-width: 992px) {
  .nav__footer-wrapper {
    padding-top: 10px;
  }
}
.nav__contact {
  padding-left: 0;
  list-style: none;
  height: 48px;
  line-height: 48px;
  margin: 0;
  text-align: center;
}
.nav__contact > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
@media (max-width: 767.98px) {
  .nav__contact {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.nav__contact > li {
  margin: 0 6px;
}
.nav__contact > li a {
  color: rgba(0, 0, 0, 0.8705882353);
}
.nav__contact > li a:hover {
  color: black;
}
@media (max-width: 767.98px) {
  .nav__contact > li {
    display: block;
    margin: 0;
    text-align: left;
  }
}
.nav__contact > li a {
  color: rgba(0, 0, 0, 0.8705882353);
}
.nav__contact > li a:hover {
  color: #A32035;
}
.nav__social {
  padding-left: 0;
  list-style: none;
  margin-left: -9px;
  margin-top: 9px;
  display: flex;
  flex-wrap: wrap;
}
.nav__social > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
@media (max-width: 767.98px) {
  .nav__social {
    display: flex;
    justify-content: stretch;
  }
}
.nav__social > li {
  padding-left: 0;
  padding-right: 20px;
  padding-bottom: 15px;
}
@media screen and (min-width: 812px) {
  .nav__social > li {
    padding-right: 10px;
  }
}
.nav__social > li a .icon {
  text-decoration: none;
}

.appear-from-right {
  left: auto !important;
  right: 0 !important;
  visibility: visible !important;
  transition: all 0.5s !important;
  z-index: 1 !important;
}
.appear-from-right.search__global {
  right: 375px !important;
}
@media (max-width: 767.98px) {
  .appear-from-right.search__global {
    left: 0;
    right: 100% !important;
    width: 100%;
  }
}
.appear-from-right.nav__subnav--open {
  overflow: hidden;
}

.header-root__elements {
  align-content: center;
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: auto;
}
@media (max-width: 767.98px) {
  .header-root__elements {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .header-root__elements {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .header-root__elements {
    width: auto;
  }
}

.header-root__ctas {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  margin-right: -10px;
}

.search-button {
  padding: 10px;
  transition: all 250ms ease-in-out;
}

.search-button--open {
  background-color: transparent;
}

.search-button--close {
  background-color: transparent;
  display: none;
}

.search-button:focus,
.search-button:hover {
  background-color: transparent;
}

.search-button--open .svg-icon,
.search-button--close .svg-icon {
  align-content: center;
  align-items: center;
  display: flex;
  flex-flow: row wrap;
}

.search-button--close {
  display: none;
}

.search__global {
  background: #eeeeee;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
  height: 100vh;
  left: auto;
  position: absolute;
  right: 0;
  top: 67px;
  transform: translateX(100%);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  visibility: hidden;
  width: 100%;
  z-index: 0;
}

.search__global .nav__search-open {
  padding: 15px;
}

.search__global .nav__search-open .form-group {
  margin-bottom: 0;
}

.search__global .search__suggestions {
  color: #545454;
  padding: 0 15px 15px 15px;
}

.search__global .search__suggestions p {
  margin-bottom: 4px;
}

.search__global .no-bullet {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile-menu-button {
  padding: 10px;
  transition: all 250ms ease-in-out;
}

.mobile-menu-button--open {
  background-color: transparent;
}

.mobile-menu-button--close {
  background-color: transparent;
  display: none;
}

.mobile-menu-button:focus,
.mobile-menu-button:hover {
  background-color: transparent;
}

.mobile-menu-button--open .svg-icon,
.mobile-menu-button--close .svg-icon {
  align-content: center;
  align-items: center;
  display: flex;
  flex-flow: row wrap;
}

.mobile-menu-button--close {
  display: none;
}

.svg-icon__text {
  display: none;
}

.nav__global {
  -webkit-transition: all;
  transition: all 0.5s;
  visibility: hidden;
  z-index: 0;
}

.overlay__close-button {
  right: 24px;
  top: 24px;
}

.simple-icons-svg {
  height: 20px;
  width: 20px;
}

.search-results__search-wrapper {
  padding-top: 23px;
}
@media (min-width: 1200px) {
  .search-results__search-wrapper {
    padding-top: 50px;
  }
}

@media screen and (min-width: 768px) {
  .header-root__ctas {
    margin-right: 0;
  }
  .svg-icon__text {
    display: block;
    font-weight: bold;
    margin-left: 4px;
    text-transform: uppercase;
  }
  .search__global {
    max-width: 375px;
  }
}
@media screen and (max-width: 959px) {
  .header {
    padding: 15px 25px;
  }
}
@media screen and (min-width: 960px) {
  .search__global {
    top: 91px;
  }
}
@media screen and (max-width: 1199px) {
  .nav__global-wrapper .nav__search-open {
    display: none;
  }
  .nav__global {
    right: -375px;
  }
}
@media screen and (min-width: 1200px) {
  .header-root__elements {
    display: inline-block;
  }
  .header-root__ctas {
    display: none;
  }
  .search__global {
    display: none;
  }
  .nav__global {
    height: auto;
    visibility: visible;
  }
}
@media (max-width: 767.98px) {
  .leftnav__content {
    margin: 0;
  }
}
.leftnav__mobile {
  display: none;
}
@media (max-width: 767.98px) {
  .leftnav__mobile {
    display: block;
  }
  .leftnav__mobile .leftnav__nav {
    display: block;
  }
}
.leftnav .container {
  padding-bottom: 50px;
  padding-top: 50px;
}
@media (min-width: 1200px) {
  .leftnav .container .container {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}
.leftnav.section--short .container {
  padding-bottom: 0;
  padding-top: 50px;
}
@media (min-width: 1200px) {
  .leftnav.section--short .container .container {
    padding-bottom: 0;
    padding-top: 40px;
  }
}

.nav__leftnav {
  margin-top: 6px;
  border-top: 2px solid #eeeeee;
}
@media (max-width: 767.98px) {
  .nav__leftnav {
    /*display: none;*/
  }
  .leftnav__mobile .nav__leftnav {
    display: block;
    content: "";
  }
}
.leftnav__mobile .nav__leftnav {
  margin-top: 0;
}
.nav__leftnav a {
  text-decoration: none;
}
.nav__leftnav li {
  border: 2px solid #eeeeee;
  border-top: none;
  display: block;
  min-height: 48px;
  overflow: hidden;
  transition: max-height 400ms ease-out;
}
@media only screen and (max-width: 767px) {
  .nav__leftnav li {
    min-height: auto;
  }
}
.nav__leftnav li a {
  background-color: #fafafa;
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 16px;
  padding: 15px 25px 15px 20px;
}
@media only screen and (max-width: 767px) {
  .nav__leftnav li a {
    line-height: initial;
    padding: 9px 15px;
  }
}
.nav__leftnav li.open {
  max-height: 10000px;
}
.nav__leftnav li.open > ul {
  display: block;
}
@media only screen and (max-width: 767px) {
  .nav__leftnav li.open > ul {
    display: none;
  }
}
.nav__leftnav li.open > a {
  font-weight: bold;
  color: #A32035;
}
.nav__leftnav li.open > a:after {
  top: 50%;
  content: " ";
  position: absolute;
  right: 0;
  border: 11px solid #A32035;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  transform: translateY(-11px);
}
.nav__leftnav li.selected > a {
  font-weight: bold;
  color: #A32035;
}
.nav__leftnav ul {
  border-top: 2px solid #eeeeee;
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
}
.nav__leftnav ul li {
  margin: 0;
  border: none;
  border-bottom: 2px solid #eeeeee;
  position: relative;
}
.nav__leftnav ul li:last-child {
  border-bottom: none;
}
.nav__leftnav ul li > a {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
  padding-left: 35px;
}
.nav__leftnav ul li > a:hover {
  color: #A32035;
}
.nav__leftnav ul li.open li > a {
  padding-left: 54px;
  position: relative;
}
.nav__leftnav ul li.open li > a::before {
  content: "-";
  position: absolute;
  margin-left: -15px;
}

@media only screen and (max-width: 767px) {
  .nav__subnav-link.selected a:after {
    display: none;
  }
}

.card a {
  display: block;
  text-decoration: none;
}

.card h3, .card .h3 {
  text-decoration: none;
}

.card-img-top {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.placeholderb {
  object-fit: contain;
}

.card-body .card-text {
  color: #383838;
}

.odometer.odometer-auto-theme, .odometer.odometer-theme-default {
  display: inline-block;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}

.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-default .odometer-digit {
  display: inline-block;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  padding-right: 5px;
  margin-right: -5px;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
  display: block;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-default .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-default .odometer-digit .odometer-value.odometer-last-value {
  position: absolute;
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
}

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
  transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-down .odometer-ribbon-inner {
  transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition: transform 2s;
  transform: translateY(0);
}

.odometer.odometer-auto-theme, .odometer.odometer-theme-default {
  font-family: inherit;
  line-height: inherit;
}

.odometer.odometer-auto-theme .odometer-value, .odometer.odometer-theme-default .odometer-value {
  text-align: center;
}

/* Accessibility: hide screen reader texts (and prefer "top" for RTL languages).
Reference: http://blog.rrwd.nl/2015/04/04/the-screen-reader-text-class-why-and-how/ */
.mejs__offscreen {
  clip: rect(1px, 1px, 1px, 1px); /* IE8-IE11 - no support for clip-path */
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

.mejs__container {
  background: #000;
  box-sizing: border-box;
  font-family: "Helvetica", Arial, serif;
  position: relative;
  text-align: left;
  text-indent: 0;
  vertical-align: top;
}

.mejs__container .mejs__video {
  min-height: 140px;
}

.mejs__container * {
  box-sizing: border-box;
}

/*!* Hide native play button from iOS to favor plugin button *!*/
/*.mejs__container video::-webkit-media-controls-start-playback-button {*/
/*    -webkit-appearance: none;*/
/*    display: none !important;*/
/*}*/
.mejs__fill-container,
.mejs__fill-container .mejs__container {
  height: 100%;
  width: 100%;
}

.mejs__fill-container {
  background: transparent;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.mejs__container:focus {
  outline: none;
}

.mejs__iframe-overlay {
  height: 100%;
  position: absolute;
  width: 100%;
}

.mejs__embed,
.mejs__embed body {
  background: #000;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.mejs__fullscreen {
  overflow: hidden !important;
}

.mejs__container-fullscreen {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}

.mejs__container-fullscreen .mejs__mediaelement,
.mejs__container-fullscreen video {
  height: 100% !important;
  width: 100% !important;
}

.mejs__clear {
  clear: both;
}

/* Start: LAYERS */
.mejs__background {
  left: 0;
  position: absolute;
  top: 0;
}

.mejs__mediaelement {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.mejs__poster {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

:root .mejs__poster-img {
  display: none;
}

.mejs__poster-img {
  border: 0;
  padding: 0;
}

.mejs__overlay {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

.mejs__layer {
  z-index: 1;
}

.mejs__overlay-play {
  cursor: pointer;
}

.mejs__overlay-button {
  background: url("/images/mejs-controls.svg") no-repeat;
  background-position: 0 -39px;
  height: 80px;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  z-index: 1;
}

.mejs__overlay:hover > .mejs__overlay-button {
  background-position: -80px -39px;
}

.mejs__overlay-loading {
  height: 80px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
}

.mejs__overlay-loading-bg-img {
  animation: mejs__loading-spinner 1s linear infinite;
  background: transparent url("/images/mejs-controls.svg") -160px -40px no-repeat;
  display: block;
  height: 80px;
  width: 80px;
  z-index: 1;
}

@keyframes mejs__loading-spinner {
  100% {
    transform: rotate(360deg);
  }
}
/* End: LAYERS */
/* Start: CONTROL BAR */
.mejs__controls {
  bottom: 0;
  height: 40px;
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 0 10px;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.mejs__controls:not([style*="display: none"]) {
  background: rgba(255, 0, 0, 0.7);
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.35));
}

.mejs__button,
.mejs__time,
.mejs__time-rail {
  float: left;
  font-size: 1rem;
  height: 40px;
  line-height: 1;
  margin: 0;
  width: 32px;
}

.mejs__button > button {
  background: transparent url("/images/mejs-controls.svg");
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  margin: 10px 6px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-decoration: none;
  width: 20px;
}

/* :focus for accessibility */
.mejs__button > button:focus {
  outline: dotted 1px #999;
}

.mejs__container-keyboard-inactive a,
.mejs__container-keyboard-inactive a:focus,
.mejs__container-keyboard-inactive button,
.mejs__container-keyboard-inactive button:focus,
.mejs__container-keyboard-inactive [role=slider],
.mejs__container-keyboard-inactive [role=slider]:focus {
  outline: 0;
}

/* End: CONTROL BAR */
/* Start: Time (Current / Duration) */
.mejs__time {
  box-sizing: content-box;
  color: #FFF;
  font-size: 1.2rem;
  font-weight: bold;
  height: 24px;
  overflow: hidden;
  padding: 16px 6px 0;
  text-align: center;
  width: auto;
}

/* End: Time (Current / Duration) */
/* Start: Play/Pause/Stop */
.mejs__play > button {
  background-position: 0 0;
}

.mejs__pause > button {
  background-position: -20px 0;
}

.mejs__replay > button {
  background-position: -160px 0;
}

/* End: Play/Pause/Stop */
/* Start: Progress Bar */
.mejs__time-rail {
  direction: ltr;
  height: 40px;
  margin: 0 10px;
  padding-top: 10px;
  position: relative;
  width: 200px;
}

.mejs__time-total,
.mejs__time-buffering,
.mejs__time-loaded,
.mejs__time-current,
.mejs__time-float,
.mejs__time-hovered,
.mejs__time-float-current,
.mejs__time-float-corner,
.mejs__time-marker {
  border-radius: 2px;
  cursor: pointer;
  display: block;
  height: 10px;
  position: absolute;
}

.mejs__time-total {
  background: rgba(255, 255, 255, 0.3);
  margin: 5px 0 0;
  width: 100%;
}

.mejs__time-buffering {
  animation: buffering-stripes 2s linear infinite;
  background: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 15px 15px;
  width: 100%;
}

@keyframes buffering-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 30px 0;
  }
}
.mejs__time-loaded {
  background: rgba(255, 255, 255, 0.3);
}

.mejs__time-current,
.mejs__time-handle-content {
  background: rgba(255, 255, 255, 0.9);
}

.mejs__time-hovered {
  background: rgba(255, 255, 255, 0.5);
  z-index: 10;
}

.mejs__time-hovered.negative {
  background: rgba(0, 0, 0, 0.2);
}

.mejs__time-current,
.mejs__time-buffering,
.mejs__time-loaded,
.mejs__time-hovered {
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0.15s ease-in all;
  width: 100%;
}

.mejs__time-hovered {
  transition: height 0.1s cubic-bezier(0.44, 0, 1, 1);
}

.mejs__time-hovered.no-hover {
  transform: scaleX(0) !important;
}

.mejs__time-handle,
.mejs__time-handle-content {
  border: 4px solid transparent;
  cursor: pointer;
  left: 0;
  position: absolute;
  transform: translateX(0);
  z-index: 11;
}

.mejs__time-handle-content {
  border: 4px solid rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  height: 10px;
  left: -7px;
  top: -4px;
  transform: scale(0);
  width: 10px;
}

.mejs__time-rail:hover .mejs__time-handle-content,
.mejs__time-rail .mejs__time-handle-content:focus,
.mejs__time-rail .mejs__time-handle-content:active {
  transform: scale(1);
}

.mejs__time-float {
  background: #eee;
  border: solid 1px #333;
  bottom: 100%;
  color: #111;
  display: none;
  height: 17px;
  margin-bottom: 9px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 36px;
}

.mejs__time-float-current {
  display: block;
  left: 0;
  margin: 2px;
  text-align: center;
  width: 30px;
}

.mejs__time-float-corner {
  border: solid 5px #eee;
  border-color: #eee transparent transparent;
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  line-height: 0;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  width: 0;
}

.mejs__long-video .mejs__time-float {
  margin-left: -23px;
  width: 64px;
}

.mejs__long-video .mejs__time-float-current {
  width: 60px;
}

.mejs__broadcast {
  color: #fff;
  height: 10px;
  position: absolute;
  top: 15px;
  width: 100%;
}

/* End: Progress Bar */
/* Start: Fullscreen */
.mejs__fullscreen-button > button {
  background-position: -80px 0;
}

.mejs__unfullscreen > button {
  background-position: -100px 0;
}

/* End: Fullscreen */
/* Start: Mute/Volume */
.mejs__mute > button {
  background-position: -60px 0;
}

.mejs__unmute > button {
  background-position: -40px 0;
}

.mejs__volume-button {
  position: relative;
}

.mejs__volume-button > .mejs__volume-slider {
  background: rgba(50, 50, 50, 0.7);
  border-radius: 0;
  bottom: 100%;
  display: none;
  height: 115px;
  left: 50%;
  margin: 0;
  position: absolute;
  transform: translateX(-50%);
  width: 25px;
  z-index: 1;
}

.mejs__volume-button:hover {
  border-radius: 0 0 4px 4px;
}

.mejs__volume-total {
  background: rgba(255, 255, 255, 0.5);
  height: 100px;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 8px;
  transform: translateX(-50%);
  width: 2px;
}

.mejs__volume-current {
  background: rgba(255, 255, 255, 0.9);
  left: 0;
  margin: 0;
  position: absolute;
  width: 100%;
}

.mejs__volume-handle {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 1px;
  cursor: ns-resize;
  height: 6px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 16px;
}

.mejs__horizontal-volume-slider {
  display: block;
  float: left;
  height: 36px;
  position: relative;
  vertical-align: middle;
  width: 56px;
}

.mejs__horizontal-volume-total {
  background: rgba(50, 50, 50, 0.8);
  border-radius: 2px;
  font-size: 1px;
  height: 8px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 16px;
  width: 50px;
}

.mejs__horizontal-volume-current {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  font-size: 1px;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.mejs__horizontal-volume-handle {
  display: none;
}

/* End: Mute/Volume */
/* Start: Track (Captions and Chapters) */
.mejs__captions-button,
.mejs__chapters-button {
  position: relative;
}

.mejs__captions-button > button {
  background-position: -140px 0;
}

.mejs__chapters-button > button {
  background-position: -180px 0;
}

.mejs__captions-button > .mejs__captions-selector,
.mejs__chapters-button > .mejs__chapters-selector {
  background: rgba(50, 50, 50, 0.7);
  border: solid 1px transparent;
  border-radius: 0;
  bottom: 100%;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: -51px;
  visibility: hidden;
  width: 85px;
}

.mejs__chapters-button > .mejs__chapters-selector {
  width: 110px;
}

.mejs__captions-button > .mejs__captions-selector,
.mejs__chapters-button > .mejs__chapters-selector {
  visibility: visible;
}

.mejs__captions-selector-list,
.mejs__chapters-selector-list {
  list-style-type: none !important;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.mejs__captions-selector-list-item,
.mejs__chapters-selector-list-item {
  color: #FFF;
  cursor: pointer;
  display: block;
  list-style-type: none !important;
  margin: 0 0 6px;
  overflow: hidden;
  padding: 0 10px;
}

.mejs__captions-selector-list-item:hover,
.mejs__chapters-selector-list-item:hover {
  background-color: rgb(200, 200, 200) !important;
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.mejs__captions-selector-input,
.mejs__chapters-selector-input {
  clear: both;
  float: left;
  left: -1000px;
  margin: 3px 3px 0 5px;
  position: absolute;
}

.mejs__captions-selector-label,
.mejs__chapters-selector-label {
  cursor: pointer;
  float: left;
  font-size: 1rem;
  line-height: 1.5;
  padding: 4px 0 0;
}

.mejs__captions-selected,
.mejs__chapters-selected {
  color: rgb(33, 248, 248);
}

.mejs__captions-translations {
  font-size: 1rem;
  margin: 0 0 5px;
}

.mejs__captions-layer {
  bottom: 0;
  color: #fff;
  font-size: 1.6rem;
  left: 0;
  line-height: 1.25;
  position: absolute;
  text-align: center;
}

.mejs__captions-layer a {
  color: #fff;
  text-decoration: underline;
}

.mejs__captions-layer[lang=ar] {
  font-size: 2rem;
  font-weight: normal;
}

.mejs__captions-position {
  bottom: 15px;
  left: 0;
  position: absolute;
  width: 100%;
}

.mejs__captions-position-hover {
  bottom: 35px;
}

.mejs__captions-text,
.mejs__captions-text * {
  background: rgba(20, 20, 20, 0.5);
  box-shadow: 5px 0 0 rgba(20, 20, 20, 0.5), -5px 0 0 rgba(20, 20, 20, 0.5);
  padding: 0;
  white-space: pre-wrap;
}

/*.mejs__container.mejs__hide-cues video::-webkit-media-text-track-container {*/
/*    display: none;*/
/*}*/
/* End: Track (Captions and Chapters) */
/* Start: Error */
.me_cannotplay a {
  font-weight: bold;
}

.mejs__container .me_cannotplay a {
  color: #FFF;
}

.me_cannotplay span {
  display: block;
  padding: 15px;
}

/* End: Error */
.related-information .bn-carousel {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}
.related-information .card,
.related-information .card__event,
.related-information .card__program {
  display: none;
  margin-right: auto;
}
.related-information .card h3, .related-information .card .h3,
.related-information .card__event h3,
.related-information .card__event .h3,
.related-information .card__program h3,
.related-information .card__program .h3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.related-information .card.active,
.related-information .card__event.active,
.related-information .card__program.active {
  display: block;
}
.related-information .carousel-control-prev .fas,
.related-information .carousel-control-next .fas {
  font-size: 4rem;
  color: rgba(0, 0, 0, 0.8);
  background-color: red !important;
}
.related-information .carousel-control-prev {
  left: -5rem;
}
.related-information .carousel-control-next {
  right: -5rem;
}
@media screen and (min-width: 0px) and (max-width: 400px) {
  .related-information .card,
  .related-information .card__event,
  .related-information .card__program {
    height: 620px;
  }
}
@media screen and (min-width: 400px) and (max-width: 768px) {
  .related-information .card,
  .related-information .card__event,
  .related-information .card__program {
    height: 600px;
  }
}
@media screen and (min-width: 0px) and (max-width: 768px) {
  .related-information .card,
  .related-information .card__event,
  .related-information .card__program {
    width: 100%;
  }
  .related-information .carousel-control-prev {
    left: -3rem;
  }
  .related-information .carousel-control-next {
    right: -3rem;
  }
  .related-information .bn-carousel {
    width: 80%;
  }
}
@media screen and (min-width: 769px) and (max-width: 992px) {
  .related-information .card,
  .related-information .card__event,
  .related-information .card__program {
    width: 45%;
    height: 475px;
  }
  .related-information .bn-carousel {
    width: 85%;
  }
}
@media screen and (min-width: 993px) {
  .related-information .card,
  .related-information .card__event,
  .related-information .card__program {
    height: 505px;
    width: 32.5%;
  }
  .related-information .bn-carousel {
    width: 100%;
  }
}

.testimonials__image img, .hero__video img, .card__image img, .two-col-img img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: all 0.5s ease;
}
@media (min-width: 768px) {
  .testimonials__image img, .hero__video img, .card__image img, .two-col-img img {
    height: 400px;
    transition: all 0.5s ease;
  }
}
@media (min-width: 992px) {
  .testimonials__image img, .hero__video img, .card__image img, .two-col-img img {
    height: 410px;
    transition: all 0.5s ease;
  }
}

.grid-results {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(3, 1fr);
}
@media (min-width: 768px) and (max-width: 959.98px) {
  .grid-results {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767.98px) {
  .grid-results {
    grid-template-columns: repeat(1, 1fr);
  }
}
.grid-results.grid-4 {
  grid-template-columns: repeat(4, 1fr);
}
@media (min-width: 768px) and (max-width: 959.98px) {
  .grid-results.grid-4 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 767.98px) {
  .grid-results.grid-4 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.two-col-img img {
  height: 160px;
}
@media (min-width: 768px) {
  .two-col-img img {
    height: 160px;
  }
}
@media (min-width: 992px) {
  .two-col-img img {
    height: 160px;
  }
}

.modal--open body {
  overflow: hidden;
  position: relative;
}

.modal--open .body {
  position: absolute;
  width: 100vw;
}

#at-custom-sidebar.at-custom-sidebar {
  box-sizing: border-box;
  border: 1px solid #dbdbdb;
  border-radius: 0;
  box-shadow: none;
  width: 85px !important;
}
#at-custom-sidebar.at-custom-sidebar .at-share-btn {
  line-height: 0 !important;
  margin-bottom: 10px;
}
#at-custom-sidebar.at-custom-sidebar .at-share-btn:last-child {
  margin-bottom: 0;
}
#at-custom-sidebar.at-custom-sidebar .at-icon-wrapper {
  line-height: 45px !important;
  height: 45px !important;
  width: 45px !important;
  margin: 0 !important;
}
#at-custom-sidebar.at-custom-sidebar .at-icon-wrapper .at-icon {
  margin: 11px !important;
  height: 24px !important;
  width: 24px !important;
}
#at-custom-sidebar.at-custom-sidebar .at-custom-sidebar-btns {
  padding: 19px;
  width: 83px !important;
  box-sizing: border-box;
}
#at-custom-sidebar.at-custom-sidebar .at-custom-sidebar-btns::before {
  content: "SHARE";
  color: #545454;
  display: block;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 26px;
  position: relative;
  margin-bottom: 20px;
  padding: 5px 0 0;
  text-align: center;
  border-bottom: 1px solid #dbdbdb;
}

.at-resp-share-element.at-mobile {
  border: 1px solid #dbdbdb;
  margin-top: 20px;
}
.at-resp-share-element.at-mobile .at-share-btn-elements {
  padding: 44px 14px 14px;
  display: flex;
  justify-content: space-between;
}
.at-resp-share-element.at-mobile .at-share-btn-elements::before {
  content: "SHARE";
  color: #545454;
  display: block;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 16px;
  position: absolute;
  margin-bottom: 15px;
  padding: 0 0 0 3px;
  text-align: left;
  top: 14px;
}
.at-resp-share-element.at-mobile .at-icon-wrapper.at-share-btn {
  margin-right: 24px;
  flex: 1 1 25%;
  text-align: center;
}
.at-resp-share-element.at-mobile .at-icon-wrapper.at-share-btn:last-child {
  margin-right: 0;
}
.at-resp-share-element.at-mobile .at-icon-wrapper.at-share-btn .at-icon-wrapper {
  float: none;
}

.container .section .container {
  width: auto;
  padding: 12px 0;
}
.container .section .container .row {
  margin-left: -5px;
  margin-right: -5px;
}
.container .section .container .col-sm-4 {
  padding-left: 5px;
  padding-right: 5px;
}

@media (min-width: 768px) {
  .leftnav__content .col-sm-8:first-child,
  .leftnav__content .col-sm-6:first-child,
  .program-detail .col-sm-8:first-child,
  .program-detail .col-sm-6:first-child,
  .admission-req .col-sm-8:first-child,
  .admission-req .col-sm-6:first-child,
  .readmore .col-sm-8:first-child,
  .readmore .col-sm-6:first-child,
  .curriculum .col-sm-8:first-child,
  .curriculum .col-sm-6:first-child {
    padding-right: 70px;
  }
}
.leftnav__content .section__description, .leftnav__content .section__header,
.program-detail .section__description,
.program-detail .section__header,
.admission-req .section__description,
.admission-req .section__header,
.readmore .section__description,
.readmore .section__header,
.curriculum .section__description,
.curriculum .section__header {
  text-align: left;
  max-width: none;
}

.section {
  background-size: cover;
  background-position: center top !important;
}
.section .container {
  padding-bottom: 60px;
  padding-top: 60px;
  z-index: 1;
  position: relative;
}
@media (min-width: 1200px) {
  .section .container {
    padding: 50px 25px;
  }
  .section .container__snippet {
    padding: 50px 25px;
  }
}
.section .acrobat__download {
  color: #707070;
  font-size: 1.4rem;
  line-height: 1.42;
  font-weight: normal;
  font-style: normal;
}
.section--short .container {
  padding-bottom: 0;
}
@media (min-width: 1200px) {
  .section--short .container, .section--short .container__snippet {
    padding: 50px 25px 0;
  }
}
.section__content .section__description, .section__content .section__header {
  text-align: left;
}
.section__content, .section__description {
  margin-bottom: 30px;
}
.section__content > h2:first-child, .section__content > .h2:first-child,
.section__content > h3:first-child,
.section__content > .h3:first-child, .section__description > h2:first-child, .section__description > .h2:first-child,
.section__description > h3:first-child,
.section__description > .h3:first-child {
  margin-top: 0;
  margin-bottom: 24px;
}
.section__content:last-child, .section__description:last-child {
  margin-bottom: 0;
}
.section__content .checks .icon__checkoutline::after, .section__description .checks .icon__checkoutline::after {
  color: #005EB8;
  font-weight: bold;
}
.section__divider .container {
  border-bottom: 1px solid #dbdbdb;
}
.section__header:first-child * {
  margin-top: 0;
}
.section__description, .section__header {
  text-align: center;
}
@media (max-width: 767.98px) {
  .section__description .btn, .section__header .btn {
    width: 100%;
  }
}
.section__dark-text .section__description *, .section__dark-text .section__header * {
  color: rgba(0, 0, 0, 0.8);
}
.section__pattern {
  background-position: 50% 50%;
  background-size: 100% auto;
}
@media (max-width: 767.98px) {
  .section__pattern {
    background-size: initial;
    background-position: 100% 0;
  }
}
.section__pattern-left {
  background-position: 50% 50%;
  background-size: 100% auto;
}
@media (max-width: 767.98px) {
  .section__pattern-left {
    background-size: initial;
    background-position: 0 0;
  }
}
.section__pattern-right {
  background-position: 50% 0;
  background-size: 100% auto;
}
@media (max-width: 767.98px) {
  .section__pattern-right {
    background-size: initial;
    background-position: 100% 0;
  }
}
.section__pattern-full {
  background-position: 50% 50%;
  background-size: 100% auto;
}
@media (max-width: 767.98px) {
  .section__pattern-full {
    background-size: initial;
    background-position: 0 0;
  }
}
.section__yellowback {
  background-color: #FFCE00;
}
.section__blueback {
  background-color: #005EB8;
}
.section__redback {
  background-color: #A32035;
  color: white;
}
.section__redback h2, .section__redback .h2,
.section__redback p {
  color: white;
}
.section__lightgrayback {
  background-color: #fafafa;
}
.section__grayback {
  background-color: #eeeeee;
}
.section__darkgrayback {
  background-color: #eeeeee;
}
.section__darkback {
  background-color: #545454;
}
.section__short .container {
  padding-bottom: 50px;
  padding-top: 17px;
}
.section__short .container__snippet {
  padding-bottom: 50px;
  padding-top: 17px;
}
.section__noback {
  background: none;
}
.section__subsection .section__header {
  overflow: hidden;
}
.section__subsection .section__header * {
  display: inline-block;
  font-size: 2.5rem;
  line-height: 1;
  margin: 0 -20px;
  position: relative;
  padding: 0 20px;
}
@media (max-width: 767.98px) {
  .section__subsection .section__header * {
    margin: 0 -5px 30px;
    padding: 0 5px;
  }
}
.section__subsection .section__header *:after, .section__subsection .section__header *:before {
  border-bottom: 1px solid #dbdbdb;
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  width: 50vw;
}
.section__subsection .section__header *:after {
  left: 100%;
}
.section__subsection .section__header *:before {
  right: 100%;
}
.section__subsection.section__light-text .section__header *:after, .section__subsection.section__light-text .section__header *:before {
  border-bottom: 1px solid #6e6e6e;
}
.section__description img, .section__content img {
  height: auto;
  max-width: 100%;
}

.link__icon-text {
  display: none;
}

.error-message {
  position: relative;
  background: #005EB8;
  min-height: 680px;
  display: flex;
  align-items: center;
  color: white;
}
@media (max-width: 767.98px) {
  .error-message {
    min-height: 0;
  }
}
.error-message__header * {
  color: white;
  font-family: "Noto Sans", sans-serif;
  font-size: 10rem;
  font-weight: normal;
  line-height: 0.8;
  margin-bottom: 40px;
}
@media (max-width: 767.98px) {
  .error-message__header * {
    font-size: 6.5rem;
    line-height: 1.25;
  }
}
.error-message p {
  font-size: 2rem;
  line-height: 1.5;
  margin: 0;
}
.error-message__cta {
  margin-top: 70px;
}
.error-message .img-overlay {
  background: #005EB8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.overlay {
  height: 100vh;
  opacity: 0;
  position: fixed;
  left: 10000px;
  visibility: hidden;
  top: 0;
  width: 100vw;
  z-index: 1051;
}
.overlay.open {
  left: 0;
  opacity: 1;
  visibility: visible;
}
.overlay__back {
  height: 100%;
  background: #000;
  opacity: 0.85;
  width: 100%;
}
.overlay__form {
  display: none;
}
.overlay__form-active .overlay__form {
  display: block;
}
.overlay__form-active .overlay__content {
  border-radius: 8px;
  background: #fff;
  flex: 1 0 100%;
  width: 100%;
  position: relative;
  max-width: 750px;
}
@media (max-height: 800px) {
  .overlay__form-active .overlay__content {
    border-radius: 0;
    height: auto;
    max-height: none;
    margin: 0 auto -100px;
  }
  .overlay__form-active .overlay__content-wrapper {
    display: block;
    padding: 0;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .overlay__form-active .overlay__content-scroll {
    max-width: 750px;
    margin: 0 auto;
  }
}
@media (max-width: 767.98px) {
  .overlay__form-active .overlay__content {
    border-radius: 0;
    max-height: none;
  }
}
.overlay__video-wrapper {
  display: none;
  padding-top: 56.25%;
  position: relative;
}
@media (max-width: 767.98px) {
  .overlay__video-wrapper {
    margin-top: 75px;
  }
}
.overlay__video-active .overlay__video-wrapper {
  display: block;
}
.overlay__video-window {
  max-width: 960px;
}
.overlay__video-container {
  display: none;
}
.overlay__video-container.active {
  display: inherit;
}
.overlay__content {
  width: 100%;
  position: relative;
  max-width: 750px;
}
.overlay__video-active .overlay__content {
  max-width: 950px;
}
@media (max-width: 767.98px) {
  .overlay__content {
    max-height: 100%;
  }
}
.overlay__content-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  left: 0;
  justify-content: center;
  position: absolute;
  padding: 50px 0;
  top: 0;
  width: 100%;
}
@media (max-width: 767.98px) {
  .overlay__content-wrapper {
    padding: 0;
  }
}
.overlay__content iframe {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}
.overlay__close-button {
  position: absolute;
  right: 12px;
  top: 3px;
  z-index: 100;
}
.overlay__close-button:hover {
  color: #A32035;
}
@media (min-width: 992px) {
  .overlay__close-button {
    font-size: 2rem;
  }
}
@media screen and (max-width: 1024px) {
  .overlay__close-button {
    right: 12px;
    top: 1.5px;
  }
}
@media (max-width: 767.98px) {
  .overlay__close-button {
    right: 15px;
  }
}
@media (max-width: 767.98px) {
  .overlay__close-button {
    right: 25px;
    top: 25px;
  }
  .program-detail .overlay__close-button .icon, .overlay__form-active .overlay__close-button .icon {
    color: rgba(0, 0, 0, 0.8);
  }
  .program-detail .overlay__close-button .icon:hover, .overlay__form-active .overlay__close-button .icon:hover {
    color: #A32035;
  }
}

.loading__wrapper {
  text-align: center;
  margin: 50px auto;
  padding: 10px 0 20px;
  width: 98px;
}
.loading__text {
  font-size: 1.4rem;
  line-height: 1.42;
  color: #707070;
  text-align: center;
  font-weight: bold;
  margin-top: -12px;
}

.nav-pills__wrapper--scrollable.scrollable {
  position: relative;
}
.nav-pills__wrapper--scrollable > .nav-pills {
  flex-wrap: nowrap;
  white-space: nowrap;
}
.nav-pills__wrapper--scrollable > .icon__rightmorearrow {
  display: none;
}
.nav-pills__wrapper--scrollable > .icon__rightmorearrow:after {
  vertical-align: baseline;
  line-height: 10px;
}
@media (max-width: 767.98px) {
  .nav-pills__wrapper--scrollable {
    overflow: hidden;
  }
  .nav-pills__wrapper--scrollable.scrollable {
    padding-top: 18px;
    margin: -15px -25px;
  }
  .nav-pills__wrapper--scrollable.scrollable .nav-pills {
    border-bottom: none;
    padding-left: 25px;
    padding-bottom: 15px;
    position: relative;
  }
  .nav-pills__wrapper--scrollable.scrollable .nav-pills > li:last-child {
    padding-right: 30px;
  }
  .nav-pills__wrapper--scrollable.scrollable .nav-pills:after {
    bottom: 15px;
    position: absolute;
    left: 30px;
    width: calc(100% + 130px);
  }
  .nav-pills__wrapper--scrollable.scrollable > .icon__rightmorearrow {
    color: #707070;
    display: block;
    font-size: 2rem;
    line-height: 0.5;
    top: 0;
    position: absolute;
    right: 12px;
  }
}

@media screen and (max-width: 992px) {
  .related-information .tabs__content.active {
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .related-information .tabs__content.active {
    display: block;
  }
}

.tabs__container {
  padding: 30px 0 0;
}
.tabs__container::after {
  display: block;
  clear: both;
  content: "";
}
.related-information .tabs__container {
  padding: 60px 0 0;
  position: relative;
}
@media (max-width: 767.98px) {
  .related-information .tabs__container {
    padding: 30px 0 0;
  }
}
.tabs__container .owl-stage {
  display: flex;
}
.tabs__container .owl-nav {
  z-index: 99999999;
  color: #545454;
  font-size: 5rem;
  width: 100%;
  height: 25px;
}
@media screen and (min-width: 992px) {
  .tabs__container .owl-nav {
    position: absolute;
    top: 30%;
    font-size: 10rem;
    height: auto;
  }
}
.tabs__container .owl-nav button {
  line-height: 50px !important;
}
@media screen and (min-width: 992px) {
  .tabs__container .owl-nav button {
    line-height: 100px !important;
  }
}
.tabs__container .owl-nav .owl-prev {
  position: absolute;
  left: 0;
}
@media screen and (min-width: 992px) {
  .tabs__container .owl-nav .owl-prev {
    left: -40px;
  }
}
.tabs__container .owl-nav .owl-next {
  position: absolute;
  right: 0;
}
@media screen and (min-width: 992px) {
  .tabs__container .owl-nav .owl-next {
    right: -40px;
  }
}
.tabs__container .card__event .card__event-content .card__text .card__subtitle {
  font: normal normal bold 14px/19px "Noto Sans", sans-serif;
  letter-spacing: 0;
}
.tabs__container .card__event .card__event-content .card__text .card__title h3, .tabs__container .card__event .card__event-content .card__text .card__title .h3,
.tabs__container .card__event .card__event-content .card__text .card__title a {
  font-size: 2rem;
  line-height: 1.5;
}
.tabs__container .card__event .card__event-content .card__text .card__copy {
  font: normal normal normal 1.6rem/1.4 "Noto Sans", sans-serif;
  letter-spacing: 0;
  color: #545454;
}
.tabs__container .card__event .card__event-content .card__information .card__date,
.tabs__container .card__event .card__event-content .card__information .card__time {
  font: normal normal normal 1.6rem/1.4 "Noto Sans", sans-serif;
  letter-spacing: 0;
  color: #545454;
}
.tabs__container .card__event .card__event-content .card__information .card__addlink .addeventatc {
  font: normal normal bold 1.5rem/1.3 "Noto Sans", sans-serif;
  letter-spacing: 0;
}
.tabs__wrapper {
  margin-top: 30px;
}
.tabs__wrapper.single-tab .nav-tabs {
  display: none;
}
.tabs__wrapper.single-tab .tabs__container {
  padding-top: 0;
}
.tabs__wrapper.scrollable {
  position: relative;
}
.tabs__wrapper > .icon__rightmorearrow {
  display: none;
}
.tabs__wrapper > .icon__rightmorearrow:after {
  vertical-align: baseline;
  line-height: 10px;
}
@media (max-width: 767.98px) {
  .tabs__wrapper {
    overflow: hidden;
  }
  .tabs__wrapper.scrollable {
    margin-top: 24px;
    margin-left: -25px;
    margin-right: -25px;
    padding-top: 18px;
  }
  .tabs__wrapper.scrollable .nav-tabs {
    border-bottom: none;
    padding-left: 30px;
    padding-bottom: 15px;
    position: relative;
  }
  .tabs__wrapper.scrollable .nav-tabs > li:last-child {
    margin-right: 30px;
  }
  .tabs__wrapper.scrollable .nav-tabs:after {
    bottom: 15px;
    position: absolute;
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);
    left: 30px;
    width: calc(100% + 130px);
  }
  .tabs__wrapper.scrollable > .icon__rightmorearrow {
    color: #707070;
    display: block;
    font-size: 2rem;
    line-height: 0.5;
    top: 0;
    position: absolute;
    right: 12px;
  }
}
@media (max-width: 767.98px) and (max-width: 767.98px) {
  .tabs__wrapper.scrollable .tabs__content {
    padding: 0 30px;
  }
}
@media (max-width: 767.98px) {
  .tabs__wrapper.scrollable .tabs__container {
    padding: 15px 0 0;
  }
}
.tabs__content {
  top: 0;
  left: -10000px;
  opacity: 0;
  position: absolute;
  transition: opacity 600ms;
  display: none;
}
.tabs__content.active {
  top: auto;
  left: auto;
  opacity: 1;
  position: relative;
  display: block;
}
.tabs__content.last {
  top: auto;
  left: 0;
  opacity: 0;
  position: absolute;
  visibility: inherit;
}
.tabs__content .row {
  display: flex;
}
@media (max-width: 767.98px) {
  .tabs__content .row {
    display: block;
  }
}

.location__edit {
  color: #A32035;
  padding-left: 5px;
}
.location__edit-window {
  cursor: pointer;
  display: inline-block;
  padding: 0 6px;
  text-align: left;
}
.location__edit-window.has-error input,
.location__edit-window.has-error .location__zipcode {
  color: #A32035;
}
.location__edit-window:hover .location__zipcode {
  text-decoration: underline;
  text-decoration-color: #383838;
}
.location__edit-window.active {
  background-color: #fafafa;
  cursor: inherit;
  display: inline-block;
}
.location__edit-window.active .location__zipcode {
  display: none;
}
.location__edit-window.active .location__field {
  display: inline-block;
}
.location__zipcode {
  color: #383838;
  display: inline-block;
  font-weight: bold;
  width: 48px;
}
.location__zipcode-label, .location__zipcode--reader {
  text-align: left;
  text-indent: -10000px;
  position: absolute;
}
.location__field {
  border: none;
  background: none;
  display: none;
  font-size: 1.4rem;
  color: #383838;
  font-weight: bold;
  letter-spacing: inherit;
  line-height: 28px;
  margin: 0;
  padding: 0;
  height: 28px;
  width: 48px;
}

.ways__value {
  align-items: flex-start;
  color: #707070;
  display: flex;
  flex-direction: column;
  padding-left: 35px;
  position: relative;
  text-align: left;
  font-size: 1.4rem;
  line-height: 1.2;
}
.ways__value .readmore button,
.ways__value a {
  font-size: 1.4rem;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-color: #dbdbdb;
  margin-top: 12px;
}
.ways__value p {
  font-weight: 500;
  margin: 0;
}
.ways__value > .icon {
  color: #949494;
  font-size: 1.9em;
  line-height: 1;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-decoration: none;
}
.ways__value .tagline {
  font-size: 1rem;
  line-height: 1.5;
}

.program-detail__info .ways__value a {
  font: normal normal bold 13px/18px "Noto Sans", sans-serif;
  letter-spacing: 0.2px;
}

.sidebyside {
  overflow: hidden;
}
.sidebyside .container-fluid, .sidebyside .container-old-lg-bs3, .sidebyside .container-xl {
  max-width: 2000px;
}
.sidebyside .container {
  padding-bottom: 40px;
}
.sidebyside__container.container {
  padding: 0;
}
.sidebyside .row {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
}
@media (max-width: 767.98px) {
  .sidebyside .row {
    flex-direction: column;
  }
}
.sidebyside__image {
  width: 100%;
  height: 100%;
}
.sidebyside__image.image__video-link {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.sidebyside__image-wrapper {
  min-height: 300px;
  position: relative;
}
.sidebyside__right .row {
  flex-direction: row;
}
@media (max-width: 767.98px) {
  .sidebyside__right .row {
    flex-direction: column;
  }
}
.sidebyside__right .sidebyside__column-image {
  padding-left: 0;
  justify-content: flex-end;
}
@media (max-width: 767.98px) {
  .sidebyside__right .sidebyside__column-image {
    padding: 0;
  }
}
.sidebyside__right .sidebyside__text {
  text-align: left;
}
.sidebyside__column {
  width: 100%;
}
.sidebyside__column img {
  width: 100%;
  max-width: 100%;
}
@media (max-width: 767.98px) {
  .sidebyside__column img {
    height: auto;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sidebyside__column {
    height: 350px;
  }
}
@media screen and (min-width: 768px) {
  .sidebyside__column {
    width: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .sidebyside__column img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.sidebyside__text {
  text-align: right;
  flex: auto;
}
@media (max-width: 767.98px) {
  .sidebyside__text {
    padding: 30px;
    text-align: left;
  }
}
.sidebyside__text h3:first-child, .sidebyside__text .h3:first-child {
  margin-top: 0;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sidebyside__text {
    padding: 15px;
  }
}
.sidebyside__divider .container {
  border-bottom: 1px solid #dbdbdb;
}

.image__caption {
  display: block;
  background-color: #fafafa;
  font-size: 1.4rem;
  line-height: 1.42;
  padding: 20px 15px 8px;
  width: 100%;
}
.image__full {
  margin: 0 0 12px;
}
.image__full img {
  height: auto;
  width: 100%;
}
.image__left {
  align-items: center;
  display: flex;
  flex-direction: row;
}
@media (max-width: 767.98px) {
  .image__left {
    align-items: flex-start;
  }
}
.image__left a {
  display: inline-block;
  max-width: 40%;
  margin-right: 10%;
  flex: 1 0 auto;
}
@media (max-width: 767.98px) {
  .image__left a {
    width: 70px;
  }
}
.image__left img {
  height: auto;
  max-width: 40%;
  margin-right: 10%;
  flex: 1 0 auto;
}
@media (max-width: 767.98px) {
  .image__left img {
    width: 30%;
  }
}
.image__left a img {
  margin: 0;
  max-width: none;
  width: 100%;
}
.image__right {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
}
.image__right img {
  height: auto;
  max-width: 40%;
  margin-left: 10%;
}
.image__floatleft, .image__floatright {
  position: relative;
  float: right;
  margin-left: 25px;
  margin-bottom: 25px;
  width: 360px;
}
@media (max-width: 767.98px) {
  .image__floatleft, .image__floatright {
    margin-left: 0;
    margin-bottom: 12px;
    width: 100%;
  }
}
.image__floatleft img, .image__floatright img {
  height: auto;
  width: 100%;
}
.image__floatleft.no-triangle:after, .image__floatright.no-triangle:after {
  content: "";
  display: none;
}
.image__floatleft {
  float: left;
  margin-left: 0;
  margin-right: 25px;
}
.image__video-link a {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.image__video-link a:after {
  content: "\f129";
}
.image__video-link a:after {
  content: " ";
  background-image: url("../../../fonts/play-icon.png");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
}
.image__video-link a:hover:after {
  background-image: url("../../../fonts/play-icon.png");
  background-position: 0 -24px;
  background-repeat: no-repeat;
}

.card__addlink a {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.3;
  display: inline-block;
  padding-left: 30px;
  position: relative;
}
.card__addlink .icon {
  color: #949494;
  font-size: 2.5rem;
  line-height: 0.75;
  position: absolute;
  left: 0;
  text-decoration: none;
}
.card__eventlisting {
  padding: 50px 0;
  border-bottom: 1px solid #dbdbdb;
}
@media (max-width: 767.98px) {
  .card__eventlisting {
    padding: 35px 0;
  }
}
.card__eventlisting:last-child {
  border-bottom: none;
}
.card__eventlisting .row {
  display: flex;
  margin: 0 -20px;
  flex-wrap: nowrap;
}
.card__eventlisting-link {
  display: block;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);
}
.card__eventlisting-link:hover {
  text-decoration: none;
  color: #821a2a;
}
.card__eventlisting-link:hover .card__eventlisting-description {
  color: rgba(0, 0, 0, 0.8);
}
.card__eventlisting-link:hover .card__eventlisting-name {
  text-decoration: underline;
}
.card__eventlisting-link:hover .card__eventlisting-location {
  color: #821a2a;
}
.card__eventlisting-when {
  border-right: 1px solid #dbdbdb;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
}
@media (max-width: 767.98px) {
  .card__eventlisting-when--desktop {
    display: none;
  }
}
.card__eventlisting-when--mobile {
  background-color: #fafafa;
  border: none;
  padding: 20px;
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .card__eventlisting-when--mobile {
    display: none;
  }
}
.card__eventlisting-text {
  padding-right: 15px;
}
.card__eventlisting-what {
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  align-items: flex-start;
}
@media (max-width: 767.98px) {
  .card__eventlisting-what {
    flex-direction: column-reverse;
    padding: 0 45px;
  }
}
.card__eventlisting-date {
  font-weight: bold;
}
.card__eventlisting-through {
  color: #707070;
  font-size: 1.4rem;
  text-align: center;
  overflow: hidden;
  max-width: 170px;
}
.card__eventlisting-through span {
  display: inline-block;
  position: relative;
  padding: 0 7px;
}
.card__eventlisting-through span:after, .card__eventlisting-through span:before {
  border-bottom: 1px solid #dee2e6;
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  width: 50vw;
}
.card__eventlisting-through span:after {
  left: 100%;
}
.card__eventlisting-through span:before {
  right: 100%;
}
.card__eventlisting-type {
  color: #A32035 !important;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}
.card__eventlisting-name {
  font-size: 2.1rem;
  line-height: 28px;
  margin-bottom: 12px;
}
.card__eventlisting-location {
  font-size: 1.4rem;
  color: #0d0d0d;
  margin-bottom: 12px;
}
.card__eventlisting-description {
  margin-bottom: 30px;
}
.card__eventlisting-image {
  flex: 1 0 auto;
  position: relative;
}
.card__eventlisting-image img {
  height: auto;
  width: 100%;
}
@media (max-width: 767.98px) {
  .card__eventlisting-image {
    margin-bottom: 24px;
  }
}
.card__eventlisting-ctas {
  white-space: nowrap;
}
@media (max-width: 767.98px) {
  .card__eventlisting-ctas {
    white-space: normal;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
.card__eventlisting-ctas .btn {
  font-size: 1.4rem;
  margin-right: 18px;
  overflow: auto;
}
.card__eventlisting-ctas .btn:last-child {
  margin-right: 0;
}
@media (max-width: 767.98px) {
  .card__eventlisting-ctas .btn {
    margin-right: 0;
    margin-top: 18px;
    width: auto;
  }
  .card__eventlisting-ctas .btn:last-child {
    margin-top: 0;
  }
}
@media (max-width: 767.98px) {
  .card__eventlisting-ctas .btn {
    font-size: 1.2rem;
    text-transform: none;
    min-width: 0;
  }
}
.card__eventlisting-ctas .icon {
  font-size: 1.8rem;
  line-height: 1.25;
  vertical-align: middle;
  text-decoration: none;
}
.card__eventlisting-ctas .btn-link {
  color: #A32035;
}
.card__eventlisting-ctas .btn-link:hover {
  color: #7f2130;
}
@media (max-width: 767.98px) {
  .card__eventlisting-ctas .btn-link {
    margin-left: -1px;
  }
}
.card__subtitle {
  color: #A32035 !important;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  line-height: 1.5;
  margin-bottom: 5px;
}
.card__title {
  font-size: 2.1rem;
  line-height: 1.5;
}
.card__title h3, .card__title .h3 {
  font-size: 1.8rem;
  line-height: 1.5;
  font-family: "Noto Sans", sans-serif;
}
.card__title a {
  color: rgba(0, 0, 0, 0.8);
}
.card__school {
  border-radius: 0;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
}
.card__faculty {
  background-color: #fff;
  display: block;
  font-size: 1.2rem;
  text-decoration: none;
}
@media (min-width: 768px) {
  .card__faculty {
    cursor: pointer;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
    transition: box-shadow 400ms;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .card__faculty:hover {
    text-decoration: none;
  }
  .card__faculty:hover .card__faculty-name * {
    text-decoration: none;
  }
  .card__faculty .card__image {
    overflow: hidden;
  }
}
@media (max-width: 767.98px) {
  .card__faculty {
    display: flex;
    flex-direction: row;
  }
  .card__faculty .card__image {
    width: 116px;
    margin-right: 16px;
    flex: 0 0 116px;
  }
}
.card__faculty:focus {
  text-decoration: none;
}
.card__faculty-column {
  margin-bottom: 24px;
  padding: 0 12px;
}
@media (max-width: 767.98px) {
  .card__faculty-column {
    margin-bottom: 0;
    padding: 25px;
    border-bottom: 1px solid #eeeeee;
  }
  .card__faculty-column:last-child {
    margin-bottom: 0;
  }
}
.card__faculty-text {
  border: 1px solid #eeeeee;
  border-top: 0;
  padding: 18px 12px;
  position: relative;
  flex-grow: 1;
}
@media (max-width: 767.98px) {
  .card__faculty-text {
    border: none;
  }
  .card__faculty-text:after {
    content: none;
  }
}
.card__faculty-label * {
  color: #707070;
  font-size: 1.2rem;
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  line-height: 1.9;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin: 0 0 8px;
}
@media (max-width: 767.98px) {
  .card__faculty-label * {
    margin: 0 0 4px;
  }
}
.card__faculty-name * {
  color: #A32035;
  font-size: 1.6rem;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.25;
  margin: 12px 0;
  text-decoration: none;
}
@media (max-width: 767.98px) {
  .card__faculty-name * {
    margin: 8px 0 0;
  }
}
.card__faculty-title {
  color: #707070;
  font-size: 1.4rem;
  line-height: 1.75;
  font-style: italic;
}
.card__faculty-specialty {
  border-top: 1px solid #eeeeee;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.3rem;
  line-height: 1.5;
  margin-top: 10px;
  padding-top: 15px;
}
.card__faculty-specialty .card__faculty-label * {
  font-weight: bold;
}
@media (max-width: 767.98px) {
  .card__faculty-specialty {
    border-top: none;
    margin-top: 0;
  }
}
.card__faculty-specialty p {
  margin: 0;
  font-size: 1.2rem;
}
.card__program-content, .card__event-content {
  height: 100%;
  background-color: #fff;
  border: 1px solid #eeeeee;
  padding: 5px 30px;
  position: relative;
}
.card__program-content.arts-sciences:hover, .card__event-content.arts-sciences:hover {
  border-bottom-color: #009739;
}
.card__program-content.business:hover, .card__event-content.business:hover {
  border-bottom-color: #002E5D;
}
.card__program-content.education:hover, .card__event-content.education:hover {
  border-bottom-color: #DA291C;
}
.card__program-content.nursing:hover, .card__event-content.nursing:hover {
  border-bottom-color: #FF8F1C;
}
.card__program-content.ext-education:hover, .card__event-content.ext-education:hover {
  border-bottom-color: #00BFB2;
}
.card__program-ways, .card__event-ways {
  margin: 0 -12px;
}
.card__program .ways__value, .card__event .ways__value {
  display: inline-flex;
  height: 31px;
  margin: 0 12px 8px;
}
.card__program .ways__value .icon, .card__event .ways__value .icon {
  top: 0;
  transform: none;
}
.card__program .card__subtitle, .card__event .card__subtitle {
  color: #A32035 !important;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 2;
}
.card__program .card__title, .card__event .card__title {
  margin-bottom: 16px;
}
.card__program .card__title *, .card__event .card__title * {
  font-size: 1.8rem;
  line-height: 1.6;
  margin: 0;
  text-decoration: none;
}
.card__program .card__title *:hover, .card__event .card__title *:hover {
  color: #A32035;
  text-decoration: underline;
}
.card__program .card__text, .card__event .card__text {
  border-bottom: 1px solid #eeeeee;
}
.card__program .card__date, .card__program .card__time, .card__program .card__copy, .card__event .card__date, .card__event .card__time, .card__event .card__copy {
  color: #707070 !important;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-weight: 500;
}
.card__program .card__copy, .card__event .card__copy {
  margin-bottom: 16px;
}
.card__program .card__description, .card__event .card__description {
  font-size: 1.6rem;
  line-height: 1.6;
}
.card__program .card__information, .card__event .card__information {
  margin: 20px 0;
}
.card__program .card__addlink, .card__program .card__cta, .card__event .card__addlink, .card__event .card__cta {
  margin-top: 20px;
}
.card__news {
  background-color: #fff;
  border: 2px solid #b8b8b8;
  cursor: pointer;
}
.card__news a.card__news-link {
  display: block;
  text-decoration: none;
}
.card__news a.card__news-link:hover {
  text-decoration: none;
}
.card__news a.card__news-link:hover .card__subtitle {
  text-decoration: none;
}
.card__news a.card__news-link:hover .card__title * {
  text-decoration: underline;
}
.card__news .card__image {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
  transition: box-shadow 400ms;
}
.card__news:hover .card__image {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}
.card__news:hover a {
  color: #A32035;
  text-decoration: underline;
}
@media (max-width: 767.98px) {
  .card__news {
    margin-bottom: 15px;
  }
  .card__news:last-child {
    margin-bottom: 0;
  }
}
.card__news .card__title * {
  text-decoration: none;
  font-weight: bold;
  margin: 0;
  font-size: 1.8rem;
  line-height: 1.5;
}
.card__news .card__title h3, .card__news .card__title .h3 {
  font-size: 2.1rem;
  line-height: 1.15;
}
.card__news .card_description {
  color: #383838;
  font-size: 1.6rem;
  margin: 10px 0;
}
.card__news .card__image {
  overflow: hidden;
}
.card__news .card__image.placeholder {
  background: #fafafa url("/images/UMassGlobal-placeholder.png") no-repeat 50% 50%;
  width: 100%;
  padding-top: 58%;
  overflow: hidden;
}
.card__news .card__image .icon {
  color: #fff;
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 3rem;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.75);
  text-decoration: none;
  line-height: 1;
}
.card__image {
  border-radius: 0;
  position: relative;
  align-self: center;
  align-content: center;
}
.card__image img {
  height: 300px;
}
@media (min-width: 768px) {
  .card__image img {
    height: 170px;
  }
}
@media (min-width: 992px) {
  .card__image img {
    height: 201px;
  }
}
.card__image-link {
  display: block;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
  transition: box-shadow 400ms;
}
.card__image-link:hover {
  text-decoration: none;
}
.card__image-link:hover {
  text-decoration: none;
  width: 100%;
  z-index: 999;
}
.card__text {
  padding: 20px;
  background: white;
}

.faq-item-accordion {
  margin: 50px 0;
}

.accordionV2 > li {
  border: 1px solid #eeeeee;
  padding: 0;
  transition: all 0.5s ease-in;
}
.accordionV2 > li:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.accordionV2 > li:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.accordionV2 > li button {
  border: none;
  cursor: pointer;
  font-weight: 700;
  outline: none;
  position: relative;
  text-align: left;
  width: 100%;
  overflow: hidden;
  padding: 16px;
  height: auto;
  text-overflow: unset;
  white-space: normal;
  display: flex;
  justify-content: space-between;
  background: transparent;
}
.accordionV2 > li button .icon {
  background: transparent;
  font-size: 1.6rem;
  position: inherit;
  align-self: center;
  width: auto;
  height: auto;
  line-height: initial;
}
.accordionV2 > li button:hover {
  background: #eeeeee;
}
.accordionV2 > li button:hover .icon {
  background: transparent;
}
.accordionV2 > li.open {
  display: block;
}
.accordionV2 > li.open .icon {
  transform: scale(1, -1);
}
.accordionV2 > li.open:first-of-type button {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.accordionV2 > li.open button {
  background: #eeeeee;
}
.accordionV2__section-wrapper {
  display: none;
  padding: 30px;
}
@media print {
  .accordionV2__section-wrapper {
    display: block !important;
    padding: 0 30px;
  }
}

.icon__large .icon {
  font-size: 2em;
  margin-right: 8px;
}

.skip {
  position: absolute;
  top: -1000px;
  left: -1000px;
  height: 1px;
  width: 1px;
  text-align: left;
  overflow: hidden;
}
.skip:focus, .skip:hover {
  left: 0;
  top: 0;
  height: auto;
  overflow: visible;
  position: static;
  width: 100%;
}
@media (max-width: 767.98px) {
  .skip {
    display: none;
  }
}

.font-12 {
  font-size: 1.2rem;
}

.people-finder__result.card__faculty-column {
  background: white;
  margin: 0;
  padding: 0;
  position: relative;
  border: 1px solid #dbdbdb;
}
.people-finder__result.card__faculty-column .card__faculty {
  background: transparent;
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
}
.people-finder__result.card__faculty-column .card__faculty .card__image {
  width: 100%;
  margin: 0;
  flex: none;
  height: auto;
}
.people-finder__result.card__faculty-column .card__faculty-text {
  border: none;
  border-bottom: 2px solid transparent;
}
.people-finder__result.card__faculty-column .card__faculty-text:after {
  display: none;
}
.people-finder__result.card__faculty-column.BusinessandProfessionalStudies:hover .card__faculty .card__faculty-text {
  border-bottom: 2px solid #002E5D;
}
.people-finder__result.card__faculty-column.ArtsandSciences:hover .card__faculty .card__faculty-text {
  border-bottom: 2px solid #009739;
}
.people-finder__result.card__faculty-column.NursingandHealthProfessions:hover .card__faculty .card__faculty-text {
  border-bottom: 2px solid #FF8F1C;
}
.people-finder__result.card__faculty-column.Education:hover .card__faculty .card__faculty-text {
  border-bottom: 2px solid #DA291C;
}
.people-finder__result.card__faculty-column.ExtendedEducation:hover .card__faculty .card__faculty-text {
  border-bottom: 2px solid #00BFB2;
}

#annBar {
  background: #eeeeee;
  color: #A32035;
  font-weight: bold;
  padding: 10px 0;
}
@media (max-width: 767.98px) {
  #annBar {
    padding: 30px 10px 10px;
    position: relative;
  }
}
#annBar .container {
  display: flex;
  align-items: center;
}
@media (max-width: 767.98px) {
  #annBar .container {
    flex-direction: column;
  }
}
#annBar #close {
  font-size: 2.1rem;
  font-weight: bold;
  padding: 0 20px;
  margin-right: 20px;
  border-right: 1px solid #b8b8b8;
  color: black;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  #annBar #close {
    position: absolute;
    right: 0;
    border: none;
    margin: 0;
    top: 0;
  }
}
#annBar p,
#annBar a {
  margin: 0;
  padding: 0;
  font-size: 1.3rem;
}
@media (min-width: 768px) {
  #annBar p,
  #annBar a {
    font-size: 1.6rem;
  }
}
#annBar p > i {
  margin-right: 10px;
}
@media (max-width: 767.98px) {
  #annBar p {
    text-align: center;
    margin-bottom: 8px;
  }
}
#annBar a {
  margin-left: 10px;
}
#annBar a i {
  padding-left: 5px;
  color: black;
}

div#sbs-tabs div#tabs-wrapper .item.active, div#sbs-tabs div#tabs-wrapper .item:hover {
  background: #094d8e;
  color: #fff;
  border-bottom: 2px solid #fff;
}

div#sbs-tabs div#tabs-wrapper {
  background: #383838;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (max-width: 959px) {
  div#sbs-tabs div#tabs-wrapper {
    overflow-x: scroll;
  }
}
div#sbs-tabs div#tabs-wrapper .item {
  padding: 10px;
  color: #fff;
  border-bottom: 2px solid #383838;
  border-right: 1px solid #b8b8b8;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 178px;
  justify-content: center;
  text-align: center;
}
div#sbs-tabs div#tabs-wrapper .item:last-child {
  border-right: none;
}
@media screen and (min-width: 1024px) {
  div#sbs-tabs div#tabs-wrapper .item {
    padding: 50px 0;
    font-size: 1.6rem;
  }
}
#sbs-content {
  background: #103869;
  overflow: hidden;
  position: relative;
}
#sbs-content .content-item {
  box-sizing: border-box;
  display: none;
}
#sbs-content .content-item.active {
  display: block;
}
@media screen and (min-width: 1024px) {
  #sbs-content .content-item.active {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}
#sbs-content .content-item .content-text {
  box-sizing: border-box;
  padding: 30px;
}
#sbs-content .content-item .content-text h3, #sbs-content .content-item .content-text .h3 {
  margin-bottom: 30px;
}
#sbs-content .content-item .content-text h3, #sbs-content .content-item .content-text .h3,
#sbs-content .content-item .content-text p {
  color: #fff;
}
#sbs-content .content-item .content-text a {
  color: #fff;
  text-decoration: none;
}
#sbs-content .content-item .content-text a:hover {
  text-decoration: underline;
}
#sbs-content div.content-img {
  width: 100%;
  text-align: center;
}
@media screen and (min-width: 1024px) {
  #sbs-content div.content-img {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 768px) {
  #sbs-content div.content-img {
    padding-bottom: 0;
  }
}
#sbs-content div.content-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.faculty__row--few {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.faculty__row--few .people-finder__result.card__faculty-column {
  width: 23%;
  margin: 0 22px 30px 0;
  border: 1px solid #dbdbdb;
}
@media (max-width: 767.98px) {
  .faculty__row--few .people-finder__result.card__faculty-column {
    margin: 0 0 30px 0;
    width: 100%;
  }
}

.section.related-information .tabs__wrapper.related-information__tabs.single-tab .tabs__content.active {
  overflow: visible;
}

section.section.schools .subheading.schools__container-header h3, section.section.schools .subheading.schools__container-header .h3 {
  color: #A32035 !important;
}

.section__content h3.subheading, .section__content .subheading.h3 {
  color: #A32035 !important;
}

.current-students-resources {
  border-top: 1px solid #dbdbdb;
}

img.my-umass-global {
  width: 24px;
  margin-right: 5px;
}

.cta-snippet .steps__links li a.btn {
  text-align: center;
}

.article img {
  display: block !important;
  max-width: 100% !important;
  height: auto !important;
}

.linkcollection__links {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
}
.linkcollection__links span:after {
  font-size: 1.8rem;
}
@media (max-width: 767.98px) {
  .linkcollection__links {
    width: 100%;
    flex-wrap: wrap;
  }
}
.linkcollection__links .item {
  margin-right: 20px;
  display: flex;
}
.linkcollection__links .item img {
  width: 24px;
  height: 20px;
  margin: 5px 5px 0 0;
}
.linkcollection__links .item:last-child {
  margin-right: 0;
}
.linkcollection__links .item span {
  margin-right: 5px;
}

/* PROGRAMS 2 COLS*/
.programs-2-col,
.programs-1-col {
  margin: 30px 0;
}
@media only screen and (min-width: 812px) {
  .programs-2-col,
  .programs-1-col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
  }
}
@media only screen and (max-width: 375px) {
  .programs-2-col .col,
  .programs-1-col .col {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 1024px) {
  .programs-2-col .col,
  .programs-1-col .col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 1024px) {
  .programs-2-col .col h6, .programs-2-col .col .h6,
  .programs-1-col .col h6,
  .programs-1-col .col .h6 {
    margin-top: 0;
  }
}
.programs-2-col .col .image,
.programs-1-col .col .image {
  margin-right: 20px;
  width: 100%;
  height: auto;
}
@media only screen and (min-width: 1024px) {
  .programs-2-col .col .image,
  .programs-1-col .col .image {
    width: 252px;
    height: 175px;
  }
}
.programs-2-col .col .image img,
.programs-1-col .col .image img {
  object-fit: cover;
  width: 100% !important;
  max-width: 100%;
  height: 100% !important;
}

.programs-1-col h5, .programs-1-col .h5 {
  font-size: 2.1rem;
  line-height: 1.2;
}
@media only screen and (min-width: 812px) {
  .programs-1-col {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px;
  }
}
@media only screen and (min-width: 1024px) {
  .programs-1-col .col .image {
    width: 500px;
    height: auto;
  }
}

/* PROGRAMS 2 COLS*/
/* loading dots */
.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 0;
  margin: 0 -5%;
  overflow: hidden;
}

div.icon__loading {
  display: block;
  padding: 30px 0;
  width: 100%;
}
div.icon__loading p {
  font-family: "Noto Sans", sans-serif;
  font-weight: 900;
  text-align: center;
  padding: 0;
  margin: 0;
}
div.icon__loading.fadeout {
  display: none;
}

/**
 * ==============================================
 * Dot Falling
 * ==============================================
 */
.degree-finder .icon__loading .dot-falling {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #005EB8;
  color: #005EB8;
  box-shadow: 10px 10px 10px 10px black;
  animation: dotFallingone 1s infinite linear;
  animation-delay: 0.1s;
}
.degree-finder .icon__loading .dot-falling::before,
.degree-finder .icon__loading .dot-falling::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.degree-finder .icon__loading .dot-falling::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #005EB8;
  color: #005EB8;
  animation: dotFallingBeforeone 1s infinite linear;
  animation-delay: 0s;
}
.degree-finder .icon__loading .dot-falling::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #005EB8;
  color: #005EB8;
  animation: dotFallingAfterone 1s infinite linear;
  animation-delay: 0.2s;
}
@keyframes dotFallingone {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%, 50%, 75% {
    box-shadow: 9999px 0 0 0 #005EB8;
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dotFallingBeforeone {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%, 50%, 75% {
    box-shadow: 9984px 0 0 0 #005EB8;
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dotFallingAfterone {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%, 50%, 75% {
    box-shadow: 10014px 0 0 0 #005EB8;
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

.hero-program-finder .icon__loading .dot-falling {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  box-shadow: 10px 10px 10px 10px black;
  animation: dotFallingtwo 1s infinite linear;
  animation-delay: 0.1s;
}
.hero-program-finder .icon__loading .dot-falling::before,
.hero-program-finder .icon__loading .dot-falling::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.hero-program-finder .icon__loading .dot-falling::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  animation: dotFallingBeforetwo 1s infinite linear;
  animation-delay: 0s;
}
.hero-program-finder .icon__loading .dot-falling::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  animation: dotFallingAftertwo 1s infinite linear;
  animation-delay: 0.2s;
}
@keyframes dotFallingtwo {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%, 50%, 75% {
    box-shadow: 9999px 0 0 0 white;
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dotFallingBeforetwo {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%, 50%, 75% {
    box-shadow: 9984px 0 0 0 white;
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dotFallingAftertwo {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%, 50%, 75% {
    box-shadow: 10014px 0 0 0 white;
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

.video-responsive {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
  margin: 30px 0;
}

.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.partner_portal_main_content .benefits .beneficts__mention {
  margin: 0 auto;
}
.partner_portal_main_content .benefits .beneficts__logo img {
  display: block;
  width: 50%;
  margin: 0 auto;
  padding-top: 30px;
}

.be-ix-link-block .be-related-link-container {
  margin-bottom: 20px;
}

.be-ix-link-block .be-related-link-container .be-label {
  margin: 0;
  color: #4b4f54 !important;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.be-ix-link-block .be-related-link-container .be-list {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.be-ix-link-block .be-related-link-container .be-list .be-list-item {
  display: inline-block;
  margin-right: 20px;
}

.be-ix-link-block .be-related-link-container .be-list .be-list-item:last-child {
  margin-right: 0;
}

@media (max-width: 767px) {
  .be-ix-link-block .be-related-link-container .be-label {
    width: 100%;
    margin-bottom: 8px;
    line-height: 1.45;
  }
  .be-ix-link-block .be-related-link-container .be-list {
    display: block;
    width: 100%;
    margin-bottom: 13px;
  }
  .be-ix-link-block .be-related-link-container .be-list .be-list-item {
    display: block;
    margin-right: 0;
    font-size: 16px;
    line-height: 50px;
  }
}
@media (min-width: 768px) {
  .be-ix-link-block .be-related-link-container {
    display: flex;
  }
  .be-ix-link-block .be-related-link-container .be-label {
    display: inline-block;
    margin-right: 20px;
    flex-basis: 125px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .be-ix-link-block .be-related-link-container .be-list {
    width: auto;
  }
}
ul.checks {
  list-style-type: none;
}

.tcc-header-image {
  max-width: 130px;
  max-height: 130px;
  align-self: center;
  margin-right: 60px;
}

.tcc-header p {
  font-size: 1.6rem;
}

.tcc-title {
  font-size: 4.1rem;
}

.newLightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newLightbox-content {
  width: 100%;
  max-width: 964px;
  height: 58%;
}

.newLightbox iframe {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.newLightbox iframe:focus {
  outline: none;
}

.newLightbox-content .lightbox-close {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 1.5em;
  cursor: pointer;
  color: #fff;
}

.forms__request-divider {
  overflow: hidden;
}

@media (max-width: 769px) {
  .forms__request-divider .tcpa_statement *:not(.mobile-only) {
    display: none;
  }
}
.ex-tab-pane {
  display: none;
}

.ex-tab-pane.active {
  display: block;
}

.terms {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

input[readonly] {
  appearance: none;
  background-color: #806700;
  border: none;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
  padding: 7px 15px;
  border-radius: 0;
}

.radio input[type=radio],
.radio-inline input[type=radio],
.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9 ;
}

.radio input[type=radio] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 15px !important;
  height: 15px !important;
  border: 2px solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
.radio input[type=radio]::before {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #A32035;
}
.radio input[type=radio]:checked::before {
  transform: scale(1);
}
.radio input[type=radio]:focus {
  outline: none;
}

.checkbox input {
  opacity: 0;
  position: absolute;
}
.checkbox input + .checkbox-button::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  width: 20px;
  display: inline-block;
  border: 2px solid #707070;
  cursor: pointer;
}
.checkbox input:checked + .checkbox-button::before {
  border: 2px solid #A32035;
  background-color: #A32035;
}
.checkbox input:checked + .checkbox-button:after {
  content: "\f10b";
}
.checkbox input:checked + .checkbox-button::after {
  background-color: #A32035;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  width: 20px;
  text-align: center;
}

.forms .form-group {
  margin-bottom: 28px;
}
.forms .form-group__question {
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 8px;
  margin-top: 41px;
  text-transform: none;
}
.forms .form-group.required > .form-group__question::after,
.forms .form-group.required > label::after, .forms .form-group.required > legend::after {
  color: #A32035;
  content: " *";
}
.forms label.form-group__question {
  margin-top: 0;
  margin-bottom: 5px;
}
.forms .form-control {
  font-weight: normal;
}
.forms__optional {
  font-weight: normal;
}
.forms__sidebar {
  color: #383838;
  font-size: 1.4rem;
  line-height: 23px;
}
@media (min-width: 768px) {
  .forms__sidebar {
    padding-left: 55px;
    padding-top: 6px;
  }
}
@media (max-width: 767.98px) {
  .forms__sidebar {
    margin-top: 35px;
  }
  .forms__sidebar .callout {
    text-align: center;
  }
}
.forms__sidebar .forms__request-subheader * {
  color: rgba(0, 0, 0, 0.8);
  line-height: 23px;
}
.forms__sidebar .image__full {
  position: relative;
}
.forms__section {
  margin-bottom: 63px;
}
.forms__group-header {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 45px;
}
.forms__group-header * {
  margin-top: 0;
}
@media (max-width: 767.98px) {
  .forms__group-header {
    margin-bottom: 30px;
  }
}
@media (max-width: 767.98px) {
  .forms textarea {
    height: 300px;
  }
}
.forms button[type=submit] {
  min-width: 342px;
  width: 100%;
}
@media (max-width: 767.98px) {
  .forms button[type=submit] {
    padding: 5px;
    min-width: 0;
  }
}
.forms .forms__container {
  max-width: 975px;
  margin: 0 auto;
}
.forms .select2-container,
.forms select {
  min-width: 342px;
}
@media (max-width: 767.98px) {
  .forms .select2-container,
  .forms select {
    min-width: 0;
    width: 100%;
  }
}
.forms__wrapper, .forms__thanks {
  top: 0;
  left: -10000px;
  opacity: 0;
  position: absolute;
  transition: opacity 600ms;
  visibility: hidden;
}
.forms__wrapper.active, .forms__thanks.active {
  top: auto;
  left: auto;
  opacity: 1;
  position: relative;
  visibility: inherit;
}
.forms__wrapper.last, .forms__thanks.last {
  top: auto;
  left: 0;
  opacity: 0;
  position: absolute;
  visibility: inherit;
}
.forms__message {
  color: hsl(0, 95%, 45%);
  display: none;
  font-size: 1.2rem;
}
.has-error__invalid .forms__message--invalid, .has-error__missing .forms__message--missing, .has-error__length .forms__message--length, .has-error__match .forms__message--match, .has-error__nodata .forms__message--nodata {
  display: block;
}
.forms__message--help {
  display: block;
  color: #383838;
}
.has-error .forms__message--help {
  display: none;
}
.forms__message p {
  margin-bottom: 0;
}
.forms__message p:last-child {
  margin-top: 15px;
  margin-bottom: 12px;
}
.forms__request {
  padding: 30px 0;
}
@media (max-width: 767.98px) {
  .forms__request {
    padding: 25px 0 125px;
  }
}
.forms__request .col-sm-4 {
  color: #383838;
}
.forms__request .col-sm-4 .forms__request-subheader * {
  color: #383838;
}
.forms__request .form-group.required > label::after, .forms__request .form-group.required > legend::after {
  color: #A32035;
  content: " *";
}
.forms__request-international .forms__message {
  bottom: auto;
  display: block;
  position: relative;
  top: auto;
}
.forms__request-international .radio {
  line-height: 15px;
  margin-right: 4px;
}
@media (max-width: 767.98px) {
  .forms__request-international .radio {
    margin: 0 35px 0 0;
    padding: 0;
  }
  .forms__request-international .radio label {
    line-height: 15px;
    margin: 0;
    padding-left: 22px;
  }
}
.forms__request-international .radio input {
  margin-top: 0;
  margin-right: 4px;
  position: relative;
  width: auto;
}
.forms__request-international--question label {
  display: flex;
  align-items: center;
}
.forms__request-international--question label span.text {
  margin-top: -6px;
  text-indent: 5px;
  margin-right: 10px;
}
@media (min-width: 768px) {
  .forms__request-zipcodegroup {
    display: flex;
    flex-direction: row;
  }
}
.forms__request-header * {
  font-size: 2.1rem;
  line-height: 1.5;
  margin-top: 0;
}
.forms__request-divider {
  border-left: 1px solid #eeeeee;
  height: 500px;
}
.forms__request-subheader * {
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.4rem;
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  line-height: 1.5;
  text-transform: uppercase;
  margin: 0;
}
.forms__request-thanks {
  display: none;
  height: 275px;
}
.forms__request-thanks.active {
  display: flex;
  align-items: flex-start;
  text-align: center;
  justify-content: center;
}
.forms__request-thanks .btn {
  width: 450px;
  max-width: 100%;
  margin-top: 30px;
}
.forms__request-thanks p {
  font-size: 1.8rem;
  line-height: 1.6px;
  max-width: 350px;
  margin: 0 auto;
}
.forms__request-thanksheader {
  font-size: 3.5rem;
  line-height: 1.5;
  max-width: 350px;
  margin: 0 auto 25px;
}
.forms__request-thanksheader * {
  margin: 0;
}
.forms__request-wrapper {
  padding: 0 30px;
  margin-top: 0;
}
.col-sm-4 .forms__request-wrapper {
  padding-left: 10px;
}
@media (max-width: 767.98px) {
  .col-sm-4 .forms__request-wrapper {
    padding-left: 30px;
    margin-top: 30px;
  }
}
.forms__request-wrapper .tabs__container {
  padding: 15px 0 0 0;
}
.forms__request-wrapper button {
  margin-top: 15px;
  width: 100%;
}
.forms__request-wrapper.tabs__wrapper ul.form-arrows li span#education, .forms__request-wrapper.tabs__wrapper ul.form-arrows li span#about {
  color: #000;
  background-color: #eeeeee;
}
.forms__request-wrapper.tabs__wrapper ul.form-arrows li span#education::after, .forms__request-wrapper.tabs__wrapper ul.form-arrows li span#about::after {
  border-left-color: #eeeeee;
}
.forms__request-wrapper.tabs__wrapper ul.form-arrows li.active span#education, .forms__request-wrapper.tabs__wrapper ul.form-arrows li.active span#about {
  color: #fff;
  background-color: #005EB8;
}
.forms__request-wrapper.tabs__wrapper ul.form-arrows li.active span#education::after, .forms__request-wrapper.tabs__wrapper ul.form-arrows li.active span#about::after {
  border-left-color: #005EB8;
}
.forms__request-wrapper.tabs__wrapper .tabs__container .tabs__content select.form-control [disabled] {
  color: #545454;
}
.forms__request-wrapper.tabs__wrapper .tabs__container .tabs__content select.form-control option {
  color: rgba(0, 0, 0, 0.8);
}
.forms__request-wrapper.tabs__wrapper .tabs__container .tabs__content .submit__tabs .icon {
  color: #fff;
}

.form-arrows.nav-tabs {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  pointer-events: none;
  z-index: 3;
  overflow: visible;
}
.form-arrows.nav-tabs li {
  border: none;
  height: 44px;
  margin: 0;
  overflow: hidden;
  position: relative;
  z-index: -1;
  flex: 1 1 49%;
}
.form-arrows.nav-tabs li:first-child a:before {
  content: none;
}
.form-arrows.nav-tabs li:last-child a {
  margin-right: 0;
}
.form-arrows.nav-tabs li span#education, .form-arrows.nav-tabs li span#about {
  border-radius: 0;
  height: 44px;
  border: none;
  font-size: 1.4rem;
  font-weight: 700;
  display: block;
  line-height: 1.7;
  margin-right: 12px;
  padding: 10px;
  position: relative;
  text-align: center;
  min-width: 45%;
  white-space: nowrap;
}
.form-arrows.nav-tabs li span#education:focus, .form-arrows.nav-tabs li span#about:focus {
  border: none;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 0, 255, 0.5);
}
.form-arrows.nav-tabs li span#education:after, .form-arrows.nav-tabs li span#about:after {
  content: " ";
  right: -25px;
  position: absolute;
  top: -2px;
  border-left: 13px solid #dbdbdb;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  border-right: 13px solid transparent;
  transition: border 400ms;
}
.form-arrows.nav-tabs li span#education:before, .form-arrows.nav-tabs li span#about:before {
  content: " ";
  left: 0;
  position: absolute;
  top: 0;
  border-left: 11px solid #fff;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-right: 11px solid transparent;
}
.form-arrows.nav-tabs li span#education:hover, .form-arrows.nav-tabs li span#about:hover {
  background-color: #fff;
  border: none;
  color: #A32035;
}
.form-arrows.nav-tabs li span#education:hover:after, .form-arrows.nav-tabs li span#about:hover:after {
  border-left-color: #fff;
}
.form-arrows.nav-tabs li.active span#education:after, .form-arrows.nav-tabs li.active span#about:after {
  border-left-color: #383838;
}
.form-arrows.nav-tabs li.active span#education:hover, .form-arrows.nav-tabs li.active span#about:hover {
  background-color: #383838;
  border: none;
  color: #fff;
}
.form-arrows.nav-tabs li.active span#education:hover:after, .form-arrows.nav-tabs li.active span#about:hover:after {
  border-left-color: #383838;
}

.student-type .checkbox,
.forms__request-predefined .checkbox {
  width: 50px;
  visibility: hidden;
  margin-bottom: 0 !important;
}
.student-type .checkbox input,
.forms__request-predefined .checkbox input {
  width: 20px !important;
}

@media (max-width: 767.98px) {
  .forms__request-predefined {
    margin: 0;
    padding: 15px 0 0 !important;
  }
}
.forms__request-predefined .forms__message {
  margin-bottom: 11px;
}

.hero__home .forms__request-wrapper .forms__request-predefined .forms__message {
  margin-bottom: 0;
}
.hero__home .forms__request-wrapper .tcpa_statement {
  color: rgba(0, 0, 0, 0.8);
}

#generealLeadMessageCampus {
  display: block;
  position: relative;
  bottom: 0;
}

#zipcodeGeneralLeadForm {
  margin-left: 16px;
}

@media (max-width: 767.98px) {
  #generalLeadFormRequest {
    padding: 20px 0 0;
  }
  #generalLeadFormRequest .checkbox label {
    padding-left: 27px !important;
  }
}

.degreeTypeBtns {
  display: flex;
  flex-wrap: wrap;
}
.degreeTypeBtns a.fullbtn-primary.degreeTypeFormBtn {
  flex: 1 auto;
  margin: 5px;
}

#formGeneralLead .select2-container,
#formGeneralLead select {
  width: 100%;
}

.iti {
  position: relative;
}

.iti * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti input, .iti input[type=text], .iti input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
  height: calc((3.5rem + 2px) * 1.4);
}

.iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}

.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px;
}

.iti__arrow {
  margin-left: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.iti__country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.iti__country-list--dropup {
  bottom: 100%;
  margin-bottom: -1px;
}

@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal;
  }
}
.iti__flag-box {
  display: inline-block;
  width: 20px;
}

.iti__divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC;
}

.iti__country {
  padding: 5px 10px;
  outline: none;
}

.iti__dial-code {
  color: #999;
}

.iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti__flag-box, .iti__country-name, .iti__dial-code {
  vertical-align: middle;
}

.iti__flag-box, .iti__country-name {
  margin-right: 6px;
}

.iti--allow-dropdown input, .iti--allow-dropdown input[type=text], .iti--allow-dropdown input[type=tel], .iti--separate-dial-code input, .iti--separate-dial-code input[type=text], .iti--separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}

.iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
  right: auto;
  left: 0;
}

.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default;
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px;
}

.iti--container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}

.iti--container:hover {
  cursor: pointer;
}

.iti-mobile .iti--container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed;
}

.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%;
}

.iti-mobile .iti__country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti__flag {
  width: 20px;
}

.iti__flag.iti__be {
  width: 18px;
}

.iti__flag.iti__ch {
  width: 15px;
}

.iti__flag.iti__mc {
  width: 19px;
}

.iti__flag.iti__ne {
  width: 18px;
}

.iti__flag.iti__np {
  width: 13px;
}

.iti__flag.iti__va {
  width: 15px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px;
  }
}
.iti__flag.iti__ac {
  height: 10px;
  background-position: 0 0;
}

.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0;
}

.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0;
}

.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0;
}

.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0;
}

.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0;
}

.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0;
}

.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0;
}

.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0;
}

.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0;
}

.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0;
}

.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0;
}

.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0;
}

.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0;
}

.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0;
}

.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0;
}

.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0;
}

.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0;
}

.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0;
}

.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0;
}

.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0;
}

.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0;
}

.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0;
}

.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0;
}

.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0;
}

.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0;
}

.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0;
}

.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0;
}

.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0;
}

.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0;
}

.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0;
}

.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0;
}

.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0;
}

.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0;
}

.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0;
}

.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0;
}

.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0;
}

.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0;
}

.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0;
}

.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0;
}

.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0;
}

.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0;
}

.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0;
}

.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0;
}

.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0;
}

.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0;
}

.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0;
}

.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0;
}

.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0;
}

.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0;
}

.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0;
}

.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0;
}

.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0;
}

.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0;
}

.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0;
}

.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0;
}

.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0;
}

.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0;
}

.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0;
}

.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0;
}

.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0;
}

.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0;
}

.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0;
}

.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0;
}

.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0;
}

.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0;
}

.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0;
}

.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0;
}

.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0;
}

.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0;
}

.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0;
}

.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0;
}

.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0;
}

.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0;
}

.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0;
}

.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0;
}

.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0;
}

.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0;
}

.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0;
}

.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0;
}

.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0;
}

.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0;
}

.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0;
}

.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0;
}

.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0;
}

.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0;
}

.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0;
}

.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0;
}

.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0;
}

.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0;
}

.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0;
}

.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0;
}

.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0;
}

.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0;
}

.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0;
}

.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0;
}

.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0;
}

.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0;
}

.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0;
}

.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0;
}

.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0;
}

.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0;
}

.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0;
}

.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0px;
}

.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0px;
}

.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0px;
}

.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0px;
}

.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0px;
}

.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0px;
}

.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0px;
}

.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0px;
}

.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0px;
}

.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0px;
}

.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0px;
}

.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0px;
}

.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0px;
}

.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0px;
}

.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0px;
}

.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0px;
}

.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0px;
}

.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0px;
}

.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0px;
}

.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0px;
}

.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0px;
}

.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0px;
}

.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0px;
}

.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0px;
}

.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0px;
}

.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0px;
}

.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0px;
}

.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0px;
}

.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0px;
}

.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0px;
}

.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0px;
}

.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0px;
}

.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0px;
}

.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0px;
}

.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0px;
}

.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0px;
}

.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0px;
}

.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0px;
}

.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0px;
}

.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0px;
}

.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0px;
}

.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0px;
}

.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0px;
}

.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0px;
}

.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0px;
}

.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0px;
}

.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0px;
}

.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0px;
}

.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0px;
}

.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0px;
}

.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0px;
}

.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0px;
}

.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0px;
}

.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0px;
}

.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0px;
}

.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0px;
}

.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0px;
}

.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0px;
}

.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0px;
}

.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0px;
}

.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0px;
}

.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0px;
}

.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0px;
}

.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0px;
}

.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0px;
}

.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0px;
}

.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0px;
}

.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0px;
}

.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0px;
}

.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0px;
}

.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0px;
}

.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0px;
}

.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0px;
}

.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0px;
}

.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0px;
}

.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0px;
}

.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0px;
}

.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0px;
}

.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0px;
}

.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0px;
}

.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0px;
}

.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0px;
}

.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0px;
}

.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0px;
}

.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0px;
}

.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0px;
}

.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0px;
}

.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0px;
}

.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0px;
}

.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0px;
}

.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0px;
}

.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0px;
}

.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0px;
}

.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0px;
}

.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0px;
}

.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0px;
}

.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0px;
}

.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0px;
}

.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0px;
}

.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0px;
}

.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0px;
}

.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0px;
}

.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0px;
}

.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0px;
}

.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0px;
}

.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0px;
}

.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0px;
}

.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0px;
}

.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0px;
}

.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0px;
}

.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0px;
}

.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0px;
}

.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0px;
}

.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0px;
}

.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0px;
}

.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0px;
}

.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0px;
}

.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0px;
}

.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0px;
}

.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0px;
}

.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0px;
}

.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0px;
}

.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0px;
}

.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0px;
}

.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0px;
}

.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0px;
}

.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0px;
}

.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0px;
}

.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0px;
}

.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0px;
}

.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0px;
}

.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0px;
}

.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0px;
}

.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0px;
}

.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0px;
}

.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0px;
}

.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0px;
}

.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0px;
}

.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0px;
}

.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0px;
}

.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0px;
}

.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0px;
}

.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0px;
}

.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0px;
}

.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0px;
}

.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0px;
}

.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0px;
}

.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0px;
}

.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0px;
}

.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0px;
}

.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0px;
}

.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0px;
}

.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0px;
}

.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0px;
}

.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0px;
}

.iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19//img/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/img/flags@2x.png");
  }
}
.iti__flag.iti__np {
  background-color: transparent;
}

#requestInfoFormContainer .military-affiliation {
  width: 310px;
}
@media screen and (max-width: 767px) {
  #requestInfoFormContainer .military-affiliation {
    width: 100%;
  }
}

.hero__background--overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero {
  color: rgba(0, 0, 0, 0.8);
  min-height: 325px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.hero .columns__container {
  margin: 0;
}
.hero svg polygon {
  display: inherit;
}
.hero__header * {
  font-size: 4rem;
  line-height: 45px;
  margin-top: 0;
}
@media (max-width: 767.98px) {
  .hero__header * {
    font-size: 3rem;
    line-height: 35px;
  }
}
.hero__description * {
  font-size: 1.6rem;
  line-height: 1.5;
}
@media (max-width: 767.98px) {
  .hero__description * {
    font-size: 1.4rem;
    line-height: 1.5;
  }
}
.hero .container {
  padding: 50px 25px;
}
@media (max-width: 767.98px) {
  .hero .container {
    width: 100%;
  }
}
.hero__interior {
  /*display: flex;
    align-items: center;*/
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  clip-path: polygon(100% 0%, 0% 0%, 0% 86%, 50% 100%, 100% 86%);
  padding: 0 0 50px;
}
@media (max-width: 767.98px) {
  .hero__interior {
    clip-path: polygon(100% 0%, 0% 0%, 0% 95%, 50% 100%, 100% 95%);
  }
}
.hero__interior .hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgb(0, 0, 0);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 20%, rgba(255, 255, 255, 0) 70%);
}
@media (max-width: 767.98px) {
  .hero__interior .hero-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
}
.hero__background {
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
  background-size: cover;
  background-position: left top;
}
.hero__background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.hero__background #hero-video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  opacity: 0;
  transition: opacity 0.5s;
}
.hero__background #hero-video.active {
  opacity: 1;
}
.hero__background--cover {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0) 65%);
  display: none;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}
.hero--light .hero__background--cover {
  display: block;
}
.hero__background-shape {
  height: 100%;
  width: 100%;
  position: absolute;
}
.hero__background-shape polygon {
  height: 100%;
  width: 100%;
}
.hero__background-image {
  background-size: cover;
  background-position: center top;
  height: 100%;
  width: 100%;
  display: block;
}
.arts-sciences .hero__background {
  border-top: 2px solid #009739;
}
.business .hero__background {
  border-top: 2px solid #002E5D;
}
.education .hero__background {
  border-top: 2px solid #DA291C;
}
.nursing .hero__background {
  border-top: 2px solid #FF8F1C;
}
.ext-education .hero__background {
  border-top: 2px solid #00BFB2;
}
.hero__video {
  position: relative;
}
@media (max-width: 767.98px) {
  .hero__video {
    margin-top: 30px;
  }
}
.hero__home {
  position: relative;
}
.hero__home .container {
  align-items: center;
  display: flex;
  flex-direction: row;
  min-height: 490px;
  padding-top: 60px;
  padding-bottom: 40px;
}
@media (max-width: 767.98px) {
  .hero__home .container {
    align-items: flex-start;
    min-height: 0;
    flex-direction: column;
    justify-content: center;
  }
}
.hero__home--alt .hero__background--triangles {
  background-size: cover;
  background-position: center top;
}
.hero__home .hero__header * {
  color: white;
  font-size: 5.2rem;
  line-height: 62px;
}
@media (max-width: 767.98px) {
  .hero__home .hero__header * {
    font-size: 3.5rem;
    line-height: 43px;
  }
}
.hero__home .hero__description p {
  color: white;
}
.hero__home .hero__description * {
  font-size: 1.8rem;
  line-height: 1.667;
  vertical-align: inherit;
}
@media (max-width: 767.98px) {
  .hero__home .hero__description * {
    font-size: 1.8rem;
    line-height: 1.667;
  }
}
@media (max-width: 767.98px) {
  .hero__home .hero__container {
    max-width: 100%;
  }
}
@media (max-width: 767.98px) {
  .hero__home .hero__cta {
    max-width: 300px;
  }
  .hero__home .hero__cta .btn {
    font-size: 1.4rem;
    text-align: center;
    width: 100%;
  }
}
.hero__container {
  max-width: 460px;
  margin-right: 60px;
}
@media (min-width: 768px) and (max-width: 959.98px) {
  .hero__container {
    max-width: 330px;
    margin-right: 20px;
  }
}
@media (max-width: 767.98px) {
  .hero__container {
    margin-right: 0;
  }
}
.hero__cta {
  margin-top: 40px;
}
.hero__cta .btn {
  font-size: 1.6rem;
}
.hero__cta .btn-primary,
.hero__cta .btn-secondary,
.hero__cta .btn-alt {
  padding: 15px 32px;
  height: 60px;
}
.hero--light {
  color: #fff;
}
.hero--light .hero__description, .hero--light .hero__header {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
.hero__container {
  position: relative;
}
.hero__header * {
  color: rgba(0, 0, 0, 0.8);
}
.hero--light .hero__header * {
  color: #fff;
}
.hero__altcta {
  color: #fff;
  padding: 20px 70px;
  position: relative;
}
@media (max-width: 767.98px) {
  .hero__altcta {
    border-left: none;
    border-top: 1px solid #b1616e;
    padding: 20px;
  }
}
.hero__altcta:first-child {
  border-left: none;
  padding-left: 15px;
}
@media (max-width: 767.98px) {
  .hero__altcta:first-child {
    border-top: none;
    padding: 20px;
  }
}
.hero__altcta:first-child::after {
  content: " ";
  position: absolute;
  height: 60%;
  width: 1px;
  background: #fff;
  display: block;
  top: 20%;
  right: 0;
}
.hero__altcta-container {
  background-color: #A32035;
}
.hero__altcta-container .container {
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 767.98px) {
  .hero__altcta-container .container {
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  .hero__altcta-container .row {
    margin: 0;
  }
}
.hero__altcta p {
  margin: 0;
}
.hero__altcta a {
  color: #FFCE00;
  text-decoration: underline;
  font-weight: 700;
}
.hero__altcta a:hover {
  color: #fff;
}
.hero__landing {
  /*margin-top: -86px;
  padding-top: 86px;*/
}
@media (max-width: 767.98px) {
  .hero__landing {
    margin-top: -64px;
    padding-top: 64px;
  }
  .hero__landing .container {
    padding-top: 15px;
    padding-bottom: 25px;
  }
}
@media (max-width: 767.98px) {
  .hero__landing .hero__header * {
    font-size: 3.2rem;
    line-height: 35px;
  }
}
.hero__landing .select2-container--classic .select2-selection--single {
  background-color: #fff;
}
.hero__landing .select2-container--classic .select2-selection__arrow {
  background-color: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.8);
  width: 52px;
}
.hero__landing .select2-container--classic .select2-selection__arrow .icon__downarrow {
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.8);
}
.hero__landing .forms__request-header {
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 22px;
}
.hero__landing .forms__request-header * {
  color: #707070;
  font-size: 2rem;
  line-height: 1.25;
  max-width: none;
  margin-bottom: 14px;
}
@media (min-width: 992px) {
  .hero__landing .forms__request-header * {
    white-space: nowrap;
  }
}
@media (max-width: 767.98px) {
  .hero__landing .forms__request-header * {
    line-height: 1.5;
    margin-bottom: 10px;
  }
}
.hero__landing .forms__request-header h3, .hero__landing .forms__request-header .h3 {
  color: rgba(0, 0, 0, 0.8);
}
@media (min-width: 992px) {
  .hero__landing .forms__request-header h3, .hero__landing .forms__request-header .h3 {
    white-space: initial;
  }
}
@media (max-width: 767.98px) {
  .hero__landing .forms__request-header {
    border: none;
    margin-bottom: 0;
  }
}
.hero__landing .forms__request-wrapper {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.5647058824);
  background-color: #fff;
  padding: 30px;
}
@media (max-width: 767.98px) {
  .hero__landing .forms__request-wrapper {
    padding: 18px 25px 16px;
    width: 100%;
  }
}
.hero__landing .forms__request-wrapper .form-group {
  margin-bottom: 8px;
}
.hero__landing .forms__request-wrapper .form-group.has-error {
  margin-bottom: 15px;
}
@media (max-width: 767.98px) {
  .hero__landing .forms__request-wrapper .form-group {
    margin-bottom: 0;
  }
}
@media (max-width: 767.98px) {
  .hero__landing .forms__request-wrapper button {
    height: 43px;
    line-height: 22px;
  }
}
.hero__landing .forms__request-wrapper .form-group.required > label::after {
  color: #A32035;
  content: " *";
}
.hero__landing .forms__request-wrapper.tabs__wrapper ul.form-arrows li a {
  color: rgba(0, 0, 0, 0.8);
}
.hero__landing .forms__request-wrapper.tabs__wrapper ul.form-arrows li.active a {
  color: #fff;
}
.hero__landing .form-arrows.nav-tabs li.active span#education, .hero__landing .form-arrows.nav-tabs li.active span#about {
  background-color: #545454;
  color: #fff;
  font-weight: bold;
}
.hero__landing .form-arrows.nav-tabs li:first-child span#education, .hero__landing .form-arrows.nav-tabs li:first-child span#about {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
@media (max-width: 767.98px) {
  .hero__landing .form-arrows.nav-tabs li:first-child span#education, .hero__landing .form-arrows.nav-tabs li:first-child span#about {
    margin-right: 9px;
  }
}
.hero__landing .form-arrows.nav-tabs li:first-child span#education:before, .hero__landing .form-arrows.nav-tabs li:first-child span#about:before {
  content: none;
}
.hero.hero-paid-landing .hero__home::before {
  background: none !important;
}
.hero.hero-paid-landing .hero__background {
  position: relative;
}
.hero.hero-paid-landing .hero__background img {
  width: 100%;
  min-height: 330px;
  height: 500px;
  object-fit: cover;
}
@media (max-width: 767.98px) {
  .hero.hero-paid-landing .hero__background img {
    min-height: 277px;
    height: 277px;
  }
}
.hero.hero-paid-landing .paid-hero-content {
  margin-top: -200px;
}
@media screen and (max-width: 767px) {
  .hero.hero-paid-landing .paid-hero-content {
    margin-top: 0;
    background: #005EB8;
    color: white;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .hero.hero-paid-landing .paid-hero-content .hero__container {
    margin-right: 0;
    margin-top: auto;
    margin-bottom: auto;
    max-width: 710px;
    padding-right: 145px;
  }
}
.hero.hero-paid-landing .paid-hero-content .hero__container .hero__header h1, .hero.hero-paid-landing .paid-hero-content .hero__container .hero__header .h1 {
  color: #A32035;
}
@media screen and (max-width: 767px) {
  .hero.hero-paid-landing .paid-hero-content .hero__container .hero__header h1, .hero.hero-paid-landing .paid-hero-content .hero__container .hero__header .h1 {
    color: white;
    margin-bottom: 19px;
  }
}
.hero.hero-paid-landing .paid-hero-content .hero__container .hero__paragraph p {
  font: normal normal normal 28px/35px Noto Sans;
  letter-spacing: 0;
}
@media screen and (max-width: 767px) {
  .hero.hero-paid-landing .paid-hero-content .hero__container .hero__paragraph p {
    font: normal normal normal 16px/26px Noto Sans;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .hero.hero-paid-landing .paid-hero-content .forms__request-wrapper {
    position: relative;
    top: -40px;
  }
}

section.hero.hero__interior.hero--light.arts-sciences {
  border-top: 4px solid #009739;
}
section.hero.hero__interior.hero--light.business {
  border-top: 4px solid #002E5D;
}
section.hero.hero__interior.hero--light.education {
  border-top: 4px solid #DA291C;
}
section.hero.hero__interior.hero--light.nursing {
  border-top: 4px solid #FF8F1C;
}
section.hero.hero__interior.hero--light.ext-education {
  border-top: 4px solid #00BFB2;
}

.hero-program-finder .hero__home .container {
  flex-wrap: wrap;
  display: block;
  min-height: auto;
}
.hero-program-finder .hero__home .container .degree-finder__filter-container {
  border: none;
  margin: 0;
}
.hero-program-finder .hero__home .container .degree-finder__filter-container .btn {
  height: 52px;
  line-height: 52px;
  margin-top: 5px;
  padding-top: 0;
  width: 100%;
}
@media screen and (min-width: 767px) {
  .hero-program-finder .hero__home .container .degree-finder__filter-container .btn {
    margin-top: 0;
    width: initial;
  }
}
.hero-program-finder .hero__home .container .degree-finder__filters {
  border: none;
  background: none;
  margin: 0;
  padding: 30px 0;
}
.hero-program-finder .hero__home .container .degree-finder__filter-wrapper {
  width: 100%;
  margin: 0;
}
.hero-program-finder .hero__home .container .degree-finder__filter-wrapper .degree-finder__filter {
  width: 100%;
  margin-left: 0;
}
@media screen and (min-width: 767px) {
  .hero-program-finder .hero__home .container .degree-finder__filter-wrapper .degree-finder__filter {
    width: 30%;
    margin-right: 30px;
  }
}

/*TO MOVE HERO TEXT*/
@media screen and (max-width: 768px) {
  .mobile-top-left .hero__home, .mobile-center-left .hero__home, .mobile-bottom-left .hero__home {
    min-height: 400px;
    padding: 25px;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: row;
  }
  .mobile-top-left .hero__home .hero__background--overlay, .mobile-center-left .hero__home .hero__background--overlay, .mobile-bottom-left .hero__home .hero__background--overlay {
    background: linear-gradient(to bottom, #0f4c85 20%, rgba(0, 94, 184, 0) 65%) no-repeat padding-box !important;
  }
  .mobile-top-left .hero__home .container, .mobile-center-left .hero__home .container, .mobile-bottom-left .hero__home .container {
    padding: 0;
    display: flex;
  }
  .mobile-top-left .hero__home .container .degree-finder, .mobile-center-left .hero__home .container .degree-finder, .mobile-bottom-left .hero__home .container .degree-finder {
    width: 100%;
  }
  .mobile-top-left .hero__home .container .hero__video, .mobile-center-left .hero__home .container .hero__video, .mobile-bottom-left .hero__home .container .hero__video {
    margin: 0;
  }
  .mobile-top-left .hero__home .container .hero__description p, .mobile-center-left .hero__home .container .hero__description p, .mobile-bottom-left .hero__home .container .hero__description p {
    margin: 0;
    font-size: 1.8rem;
    line-height: 1.4;
  }
  .mobile-top-left .hero__home .container .hero__header, .mobile-center-left .hero__home .container .hero__header, .mobile-bottom-left .hero__home .container .hero__header {
    font-size: 3.5rem;
    line-height: 1;
  }
  .mobile-top-left #heroInterior .hero__background--overlay, .mobile-center-left #heroInterior .hero__background--overlay, .mobile-bottom-left #heroInterior .hero__background--overlay {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 20%, rgba(255, 255, 255, 0) 70%) no-repeat padding-box;
  }
  .mobile-top-left #heroInterior .container, .mobile-center-left #heroInterior .container, .mobile-bottom-left #heroInterior .container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    min-height: 280px;
    padding: 10px 25px;
  }
}

@media screen and (max-width: 768px) {
  .mobile-bottom-left .hero__home {
    align-items: flex-end;
  }
  .mobile-bottom-left .hero__home .container {
    flex-direction: column-reverse;
    align-content: flex-start;
  }
  .mobile-bottom-left .hero__home .hero__background--overlay {
    background: linear-gradient(to top, #0f4c85 20%, rgba(0, 94, 184, 0) 65%) no-repeat padding-box !important;
  }
  .mobile-bottom-left #heroInterior .hero__background--overlay {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 25%, rgba(255, 255, 255, 0) 70%) no-repeat padding-box !important;
  }
  .mobile-bottom-left #heroInterior .container {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 768px) {
  .mobile-center-left .hero__home {
    align-items: center;
  }
  .mobile-center-left .hero__home .hero__background--overlay {
    background: linear-gradient(to right, #0f4c85 20%, rgba(0, 94, 184, 0) 65%) no-repeat padding-box !important;
  }
  .mobile-center-left #heroInterior .container {
    justify-content: center;
  }
}

.partner_portal_main_content .hero__background--overlay {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .partner_portal_main_content .mobile-top-left .hero__home .hero__background--cover, .partner_portal_main_content .mobile-center-left .hero__home .hero__background--cover, .partner_portal_main_content .mobile-bottom-left .hero__home .hero__background--cover {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 25%, rgba(0, 0, 0, 0) 65%);
  }
}
@media screen and (max-width: 768px) {
  .partner_portal_main_content .mobile-bottom-left .hero__home .hero__background--cover {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8) 25%, rgba(0, 0, 0, 0) 65%);
  }
}
@media screen and (max-width: 768px) {
  .partner_portal_main_content .mobile-center-left .hero__home .hero__background--cover {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8) 25%, rgba(0, 0, 0, 0) 65%);
  }
}

/*TO MOVE HERO TEXT*/
.teaser {
  background-position: 50% 0;
  background-repeat: repeat-y;
}
.teaser .section__description {
  margin-bottom: 50px;
}
.teaser__description {
  text-align: center;
  max-width: 780px;
  margin: 0 auto 45px;
}
.teaser__thumb {
  padding-bottom: 10px;
  padding-top: 10px;
}
.teaser__image {
  color: rgba(0, 0, 0, 0.8);
  display: block;
  position: relative;
  text-decoration: none;
}
.teaser__image img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: all 0.5s ease;
}
@media (min-width: 992px) {
  .teaser__image img {
    height: 370px;
  }
}
.teaser__image-wrapper {
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
  transition: box-shadow 400ms;
  position: relative;
  max-height: 350px;
  overflow: hidden;
}
.teaser__image-wrapper:hover {
  text-decoration: none;
}
.teaser__image-wrapper img {
  width: 100%;
  max-height: 350px;
  object-fit: cover;
  max-width: 100%;
}
.teaser__text {
  text-decoration: none;
}
.teaser__link {
  bottom: 0;
  content: " ";
  border-bottom: 800px solid #FFCE00;
  border-right: 800px solid transparent;
  height: 0;
  right: 30px;
  opacity: 0.5;
  position: absolute;
  width: 0;
}
.teaser__title * {
  font-size: 2.1rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 32px;
}
.teaser__copy {
  color: #383838;
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 20px;
  text-decoration: none;
}

a.teaser__text:hover,
a.teaser__image:hover {
  text-decoration: none;
}
a.teaser__text:hover .teaser__title *,
a.teaser__image:hover .teaser__title * {
  color: #A32035;
  text-decoration: underline;
}
a.teaser__text:hover .teaser__copy *,
a.teaser__image:hover .teaser__copy * {
  color: #707070;
}

.readmore__image img {
  width: 100%;
  object-fit: cover;
  max-height: 330px;
  height: 300px;
  transition: all 0.5s ease;
}
.readmore__image.v-image {
  zoom: 1;
}
.readmore__image.v-image img {
  height: auto;
  max-height: 100%;
}
@media (max-width: 767.98px) {
  .readmore__image {
    margin: 25px 0 0;
  }
}
.readmore .section__header * {
  margin-top: 0;
}
.readmore .section__description {
  margin-bottom: 0;
  line-height: 24px;
}
.readmore__content .btn {
  margin-top: 8px;
}
@media (max-width: 767.98px) {
  .readmore .container {
    padding-top: 30px;
  }
}

.readmore__text ul,
.teaser__copy ul {
  margin: 0;
  padding: 0;
}
.readmore__text ul li,
.teaser__copy ul li {
  padding-left: 11px;
  margin: 10px 4px;
}
.readmore__text.program-detail__readmore ul,
.teaser__copy.program-detail__readmore ul {
  padding: 0;
  margin-left: -5px !important;
}
.readmore__text.program-detail__readmore ul li,
.teaser__copy.program-detail__readmore ul li {
  padding-left: 0;
}

.readmore__text ul:not(.checks),
.teaser__copy ul:not(.checks) {
  list-style-type: disc;
}
@supports (list-style: symbols("\f108")) {
  .readmore__text ul:not(.checks),
  .teaser__copy ul:not(.checks) {
    list-style: umass-carets;
  }
  .readmore__text ul:not(.checks) > li::marker,
  .teaser__copy ul:not(.checks) > li::marker {
    color: #005EB8;
    font-family: "standard-icons";
  }
}

.thumbnails {
  background-position: 50% 0;
  background-repeat: repeat-y;
  padding-bottom: 30px;
}
.thumbnails .section__description {
  margin-bottom: 50px;
}
.thumbnails__description {
  text-align: center;
  max-width: 780px;
  margin: 0 auto 45px;
}
.thumbnails__angle {
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: auto;
}
.thumbnails__thumb {
  padding-bottom: 10px;
  padding-top: 10px;
  height: 360px;
  margin-bottom: 15px;
}
@media screen and (max-width: 767px) {
  .thumbnails__thumb {
    padding-bottom: 0;
  }
}
.thumbnails__image {
  color: rgba(0, 0, 0, 0.8);
  display: block;
  position: relative;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
  transition: box-shadow 400ms;
  width: 100%;
  height: 360px;
  margin: 0 auto;
}
.thumbnails__image:hover {
  text-decoration: none;
}
@media (max-width: 767.98px) {
  .thumbnails__image {
    height: 350px;
    margin: 0 auto;
  }
}
.thumbnails__image img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 360px;
}
@media screen and (max-width: 767px) {
  .thumbnails__image img {
    height: 350px;
  }
}
.thumbnails__image--shadow {
  fill: #fff;
  opacity: 0.9;
}
.thumbnails__image--cta {
  transition: fill 400ms;
  fill: #FFCE00;
}
.thumbnails__image .icon {
  bottom: 1px;
  font-size: 2.5rem;
  line-height: 1;
  position: absolute;
  right: 22px;
  text-decoration: none;
}
.thumbnails__image:hover {
  text-decoration: none;
}
.thumbnails__image:hover .thumbnails__image--cta {
  fill: #806700;
}
.thumbnails__image:hover .icon {
  color: rgba(0, 0, 0, 0.8);
}
.thumbnails__image:hover .thumbnails__copy * {
  color: #fff;
}
.thumbnails__image:hover .thumbnails__title * {
  color: #fff;
  text-decoration: none;
}
.thumbnails__image.card {
  top: 50%;
  left: 50%;
  width: 360px;
  height: 360px;
  margin: -150px;
  border: none;
}
@media screen and (max-width: 767px) {
  .thumbnails__image.card {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
    float: none;
    perspective: none;
  }
}
.thumbnails__image.card .content {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
}
.thumbnails__image.card:hover .content {
  transform: rotateY(180deg);
  transition: transform 1.25s;
}
.thumbnails__image.card:hover .content .front .thumbnails__text {
  display: none;
}
.thumbnails__image.card .front,
.thumbnails__image.card .back {
  position: absolute;
  height: 100%;
  width: 100%;
  background: white;
  line-height: 5;
  text-align: center;
  font-size: 6rem;
  border-radius: 5px;
  backface-visibility: hidden;
}
.thumbnails__image.card .front .thumbnails__text,
.thumbnails__image.card .back .thumbnails__text {
  width: 100%;
  height: 35%;
  background: transparent linear-gradient(0deg, rgba(0, 0, 0, 0.7490196078) 0, rgba(16, 16, 16, 0.5098039216) 57%, rgba(128, 128, 128, 0) 100%) 0 0 no-repeat padding-box;
  top: auto;
  padding: 45px 15px 25px;
}
@media screen and (max-width: 767px) {
  .thumbnails__image.card .front .thumbnails__text,
  .thumbnails__image.card .back .thumbnails__text {
    bottom: 0 !important;
  }
}
.thumbnails__image.card .back {
  color: white;
  transform: rotateY(180deg);
}
.thumbnails__image.card .back .thumbnails__text {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5058823529) 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 45px 30px 25px;
}
.thumbnails__link {
  bottom: 0;
  content: " ";
  border-bottom: 800px solid #FFCE00;
  border-right: 800px solid transparent;
  height: 0;
  right: 30px;
  opacity: 0.5;
  position: absolute;
  width: 0;
}
.thumbnails__title * {
  color: #fff;
  font-size: 2.1rem;
  font-weight: normal;
  line-height: 1.1;
  margin-bottom: 10px;
  font-family: "Noto Serif", serif;
  text-decoration: none;
  text-align: left;
  padding: 0.5rem 0;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .thumbnails__title * {
    line-height: 22px;
    margin-bottom: 12px;
  }
}
.thumbnails__text {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 45px 15px 25px;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 1.4rem;
  line-height: 1.4;
  width: 70%;
  text-align: left;
  color: #fff;
  word-break: break-word;
}
@media (min-width: 1200px) {
  .thumbnails__text {
    padding: 0 15px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .thumbnails__text:before {
    content: " ";
    shape-outside: polygon(0 0, 100% 0, 100% 100%);
    height: 230px;
    width: 273px;
    float: right;
  }
}
.thumbnails__copy p {
  overflow: auto;
}

.testimonials {
  background-position: 0 50%;
}
@media (max-width: 767.98px) {
  .testimonials {
    padding: 50px 0;
  }
}
.testimonials .container {
  padding-bottom: 35px;
  padding-top: 35px;
  background-color: #eeeeee;
}
.testimonials blockquote {
  padding: 0;
  margin: 0 0 20px;
}
.testimonials blockquote footer {
  position: absolute;
}
@media (max-width: 767.98px) {
  .testimonials blockquote {
    font-size: 1.8rem;
  }
  .testimonials blockquote:before {
    left: -40px;
    top: 17px;
  }
}
.testimonials__image {
  text-align: center;
}
.testimonials__image img {
  margin: 0 auto;
  width: 100%;
  height: 215px;
  object-fit: cover;
}
@media (max-width: 767.98px) {
  .testimonials__image img {
    height: 150px;
  }
}
.testimonials__name {
  font-size: 1.2rem;
}
.testimonials__quote {
  padding: 30px;
  margin: 30px;
}
@media (max-width: 767.98px) {
  .testimonials__quote {
    padding: 30px 40px;
    margin: 0;
  }
}
.testimonials__continer {
  position: relative;
  padding: 0 50px;
}
@media (max-width: 767.98px) {
  .testimonials__continer {
    padding: 0 5px;
  }
}
.testimonials__previous, .testimonials__next {
  color: #545454;
  font-size: 5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 767.98px) {
  .testimonials__previous, .testimonials__next {
    font-size: 3.5rem;
    top: 75px;
    transform: none;
  }
}
.testimonials__previous:hover, .testimonials__next:hover {
  color: #000;
}
.testimonials__previous {
  left: 0;
}
.testimonials__next {
  right: 0;
}
.testimonials__slide {
  padding: 0 50px;
}
.testimonials__slide .row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 767.98px) {
  .testimonials__slide .row {
    flex-direction: column;
  }
}

@media (max-width: 960px) {
  .partner_hero .sidebyside__image {
    width: 100%;
  }
  .partner_hero .sidebyside__column img {
    width: 100%;
  }
  .partner_hero .sidebyside__text {
    padding: 30px;
    text-align: left;
  }
}
@media (min-width: 960px) {
  .partner_hero .sidebyside__column-image {
    display: -ms-flexbox;
  }
  .partner_hero .float-left {
    float: left;
  }
  .partner_hero .float-right {
    float: right;
  }
  .partner_hero .sidebyside__image-wrapper {
    width: 50%;
  }
}
.partner_hero h2, .partner_hero .h2 {
  color: black;
}
@media (min-width: 992px) {
  .partner_hero h2, .partner_hero .h2 {
    font-size: 30px;
  }
}
.partner_hero .sidebyside__text {
  text-align: left;
  margin: 10px 0;
  margin: 10px 10%;
}
@media (min-width: 768px) {
  .partner_hero .sidebyside__text {
    margin: 10px 10%;
  }
}
@media (min-width: 992px) {
  .partner_hero .sidebyside__text {
    margin: 20% 8% 0 1%;
  }
}
@media screen and (min-width: 768px) and (orientation: portrait) {
  .partner_hero .sidebyside__text {
    margin: 20px 10% 10px;
  }
}
@media screen and (min-width: 1600px) {
  .partner_hero .sidebyside__text {
    margin: 30% 8% 0 2%;
  }
}
.partner_hero.sidebyside__right .sidebyside__text {
  text-align: left;
  margin: 10px 0;
  margin: 10px 10%;
}
@media (min-width: 768px) {
  .partner_hero.sidebyside__right .sidebyside__text {
    margin: 10px 10%;
  }
}
@media (min-width: 992px) {
  .partner_hero.sidebyside__right .sidebyside__text {
    margin: 20% 1% 0 8%;
  }
}
@media screen and (min-width: 768px) and (orientation: portrait) {
  .partner_hero.sidebyside__right .sidebyside__text {
    margin: 20px 10% 10px;
  }
}
@media screen and (min-width: 1600px) {
  .partner_hero.sidebyside__right .sidebyside__text {
    margin: 30% 1% 0 8%;
  }
}
.partner_hero .testimonials {
  margin: 20px 0;
}
.partner_hero .testimonials .testimonials__image {
  margin: auto;
}
@media (min-width: 768px) {
  .partner_hero .testimonials {
    margin: 40px 10% 0;
  }
}
@media (min-width: 992px) {
  .partner_hero .testimonials {
    margin: 10% 45px;
  }
}
.partner_hero .testimonials .testimonials__quote {
  padding: 12px 12px 60px 12px;
}
.partner_hero .testimonials .testimonials__quote blockquote {
  line-height: 22pt;
  font-size: 1.5em;
}
@media (min-width: 768px) {
  .partner_hero .testimonials .testimonials__quote blockquote {
    line-height: 16pt;
    font-size: 0.9em;
  }
}
@media (min-width: 992px) {
  .partner_hero .testimonials .testimonials__quote blockquote {
    font-size: 1.2em;
    line-height: 24pt;
  }
}
@media screen and (min-width: 768px) and (orientation: portrait) {
  .partner_hero .testimonials .testimonials__quote blockquote {
    line-height: 17pt;
    font-size: 0.9em;
  }
}
@media (min-width: 768px) {
  .partner_hero .testimonials .testimonials__quote {
    padding: 12px 12px 30px 12px;
  }
}
@media (min-width: 992px) {
  .partner_hero .testimonials .testimonials__quote {
    padding: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 960px) and (orientation: portrait) {
  .partner_hero .testimonials .testimonials__quote {
    padding-bottom: 60px;
  }
}
.partner_hero .testimonials .testimonials__image img {
  border-radius: 50%;
  height: auto;
  width: 18vw;
}
@media screen and (max-width: 767px) {
  .partner_hero .testimonials .testimonials__image img {
    height: 18vw;
    width: 18vw;
  }
}
.partner_hero .testimonials .testimonials__image .photo_testimonial {
  border-radius: 50%;
  margin: auto;
  background-size: 100%;
  height: 24vw;
  width: 24vw;
  height: 18vw;
  width: 18vw;
}
@media (min-width: 768px) {
  .partner_hero .testimonials .testimonials__image .photo_testimonial {
    height: 8vw;
    width: 8vw;
  }
}
@media (min-width: 992px) {
  .partner_hero .testimonials .testimonials__image .photo_testimonial {
    height: 8vw;
    width: 8vw;
  }
}
@media screen and (min-width: 768px) and (max-width: 960px) and (orientation: portrait) {
  .partner_hero .testimonials .testimonials__image .photo_testimonial {
    height: 16vw;
    width: 16vw;
  }
}
.partner_hero .texture_overlay {
  background-image: url(/images/pattern_back_full--transparent.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}
@media (max-width: 767.98px) {
  .partner_hero .texture_overlay {
    background-position: 155% top;
  }
}

.variant_red {
  background-color: #7a1828;
}
.variant_red h2, .variant_red .h2 {
  color: white;
}
.variant_red .testimonials__quote {
  color: #fff;
}
.variant_red .testimonials__quote blockquote {
  color: #fff;
}
.variant_red .testimonials__quote blockquote:before, .variant_red .testimonials__quote blockquote:after {
  color: #fff;
}

.variant_yellow {
  background-color: #FFCE00;
}
.variant_yellow h2, .variant_yellow .h2 {
  color: black;
}
.variant_yellow .testimonials__quote {
  color: #1c1c1c;
}
.variant_yellow .testimonials__quote blockquote {
  color: #1c1c1c;
}

.variant_black {
  background: #1c1c1c;
}
.variant_black h2, .variant_black .h2 {
  color: silver;
}
.variant_black .testimonials__quote {
  color: silver;
}
.variant_black .testimonials__quote blockquote {
  color: silver;
}

.variant_grey {
  background: #fafafa;
}
.variant_grey h2, .variant_grey .h2 {
  color: #1c1c1c;
}
.variant_grey .testimonials__quote {
  color: #1c1c1c;
}
.variant_grey .testimonials__quote blockquote {
  color: #1c1c1c;
}

.benefits .surround {
  border: solid 1px #b8b8b8;
  color: #707070;
  font-size: 1.4rem;
  text-align: center;
}
@media (min-width: 992px) {
  .benefits .surround {
    max-width: 430px;
    padding: 20px 20px 20px 15%;
  }
}
.benefits__mention {
  font-family: "Noto Serif", serif;
  font-size: 1.4rem;
  text-align: center;
  width: 50%;
  margin-left: 14%;
}
.benefits .form-group.required > label::after {
  color: #7a1828;
  content: " *";
}
.benefits .forms__request-wrapper {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  padding: 30px;
}
.benefits select.form-control {
  width: 100%;
}
.benefits__logo {
  padding: 50px 0;
}
.benefits__logo img {
  display: block;
  margin-left: 13%;
  margin-right: auto;
  width: 50%;
}

.partner_portal_main_content .forms__request-header * {
  max-width: 100% !important;
}
.partner_portal_main_content .section__header:first-child * {
  text-align: left;
}
.partner_portal_main_content.center-titles .section__header:first-child * {
  text-align: center;
}

.horizontal_form .forms__wrapper {
  margin-left: 15px;
}
@media (max-width: 992px) {
  .horizontal_form .col-md-12 {
    width: 100%;
  }
}
.horizontal_form h5, .horizontal_form .h5 {
  font-size: 1.4rem;
  line-height: 1.5;
  text-align: center;
  color: #545454;
  font-family: "Noto Serif", serif;
}
.horizontal_form .wrapper {
  padding: 50px 5px;
  text-align: center;
}
@media (max-width: 992px) {
  .horizontal_form .wrapper {
    padding: 20px 5px;
  }
}
.horizontal_form .wrapper img {
  height: auto;
  width: auto;
  max-width: 80%;
}
.horizontal_form .sidebyside__column img {
  height: auto;
  width: auto;
  max-width: 80%;
}
.horizontal_form .forms__request-header * {
  max-width: 100%;
}
.horizontal_form .form-arrows.nav-tabs li a:hover {
  background-color: #dfdfdf;
  color: #822433;
}
.horizontal_form .form-arrows.nav-tabs li a:hover:after {
  border-left-color: #dfdfdf;
}
.horizontal_form .form-arrows.nav-tabs li.active a:hover {
  background-color: #545454;
  color: white;
}
.horizontal_form .form-arrows.nav-tabs li.active a:hover:after {
  border-left-color: #545454;
}
.horizontal_form .student-type--question {
  position: relative !important;
}
.horizontal_form .forms__message--international {
  display: none;
}
.horizontal_form .student-type--question .form-inline {
  padding-top: 15px;
}
.horizontal_form .student-type {
  position: absolute;
  width: 100%;
  padding-left: 0;
}
@media (max-width: 767.98px) {
  .horizontal_form .student-type {
    position: inherit;
  }
}
.horizontal_form .radio-inline, .horizontal_form .checkbox-inline {
  padding: 0;
}
.horizontal_form .form-inline .radio label {
  font-size: 1.2rem;
}
.horizontal_form .nurseRequestHorizontal .forms__message {
  bottom: -20px !important;
}
.horizontal_form .tcpa_statement {
  margin-top: 20px;
  padding: 10px;
  display: inline-table;
}
@media (max-width: 767.98px) {
  .horizontal_form .tcpa_statement {
    padding: 0 !important;
  }
}
.horizontal_form .tcpa_statement.education, .horizontal_form .tcpa_statement.about {
  padding: 20px;
}
@media (max-width: 767.98px) {
  .horizontal_form .forms__request-wrapper {
    padding: 15px;
  }
}
.horizontal_form .form-arrows.nav-tabs li a {
  cursor: pointer;
}
.horizontal_form .form-arrows.nav-tabs {
  pointer-events: all;
}
.horizontal_form .form-inline .form-group {
  display: inline-block;
  vertical-align: middle;
}
@media (max-width: 991px) {
  .horizontal_form .form-inline .form-group {
    margin-bottom: 8px;
  }
}
.horizontal_form .form-inline select.form-control {
  background: transparent url(/images/downarrow-line.png) no-repeat 100% 50%;
  background-size: auto 100%;
  color: #383838;
  padding-right: 46px;
  appearance: none;
}
@media (min-width: 992px) {
  .horizontal_form .brd-col-lg-3 {
    width: 22.5%;
  }
  .horizontal_form .brd-col-lg-2 {
    width: 12%;
  }
}
.horizontal_form .submit__tabs {
  float: right;
}
.horizontal_form .submit__tabs .icon {
  color: #383838;
}
.horizontal_form .horizontal_form_divider {
  margin-top: 50px;
  border-top: 2px solid #dbdbdb;
}
@media (max-width: 767.98px) {
  .horizontal_form .horizontal_form_divider {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 959.98px) {
  .horizontal_form .horizontal_form_divider {
    display: none;
  }
}
.horizontal_form li.disabled {
  pointer-events: none;
}
.horizontal_form .tab-second.disabled a {
  color: #606060 !important;
}

.osh {
  margin: 0;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1199px) and (orientation: landscape) {
  .hidden_for_large {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .hidden_for_small {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 959.98px) {
  .hidden_for_medium {
    display: none;
  }
}
@media (min-width: 1200px) {
  .hidden_for_large {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .horizontal_form.full_width .forms__wrapper {
    padding: 0 25px;
  }
}
.horizontal_form.full_width .tcpa_statement {
  margin-top: 78px;
}
@media (max-width: 767.98px) {
  .horizontal_form.full_width .tcpa_statement {
    margin: 20px 0;
  }
}
.horizontal_form.full_width .tcpa_statement p {
  font-size: 1.3rem;
}

.partnerships__template {
  display: none;
}
.partnerships__name {
  display: none;
}
.partnerships__results {
  padding: 30px 0;
}
.partnerships .tabs__wrapper {
  margin-top: 60px;
}
@media (max-width: 767.98px) {
  .partnerships .tabs__wrapper {
    margin-top: 30px;
  }
}
.partnerships .tabs__container {
  padding-top: 32px;
}
.partnerships .tabs__content {
  margin: -5px;
}
.partnerships__result {
  padding: 5px;
}
.partnerships__result-image {
  border: 1px solid #dee2e6;
  padding: 0;
  position: relative;
}
.partnerships__result-image img {
  height: auto;
  max-width: 65%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.partnerships__result-image:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.partnerships__cta {
  text-align: center;
  margin-top: 32px;
}

.btn-primary-carat {
  color: #fff;
  background-color: #A32035;
  border-color: #A32035;
}
.btn-primary-carat:hover {
  color: #fff;
  background-color: #8b1b2d;
  border-color: #821a2a;
}
.btn-check:focus + .btn-primary-carat, .btn-primary-carat:focus {
  color: #fff;
  background-color: #8b1b2d;
  border-color: #821a2a;
  box-shadow: 0 0 0 0.25rem rgba(177, 65, 83, 0.5);
}
.btn-check:checked + .btn-primary-carat, .btn-check:active + .btn-primary-carat, .btn-primary-carat:active, .btn-primary-carat.active, .show > .btn-primary-carat.dropdown-toggle {
  color: #fff;
  background-color: #821a2a;
  border-color: #7a1828;
}
.btn-check:checked + .btn-primary-carat:focus, .btn-check:active + .btn-primary-carat:focus, .btn-primary-carat:active:focus, .btn-primary-carat.active:focus, .show > .btn-primary-carat.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(177, 65, 83, 0.5);
}
.btn-primary-carat:disabled, .btn-primary-carat.disabled {
  color: #fff;
  background-color: #A32035;
  border-color: #A32035;
}
.btn-primary-carat:after {
  content: "\f108";
  margin-left: 4px;
  font-family: "standard-icons";
}

.btn-secondary-carat {
  color: #fff;
  background-color: #005EB8;
  border-color: #005EB8;
}
.btn-secondary-carat:hover {
  color: #fff;
  background-color: #00509c;
  border-color: #004b93;
}
.btn-check:focus + .btn-secondary-carat, .btn-secondary-carat:focus {
  color: #fff;
  background-color: #00509c;
  border-color: #004b93;
  box-shadow: 0 0 0 0.25rem rgba(38, 118, 195, 0.5);
}
.btn-check:checked + .btn-secondary-carat, .btn-check:active + .btn-secondary-carat, .btn-secondary-carat:active, .btn-secondary-carat.active, .show > .btn-secondary-carat.dropdown-toggle {
  color: #fff;
  background-color: #004b93;
  border-color: #00478a;
}
.btn-check:checked + .btn-secondary-carat:focus, .btn-check:active + .btn-secondary-carat:focus, .btn-secondary-carat:active:focus, .btn-secondary-carat.active:focus, .show > .btn-secondary-carat.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 118, 195, 0.5);
}
.btn-secondary-carat:disabled, .btn-secondary-carat.disabled {
  color: #fff;
  background-color: #005EB8;
  border-color: #005EB8;
}
.btn-secondary-carat:after {
  content: "\f108";
  margin-left: 4px;
  font-family: "standard-icons";
}

.btn-primary-blue-carat {
  color: #fff;
  background-color: #005EB8;
  border-color: #005EB8;
}
.btn-primary-blue-carat:hover {
  color: #fff;
  background-color: #00509c;
  border-color: #004b93;
}
.btn-check:focus + .btn-primary-blue-carat, .btn-primary-blue-carat:focus {
  color: #fff;
  background-color: #00509c;
  border-color: #004b93;
  box-shadow: 0 0 0 0.25rem rgba(38, 118, 195, 0.5);
}
.btn-check:checked + .btn-primary-blue-carat, .btn-check:active + .btn-primary-blue-carat, .btn-primary-blue-carat:active, .btn-primary-blue-carat.active, .show > .btn-primary-blue-carat.dropdown-toggle {
  color: #fff;
  background-color: #004b93;
  border-color: #00478a;
}
.btn-check:checked + .btn-primary-blue-carat:focus, .btn-check:active + .btn-primary-blue-carat:focus, .btn-primary-blue-carat:active:focus, .btn-primary-blue-carat.active:focus, .show > .btn-primary-blue-carat.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 118, 195, 0.5);
}
.btn-primary-blue-carat:disabled, .btn-primary-blue-carat.disabled {
  color: #fff;
  background-color: #005EB8;
  border-color: #005EB8;
}
.btn-primary-blue-carat:after {
  content: "\f108";
  margin-left: 4px;
  font-family: "standard-icons";
}

.btn-tertiary-carat {
  color: #000;
  background-color: #FFCE00;
  border-color: #FFCE00;
}
.btn-tertiary-carat:hover {
  color: #000;
  background-color: #ffd526;
  border-color: #ffd31a;
}
.btn-check:focus + .btn-tertiary-carat, .btn-tertiary-carat:focus {
  color: #000;
  background-color: #ffd526;
  border-color: #ffd31a;
  box-shadow: 0 0 0 0.25rem rgba(217, 175, 0, 0.5);
}
.btn-check:checked + .btn-tertiary-carat, .btn-check:active + .btn-tertiary-carat, .btn-tertiary-carat:active, .btn-tertiary-carat.active, .show > .btn-tertiary-carat.dropdown-toggle {
  color: #000;
  background-color: #ffd833;
  border-color: #ffd31a;
}
.btn-check:checked + .btn-tertiary-carat:focus, .btn-check:active + .btn-tertiary-carat:focus, .btn-tertiary-carat:active:focus, .btn-tertiary-carat.active:focus, .show > .btn-tertiary-carat.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 175, 0, 0.5);
}
.btn-tertiary-carat:disabled, .btn-tertiary-carat.disabled {
  color: #000;
  background-color: #FFCE00;
  border-color: #FFCE00;
}
.btn-tertiary-carat:after {
  content: "\f108";
  margin-left: 4px;
  font-family: "standard-icons";
}

.btn-primary-yellow-carat {
  color: #000;
  background-color: #FFCE00;
  border-color: #FFCE00;
}
.btn-primary-yellow-carat:hover {
  color: #000;
  background-color: #ffd526;
  border-color: #ffd31a;
}
.btn-check:focus + .btn-primary-yellow-carat, .btn-primary-yellow-carat:focus {
  color: #000;
  background-color: #ffd526;
  border-color: #ffd31a;
  box-shadow: 0 0 0 0.25rem rgba(217, 175, 0, 0.5);
}
.btn-check:checked + .btn-primary-yellow-carat, .btn-check:active + .btn-primary-yellow-carat, .btn-primary-yellow-carat:active, .btn-primary-yellow-carat.active, .show > .btn-primary-yellow-carat.dropdown-toggle {
  color: #000;
  background-color: #ffd833;
  border-color: #ffd31a;
}
.btn-check:checked + .btn-primary-yellow-carat:focus, .btn-check:active + .btn-primary-yellow-carat:focus, .btn-primary-yellow-carat:active:focus, .btn-primary-yellow-carat.active:focus, .show > .btn-primary-yellow-carat.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 175, 0, 0.5);
}
.btn-primary-yellow-carat:disabled, .btn-primary-yellow-carat.disabled {
  color: #000;
  background-color: #FFCE00;
  border-color: #FFCE00;
}
.btn-primary-yellow-carat:after {
  content: "\f108";
  margin-left: 4px;
  font-family: "standard-icons";
}

.btn-success-carat {
  color: #fff;
  background-color: hsl(121, 100%, 26%);
  border-color: hsl(121, 100%, 26%);
}
.btn-success-carat:hover {
  color: #fff;
  background-color: #007102;
  border-color: #006a02;
}
.btn-check:focus + .btn-success-carat, .btn-success-carat:focus {
  color: #fff;
  background-color: #007102;
  border-color: #006a02;
  box-shadow: 0 0 0 0.25rem rgba(38, 151, 40, 0.5);
}
.btn-check:checked + .btn-success-carat, .btn-check:active + .btn-success-carat, .btn-success-carat:active, .btn-success-carat.active, .show > .btn-success-carat.dropdown-toggle {
  color: #fff;
  background-color: #006a02;
  border-color: #006402;
}
.btn-check:checked + .btn-success-carat:focus, .btn-check:active + .btn-success-carat:focus, .btn-success-carat:active:focus, .btn-success-carat.active:focus, .show > .btn-success-carat.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 151, 40, 0.5);
}
.btn-success-carat:disabled, .btn-success-carat.disabled {
  color: #fff;
  background-color: hsl(121, 100%, 26%);
  border-color: hsl(121, 100%, 26%);
}
.btn-success-carat:after {
  content: "\f108";
  margin-left: 4px;
  font-family: "standard-icons";
}

.btn-info-carat {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-info-carat:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-info-carat, .btn-info-carat:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-info-carat, .btn-check:active + .btn-info-carat, .btn-info-carat:active, .btn-info-carat.active, .show > .btn-info-carat.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:checked + .btn-info-carat:focus, .btn-check:active + .btn-info-carat:focus, .btn-info-carat:active:focus, .btn-info-carat.active:focus, .show > .btn-info-carat.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-info-carat:disabled, .btn-info-carat.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-info-carat:after {
  content: "\f108";
  margin-left: 4px;
  font-family: "standard-icons";
}

.btn-warning-carat {
  color: #000;
  background-color: #ff8307;
  border-color: #ff8307;
}
.btn-warning-carat:hover {
  color: #000;
  background-color: #ff962c;
  border-color: #ff8f20;
}
.btn-check:focus + .btn-warning-carat, .btn-warning-carat:focus {
  color: #000;
  background-color: #ff962c;
  border-color: #ff8f20;
  box-shadow: 0 0 0 0.25rem rgba(217, 111, 6, 0.5);
}
.btn-check:checked + .btn-warning-carat, .btn-check:active + .btn-warning-carat, .btn-warning-carat:active, .btn-warning-carat.active, .show > .btn-warning-carat.dropdown-toggle {
  color: #000;
  background-color: #ff9c39;
  border-color: #ff8f20;
}
.btn-check:checked + .btn-warning-carat:focus, .btn-check:active + .btn-warning-carat:focus, .btn-warning-carat:active:focus, .btn-warning-carat.active:focus, .show > .btn-warning-carat.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 111, 6, 0.5);
}
.btn-warning-carat:disabled, .btn-warning-carat.disabled {
  color: #000;
  background-color: #ff8307;
  border-color: #ff8307;
}
.btn-warning-carat:after {
  content: "\f108";
  margin-left: 4px;
  font-family: "standard-icons";
}

.btn-danger-carat {
  color: #fff;
  background-color: hsl(0, 95%, 45%);
  border-color: hsl(0, 95%, 45%);
}
.btn-danger-carat:hover {
  color: #fff;
  background-color: #be0505;
  border-color: #b30505;
}
.btn-check:focus + .btn-danger-carat, .btn-danger-carat:focus {
  color: #fff;
  background-color: #be0505;
  border-color: #b30505;
  box-shadow: 0 0 0 0.25rem rgba(229, 43, 43, 0.5);
}
.btn-check:checked + .btn-danger-carat, .btn-check:active + .btn-danger-carat, .btn-danger-carat:active, .btn-danger-carat.active, .show > .btn-danger-carat.dropdown-toggle {
  color: #fff;
  background-color: #b30505;
  border-color: #a80505;
}
.btn-check:checked + .btn-danger-carat:focus, .btn-check:active + .btn-danger-carat:focus, .btn-danger-carat:active:focus, .btn-danger-carat.active:focus, .show > .btn-danger-carat.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(229, 43, 43, 0.5);
}
.btn-danger-carat:disabled, .btn-danger-carat.disabled {
  color: #fff;
  background-color: hsl(0, 95%, 45%);
  border-color: hsl(0, 95%, 45%);
}
.btn-danger-carat:after {
  content: "\f108";
  margin-left: 4px;
  font-family: "standard-icons";
}

.btn-light-carat {
  color: #000;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}
.btn-light-carat:hover {
  color: #000;
  background-color: #e0e0e0;
  border-color: #dfdfdf;
}
.btn-check:focus + .btn-light-carat, .btn-light-carat:focus {
  color: #000;
  background-color: #e0e0e0;
  border-color: #dfdfdf;
  box-shadow: 0 0 0 0.25rem rgba(186, 186, 186, 0.5);
}
.btn-check:checked + .btn-light-carat, .btn-check:active + .btn-light-carat, .btn-light-carat:active, .btn-light-carat.active, .show > .btn-light-carat.dropdown-toggle {
  color: #000;
  background-color: #e2e2e2;
  border-color: #dfdfdf;
}
.btn-check:checked + .btn-light-carat:focus, .btn-check:active + .btn-light-carat:focus, .btn-light-carat:active:focus, .btn-light-carat.active:focus, .show > .btn-light-carat.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(186, 186, 186, 0.5);
}
.btn-light-carat:disabled, .btn-light-carat.disabled {
  color: #000;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}
.btn-light-carat:after {
  content: "\f108";
  margin-left: 4px;
  font-family: "standard-icons";
}

.btn-dark-carat {
  color: #fff;
  background-color: #1c1c1c;
  border-color: #1c1c1c;
}
.btn-dark-carat:hover {
  color: #fff;
  background-color: #181818;
  border-color: #161616;
}
.btn-check:focus + .btn-dark-carat, .btn-dark-carat:focus {
  color: #fff;
  background-color: #181818;
  border-color: #161616;
  box-shadow: 0 0 0 0.25rem rgba(62, 62, 62, 0.5);
}
.btn-check:checked + .btn-dark-carat, .btn-check:active + .btn-dark-carat, .btn-dark-carat:active, .btn-dark-carat.active, .show > .btn-dark-carat.dropdown-toggle {
  color: #fff;
  background-color: #161616;
  border-color: #151515;
}
.btn-check:checked + .btn-dark-carat:focus, .btn-check:active + .btn-dark-carat:focus, .btn-dark-carat:active:focus, .btn-dark-carat.active:focus, .show > .btn-dark-carat.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(62, 62, 62, 0.5);
}
.btn-dark-carat:disabled, .btn-dark-carat.disabled {
  color: #fff;
  background-color: #1c1c1c;
  border-color: #1c1c1c;
}
.btn-dark-carat:after {
  content: "\f108";
  margin-left: 4px;
  font-family: "standard-icons";
}

.btn-default-carat {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-default-carat:hover {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:focus + .btn-default-carat, .btn-default-carat:focus {
  color: #000;
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-check:checked + .btn-default-carat, .btn-check:active + .btn-default-carat, .btn-default-carat:active, .btn-default-carat.active, .show > .btn-default-carat.dropdown-toggle {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-default-carat:focus, .btn-check:active + .btn-default-carat:focus, .btn-default-carat:active:focus, .btn-default-carat.active:focus, .show > .btn-default-carat.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-default-carat:disabled, .btn-default-carat.disabled {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-default-carat:after {
  content: "\f108";
  margin-left: 4px;
  font-family: "standard-icons";
}

.btn-disabled-carat {
  color: #fff;
  background-color: #545454;
  border-color: #545454;
}
.btn-disabled-carat:hover {
  color: #fff;
  background-color: #474747;
  border-color: #434343;
}
.btn-check:focus + .btn-disabled-carat, .btn-disabled-carat:focus {
  color: #fff;
  background-color: #474747;
  border-color: #434343;
  box-shadow: 0 0 0 0.25rem rgba(110, 110, 110, 0.5);
}
.btn-check:checked + .btn-disabled-carat, .btn-check:active + .btn-disabled-carat, .btn-disabled-carat:active, .btn-disabled-carat.active, .show > .btn-disabled-carat.dropdown-toggle {
  color: #fff;
  background-color: #434343;
  border-color: #3f3f3f;
}
.btn-check:checked + .btn-disabled-carat:focus, .btn-check:active + .btn-disabled-carat:focus, .btn-disabled-carat:active:focus, .btn-disabled-carat.active:focus, .show > .btn-disabled-carat.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(110, 110, 110, 0.5);
}
.btn-disabled-carat:disabled, .btn-disabled-carat.disabled {
  color: #fff;
  background-color: #545454;
  border-color: #545454;
}
.btn-disabled-carat:after {
  content: "\f108";
  margin-left: 4px;
  font-family: "standard-icons";
}

.btn-arts-and-sciences-carat {
  color: #000;
  background-color: #009739;
  border-color: #009739;
}
.btn-arts-and-sciences-carat:hover {
  color: #000;
  background-color: #26a757;
  border-color: #1aa14d;
}
.btn-check:focus + .btn-arts-and-sciences-carat, .btn-arts-and-sciences-carat:focus {
  color: #000;
  background-color: #26a757;
  border-color: #1aa14d;
  box-shadow: 0 0 0 0.25rem rgba(0, 128, 48, 0.5);
}
.btn-check:checked + .btn-arts-and-sciences-carat, .btn-check:active + .btn-arts-and-sciences-carat, .btn-arts-and-sciences-carat:active, .btn-arts-and-sciences-carat.active, .show > .btn-arts-and-sciences-carat.dropdown-toggle {
  color: #000;
  background-color: #33ac61;
  border-color: #1aa14d;
}
.btn-check:checked + .btn-arts-and-sciences-carat:focus, .btn-check:active + .btn-arts-and-sciences-carat:focus, .btn-arts-and-sciences-carat:active:focus, .btn-arts-and-sciences-carat.active:focus, .show > .btn-arts-and-sciences-carat.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 128, 48, 0.5);
}
.btn-arts-and-sciences-carat:disabled, .btn-arts-and-sciences-carat.disabled {
  color: #000;
  background-color: #009739;
  border-color: #009739;
}
.btn-arts-and-sciences-carat:after {
  content: "\f108";
  margin-left: 4px;
  font-family: "standard-icons";
}

.btn-business-carat {
  color: #fff;
  background-color: #002E5D;
  border-color: #002E5D;
}
.btn-business-carat:hover {
  color: #fff;
  background-color: #00274f;
  border-color: #00254a;
}
.btn-check:focus + .btn-business-carat, .btn-business-carat:focus {
  color: #fff;
  background-color: #00274f;
  border-color: #00254a;
  box-shadow: 0 0 0 0.25rem rgba(38, 77, 117, 0.5);
}
.btn-check:checked + .btn-business-carat, .btn-check:active + .btn-business-carat, .btn-business-carat:active, .btn-business-carat.active, .show > .btn-business-carat.dropdown-toggle {
  color: #fff;
  background-color: #00254a;
  border-color: #002346;
}
.btn-check:checked + .btn-business-carat:focus, .btn-check:active + .btn-business-carat:focus, .btn-business-carat:active:focus, .btn-business-carat.active:focus, .show > .btn-business-carat.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 77, 117, 0.5);
}
.btn-business-carat:disabled, .btn-business-carat.disabled {
  color: #fff;
  background-color: #002E5D;
  border-color: #002E5D;
}
.btn-business-carat:after {
  content: "\f108";
  margin-left: 4px;
  font-family: "standard-icons";
}

.btn-red-carat {
  color: #fff;
  background-color: #A32035;
  border-color: #A32035;
}
.btn-red-carat:hover {
  color: #fff;
  background-color: #8b1b2d;
  border-color: #821a2a;
}
.btn-check:focus + .btn-red-carat, .btn-red-carat:focus {
  color: #fff;
  background-color: #8b1b2d;
  border-color: #821a2a;
  box-shadow: 0 0 0 0.25rem rgba(177, 65, 83, 0.5);
}
.btn-check:checked + .btn-red-carat, .btn-check:active + .btn-red-carat, .btn-red-carat:active, .btn-red-carat.active, .show > .btn-red-carat.dropdown-toggle {
  color: #fff;
  background-color: #821a2a;
  border-color: #7a1828;
}
.btn-check:checked + .btn-red-carat:focus, .btn-check:active + .btn-red-carat:focus, .btn-red-carat:active:focus, .btn-red-carat.active:focus, .show > .btn-red-carat.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(177, 65, 83, 0.5);
}
.btn-red-carat:disabled, .btn-red-carat.disabled {
  color: #fff;
  background-color: #A32035;
  border-color: #A32035;
}
.btn-red-carat:after {
  content: "\f108";
  margin-left: 4px;
  font-family: "standard-icons";
}

.sticky-nav {
  position: sticky;
  top: 130px;
  z-index: 1000;
}

.scrollspy-section {
  position: relative;
}

.affix ~ .container-fluid, .affix ~ .container-old-lg-bs3, .affix ~ .container-xl {
  position: relative;
  top: 150px;
}

.affix {
  top: 60px;
  width: 100%;
  z-index: 9999 !important;
}

.nav-link {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .sticky-nav {
    top: 95px;
  }
  #current-section-title {
    font-size: 1.5rem;
    font-weight: normal;
    margin-left: 15px;
    margin: 5px 0px;
    justify-content: center;
  }
  .scrollspy-dynamic {
    height: 250px;
  }
  .nav-pills {
    flex-direction: column;
    width: 100%;
  }
  .nav-link {
    width: 100%;
  }
  .dropdown-menu.show {
    width: 320px !important;
  }
  .dropdown-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #ffffff;
    width: 100%;
  }
  .progress-container {
    position: relative;
    width: 100%;
    height: 10px;
    background-color: #e0e0e0;
  }
  .progress-bar {
    height: 100%;
    background-color: #007bff;
    width: 0;
  }
  #progress-points-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
  }
  .progress-point {
    width: 10px;
    height: 10px;
    background-color: #ffffff;
    border: 2px solid #007bff;
    border-radius: 50%;
    pointer-events: none;
  }
}
@media (max-width: 767.98px) {
  .degree-finder {
    background-size: initial;
    background-position: 0 100%;
  }
}
.degree-finder .container {
  max-width: 1005px;
}
@media (min-width: 992px) {
  .degree-finder .container {
    padding: 0 30px;
  }
}
.degree-finder__loading {
  opacity: 0;
  position: absolute;
  width: 100%;
}
.degree-finder__loading .loading__wrapper {
  display: none;
}
.degree-finder__loading.fadeout {
  transition: opacity 600ms;
  opacity: 0;
}
.degree-finder__loading.fadein {
  transition: opacity 600ms;
  opacity: 1;
}
.degree-finder__loading.fadein .loading__wrapper {
  display: block;
}
.degree-finder__notfound {
  display: none;
  max-width: 750px;
  margin-bottom: 50px;
  opacity: 0;
  position: absolute;
  transform: translateY(30px);
}
.degree-finder__notfound hr {
  margin: 45px 0;
}
.degree-finder__notfound .degree-finder__header * {
  font-size: 3.2rem;
  font-weight: normal;
  line-height: 1;
  margin-bottom: 50px;
}
.degree-finder__notfound .degree-finder__header * .searchterm {
  font-weight: bold;
}
.degree-finder__notfound.fadeout {
  transition: transform 600ms ease-out, opacity 600ms;
  opacity: 0;
  transform: translateY(-30px);
}
.degree-finder__notfound.fadein {
  display: block;
  transition: transform 600ms ease-out, opacity 600ms;
  opacity: 1;
  position: relative;
  transform: translateY(0);
}
.degree-finder__header-container.container {
  padding: 50px 30px 0;
}
@media (max-width: 767.98px) {
  .degree-finder__header-container.container {
    padding: 50px 25px 0;
  }
}
.degree-finder__mobilebutton {
  background-color: #383838;
  color: #fff;
  display: none;
}
@media (max-width: 767.98px) {
  .degree-finder__mobilebutton {
    display: block;
    position: absolute;
    width: 100%;
  }
}
.degree-finder__filter .select2-container {
  width: 100%;
}
@media (max-width: 767.98px) {
  .degree-finder__filter {
    margin: 15px 0;
    display: none;
  }
  .open .degree-finder__filter {
    display: block;
  }
}
.degree-finder__filter .nav-tabs {
  display: inline-block;
}
@media (min-width: 768px) {
  .degree-finder__filter {
    display: inline-block;
    margin-left: 40px;
  }
  .degree-finder__filter:first-child {
    margin-left: 0;
  }
}
@media (min-width: 768px) and (max-width: 959.98px) {
  .degree-finder__filter {
    margin: 10px 20px;
  }
  .degree-finder__filter:first-child {
    margin: 10px 20px;
  }
}
.degree-finder__filter-container {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 30px;
}
@media (max-width: 767.98px) {
  .degree-finder__filter-container {
    border-bottom: none;
    margin-bottom: 5px;
  }
  .degree-finder__filter-container .container {
    padding: 25px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .degree-finder__filter-container .container {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.degree-finder__filter-wrapper {
  min-height: 45px;
  position: relative;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  .degree-finder__filter-wrapper {
    position: relative;
  }
}
.degree-finder__filter-wrapper .container {
  padding-top: 0;
  padding-bottom: 0;
}
.degree-finder__filter .nav-pills {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.375rem 0;
}
.degree-finder__filter .nav-pills li:not(:first-child) {
  margin-left: 0.5em;
}
@media (min-width: 768px) {
  .degree-finder__filter .nav-pills > li {
    flex: 1 0 auto;
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .degree-finder__filter .nav-pills {
    height: auto;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .degree-finder__filter .nav-pills li {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 959.98px) {
  .degree-finder__filter .nav-pills {
    justify-content: flex-start;
  }
}
.degree-finder__filter legend,
.degree-finder__filter label {
  color: #545454;
  display: block;
  margin-bottom: 12px;
  line-height: 25px;
  letter-spacing: 0;
  font-weight: normal;
}
@media (max-width: 767.98px) {
  .degree-finder__filter {
    margin-right: 0;
  }
}
.degree-finder__filter input {
  font-weight: normal;
}
.degree-finder__filters {
  padding: 30px 0;
}
@media (min-width: 768px) {
  .degree-finder__filters {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}
@media (min-width: 768px) and (max-width: 959.98px) {
  .degree-finder__filters {
    margin: 0 -20px;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 15px 0;
  }
}
@media (max-width: 767.98px) {
  .degree-finder__filters {
    border: 1px solid #eeeeee;
    background: #fff;
    visibility: hidden;
    left: -10000px;
    opacity: 0;
    padding: 15px;
    position: absolute;
    transition: opacity 0.5s;
    width: 100%;
  }
  .degree-finder__filters.open {
    opacity: 1;
    visibility: visible;
    left: auto;
    position: relative;
  }
}
.degree-finder__name {
  font-size: 2rem;
  margin-top: 10px;
  margin-bottom: 5px;
  line-height: 1.35;
  text-decoration: none;
}
.degree-finder__school {
  margin-top: 0;
  line-height: 30px;
}
.degree-finder__school.subheading {
  color: #707070;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.6;
}
.degree-finder__school.subheading span.icon {
  margin-left: 10px;
  text-decoration: none;
  display: none;
  transition: all 1s;
}
@media (max-width: 767.98px) {
  .degree-finder__school.subheading span.icon {
    display: inline-block;
  }
}
.degree-finder__school.subheading span.icon.open {
  transition: all 0.5s;
  transform: scale(1, -1);
}
.degree-finder__close {
  display: none;
}
@media (max-width: 767.98px) {
  .degree-finder__close {
    display: inherit;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
.degree-finder__results {
  opacity: 0;
  transform: translateY(30px);
}
.degree-finder__results.fadeout {
  transition: transform 600ms ease-out, opacity 600ms;
  opacity: 0;
  transform: translateY(-30px);
}
.degree-finder__results.fadein {
  transition: transform 600ms ease-out, opacity 600ms;
  opacity: 1;
  transform: translateY(0);
}
@media (max-width: 767.98px) {
  .degree-finder__results-container {
    background-size: initial;
    background-position: 100% 0;
  }
}
.degree-finder__results-container .container {
  padding: 0 30px 50px;
  position: relative;
  min-height: 250px;
}
@media (max-width: 767.98px) {
  .degree-finder__results-container .container {
    padding: 0 25px 50px;
  }
}
.degree-finder__results-container .degree-finder__results .location-finder__result .card__link:hover {
  box-shadow: 0 0 25px #5b6770;
}
.degree-finder__result {
  text-decoration: none;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 0;
  border-color: #dbdbdb;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}
.degree-finder__result .degree-finder__name {
  text-decoration: none;
}
.degree-finder__result:hover {
  text-decoration: none;
}
.degree-finder__result:hover .degree-finder__name {
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
}
.degree-finder__result > .panel-heading {
  color: #383838;
  background-color: #fafafa;
  border-color: #dbdbdb;
}
.degree-finder__result > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #dbdbdb;
}
.degree-finder__result > .panel-heading .badge {
  color: #fafafa;
  background-color: #383838;
}
.degree-finder__result > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #dbdbdb;
}
@media (max-width: 767.98px) {
  .degree-finder__result {
    flex-direction: column;
  }
}
.degree-finder__result:hover {
  border-bottom: 1px solid #005EB8 !important;
}
.degree-finder__result.arts-sciences:hover {
  border-bottom: 1px solid #009739 !important;
}
.degree-finder__result.business:hover {
  border-bottom: 1px solid #002E5D !important;
}
.degree-finder__result.education:hover {
  border-bottom: 1px solid #DA291C !important;
}
.degree-finder__result.nursing:hover {
  border-bottom: 1px solid #FF8F1C !important;
}
.degree-finder__result.ext-education:hover {
  border-bottom: 1px solid #00BFB2 !important;
}
.degree-finder__result-content {
  border-right: 1px solid #eeeeee;
  height: 100%;
  float: none;
  padding: 30px 30px 200px;
  margin-bottom: -170px;
}
@media (min-width: 768px) {
  .degree-finder__result-content {
    flex: 1 1 75%;
  }
}
@media (max-width: 767.98px) {
  .degree-finder__result-content {
    border-right: none;
    height: auto;
    margin-bottom: 0;
    padding: 24px;
  }
}
.degree-finder__description {
  color: rgba(0, 0, 0, 0.8);
  display: block;
}
@media (max-width: 767.98px) {
  .degree-finder__description {
    display: none;
  }
}
.degree-finder__ways {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding: 35px 35px 35px 40px;
  float: none;
}
@media (max-width: 767.98px) {
  .degree-finder__ways {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 24px 24px;
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .degree-finder__ways {
    flex: 1 1 25%;
  }
}
.degree-finder__ways-value {
  margin-bottom: 30px;
}
.degree-finder__ways-value:last-child {
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .degree-finder__ways-value {
    flex: 1 1 auto;
    height: 31px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .degree-finder__ways-value .icon {
    top: 0;
    transform: none;
  }
}
.degree-finder__template, .degree-finder__loadmore {
  display: none;
}

.degree-finder__result {
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
  transition: box-shadow 400ms;
  cursor: pointer;
}
.degree-finder__result:hover {
  text-decoration: none;
}

/*Degree finder for paid landing pages*/
.degree-finder__result.no-links {
  margin-bottom: 10px;
  box-shadow: 0 1px 3px 0 #ccc;
  position: relative;
}
.degree-finder__result.no-links:hover {
  border-bottom: none !important;
}
.degree-finder__result.no-links.arts-sciences {
  border-left: 5px solid #009739 !important;
}
@media screen and (max-width: 767px) {
  .degree-finder__result.no-links.arts-sciences {
    border-top: 5px solid #009739 !important;
  }
}
.degree-finder__result.no-links.business {
  border-left: 5px solid #002E5D !important;
}
@media screen and (max-width: 767px) {
  .degree-finder__result.no-links.business {
    border-top: 5px solid #002E5D !important;
  }
}
.degree-finder__result.no-links.education {
  border-left: 5px solid #DA291C !important;
}
@media screen and (max-width: 767px) {
  .degree-finder__result.no-links.education {
    border-top: 5px solid #DA291C !important;
  }
}
.degree-finder__result.no-links.nursing {
  border-left: 5px solid #FF8F1C !important;
}
@media screen and (max-width: 767px) {
  .degree-finder__result.no-links.nursing {
    border-top: 5px solid #FF8F1C !important;
  }
}
.degree-finder__result.no-links.ext-education {
  border-left: 5px solid #00BFB2 !important;
}
@media screen and (max-width: 767px) {
  .degree-finder__result.no-links.ext-education {
    border-top: 5px solid #00BFB2 !important;
  }
}
@media screen and (max-width: 767px) {
  .degree-finder__result.no-links {
    border-left: none;
  }
}
.degree-finder__result.no-links:hover {
  border: none;
}
.degree-finder__result.no-links .degree-finder__result-content {
  margin: 0;
  padding: 0;
  display: block;
}
@media screen and (max-width: 767px) {
  .degree-finder__result.no-links .degree-finder__result-content {
    padding: 20px;
  }
}
.degree-finder__result.no-links .degree-finder__result-content > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.degree-finder__result.no-links .degree-finder__result-content > div:first-child h3, .degree-finder__result.no-links .degree-finder__result-content > div:first-child .h3 {
  margin: 0;
  padding: 0 0 0 30px;
  transition: all 0.5s 0s ease;
}
.degree-finder__result.no-links .degree-finder__result-content > div:first-child .arrow {
  border-left: 1px solid #dbdbdb;
  padding: 15px;
  color: #dbdbdb;
  transition: all 0.5s 0s ease;
}
@media screen and (max-width: 767px) {
  .degree-finder__result.no-links .degree-finder__result-content > div:first-child .arrow {
    border: none;
    position: absolute;
    right: 0;
    top: -10px;
  }
}
.degree-finder__result.no-links .degree-finder__result-content > div:first-child .arrow .icon__downarrow:after {
  transition: all 0.5s 0s ease;
}
.degree-finder__result.no-links .degree-finder__result-content .content-bottom {
  display: none;
}
.degree-finder__result.no-links .degree-finder__result-content .content-bottom > div {
  display: grid;
  grid-template-columns: 77% 23%;
  transition: all 0.5s 0s ease;
}
@media screen and (max-width: 767px) {
  .degree-finder__result.no-links .degree-finder__result-content .content-bottom > div {
    grid-template-columns: 100%;
  }
}
.degree-finder__result.no-links .degree-finder__result-content .content-bottom > div .degree-description {
  padding: 0 30px 30px 30px;
  transition: all 0.5s 0s ease;
}
.degree-finder__result.no-links .degree-finder__result-content .content-bottom a {
  color: #005eb8;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .degree-finder__result.no-links .degree-finder__result-content .content-bottom a {
    width: 100%;
    background: #005eb8;
    color: white;
    text-align: center;
    padding: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    text-decoration: none;
  }
}
.degree-finder__result.no-links.open .degree-finder__description {
  display: block !important;
}
.degree-finder__result.no-links.open .degree-finder__result-content > div:first-child {
  display: grid;
  grid-template-columns: 77% 23%;
  transition: all 0.5s 0s ease;
}
.degree-finder__result.no-links.open .degree-finder__result-content > div:first-child h3, .degree-finder__result.no-links.open .degree-finder__result-content > div:first-child .h3 {
  padding: 25px 0 0 30px;
  transition: all 0.5s 0s ease;
}
.degree-finder__result.no-links.open .subheading {
  padding-top: 10px;
}
.degree-finder__result.no-links.open .degree-finder__ways {
  border-left: 1px solid #dbdbdb;
  padding: 0 35px 35px 40px;
  transition: all 0.5s 0s ease;
}
@media screen and (max-width: 767px) {
  .degree-finder__result.no-links.open .degree-finder__ways {
    border-left: none;
    border-top: 1px solid #dbdbdb;
    padding: 30px 0 50px 0;
  }
}
.degree-finder__result.no-links.open .arrow {
  text-align: end;
  transition: all 0.5s 0s ease;
}
.degree-finder__result.no-links.open .arrow .icon__downarrow:after {
  transition: all 0.5s 0s ease;
  transform: rotate(180deg);
}

.people-finder {
  background: #002E5D;
  background-image: none !important;
}
@media (max-width: 767.98px) {
  .people-finder {
    background-size: initial;
    background-position: 0% 100%;
  }
}
.people-finder .section__header * {
  color: #fff;
}
@media (min-width: 992px) {
  .people-finder .container {
    padding: 0 30px;
  }
}
.people-finder__result:hover {
  border-bottom: 2px solid #005EB8 !important;
}
.people-finder__result.arts-sciences:hover {
  border-bottom: 2px solid #009739 !important;
}
.people-finder__result.business:hover {
  border-bottom: 2px solid #002E5D !important;
}
.people-finder__result.education:hover {
  border-bottom: 2px solid #DA291C !important;
}
.people-finder__result.nursing:hover {
  border-bottom: 2px solid #FF8F1C !important;
}
.people-finder__result.ext-education:hover {
  border-bottom: 2px solid #00BFB2 !important;
}
.people-finder__result.card__faculty {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.75);
}
.people-finder__result.card__faculty:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.75);
}
@media (max-width: 767.98px) {
  .people-finder__result.card__faculty-title {
    color: rgba(0, 0, 0, 0.8);
  }
}
.people-finder__result.card__faculty-column {
  border: none;
}
.people-finder .degree-finder__header-container.container {
  padding: 50px 30px 0;
}
@media (max-width: 767.98px) {
  .people-finder .degree-finder__header-container.container {
    padding: 50px 25px 0;
  }
}
.people-finder .degree-finder__loading .loading__wrapper {
  background-color: #fff;
}
.people-finder .degree-finder__notfound {
  max-width: none;
  padding-left: 12px;
}
@media (max-width: 767.98px) {
  .people-finder .degree-finder__notfound {
    padding-left: 0;
  }
}
.people-finder .degree-finder__notfound * {
  color: #fff;
}
.people-finder .degree-finder__notfound hr {
  border-top: 1px solid #9e5b64;
}
.people-finder .degree-finder__notfound .degree-finder__header * {
  color: #fff;
}
.people-finder .degree-finder__notfound li .list__icon {
  color: #FFCE00;
}
.people-finder .degree-finder__filters {
  flex-wrap: wrap;
  margin: 0 -7px;
  padding: 0 0 30px;
}
@media (min-width: 768px) {
  .people-finder .degree-finder__filters {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
  }
}
@media (max-width: 767.98px) {
  .people-finder .degree-finder__filters {
    padding: 15px;
    margin: 0;
  }
}
.people-finder .degree-finder__filter {
  margin-left: 0;
  padding: 30px 7px 0;
}
@media (max-width: 767.98px) {
  .people-finder .degree-finder__filter {
    padding: 0;
    margin: 15px 0;
  }
}
.people-finder .degree-finder__filter label {
  color: #fff !important;
}
@media (max-width: 767.98px) {
  .people-finder .degree-finder__filter label {
    color: #4b4f54 !important;
  }
}
.people-finder .degree-finder__filter-wrapper {
  max-width: none;
}
.people-finder .degree-finder__filter-wrapper .degree-finder__mobilebutton {
  background: white;
  color: #000;
}
.people-finder .degree-finder__filter .input-group-addon,
.people-finder .degree-finder__filter .input-group-btn {
  position: absolute;
  right: 0;
  top: 0.1rem;
  z-index: 4;
}
.people-finder .degree-finder__filter-container {
  border: none;
}
.people-finder .degree-finder__results-container {
  padding: 0 30px 50px;
}
@media (max-width: 767.98px) {
  .people-finder .degree-finder__results-container {
    padding: 0 15px 50px;
  }
}
@media (max-width: 767.98px) {
  .people-finder .degree-finder__results .card__faculty-column {
    margin-bottom: 15px;
    padding: 0;
    border-bottom: none;
  }
}
.people-finder .degree-finder__results .card__faculty:hover {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3882352941);
}
@media (max-width: 767.98px) {
  .people-finder .degree-finder__results .card__faculty {
    padding: 18px 12px;
  }
  .people-finder .degree-finder__results .card__faculty:hover {
    text-decoration: none;
  }
}
.people-finder .form-group,
.people-finder .input-group {
  width: 100%;
  margin-bottom: 0;
  position: relative;
}
.people-finder .input-group-addon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.8);
}
.people-finder .readmore__button {
  color: #fff;
}
@media (max-width: 767.98px) {
  .people-finder .readmore__button {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.people-finder .readmore__button button:hover {
  color: white;
  text-decoration: underline;
}
@media screen and (max-width: 767px) {
  .select2-container.select2-container--filter.select2-container--open {
    left: 3% !important;
    width: 94% !important;
  }
}
@media screen and (max-width: 767px) {
  .select2-container.select2-container--filter.select2-container--open .select2-dropdown.select2-dropdown--below,
  .select2-container.select2-container--filter.select2-container--open .select2-dropdown.select2-dropdown--above,
  .select2-container.select2-container--filter.select2-container--open .select2-dropdown.select2-dropdown__columns.select2-dropdown--above,
  .select2-container.select2-container--filter.select2-container--open .select2-dropdown.select2-dropdown--below.select2-dropdown__columns {
    width: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .select2.select2-container.select2-container--filter.select2-container--below.select2-container--open,
  .select2.select2-container.select2-container--filter.select2-container--above.select2-container--open {
    left: 0 !important;
    width: 100% !important;
  }
}

.location-finder {
  background: #fafafa url("/images/pattern_back_left--transparent.svg") 50% 100%/100% auto no-repeat;
  background-image: none !important;
}
@media (max-width: 767.98px) {
  .location-finder {
    background-size: initial;
    background-position: 0 100%;
  }
}
@media (min-width: 992px) {
  .location-finder .container {
    padding: 0 30px;
  }
}
.location-finder__result .card__link {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 27px;
  position: relative;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
  transition: box-shadow 400ms;
  text-decoration: none;
}
.location-finder__result .card__link:hover {
  text-decoration: none;
}
.location-finder__result .card__link:hover {
  text-decoration: none;
}
.location-finder__result .card__link:hover .card__text {
  border-bottom: 1px solid #005EB8;
}
.location-finder__result .card__link .card__text {
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  flex-grow: 100;
}
.location-finder__result .card__title {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.location-finder__result .card__title * {
  color: #005EB8;
  font-size: 1.4rem;
  line-height: 19px;
  margin: 0;
  text-decoration: none;
}
.location-finder__result .card__text {
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
  padding: 30px;
}
.location-finder__result .card__text .icon {
  color: #A32035;
  float: left;
  font-size: 3rem;
  line-height: 0.66;
  margin-right: 10px;
  text-decoration: none;
  margin-bottom: 26px;
}
.location-finder__result .card__distance {
  padding: 0 40px;
  margin: 0;
  position: relative;
  bottom: 10px;
  font-size: 1.4rem;
  line-height: 1.5;
}
.location-finder .degree-finder__results {
  min-height: 500px;
}
.location-finder .degree-finder__results .location-finder__result {
  display: none;
}
.location-finder .degree-finder__results.fadeout .location-finder__result {
  display: block;
}
.location-finder .degree-finder__results.fadein .location-finder__result {
  display: block;
}
.location-finder .degree-finder__header-container.container {
  padding: 50px 30px 0;
}
@media (max-width: 767.98px) {
  .location-finder .degree-finder__header-container.container {
    padding: 50px 25px 0;
  }
}
.location-finder .degree-finder__distance {
  width: 270px;
}
@media (max-width: 767.98px) {
  .location-finder .degree-finder__filters {
    visibility: visible;
    position: static;
    left: auto;
    opacity: 1;
    padding: 0;
    border: none;
    background: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
  }
}
.location-finder .degree-finder__filters-wrapper {
  margin: 0 auto;
  max-width: 475px;
}
@media (max-width: 767.98px) {
  .location-finder .degree-finder__distance {
    width: 100%;
  }
}
.location-finder .degree-finder__filter {
  margin-left: 0;
}
@media (max-width: 767.98px) {
  .location-finder .degree-finder__filter {
    flex: 1 1 auto;
    display: block;
  }
}
.location-finder .degree-finder__filter-container {
  border-bottom: none;
  margin-bottom: 0;
}
.location-finder .degree-finder__filter-container .degree-finder__filter label {
  color: #A32035 !important;
}
.location-finder .degree-finder__filter-wrapper {
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 30px;
  max-width: none;
}
@media (max-width: 767.98px) {
  .location-finder .degree-finder__filter-wrapper {
    border-bottom: none;
    margin-bottom: 0;
  }
}
.location-finder .degree-finder__filter .input-group-addon,
.location-finder .degree-finder__filter .input-group-btn {
  width: 52px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 4;
}
.location-finder .degree-finder__filter input {
  height: 52px;
  line-height: 38px;
  border: 1px solid #eeeeee;
  width: 140px;
  padding-left: 20px;
}
@media (max-width: 767.98px) {
  .location-finder .degree-finder__filter input {
    width: 100%;
    appearance: none;
    border: none;
  }
}
.location-finder .degree-finder__filter .has-error input {
  border: 1px solid #A32035;
}
.location-finder .degree-finder__filter-text {
  display: block;
  margin-bottom: 0;
  color: #383838 !important;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  text-transform: none;
  height: 79px;
  line-height: 52px;
  padding: 27px 25px 0;
  text-align: center;
}
@media (max-width: 767.98px) {
  .location-finder .degree-finder__filter-text {
    padding-left: 0;
    padding-top: 34px;
    margin: 15px 0;
  }
}
.location-finder .degree-finder__filter .forms__message {
  bottom: -18px;
  white-space: nowrap;
}
.location-finder .degree-finder__filter button {
  height: 52px;
  line-height: 52px;
  min-width: 0;
  border: none;
  background: none;
  padding: 0;
  width: 52px;
}
.location-finder .degree-finder__filter button .icon {
  color: #A32035;
  margin: 0;
}
.location-finder .degree-finder__filter button:hover .icon {
  color: #A32035;
}
.location-finder .degree-finder__filter label {
  font-weight: bold;
  color: #A32035;
}
.location-finder .degree-finder__results-container {
  padding: 0 30px 50px;
}
@media (max-width: 767.98px) {
  .location-finder .degree-finder__results-container {
    padding: 0 25px 50px;
  }
  .location-finder .degree-finder__results-container .container {
    padding: 0;
  }
}
.location-finder .form-group,
.location-finder .input-group {
  width: 100%;
  margin-bottom: 0;
  position: relative;
}
.location-finder .input-group-addon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.8);
}

.event-finder {
  background-color: #fff;
}
.event-finder.week-view .day-view {
  display: none;
}
.event-finder.day-view .week-view {
  display: none;
}
.event-finder .container {
  max-width: none;
}
@media (min-width: 992px) {
  .event-finder .container {
    padding: 0 30px;
  }
}
.event-finder__search-filter label {
  text-indent: -10000px;
}
@media (max-width: 767.98px) {
  .event-finder__search-filter label {
    position: absolute;
  }
}
@media (max-width: 767.98px) {
  .event-finder__search-filter {
    margin-top: 25px;
  }
}
.event-finder__navbar {
  background-color: #707070;
  text-align: center;
  padding: 16px 0;
}
@media (max-width: 767.98px) {
  .event-finder__navbar {
    padding: 10px 0;
    display: flex;
  }
}
.event-finder__navbar-date {
  display: inline-block;
  flex: 1 1 auto;
}
.event-finder__navbar-date * {
  color: #fff;
  margin: 0 60px;
}
@media (max-width: 767.98px) {
  .event-finder__navbar-date * {
    font-size: 1.2rem;
    line-height: 1.75;
    margin: 0;
  }
}
.event-finder__navbar button {
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 28px;
  white-space: nowrap;
}
@media (max-width: 767.98px) {
  .event-finder__navbar button {
    font-size: 0.9rem;
    line-height: 2.6;
    padding: 0 10px;
    flex: 1 1 auto;
  }
}
.event-finder__navbar button .icon:after {
  vertical-align: baseline;
}
.event-finder__navbar button:hover {
  color: #FFCE00;
}
.event-finder__navbar button.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.event-finder__calendar {
  border: 1px solid #eeeeee;
  margin-top: 50px;
}
@media (min-width: 768px) and (max-width: 959.98px) {
  .event-finder__calendar {
    margin-top: 35px;
  }
}
@media (max-width: 767.98px) {
  .event-finder__calendar {
    margin-top: 0;
  }
}
.event-finder__calendar-wrapper .btn {
  width: 100%;
  margin-top: 30px;
  speak-as: literal-punctuation;
}
.event-finder__calendar-wrapper--mobile {
  padding: 0 25px 25px;
}
@media (min-width: 992px) {
  .event-finder__calendar-column {
    padding-left: 25px;
  }
}
.event-finder__result .card__eventlisting-image {
  width: 170px;
}
@media (max-width: 767.98px) {
  .event-finder__result .card__eventlisting-image {
    width: 100%;
  }
}
.event-finder__result .card__eventlisting-image-fit {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .event-finder__result .card__eventlisting-image-fit {
    display: block;
  }
}
.event-finder__result .card__eventlisting-image-fit img {
  width: 100%;
}
.event-finder__result .card__eventlisting-when {
  width: 30%;
}
@media (max-width: 767.98px) {
  .event-finder__result .card__eventlisting-when {
    width: auto;
  }
}
.event-finder__result .card__eventlisting-what {
  width: 69.9%;
}
@media (max-width: 767.98px) {
  .event-finder__result .card__eventlisting-what {
    width: auto;
  }
}
.event-finder .degree-finder__notfound {
  color: #707070;
  padding-top: 50px;
}
.event-finder .degree-finder__notfound .degree-finder__header * {
  color: #707070;
  margin-top: 0;
}
.event-finder .degree-finder__notfound .degree-finder__header * .searchterm {
  color: rgba(0, 0, 0, 0.8);
}
.event-finder .degree-finder__notfound-thisweek {
  margin-top: 60px;
}
.event-finder .degree-finder__notfound hr {
  margin-bottom: 0;
}
.event-finder .degree-finder__notfound ul {
  color: rgba(0, 0, 0, 0.8);
}
.event-finder .degree-finder__header-container.container {
  padding: 50px 30px 0;
}
@media (max-width: 767.98px) {
  .event-finder .degree-finder__header-container.container {
    padding: 50px 25px 0;
  }
}
.event-finder .degree-finder__filter {
  margin-left: 0px;
}
@media (min-width: 992px) {
  .event-finder .degree-finder__filter {
    margin-left: 10px;
  }
}
@media (max-width: 767.98px) {
  .event-finder .degree-finder__filter {
    margin-left: 0px;
  }
}
.event-finder .degree-finder__filter-container {
  border: none;
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .event-finder .degree-finder__filter-container .container {
    padding: 35px 25px 25px;
  }
}
.event-finder .degree-finder__filter-wrapper {
  max-width: none;
}
.event-finder .degree-finder__filter .input-group-addon,
.event-finder .degree-finder__filter .input-group-btn {
  width: 52px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 4;
}
.event-finder .degree-finder__filter input {
  height: 52px;
  line-height: 38px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
}
@media (max-width: 767.98px) {
  .event-finder .degree-finder__filter input {
    min-width: 0;
  }
}
.event-finder .degree-finder__filter button {
  height: 52px;
  line-height: 52px;
  min-width: 0;
  border: none;
  background: none;
  padding: 0;
  width: 52px;
}
.event-finder .degree-finder__filter button .icon {
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
}
.event-finder .degree-finder__filter button:hover .icon {
  color: #A32035;
}
.event-finder .degree-finder__mobilebutton {
  border-radius: 4px;
  text-transform: none;
  height: 50px;
  font-size: 1.6rem;
  line-height: 1.75;
  padding: 11px 20px;
}
.event-finder .degree-finder__results-container {
  padding: 0 30px 50px;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .event-finder .degree-finder__results-container {
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  .event-finder .degree-finder__results-container {
    padding: 0;
  }
  .event-finder .degree-finder__results-container .container {
    padding: 0;
  }
}
@media (min-width: 992px) {
  .event-finder .degree-finder__results {
    padding-right: 30px;
  }
}
.event-finder .form-group,
.event-finder .input-group {
  width: 100%;
  margin-bottom: 0;
  position: relative;
}
.event-finder .input-group-addon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.8);
}

@media (min-width: 992px) {
  .news-finder .container {
    padding: 0 30px;
  }
}
.news-finder .degree-finder__header-container.container {
  padding: 50px 30px 0;
}
@media (max-width: 767.98px) {
  .news-finder .degree-finder__header-container.container {
    padding: 50px 25px 0;
  }
}
.news-finder .degree-finder__notfound {
  max-width: none;
  padding-left: 12px;
}
@media (max-width: 767.98px) {
  .news-finder .degree-finder__notfound {
    padding-left: 0;
  }
}
.news-finder .degree-finder__filter {
  margin-left: 0;
  padding: 30px 7px 0;
}
.news-finder .degree-finder__filter .input-group-addon,
.news-finder .degree-finder__filter .input-group-btn {
  width: 52px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 4;
}
.news-finder .degree-finder__filter input {
  height: 52px;
  line-height: 38px;
  border: 1px solid #dee2e6;
  min-width: 330px;
  border-radius: 4px;
}
@media (max-width: 767.98px) {
  .news-finder .degree-finder__filter input {
    min-width: 0;
  }
}
.news-finder .degree-finder__filter button {
  height: 52px;
  line-height: 52px;
  min-width: 0;
  border: none;
  background: none;
  padding: 0;
  width: 52px;
}
.news-finder .degree-finder__filter button .icon {
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
}
.news-finder .degree-finder__filter button:hover .icon {
  color: #A32035;
}
.news-finder .degree-finder__results-container {
  padding: 0 30px 50px;
}
@media (max-width: 767.98px) {
  .news-finder .degree-finder__results-container {
    padding: 0;
  }
  .news-finder .degree-finder__results-container .container {
    padding-bottom: 30px;
  }
}
.news-finder .degree-finder__results-container .container {
  position: relative;
}
.news-finder .form-group,
.news-finder .input-group {
  width: 100%;
  margin-bottom: 0;
  position: relative;
}
.news-finder .input-group-addon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.8);
}

.video-gallery__row {
  margin: 0 -8px;
}
@media (min-width: 992px) {
  .video-gallery__row {
    display: flex;
    flex-direction: row;
  }
}
.video-gallery__container {
  margin-top: 30px;
}
.video-gallery__video-data {
  top: 0;
  left: -10000px;
  margin-top: 0;
  opacity: 0;
  position: absolute;
  transition: opacity 600ms;
  visibility: hidden;
}
.video-gallery__video-data.active {
  top: auto;
  left: auto;
  opacity: 1;
  position: relative;
  visibility: inherit;
}
.video-gallery__video-data.last {
  top: auto;
  left: 0;
  opacity: 0;
  position: absolute;
  visibility: inherit;
}
.video-gallery__tabs {
  position: relative;
  margin-top: 30px;
  overflow: hidden;
}
.video-gallery__player-container {
  padding-left: 8px;
  padding-right: 8px;
}
.video-gallery__player-wrapper {
  padding-top: 56.25%;
  position: relative;
}
@media (max-width: 767.98px) {
  .video-gallery__player-wrapper {
    margin: 0 0 30px;
  }
}
.video-gallery__player-wrapper iframe {
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}
.video-gallery__player-wrapper.playing iframe {
  display: block;
}
.video-gallery__player-name {
  margin: 24px 0;
}
.video-gallery__player-name * {
  font-weight: normal;
  font-size: 2.1rem;
  line-height: 1.5;
}
.video-gallery__player-play {
  background-color: #000;
  display: flex;
  align-items: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.video-gallery__player-play img {
  height: auto;
  width: 100%;
}
.playing .video-gallery__player-play {
  display: none;
}
.video-gallery__thumb {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  padding: 8px 0;
  border-bottom: 1px solid #dee2e6;
  width: 100%;
}
.video-gallery__thumb:first-child {
  padding-top: 0;
}
.video-gallery__thumb:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.video-gallery__thumb:focus {
  outline: blue;
}
.video-gallery__thumb-duration {
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  position: absolute;
  line-height: 1.75;
  padding: 0 4px;
  right: 0;
  bottom: 0;
  background-color: #000;
}
.video-gallery__thumb-image {
  width: 38%;
  margin-right: 5%;
  position: relative;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  flex: 1 1 36%;
}
.video-gallery__thumb-image img {
  height: auto;
  width: 100%;
}
.active .video-gallery__thumb-image:before {
  background-color: rgba(0, 0, 0, 0.5);
  content: "Now Playing";
  height: 100%;
  width: 100%;
  position: absolute;
  left: auto;
  top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
.video-gallery__thumb-name {
  text-align: left;
  flex: 1 1 59%;
}
.video-gallery__thumb-name * {
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.15;
  margin: 0;
}
.active .video-gallery__thumb-name * {
  font-weight: bold;
}
.video-gallery__thumb-wrapper {
  padding-left: 8px;
  padding-right: 8px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.video-gallery__thumb-wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.video-gallery__thumb-wrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
@media (max-width: 767.98px) {
  .video-gallery__thumb-wrapper {
    height: 340px;
    margin: 50px 0 0;
    padding: 0;
  }
}

.search-results .container {
  max-width: 1005px;
}
@media (min-width: 768px) {
  .search-results .container {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .search-results .container {
    padding: 0 30px;
  }
}
.search-results .container .search-results__search-field {
  color: white;
}
.search-results__notfound {
  display: block;
  max-width: none;
  margin-bottom: 50px;
  opacity: 1;
  position: relative;
}
.search-results__notfound hr {
  margin: 45px 0;
}
.search-results__notfound .degree-finder__header * {
  font-size: 3.2rem;
  font-weight: normal;
  line-height: 1;
  margin-bottom: 50px;
}
.search-results__notfound .degree-finder__header * .searchterm {
  font-weight: bold;
}
.search-results__notfound .degree-finder__header * strong {
  font-weight: bold;
}
.search-results__notfound p strong {
  color: #707070;
}
.search-results__notfound-message {
  max-width: 750px;
}
.search-results__popular {
  margin: 50px 0;
}
.search-results__popular-row {
  margin: 20px -8px;
}
.search-results__popularterm {
  color: #fff;
  text-decoration: none;
  padding: 8px;
}
.search-results__popularterm img {
  transition: opacity 400ms;
  opacity: 0.4;
}
.search-results__popularterm .card__image {
  background: rgba(0, 0, 0, 0.8);
  position: relative;
}
.search-results__popularterm .card__image-link:hover img {
  opacity: 0.2;
}
.search-results__popularterm p {
  display: flex;
  color: #fff;
  font-weight: bold;
  font-size: 2.1rem;
  line-height: 1.5;
  text-align: center;
  position: absolute;
  text-decoration: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}
@media (min-width: 768px) and (max-width: 959.98px) {
  .search-results__popularterm p {
    font-size: 1.8rem;
    line-height: 1.5;
  }
}
@media (max-width: 767.98px) {
  .search-results__popularterm p {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
.search-results__search-wrapper {
  background-color: #005EB8;
  color: white;
}
@media (min-width: 768px) {
  .search-results__search-wrapper .container {
    padding-bottom: 32px;
    padding-top: 32px;
  }
}
@media (max-width: 767.98px) {
  .search-results__search-wrapper .container {
    padding: 25px;
  }
}
.search-results__search-field {
  appearance: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid white;
  background-color: transparent;
  font-size: 3.2rem;
  font-weight: normal;
  line-height: 1.5;
  padding: 0 0 0 38px;
  height: 46px;
}
@media (max-width: 767.98px) {
  .search-results__search-field {
    font-size: 2.1rem;
    line-height: 1.5;
    height: 29px;
    padding: 0 0 0 22px;
  }
}
.search-results__search-field::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1;
  /* Firefox */
}
.search-results__search button {
  border: none;
  color: white;
  position: absolute;
  left: 0;
  top: 0;
  height: 46px;
  font-size: 2.5rem;
  padding: 0;
  line-height: 1.5;
}
.search-results__search button .icon {
  line-height: 1.5;
}
@media (max-width: 767.98px) {
  .search-results__search button {
    font-size: 1.8rem;
    height: 29px;
    line-height: 1.5;
  }
}
.search-results__search label {
  position: absolute;
  left: -1000px;
}
.search-results__search .form-group {
  position: relative;
}
.search-results__filter .select2-container {
  width: 100%;
}
@media (max-width: 767.98px) {
  .search-results__filter {
    margin: 15px 0;
  }
}
.search-results__filter .nav-tabs {
  display: inline-block;
}
@media (min-width: 768px) {
  .search-results__filter {
    display: inline-block;
    flex: 1 1 auto;
    margin-left: 40px;
  }
  .search-results__filter:first-child {
    margin-left: 0;
  }
}
@media (min-width: 768px) and (max-width: 959.98px) {
  .search-results__filter {
    margin: 10px 20px;
  }
  .search-results__filter:first-child {
    margin: 10px 20px;
  }
}
.search-results__filter-container {
  background-color: #fafafa;
  border-bottom: 1px solid #eeeeee;
}
@media (max-width: 767.98px) {
  .search-results__filter-container {
    border-bottom: none;
    margin-bottom: 5px;
  }
  .search-results__filter-container .container {
    padding: 25px;
  }
}
.search-results__filter-wrapper {
  min-height: 45px;
  position: relative;
  max-width: 810px;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  .search-results__filter-wrapper {
    position: relative;
  }
}
.search-results__filter-wrapper .container {
  padding-top: 0;
  padding-bottom: 0;
}
.search-results__filter .nav-pills {
  display: flex;
  height: 52px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 959.98px) {
  .search-results__filter .nav-pills {
    justify-content: flex-start;
  }
}
.search-results__filter label {
  display: block;
  margin-bottom: 12px;
}
@media (max-width: 767.98px) {
  .search-results__filter {
    margin-right: 0;
  }
}
.search-results__filters {
  padding: 30px 0;
}
@media (min-width: 768px) {
  .search-results__filters {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}
@media (min-width: 768px) and (max-width: 959.98px) {
  .search-results__filters {
    margin: 0 -20px;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 20px 0;
  }
}
@media (max-width: 767.98px) {
  .search-results__filters {
    padding: 0;
  }
  .search-results__filters label {
    margin-bottom: 0;
  }
}
.search-results__header {
  position: absolute;
  text-indent: -10000px;
}
.search-results__label {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Noto Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.42;
  padding: 20px 0;
}
.search-results__label strong {
  color: #A32035;
  font-weight: bold;
}
.search-results__name {
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 12px;
  line-height: 30px;
  text-decoration: none;
}
.search-results__name .highlight {
  display: inline-block;
  background: rgba(255, 206, 7, 0.5);
  padding: 0 2px;
}
.search-results__description {
  color: #707070;
  margin-bottom: 12px;
}
.search-results__description .highlight {
  display: inline-block;
  background: rgba(255, 206, 7, 0.5);
  padding: 0 2px;
}
.search-results__url {
  color: #A32035;
  font-size: 1.4rem;
  line-height: 1.42;
  text-decoration: underline;
}
.search-results__result {
  text-decoration: none;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 0;
  border-color: #dbdbdb;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
  transition: box-shadow 400ms;
  cursor: pointer;
  display: flex;
  position: relative;
  flex-direction: row;
  margin-bottom: 30px;
}
.search-results__result .search-results__name {
  text-decoration: none;
}
.search-results__result:hover {
  text-decoration: none;
}
.search-results__result:hover .search-results__name {
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
}
.search-results__result > .panel-heading {
  color: #383838;
  background-color: #fafafa;
  border-color: #dbdbdb;
}
.search-results__result > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #dbdbdb;
}
.search-results__result > .panel-heading .badge {
  color: #fafafa;
  background-color: #383838;
}
.search-results__result > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #dbdbdb;
}
.search-results__result:hover {
  text-decoration: none;
}
@media (max-width: 767.98px) {
  .search-results__result {
    flex-direction: column;
  }
}
.search-results__result.arts-sciences:after {
  border-bottom-color: #009739;
  border-right-color: #009739;
}
.search-results__result.business:after {
  border-bottom-color: #002E5D;
  border-right-color: #002E5D;
}
.search-results__result.education:after {
  border-bottom-color: #DA291C;
  border-right-color: #DA291C;
}
.search-results__result.nursing:after {
  border-bottom-color: #FF8F1C;
  border-right-color: #FF8F1C;
}
.search-results__result.ext-education:after {
  border-bottom-color: #00BFB2;
  border-right-color: #00BFB2;
}
.search-results__result-content {
  height: 100%;
  padding: 30px;
}

.featured-events {
  overflow: hidden;
}
.featured-events__cta {
  margin-top: 30px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .featured-events .card__eventlisting:first-child {
    padding-top: 20px;
  }
}
.featured-events .card__eventlisting:last-child {
  border-bottom: 1px solid #eeeeee;
}

.featured-news__cta {
  margin-top: 30px;
  text-align: center;
}
.featured-news .row {
  margin: -8px;
}
.featured-news .col-sm-5,
.featured-news .col-sm-7 {
  padding: 0 8px;
}
.featured-news__container {
  margin-top: 30px;
}
@media (max-width: 767.98px) {
  .featured-news__container {
    margin-top: 0;
  }
}
.featured-news__label {
  background-color: #eeeeee;
  display: inline-block;
  padding: 0 6px;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0;
}
.featured-news__label * {
  color: #A32035 !important;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  line-height: 30px;
  margin: 0;
  text-decoration: none;
}
.featured-news__main {
  display: block;
  padding: 8px 0;
  text-decoration: none;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .featured-news__main {
    padding: 12px 0 13px;
  }
}
.featured-news__main .card__image {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
  transition: box-shadow 400ms;
}
.featured-news__main:hover .card__image {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}
.featured-news__main:hover a {
  color: #A32035;
  text-decoration: underline;
}
.featured-news__main-image {
  position: relative;
}
.featured-news__main-image .card__image img {
  height: 494px;
  top: 0;
  position: absolute;
}
.featured-news__main .featured-news__label {
  position: absolute;
  left: 0;
  top: 0;
}
.featured-news__main-header * {
  color: #fff;
  font-weight: normal;
  text-decoration: none;
}
.featured-news__main:hover .featured-news__main-header * {
  color: #fff;
  text-decoration: none;
}
.featured-news__main:hover {
  text-decoration: none;
}
.featured-news__main:hover .featured-news__label * {
  text-decoration: none;
}
.featured-news__main-overlay {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8) 30%, rgba(0, 0, 0, 0.1) 55%);
  color: #fff;
  display: flex;
  font-size: 1.4rem;
  line-height: 1.42;
  align-items: flex-end;
  min-height: 494px;
  height: 100%;
  padding: 30px 40px;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (max-width: 767.98px) {
  .featured-news__main-overlay {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8) 40%, rgba(0, 0, 0, 0.1) 100%);
    position: relative;
    padding: 15px;
  }
}
.featured-news__sub {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  padding: 8px 0;
  text-decoration: none;
  cursor: pointer;
}
.featured-news__sub .card__image {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
  transition: box-shadow 400ms;
}
.featured-news__sub:hover .card__image {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}
.featured-news__sub:hover a {
  color: #A32035;
  text-decoration: underline;
}
@media (max-width: 767.98px) {
  .featured-news__sub {
    padding: 12px 0 13px;
  }
}
.featured-news__sub:hover {
  text-decoration: none;
}
.featured-news__sub:hover .featured-news__sub-image {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
}
.featured-news__sub:hover .featured-news__sub-title {
  color: #A32035;
  text-decoration: underline;
}
.featured-news__sub:hover .featured-news__sub .card__image img {
  height: 154px;
}
.featured-news__sub:hover .featured-news__label * {
  text-decoration: none;
}
.featured-news__sub-image {
  flex: 0 0 auto;
  margin-right: 20px;
  width: 56%;
  max-width: 56%;
}
@media (min-width: 1200px) {
  .featured-news__sub-image {
    max-width: 50%;
  }
}
@media (max-width: 767.98px) {
  .featured-news__sub-image {
    width: 135px;
  }
}
.featured-news__sub-image.card__image img {
  height: 90px;
  object-fit: cover;
}
@media (min-width: 768px) {
  .featured-news__sub-image.card__image img {
    height: 150px;
  }
}
@media (min-width: 992px) {
  .featured-news__sub-image.card__image img {
    height: 152px;
  }
}
.featured-news__sub .featured-news__label {
  margin-bottom: 12px;
}
.featured-news__sub-title {
  transition: color 400ms;
  font-size: 1.8rem;
  line-height: 23px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);
}

.news-detail .container {
  padding-top: 28px;
}
.news-detail__tags {
  font-size: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}
.news-detail__tags > li {
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 12px;
}
.news-detail__tags > li:last-child {
  margin-right: 0;
}
@media (max-width: 767.98px) {
  .news-detail__tags > li {
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .news-detail__tags > li:last-child {
    margin-right: 0;
  }
}
.news-detail__tags > li a {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.8);
  display: block;
  font-size: 1.4rem;
  text-decoration: none;
  line-height: 35px;
  padding: 0 10px;
}
.news-detail__tags > li a:hover {
  color: #821a2a;
}
@media (max-width: 767.98px) {
  .news-detail__tags > li a {
    font-size: 1rem;
    text-decoration: none;
    line-height: 2.2;
    padding: 0 8px;
  }
}
.news-detail__header {
  position: relative;
}
@media (min-width: 768px) {
  .news-detail__header.event-detail__header {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  .news-detail__header.event-detail__header .news-detail__title-wrapper {
    flex: 1 1 520px;
    margin-right: 100px;
  }
  .news-detail__header.event-detail__header .news-detail__headercta {
    flex: 1 1 200px;
    margin: 30px 0;
  }
  .news-detail__header.event-detail__header .news-detail__headercta .btn {
    width: 100%;
  }
  .news-detail__header.event-detail__header .news-detail__title * {
    margin-bottom: 0;
  }
}
@media (max-width: 767.98px) {
  .news-detail__headercta .btn {
    width: 100%;
  }
}
.news-detail__type {
  color: #A32035 !important;
  font-size: 1.4rem;
  line-height: 1.4;
  letter-spacing: 0;
}
.news-detail__type.subheading {
  color: #A32035;
  letter-spacing: 0;
}
.news-detail__date-author {
  font-size: 1.4rem;
  line-height: 1.42;
  color: #383838;
}
.news-detail__related.callout.columns__column {
  align-items: flex-start;
  padding: 30px;
  margin: 30px 0 60px;
  position: relative;
}
@media (max-width: 767.98px) {
  .news-detail__related.callout.columns__column {
    padding: 25px;
  }
}
.news-detail__related.callout.columns__column .columns__content {
  padding: 0 0 0 40px;
}
@media (max-width: 767.98px) {
  .news-detail__related.callout.columns__column .columns__content {
    padding: 25px 0;
  }
}
.news-detail__related.callout.columns__column .subheading {
  margin: 0 0 4px;
}
@media (max-width: 767.98px) {
  .news-detail__related.callout.columns__column:after {
    content: "";
    display: none;
  }
}
.news-detail__related-title * {
  font-size: 2.1rem;
  font-weight: normal;
  line-height: 28px;
  margin-top: 0;
  margin-bottom: 12px;
}
.news-detail__audio-wrapper {
  margin: 24px 0 12px;
}
.news-detail__title * {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Noto Sans", sans-serif;
  font-size: 3.2rem;
  line-height: 40px;
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: normal;
  text-transform: none;
}
@media (max-width: 767.98px) {
  .news-detail__title * {
    font-size: 2.1rem;
    line-height: 1.5;
  }
}
.news-detail__content {
  margin-top: 30px;
}
.news-detail__content h2, .news-detail__content .h2 {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Noto Sans", sans-serif;
  font-size: 2.1rem;
  font-weight: bold;
  line-height: 28px;
}
.news-detail__print-link {
  color: #A32035 !important;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}
@media print {
  .news-detail__print-link {
    display: none;
  }
}
.news-detail__print-link .icon {
  text-decoration: none;
  color: #005EB8;
}
.news-detail__print-link:hover {
  text-decoration: none;
}
@media (max-width: 767.98px) {
  .news-detail__print-link {
    display: none;
  }
}

div.col-md-9.article img {
  display: block;
  max-width: 100%;
  height: auto;
}

.program-detail__campus-wrapper {
  width: 100%;
}
.program-detail .section__description {
  margin-bottom: 0;
}
.program-detail .section__description ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -19px 1rem;
}
.program-detail .section__description ul > li {
  flex: 1 0 42%;
  margin: 5px 19px;
}
.program-detail__loading {
  opacity: 0;
  position: relative;
  margin-left: 15px;
}
.program-detail__loading .loading__wrapper {
  display: none;
  width: 140px;
  margin: 12px 0 12px -10px;
  padding: 0;
}
.program-detail__loading .loading__wrapper img {
  width: 50px;
  height: auto;
  float: left;
}
.program-detail__loading .loading__text {
  text-align: left;
  margin-top: 0;
  padding: 14px 0;
}
.program-detail__loading.fadeout {
  transition: opacity 600ms;
  opacity: 0;
}
.program-detail__loading.fadein {
  transition: opacity 600ms;
  opacity: 1;
}
.program-detail__loading.fadein .loading__wrapper {
  display: block;
}
.program-detail__locations {
  border: 2px solid #eeeeee;
  background-color: #fff;
  width: 100%;
  max-width: 885px;
}
.program-detail__locations .container {
  padding: 40px;
  width: 100%;
}
.program-detail__locations .row {
  margin-left: -40px;
  margin-right: -40px;
}
.program-detail__locations-header * {
  margin-bottom: 20px;
  margin-top: 0;
}
.program-detail__locations .program-detail__location {
  padding: 0 40px;
}
.program-detail__locations .program-detail__location a {
  font-weight: bold;
}
.program-detail__template {
  display: none;
}
.program-detail__campus {
  display: none;
}
.program-detail__campus--notfound {
  display: none;
}
.program-detail__value .program-detail__campus--notfound p {
  font-weight: normal;
  color: #707070;
  line-height: 19px;
  max-width: 380px;
}
.program-detail__campus--notfound p a[href^=tel] {
  color: #A32035;
  font-weight: bold;
  text-decoration: none;
}
.program-detail__campus--notfound p a[href^=tel]:hover {
  color: #821a2a;
}
.program-detail__campus--notfound.active {
  display: block;
}
.program-detail__campus.active {
  display: block;
}
.program-detail__scroll {
  max-height: 405px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 767.98px) {
  .program-detail__scroll {
    max-height: 100vh;
  }
}
.program-detail__detail {
  margin-bottom: 32px;
}
.program-detail__detail-window {
  background-color: #fff;
  border: 1px solid #eeeeee;
}
.program-detail .program-detail__detail-window {
  padding: 30px 40px;
}
@media (max-width: 767.98px) {
  .program-detail .program-detail__detail-window {
    padding: 0 15px;
  }
  .program-detail .program-detail__detail-window .col-md-6 {
    padding: 0 15px;
  }
}
@media (max-width: 767.98px) {
  .program-detail__detail-window {
    margin-top: 25px;
  }
  .program-detail__detail-window .row {
    display: flex;
    flex-direction: column;
  }
  .program-detail__detail-window [class*=col-md-] {
    order: 3;
    padding: 0;
  }
  .program-detail__detail-window [class*=col-md-]:first-child {
    order: 1;
  }
  .program-detail__detail-window [class*=col-md-]:last-child {
    order: 2;
  }
}
.program-detail__detail .ways__value {
  margin-bottom: 22px;
}
@media (max-width: 767.98px) {
  .program-detail__detail .ways__value {
    display: flex;
    justify-content: center;
  }
}
.program-detail__detail .heading__type {
  text-align: left;
  margin-bottom: 9px;
  margin-top: 8px;
  padding-bottom: 1px;
  font-weight: 500;
}
.program-detail__detail .heading__type h3, .program-detail__detail .heading__type .h3 {
  font-weight: 500;
  display: inline;
  margin: 0;
}
.program-detail__value {
  color: rgba(0, 0, 0, 0.8);
}
.program-detail__value p {
  font-weight: 700;
  line-height: 18px;
}
.program-detail__value > .icon {
  font-size: 2.5rem;
  top: -8px;
  transform: none;
}
.program-detail__value .tagline {
  font-size: 1.2rem;
}
.program-detail__value.loading {
  padding-left: 0;
}
.program-detail__value.loading > .icon {
  display: none;
}
.program-detail__location .forms__message {
  white-space: nowrap;
  right: 0;
  max-width: none;
  text-align: right;
}
@media (max-width: 767.98px) {
  .program-detail__location .forms__message {
    left: -67px;
    max-width: none;
    text-align: left;
  }
}
.program-detail__location .program-detail__detail {
  background: #fafafa;
  padding: 9px 20px 1px;
  margin-bottom: 12px;
}
.program-detail__location .program-detail__detail .heading__type {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
@media (max-width: 767.98px) {
  .program-detail__location .program-detail__detail .heading__type {
    margin-bottom: 12px;
  }
}
.program-detail__location .location__edit-window {
  display: inline-block;
  line-height: 32px;
  margin: 0;
  text-transform: none;
  border: 1px solid #a9a9a9;
}
.program-detail__location .location__edit-window.has-error {
  border-color: #A32035;
  margin-bottom: 16px;
}
.program-detail__location .location__zipcode-label {
  text-indent: 0;
  position: static;
  display: inline-block;
  font-size: 1.4rem;
  color: #707070;
  font-weight: normal;
  line-height: 2.3;
  margin: 0;
  text-transform: none;
}
@media (max-width: 767.98px) {
  .program-detail__location .location__zipcode-label {
    font-size: 1.2rem;
  }
  .program-detail__location .location__zipcode-label .career-impact__location--divider {
    margin-left: 0;
  }
}
.program-detail__location .ways__value {
  margin-top: 7px;
}
@media (max-width: 767.98px) {
  .program-detail__location .ways__value a {
    margin-top: 6px;
  }
}
@media (max-width: 767.98px) {
  .program-detail__location .program-detail__value > .icon {
    font-size: 2.5rem;
    top: -3px;
  }
}
.program-detail__ways .program-detail__detail {
  margin-bottom: 20px;
}

.faqs {
  background-color: #eeeeee;
}
.leftnav__container .faqs, .section__content .faqs {
  background-color: transparent;
}
.leftnav__container .faqs .section__header *, .section__content .faqs .section__header * {
  color: rgba(0, 0, 0, 0.8);
  font-size: 2.1rem;
  line-height: 28px;
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
}
.faqs .tabs__wrapper {
  margin-top: 50px;
}
.faqs .tabs__container {
  padding-top: 30px;
}
.faqs__category {
  margin-bottom: 30px;
}
.faqs__category-header {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
}
.faqs__category-header * {
  color: #A32035;
  margin-bottom: 0;
  font: normal normal bold 2.1rem/1.66 "Noto Sans", sans-serif;
  letter-spacing: 0;
  text-transform: uppercase;
}
.faqs__question {
  margin-bottom: 40px;
}
.faqs__question-header * {
  color: rgba(0, 0, 0, 0.8);
  font: normal normal bold 1.7rem/1.35 "Noto Sans", sans-serif;
}
.faqs__container.section__text ul li {
  list-style: none;
}

.admission-req .section__description {
  width: 100%;
  margin: 0;
}
.admission-req__title {
  color: #707070 !important;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin-bottom: 9px;
}
.admission-req__additional {
  margin: 15px 0;
}
.admission-req__additional-container {
  background-position: 50% 50%;
  background-size: 100% auto;
  background-image: none;
  background-color: #eeeeee;
}
@media (max-width: 767.98px) {
  .admission-req__additional-container {
    background-size: initial;
    background-position: 0 0;
  }
}
.admission-req__additional-text {
  background-color: #fff;
  border: 1px solid #005EB8;
  padding: 30px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.6rem;
  line-height: 1.5;
  position: relative;
}
.admission-req__disclaimer {
  font-size: 1.4rem;
  font-style: italic;
  margin-top: 45px;
}
.admission-req__option {
  margin: 15px 0;
}
.admission-req__option-link {
  display: block;
  font-style: italic;
}
.admission-req__option-link a {
  font-weight: 700;
  text-decoration: underline;
}
.admission-req__option-header * {
  font-size: 1.8rem;
}
.admission-req__option-container {
  background: url("/images/pattern_back--transparent.svg") no-repeat 50% 50%;
  background-size: 100% auto;
}
@media (max-width: 767.98px) {
  .admission-req__option-container {
    background-size: initial;
    background-position: 100% 0;
  }
}
.admission-req__option-text {
  background-color: #fff;
  border: 1px solid #eeeeee;
  padding: 30px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.6rem;
  line-height: 1.5;
  position: relative;
}
.admission-req__option-text p {
  margin: 0 0 7px;
}
.admission-req__option-text .admission-req__title {
  color: #A32035;
}

.career-impact {
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .career-impact {
    background: none;
  }
}
.career-impact .section__header {
  text-align: left;
}
@media (max-width: 767.98px) {
  .career-impact .section__header {
    max-width: 230px;
  }
  .career-impact .section__header * {
    margin-bottom: 18px;
  }
}
.career-impact .section__header span {
  color: #A32035;
}
.career-impact__loading {
  pointer-events: none;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  jusify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 600ms;
}
.loaded .career-impact__loading, .onscreen .career-impact__loading {
  opacity: 0;
}
.career-impact__detail {
  text-align: center;
  opacity: 0;
  transform: translateY(30px);
}
.career-impact__detail-header * {
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.8rem;
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  line-height: 20px;
  margin-top: 0;
}
.onscreen .career-impact__detail {
  transition: transform 600ms ease-out, opacity 600ms;
  transition-delay: 1s;
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .career-impact__detail {
    margin-top: 40px;
  }
}
.career-impact__data {
  position: relative;
}
.career-impact__data > div {
  padding: 25px 15px;
}
@media (max-width: 767.98px) {
  .career-impact__data > div {
    padding: 25px 0;
  }
}
@media (min-width: 992px) {
  .career-impact__data {
    display: flex;
    align-items: center;
    margin: 0 -50px;
    margin-top: 50px;
  }
  .career-impact__data > div {
    padding: 0 50px;
  }
  .career-impact__data > div.career-impact__organizations-wrapper {
    padding: 0 0 0 50px;
  }
}
@media (max-width: 767.98px) {
  .career-impact__data {
    max-width: 400px;
    margin: 10px auto;
  }
}
.career-impact__subhead {
  color: #383838;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.5;
  text-transform: uppercase;
  margin-bottom: 25px;
}
.career-impact__table {
  text-align: left;
}
.career-impact__table .table > thead > tr > th {
  color: #fff;
  background-color: #A32035;
}
.career-impact__table .table {
  margin-bottom: 0;
}
.career-impact__table .table > thead > tr > th,
.career-impact__table .table > tbody > tr > th {
  font-size: 1rem;
  line-height: 1.75;
  padding: 7px 20px;
}
@media (min-width: 768px) {
  .career-impact__table .table > thead > tr > th,
  .career-impact__table .table > tbody > tr > th {
    font-size: 1.2rem;
    padding: 7px 10px;
  }
  .career-impact__table .table > thead > tr > th:first-child,
  .career-impact__table .table > tbody > tr > th:first-child {
    padding-left: 30px;
  }
}
.career-impact__table .table > thead > tr > td,
.career-impact__table .table > tbody > tr > td {
  font-size: 1rem;
  line-height: 1.75;
  padding: 7px 20px;
  white-space: nowrap;
}
.career-impact__table .table > thead > tr > td:first-child,
.career-impact__table .table > tbody > tr > td:first-child {
  white-space: normal;
}
@media (min-width: 768px) {
  .career-impact__table .table > thead > tr > td,
  .career-impact__table .table > tbody > tr > td {
    font-size: 1.2rem;
    padding: 7px 10px;
  }
  .career-impact__table .table > thead > tr > td:first-child,
  .career-impact__table .table > tbody > tr > td:first-child {
    padding-left: 30px;
  }
}
.career-impact__table .icon {
  color: #FFCE00;
  vertical-align: baseline;
}
.career-impact__table .icon:after {
  vertical-align: baseline;
}
@media (min-width: 992px) {
  .career-impact__location {
    text-align: right;
  }
}
.career-impact__location .forms__message {
  white-space: nowrap;
  right: 0;
  max-width: none;
  text-align: right;
}
@media (max-width: 767.98px) {
  .career-impact__location .forms__message {
    left: -67px;
    max-width: none;
    text-align: left;
  }
}
.career-impact__location-heading {
  display: inline-block;
  font-size: 1.4rem;
  color: #707070;
  font-weight: normal;
  line-height: 2;
  margin: 0;
  text-transform: none;
}
@media (max-width: 767.98px) {
  .career-impact__location-heading {
    font-size: 1.2rem;
  }
}
@media (max-width: 767.98px) {
  .career-impact__location .location__field,
  .career-impact__location .subheading {
    font-size: 1.2rem;
  }
}
.career-impact__location .location__edit-window {
  display: inline-block;
  line-height: 32px;
  margin: 0;
  text-transform: none;
  border: 1px solid #eeeeee;
}
.career-impact__location .location__edit-window.has-error {
  border-color: #A32035;
}
.career-impact__location .location__zipcode-label {
  text-indent: 0;
  position: static;
  display: inline-block;
  font-size: 1.4rem;
  color: #707070;
  font-weight: normal;
  line-height: 2;
  margin: 0;
  text-transform: none;
}
@media (max-width: 767.98px) {
  .career-impact__location .location__zipcode-label {
    font-size: 1.2rem;
  }
}
.career-impact__location--name {
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
}
.career-impact__location--divider {
  color: #A32035;
  display: inline-block;
  margin: 0 8px;
}
.career-impact__openings--tagline {
  color: #707070;
  display: block;
}
.career-impact__organizations-container {
  opacity: 0;
  transform: translateY(30px);
}
.onscreen .career-impact__organizations-container {
  transition: transform 600ms ease-out, opacity 600ms;
  transition-delay: 0s;
  opacity: 1;
  transform: translateY(0);
}
.career-impact__organizations-container .career-impact__detail-header * {
  margin-bottom: 30px;
}
@media (max-width: 767.98px) {
  .career-impact__organizations-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .career-impact__organizations-wrapper {
    display: flex;
    align-items: center;
    height: 45vw;
  }
}
.career-impact__organizations > li {
  font-size: 1.3rem;
  line-height: 1.75;
  margin-bottom: 16px;
}
.career-impact__jobs-wrapper {
  opacity: 0;
  transform: translateY(30px);
}
.onscreen .career-impact__jobs-wrapper {
  transition: transform 600ms ease-out, opacity 600ms;
  transition-delay: 0.5s;
  opacity: 1;
  transform: translateY(0);
}
.career-impact__jobs-container {
  padding-top: 100%;
  position: relative;
}
.career-impact__jobs-percent {
  background-color: #005EB8;
  position: absolute;
  width: 100%;
  height: 0;
  left: 0;
  bottom: 0;
}
.animate .career-impact__jobs-percent {
  height: 100%;
  transition: height 2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.career-impact__jobs-inner {
  background-color: #fff;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  position: relative;
}
.career-impact__jobs-outer {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: #fafafa;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  padding: 20px;
}
@media (max-width: 767.98px) {
  .career-impact__jobs-outer {
    padding: 12px;
  }
}
.career-impact__jobs-total {
  color: #000;
  font-size: 6rem;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 5px;
  width: 100%;
}
.career-impact__jobs-data {
  font-size: 1.4rem;
  line-height: 1.25;
  text-align: center;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}
.career-impact__source {
  color: #707070;
  font-style: italic;
  font-size: 1.4rem;
  line-height: 1.42;
  margin-top: 40px;
  opacity: 0;
  transform: translateY(30px);
}
@media (max-width: 767.98px) {
  .career-impact__source {
    max-width: 400px;
    margin: 0 auto;
  }
}
.onscreen .career-impact__source {
  transition: transform 600ms ease-out, opacity 600ms;
  transition-delay: 1.5s;
  opacity: 1;
  transform: translateY(0);
}

.curriculum__container {
  display: flex;
  flex-direction: row;
  max-width: 700px;
  flex-wrap: wrap;
}
@media (max-width: 767.98px) {
  .curriculum__container {
    flex-direction: column;
  }
}
.curriculum__course {
  font-size: 1.6rem;
  line-height: 1.5;
  margin-right: 5%;
  margin-bottom: 30px;
  flex: 0 0 45%;
}
@media (max-width: 767.98px) {
  .curriculum__course {
    flex: 0 0 100%;
  }
}
.curriculum__totals {
  border-top: 1px solid #dee2e6;
  display: block;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.5;
  padding-top: 30px;
}
.curriculum__name {
  font-weight: bold;
}
.curriculum__hours {
  color: #383838;
  font-style: italic;
}

@media (max-width: 767.98px) {
  .campus-detail .container {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 767.98px) {
  .campus-detail__image {
    order: -1;
  }
}
@media (max-width: 767.98px) {
  .campus-detail .linkcollection:after {
    content: none;
  }
}

.specialbullets {
  counter-increment: specialbullets;
}
.specialbullets input {
  color: #A32035;
  border: 1px solid #A32035;
  font-weight: 900;
  max-width: 67px;
  text-align: center;
}
.specialbullets:last-child {
  border-bottom: none;
}
.specialbullets .section__header {
  text-align: left;
  padding-left: 44px;
  position: relative;
}
.specialbullets .section__header * {
  color: #A32035;
  font-size: 2.1rem;
  font-family: "Noto Serif", serif;
  font-weight: normal;
  line-height: 35px;
  position: static;
}
.specialbullets .section__header *:before {
  background-color: #005EB8;
  content: counter(specialbullets);
  color: #fff;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.8rem;
  line-height: 35px;
  text-align: center;
  font-weight: bold;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  position: absolute;
  left: 0;
}
.specialbullets .section__content h4, .specialbullets .section__content .h4 {
  font: normal normal bold 15px/20px Noto Sans;
  letter-spacing: 0;
  color: #A32035;
}
.specialbullets .section__content ul.checks li .list__icon {
  color: #005EB8;
}
.specialbullets .section__content ul.checks li .list__icon::after {
  font-weight: bold;
}

.media__image img {
  height: auto;
  width: 100%;
}
@media (max-width: 767.98px) {
  .media__image {
    margin-bottom: 25px;
  }
}
.media .columns__container {
  margin-top: 58px;
}
@media (max-width: 767.98px) {
  .media .columns__container {
    margin-top: 30px;
  }
}
.media__content {
  padding-bottom: 30px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 30px;
  margin-top: -8px;
}
.media__content:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
@media (max-width: 767.98px) {
  .media__content {
    margin-top: 0;
  }
}
.media__content .columns__header {
  font-size: 2.1rem;
  line-height: 1.4;
  font-weight: normal;
}
.media__contacts {
  display: flex;
  flex-direction: row;
  margin: 0 -10px;
}
@media (max-width: 767.98px) {
  .media__contacts {
    display: block;
  }
}
.media__contacts-header * {
  color: #707070 !important;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  line-height: 30px;
  margin: 0;
}
.media__contacts-phone {
  font-weight: bold;
}
.media__contacts a {
  font-weight: bold;
}
.media__contacts-info {
  flex: 1 1 auto;
  line-height: 30px;
  margin: 0 10px;
}
@media (max-width: 767.98px) {
  .media__contacts-info {
    margin-bottom: 25px;
  }
  .media__contacts-info:last-child {
    margin-bottom: 0;
  }
}

.anchors__column {
  background-color: #fff;
  border-radius: 0;
  color: #383838;
  padding: 20px 30px;
  position: relative;
  border-color: #dbdbdb;
}
.anchors__column > .panel-heading {
  color: #383838;
  background-color: #fafafa;
  border-color: #dbdbdb;
}
.anchors__column > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #dbdbdb;
}
.anchors__column > .panel-heading .badge {
  color: #fafafa;
  background-color: #383838;
}
.anchors__column > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #dbdbdb;
}
.anchors__column-wrapper {
  counter-increment: section;
  padding-left: 6px;
  padding-right: 6px;
}
@media (max-width: 767.98px) {
  .anchors__column-wrapper {
    margin-bottom: 25px;
  }
  .anchors__column-wrapper:last-child {
    margin-bottom: 0;
  }
}
.anchors__column-wrapper:after {
  bottom: 0;
  content: " ";
  position: absolute;
  right: 0;
  border-right: 1px solid #eeeeee;
  height: 100%;
}
@media screen and (min-width: 960px) {
  .anchors__column-wrapper:after {
    border-right: 1px solid #eeeeee;
  }
}
.anchors__column-wrapper:last-child:after {
  display: none;
}
.anchors__container {
  counter-reset: section;
  margin-left: -6px;
  margin-right: -6px;
}
@media (max-width: 767.98px) {
  .anchors__container {
    margin: 0;
  }
}
.anchors__container.no-border .anchors__column-wrapper:after {
  border-right: 0;
}
.anchors__header {
  position: relative;
  min-height: 38px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  padding-left: 40px;
}
.anchors__header h3, .anchors__header .h3 {
  font-size: 1.4rem;
  line-height: 1.42;
  margin: 0;
  text-align: left;
}
.anchors__header h3 > span.icon, .anchors__header .h3 > span.icon {
  font-size: 2.3em;
  line-height: 1;
  color: #A32035;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.anchors__header a {
  font-size: 1.4rem;
  line-height: 20px;
  text-decoration-color: #A32035;
  vertical-align: baseline;
}
.anchors__header a .icon {
  color: #949494;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2.3em;
  line-height: 1;
  text-decoration: none;
}
.anchors__header a:after {
  color: #A32035;
  display: inline;
  font-size: 1.4rem;
  line-height: 20px;
  vertical-align: baseline;
}
.anchors__header a:hover {
  color: #A32035;
}
.anchors__header .icon__increment:after {
  background-color: #005EB8;
  content: counter(section);
  color: #fff;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.6rem;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.anchors__description * {
  font-size: 1.4rem;
  line-height: 1.42;
}
.anchors .section__header * {
  color: rgba(0, 0, 0, 0.8);
  font-family: inherit;
  font-weight: 500;
  font-size: 2.1rem;
  line-height: 1.4;
}
@media (max-width: 767.98px) {
  .anchors .section__header * {
    margin-top: 0;
  }
}
.anchors .section__description, .anchors .section__header {
  text-align: left;
  max-width: none;
}

.bulleted-block {
  border-bottom: 1px solid #eeeeee;
}
.bulleted-block:last-child {
  border-bottom: none;
}
.section .responsivetable__tagline .bulleted-block .row, .container .bulleted-block .row {
  margin: 0;
}
.bulleted-block .section__header {
  margin-top: -10px;
  text-align: left;
}
.bulleted-block .section__header * {
  line-height: 40px;
  margin-top: 0;
  margin-bottom: 24px;
}
.bulleted-block .section__header p {
  margin-bottom: 0;
  font-size: 2.1rem;
  font-family: "Noto Serif", serif;
}
.bulleted-block .section__description {
  text-align: left;
}
@media (min-width: 992px) {
  .bulleted-block .section__description {
    padding-right: 90px;
  }
}
.bulleted-block .section__description .btn:last-child {
  margin-top: 12px;
}
@media (max-width: 767.98px) {
  .bulleted-block .section__description .btn:last-child {
    margin-top: 22px;
  }
}
.bulleted-block__card {
  border: 2px solid #dee2e6;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .bulleted-block__card {
    margin-top: 35px;
  }
}
.bulleted-block__card-text {
  padding: 30px;
  text-align: center;
}
.bulleted-block__card-text * {
  color: #383838;
  font-size: 1.4rem;
  line-height: 1.42;
  margin-top: 0;
}
.bulleted-block__card-text * a {
  color: #A32035;
}
.bulleted-block__cardheader {
  background: #005EB8;
  padding: 50px;
}
.bulleted-block__cardheader--background {
  background-size: cover;
  position: relative;
}
.bulleted-block__cardheader--background:before {
  background-color: transparent;
  content: " ";
  position: absolute;
  opacity: 0.5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.bulleted-block__cardheader--background * {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
.bulleted-block__cardheader * {
  color: #fff;
  font-family: "Noto Serif", serif;
  font-size: 2.1rem;
  font-weight: normal;
  line-height: 1.2;
  margin: 0;
  position: relative;
  text-align: center;
  z-index: 2;
}
.bulleted-block__cardheader-wrapper {
  position: relative;
}
.bulleted-block__cardheader-wrapper:before, .bulleted-block__cardheader-wrapper:after {
  bottom: 0;
  content: " ";
  position: absolute;
  border-bottom: 16px solid #fff;
  border-top: 16px solid transparent;
  z-index: 3;
}
.bulleted-block__cardheader-wrapper:before {
  right: 50%;
  border-left: 90px solid #fff;
  border-right: 90px solid transparent;
}
@media (min-width: 240px) and (max-width: 767px) {
  .bulleted-block__cardheader-wrapper:before {
    border-bottom: 30px solid #fff;
    border-top: 30px solid transparent;
    border-left: 180px solid #fff;
    border-right: 180px solid transparent;
  }
}
.bulleted-block__cardheader-wrapper:after {
  left: 50%;
  border-left: 90px solid transparent;
  border-right: 90px solid #fff;
}
@media (min-width: 240px) and (max-width: 767px) {
  .bulleted-block__cardheader-wrapper:after {
    border-bottom: 30px solid #fff;
    border-top: 30px solid transparent;
    border-right: 180px solid #fff;
    border-left: 180px solid transparent;
  }
}

.steps,
.section__blueback {
  background: #005EB8;
}
.steps p,
.section__blueback p {
  color: white;
}
.steps .section__header *,
.section__blueback .section__header * {
  margin-bottom: 12px;
}
.steps .section__header h2, .steps .section__header .h2,
.section__blueback .section__header h2,
.section__blueback .section__header .h2 {
  color: #FFCE00;
}
.steps .section__header p,
.steps .section__header li,
.section__blueback .section__header p,
.section__blueback .section__header li {
  color: white;
}
.steps__links,
.section__blueback__links {
  font-size: 0;
  padding: 0;
  text-align: center;
}
.steps__links li,
.section__blueback__links li {
  display: block;
  margin-right: 10px;
}
.steps__links li:last-child,
.section__blueback__links li:last-child {
  margin-right: 0;
}
@media (max-width: 767.98px) {
  .steps__links li,
  .section__blueback__links li {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .steps__links li:last-child,
  .section__blueback__links li:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .steps__links li,
  .section__blueback__links li {
    display: inline-block;
  }
}
.steps__links li .btn,
.section__blueback__links li .btn {
  width: auto;
  height: auto;
  white-space: normal;
  line-height: 24px;
  padding: 15px 10px;
}
@media (max-width: 767.98px) {
  .steps__links li .btn,
  .section__blueback__links li .btn {
    width: 100%;
    text-align: left;
  }
}
@media (min-width: 768px) and (max-width: 959.98px) {
  .steps__links li .btn,
  .section__blueback__links li .btn {
    width: auto;
  }
}

.linkcollection {
  border: 1px solid #eeeeee;
  background-color: #eeeeee;
  padding: 18px 30px 0;
  margin-top: 30px;
  text-align: left;
  position: relative;
}
.section__description .linkcollection, .section__content .linkcollection {
  margin-bottom: 24px;
  margin-top: 24px;
}
.section__description .linkcollection:first-child, .section__content .linkcollection:first-child {
  margin-top: 0;
}
.section__description .linkcollection:last-child, .section__content .linkcollection:last-child {
  margin-bottom: 0;
}
.linkcollection .addcalendar-button {
  font-size: 1.4rem;
  line-height: 1.6;
  font-weight: bold;
}
.linkcollection .addcalendar-button .icon {
  text-decoration: none;
  color: #949494;
  font-size: 1.4em;
  line-height: 1.6;
  margin-right: 4px;
}
.linkcollection .addcalendar-button .icon:after {
  vertical-align: bottom;
}
.linkcollection.section__lightgrayback, .linkcollection.section__grayback, .linkcollection.section__darkgrayback {
  border: none;
}
@media (max-width: 767.98px) {
  .linkcollection {
    padding: 30px 25px 10px;
    margin: 25px 0;
  }
}
.linkcollection.section .container {
  padding: 0;
}
.linkcollection__dark {
  border: 2px solid #dee2e6;
}
.linkcollection__select {
  background: none;
  border: none;
  padding: 0;
}
.linkcollection__select:after {
  content: "";
  display: none;
}
.linkcollection__select .btn,
.linkcollection__select .select2 {
  width: 100%;
}
.linkcollection address {
  font-size: 1.4rem;
  line-height: 1.42;
  text-decoration: underline;
}
@media (max-width: 767.98px) {
  .linkcollection address {
    text-decoration: none;
  }
}
.linkcollection .btn {
  margin-right: 30px;
  margin-bottom: 20px;
  width: 280px;
}
@media (max-width: 767.98px) {
  .linkcollection .btn {
    display: block;
    width: auto;
    margin-right: 0;
  }
}
.linkcollection .btn-link-carat, .linkcollection .btn-link-leave, .linkcollection .btn-link {
  color: rgba(0, 0, 0, 0.8);
  width: auto;
  margin-right: 20px;
}
.linkcollection .btn-link-carat:after, .linkcollection .btn-link-leave:after, .linkcollection .btn-link:after {
  color: #A32035;
}
.linkcollection .btn-link-carat .icon, .linkcollection .btn-link-leave .icon, .linkcollection .btn-link .icon {
  color: #949494;
  font-size: 2.5rem;
}
.linkcollection .btn-link-carat:hover, .linkcollection .btn-link-leave:hover, .linkcollection .btn-link:hover {
  color: #A32035;
}
.linkcollection .btn:last-child {
  margin-right: 0;
}
.linkcollection__side {
  padding: 8px 20px;
}
.linkcollection__side .btn {
  margin-right: 0;
  margin-bottom: 10px;
}
.linkcollection__side .btn-link-carat, .linkcollection__side .btn-link-leave, .linkcollection__side .btn-link {
  margin-right: 0;
}
.linkcollection__header {
  margin-bottom: 10px;
}
.linkcollection__header * {
  color: #707070;
  line-height: 21px;
  margin: 3px 0;
}
.linkcollection__header .subheading {
  color: #545454 !important;
  font-size: 1.6rem;
  line-height: 1.3;
  letter-spacing: 0;
}
@media (max-width: 767.98px) {
  .linkcollection__columns .linkcollection__header {
    border-bottom: 1px solid #eeeeee;
  }
  .linkcollection__columns .linkcollection__header .subheading {
    font-weight: normal;
  }
}
.linkcollection .linkcollection__header .subheading {
  color: #A32035 !important;
}
.linkcollection .linkcollection__links span.icon {
  color: #005EB8;
  display: inline;
}
.linkcollection .linkcollection__links a {
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
  text-decoration: none;
  border-bottom: 1px solid #A32035;
}
.linkcollection p {
  font-size: 1.4rem;
  line-height: 1.42;
  margin-bottom: 0;
}
.linkcollection p.phone {
  margin-bottom: 12px;
}
.linkcollection__column {
  margin: 0 10px 20px;
  flex: 1 1 25%;
}
.linkcollection__column address {
  margin-bottom: 0;
}
.linkcollection__column a {
  font-weight: bold;
}
@media (max-width: 767.98px) {
  .linkcollection__column {
    flex: 1 1 auto;
    margin-bottom: 30px;
  }
}
.linkcollection__columns {
  display: flex;
  flex-direction: row;
  margin: 0 -12px;
  justify-content: stretch;
}
@media (min-width: 1200px) {
  .linkcollection__columns {
    flex-wrap: wrap;
  }
}
@media (max-width: 767.98px) {
  .linkcollection__columns {
    flex-direction: column;
  }
}
.linkcollection__connect {
  font-size: 4rem;
  line-height: 0.9;
  position: relative;
}
.linkcollection__connect .trademark {
  bottom: 0;
  color: #383838;
  font-size: 0.9rem;
  position: absolute;
}
.linkcollection__connect .icon__linkedinfull {
  transition: color 400ms;
  color: #0077b5;
  text-decoration: none;
}
.linkcollection__connect .icon__linkedinfull:after {
  text-decoration: underline;
}
.linkcollection__connect .icon__linkedinfull:after {
  text-decoration: none;
}
.linkcollection__connect:hover .icon__linkedinfull {
  color: #00669c;
}
.linkcollection__dropdown {
  margin-top: 12px;
}
.linkcollection__dropdown__columns {
  display: flex;
  flex-direction: row;
  margin: 0 -12px;
  justify-content: stretch;
}
@media (min-width: 1200px) {
  .linkcollection__dropdown__columns {
    flex-wrap: wrap;
  }
}
@media (max-width: 767.98px) {
  .linkcollection__dropdown__columns {
    flex-direction: column;
  }
}
.linkcollection__dropdown__connect {
  font-size: 4rem;
  line-height: 0.9;
  position: relative;
}
.linkcollection__dropdown__connect .trademark {
  bottom: 0;
  color: #383838;
  font-size: 0.9rem;
  position: absolute;
}
.linkcollection__dropdown__connect .icon__linkedinfull {
  transition: color 400ms;
  color: #0077b5;
  text-decoration: none;
}
.linkcollection__dropdown__connect .icon__linkedinfull:after {
  text-decoration: underline;
}
.linkcollection__dropdown__connect .icon__linkedinfull:after {
  text-decoration: none;
}
.linkcollection__dropdown__connect:hover .icon__linkedinfull {
  color: #00669c;
}
.linkcollection__dropdown select {
  width: 100%;
}
.linkcollection__dropdown .select2-selection__rendered {
  color: #383838;
}
.linkcollection__callout {
  background-color: #fafafa;
  border: none;
  padding: 30px 30px 0;
}
.container .linkcollection__callout.section .row {
  margin: 0 -30px;
}
.container .linkcollection__callout.section .linkcollection__column {
  padding: 0 20px 0 30px;
}
.container .linkcollection__callout.section .linkcollection__column-noheader {
  padding-top: 34px;
}
@media (max-width: 767.98px) {
  .container .linkcollection__callout.section .linkcollection__column-noheader {
    padding-top: 0;
  }
}
.linkcollection__callout .readmore {
  margin-bottom: 30px;
}
.linkcollection__callout .linkcollection__header {
  margin-bottom: 6px;
}
@media (max-width: 767.98px) {
  .linkcollection__callout .linkcollection__header {
    border-bottom: 1px solid #eeeeee;
  }
}
.linkcollection__callout .linkcollection__column {
  margin: 0 0 30px;
  border-left: 1px solid #eeeeee;
}
.linkcollection__callout .linkcollection__column:first-child {
  border-left: none;
}
.linkcollection__callout p {
  font-size: 1.6rem;
  line-height: 1.5;
}
.linkcollection__callout .linkcollection__column .linkcollection__header {
  font: normal normal bold 1.5rem/1.3 Noto Sans;
  letter-spacing: 0;
  color: #545454;
}
.linkcollection__callout .linkcollection__column p {
  font-size: 1.8rem;
  line-height: 1.3;
}
.linkcollection__callout .linkcollection__column a {
  font: normal normal bold 1.5rem/1.3 Noto Sans;
  letter-spacing: 0;
}
.linkcollection .card__eventlisting-date {
  font: normal normal bold 1.8rem/1.3 Noto Sans;
  letter-spacing: 0;
  margin-bottom: 5px;
}
.linkcollection .card__eventlisting-time {
  font: normal normal normal 1.8rem/1.3 Noto Sans;
  letter-spacing: 0;
  margin-bottom: 7px;
}
.linkcollection .card__addlink .addeventatc {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.3;
}

.cta-snippet .btn {
  height: 60px;
  padding: 15px 25px;
}
@media (max-width: 767.98px) {
  .cta-snippet .btn {
    min-width: 225px;
    width: auto;
  }
}
.cta-snippet__cta {
  margin-top: 25px;
}
.cta-snippet__emailform {
  margin-top: 25px;
}
.cta-snippet__emailform .icon {
  position: absolute;
  font-size: 3.2rem;
  line-height: 1.3;
  left: 18px;
  top: 0;
  color: #949494;
  text-align: left;
  pointer-events: none;
}
.cta-snippet__emailform .form-group {
  margin-bottom: 15px;
  position: relative;
  display: inline-flex;
}
@media (max-width: 767.98px) {
  .cta-snippet__emailform .form-group {
    flex-direction: column;
    width: 100%;
  }
}
.cta-snippet__emailform label {
  position: absolute;
  left: -1000px;
}
.cta-snippet__emailform input {
  padding: 12px 12px 12px 62px;
  border-radius: 0;
  height: 50px;
  display: inline-block;
  width: 350px;
  flex: 1 1 auto;
  border: none;
}
.has-error .cta-snippet__emailform input {
  border: 1px solid #FFCE00;
}
@media (max-width: 767.98px) {
  .cta-snippet__emailform input {
    width: 100%;
  }
}
.cta-snippet__emailform .btn {
  display: inline-block;
  height: 50px;
  padding: 10px 25px;
  flex: 0 1 auto;
}
@media (max-width: 767.98px) {
  .cta-snippet__emailform .btn {
    margin-top: 12px;
  }
}
.cta-snippet__emailform .forms__message {
  color: #FFCE00;
  left: 0;
}

.schools__container-header * {
  color: #383838 !important;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.schools__container-header h3, .schools__container-header .h3 {
  color: #A32035;
  line-height: 25px;
  font-weight: normal;
  letter-spacing: 0;
}
.schools__image {
  transition: opacity 400ms;
  opacity: 1;
  overflow: hidden;
}
.schools__image img {
  transition: opacity 400ms;
  height: auto;
  width: 100% !important;
  object-fit: cover;
}
.schools__image-wrapper {
  position: relative;
  background-color: #fff;
}
.schools__wrapper {
  margin: 25px -3px 60px;
}
.schools__wrapper.hover {
  margin-bottom: 3px;
}
.schools__degrees {
  max-width: 900px;
}
.schools__degree {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.5;
}
.schools__degree .btn {
  padding: 12px 0;
  text-transform: none;
}
.schools__school {
  background-color: #fff;
  border: 1px solid #eeeeee;
  overflow: hidden;
  position: relative;
}
.schools__school.arts-sciences::before {
  content: "";
  position: absolute;
  display: block;
  height: 3px;
  width: 100%;
  background-color: #009739;
  z-index: 1;
}
.schools__school.arts-sciences:hover .schools__image {
  background-color: #009739;
}
.schools__school.business::before {
  content: "";
  position: absolute;
  display: block;
  height: 3px;
  width: 100%;
  background-color: #002E5D;
  z-index: 1;
}
.schools__school.business:hover .schools__image {
  background-color: #002E5D;
}
.schools__school.education::before {
  content: "";
  position: absolute;
  display: block;
  height: 3px;
  width: 100%;
  background-color: #DA291C;
  z-index: 1;
}
.schools__school.education:hover .schools__image {
  background-color: #DA291C;
}
.schools__school.nursing::before {
  content: "";
  position: absolute;
  display: block;
  height: 3px;
  width: 100%;
  background-color: #FF8F1C;
  z-index: 1;
}
.schools__school.nursing:hover .schools__image {
  background-color: #FF8F1C;
}
.schools__school.ext-education::before {
  content: "";
  position: absolute;
  display: block;
  height: 3px;
  width: 100%;
  background-color: #00BFB2;
  z-index: 1;
}
.schools__school.ext-education:hover .schools__image {
  background-color: #00BFB2;
}
.schools__school:hover::before {
  background-color: transparent;
}
.schools__school:hover .schools__image {
  border-top: none;
  opacity: 1;
}
.schools__school:hover .schools__image img {
  opacity: 0.8;
}
.schools__school-title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 82px;
  padding: 15px;
}
.schools__school-title * {
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.8rem;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.4;
  font-weight: 700;
  text-align: center;
  margin: 0;
}
.schools__column {
  position: relative;
  min-height: 1px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding: 0 3px;
}
@media (min-width: 768px) {
  .schools__column {
    float: left;
    width: 20%;
  }
}
@media (max-width: 767.98px) {
  .schools__column {
    margin: 0 auto 15px;
    max-width: 260px;
  }
  .schools__column:last-child {
    margin-bottom: 0;
  }
}

.bubbles__container {
  margin: 40px auto;
  text-align: center;
}
@media (min-width: 768px) {
  .bubbles__container {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  .bubbles__container {
    margin: 0 auto;
  }
}
.bubbles__bubble {
  padding: 15px 0;
}
@media (min-width: 768px) {
  .bubbles__bubble {
    padding: 0 15px;
    flex: 0.5 1 20%;
    max-width: 33%;
  }
}
@media (max-width: 767.98px) {
  .bubbles__bubble {
    padding: 22px 0;
  }
}
.bubbles__image {
  margin-bottom: 40px;
}
.bubbles__image img {
  max-width: 100%;
}
@media (max-width: 767.98px) {
  .bubbles__image {
    margin-bottom: 10px;
  }
}
.bubbles p {
  font-size: 1.4rem;
  line-height: 1.4;
}
.bubbles__header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bubbles__header * {
  font-size: 1.6rem;
  line-height: 1.25;
  font-weight: 700;
  margin: 0;
  text-transform: none;
}
.bubbles__header * a {
  color: rgba(0, 0, 0, 0.8);
  display: inline-block;
  margin: 0 0 20px;
  text-decoration: underline;
  text-decoration-color: #A32035;
  text-transform: none;
  text-align: center;
  white-space: normal;
}
.bubbles__header * a:hover {
  color: #A32035;
}
@media (max-width: 767.98px) {
  .bubbles__header * a {
    margin-bottom: 12px;
  }
}

.columns p {
  font-size: 1.4rem;
  line-height: 1.4;
}
.columns__header {
  font-size: 1.6rem;
  line-height: 1.25;
  margin-top: 0;
  font-weight: 700;
  text-transform: none;
}
.columns__header a {
  display: inline-block;
  text-transform: none;
  text-align: center;
  white-space: normal;
}
.columns__container {
  margin-top: 30px;
}
.container .section .container .columns__container {
  margin-left: -15px;
  margin-right: -15px;
}
@media (max-width: 767.98px) {
  .columns__container {
    margin-top: 0;
  }
}
.columns__container:last-child .columns__column:last-child {
  margin-bottom: 0;
}
.columns__content {
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.3rem;
  line-height: 1.5;
  flex: 1 1 auto;
}
.col-sm-6 .columns__content {
  padding-right: 80px;
}
@media (max-width: 767.98px) {
  .col-sm-6 .columns__content {
    padding-right: 0;
  }
}
.col-sm-6 .columns__content p a {
  color: black;
  text-decoration: none;
}
.col-sm-6 .columns__content p a:hover {
  color: black;
  text-decoration: none;
}
.callout .columns__content {
  padding: 30px 40px;
}
@media (max-width: 767.98px) {
  .callout .columns__content {
    padding: 25px;
  }
}
.columns__content .card__title * {
  text-decoration: none;
  font-weight: normal;
  margin: 0 0 12px;
}
.columns__image-link {
  text-decoration: none;
  cursor: pointer;
}
.columns__image-link:hover {
  text-decoration: none;
}
.columns__image-link .card__image {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
  transition: box-shadow 400ms;
}
.columns__image-link:hover .card__image {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}
.columns__image-link:hover a {
  color: #A32035;
  text-decoration: underline;
}
.columns__column {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.columns__single .columns__column {
  float: none;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .columns__single .columns__column {
    width: 75%;
  }
}
.section__light-text .columns__column .card__image a {
  color: #FFCE00;
}
.section__light-text .columns__column .card__image a:hover {
  color: #806700;
}
.columns__column.callout {
  align-items: center;
  padding: 0;
  width: 100%;
}
@media (max-width: 767.98px) {
  .columns__column.callout {
    margin-bottom: 25px;
  }
  .columns__column.callout:last-child {
    margin-bottom: 0;
  }
}
.columns__column.callout .card__image {
  margin-right: 0;
}
.blog-listing .columns__column.callout .subheading {
  color: #A32035;
}
@media (max-width: 767.98px) {
  .columns__column {
    margin-bottom: 15px;
  }
}
.columns__column .card__image--transparent img,
.columns__column .card__image img {
  height: auto;
  margin-bottom: 1rem;
  max-width: 100%;
  width: auto;
}
@media (max-width: 767.98px) {
  .columns__column .card__image--transparent img,
  .columns__column .card__image img {
    width: 100%;
  }
}
.columns__column .card__image--transparent a,
.columns__column .card__image a {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
}
.columns__column.callout {
  background-color: #fafafa;
}
@media (max-width: 767.98px) {
  .columns__column.callout {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
.columns__column.callout .card__image {
  border: 1px solid #eeeeee;
}
@media (max-width: 767.98px) {
  .columns__column.callout .card__image {
    margin-right: 0;
    width: 100%;
    max-width: none;
    height: auto;
  }
  .columns__column.callout .card__image:after {
    content: "";
  }
}
.columns__column.callout .columns__content p {
  flex-wrap: wrap;
}
@media (max-width: 767.98px) {
  .columns__column.callout .columns__content .btn {
    width: 100%;
  }
}

.faculty__container {
  margin-top: 30px;
  margin-bottom: 26px;
}
@media (max-width: 767.98px) {
  .faculty__container {
    margin-bottom: 26px;
  }
}
.faculty__cta {
  text-align: center;
}
@media (max-width: 767.98px) {
  .faculty {
    margin-bottom: 50px;
  }
  .faculty .container {
    padding: 25px 15px;
  }
}
@media (min-width: 768px) {
  .faculty__row--few > [class*=col-] {
    display: flex;
    flex-direction: column;
  }
}

#sectionFactsStats {
  position: relative;
}

.stats__background {
  border: 1px solid #707070;
  opacity: 0.79;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin-top: 0;
  background-size: cover;
  background: #002d5d no-repeat center top;
}
.section__afterhero .stats__background {
  margin-top: -80px;
  padding-top: 80px;
}
@media (max-width: 767.98px) {
  .stats__background {
    background-size: 1600px auto;
    background-position: 0 50%;
  }
}
.section__noback .stats__background {
  background: none;
}
.stats__facts {
  max-width: 750px;
  margin: 60px auto 10px;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .stats__facts {
    margin: 30px auto 0;
  }
}
.stats__fact {
  border-right: 1px solid #dee2e6;
  text-align: center;
  padding: 0 20px;
}
@media (max-width: 767.98px) {
  .stats__fact {
    border-right: 0;
    margin-bottom: 20px;
  }
}
.stats__fact:last-child {
  border-right: 0;
}
@media (max-width: 767.98px) {
  .stats__fact:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .stats__fact {
    padding-bottom: 100px;
    margin-bottom: -100px;
  }
}
.stats__label {
  font-size: 4.2rem;
  top: 8px;
  right: 0;
  position: absolute;
  display: block;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
}
.stats__value {
  color: #005EB8;
  font-size: 8rem;
  line-height: 1;
  padding-right: 34px;
  position: relative;
  display: inline-block;
}
.section__light-text .stats__value {
  color: #FFCE00;
}
.stats__description {
  color: #fff;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.4;
}
.stats.section__afterhero .stats__background {
  display: none;
}
.stats.section__afterhero .section__light-text {
  color: black;
}
.stats.section__afterhero .stats__fact .stats__description {
  color: #A32035 !important;
}

.partnerships__name_text {
  position: absolute;
  font-size: 1em;
  line-height: 1.2em;
  max-width: 50%;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 40%;
  margin: -20% 0 0 -25%;
}
@media (max-width: 767.98px) {
  .partnerships__name_text {
    font-size: 0.8em;
  }
}

section.stats.waypoint.section__afterhero .stats__description {
  color: white;
}

.schools__wrapper span {
  display: block;
}

/*!
 * FullCalendar v3.4.0 Stylesheet
 * Docs & License: https://fullcalendar.io/
 * (c) 2017 Adam Shaw
 */
.fc {
  direction: ltr;
  text-align: left;
}

.fc-rtl {
  text-align: right;
}

body .fc { /* extra precedence to overcome jqui */
  font-size: 1em;
}

/* Colors
--------------------------------------------------------------------------------------------------*/
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border-color: #dbdbdb;
}

.fc-unthemed .fc-popover {
  background-color: #fff;
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover .fc-header,
.fc-unthemed .fc-list-heading td {
  background: #eee;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
  color: #545454;
}

.fc-highlight { /* when user is selecting cells */
  background: #bce8f1;
  opacity: 0.3;
}

.fc-bgevent { /* default look for background events */
  background: rgb(143, 223, 130);
  opacity: 0.3;
}

.fc-nonbusiness { /* default look for non-business-hours areas */
  /* will inherit .fc-bgevent's styles */
  background: #d7d7d7;
}

.fc-unthemed .fc-disabled-day {
  background: #d7d7d7;
  opacity: 0.3;
}

.ui-widget .fc-disabled-day { /* themed */
  background-image: none;
}

/* Icons (inline elements with styled text that mock arrow icons)
--------------------------------------------------------------------------------------------------*/
.fc-icon:after {
  font-family: "standard-icons";
  display: inline-block;
  vertical-align: baseline;
  line-height: 36px;
  font-weight: normal;
  font-style: normal;
  position: relative;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
Acceptable font-family overrides for individual icons:
	"Arial", sans-serif
	"Times New Roman", serif

NOTE: use percentage font sizes or else old IE chokes
*/
.fc-icon-left-single-arrow:after {
  content: "‹";
  font-weight: bold;
  font-size: 200%;
  top: -7%;
}

.fc-icon-right-single-arrow:after {
  content: "›";
  font-weight: bold;
  font-size: 200%;
  top: -7%;
}

.fc-icon-left-double-arrow:after {
  content: "«";
  font-size: 160%;
  top: -7%;
}

.fc-icon-right-double-arrow:after {
  content: "»";
  font-size: 160%;
  top: -7%;
}

.fc-icon-left-triangle:after {
  content: "◄";
  font-size: 125%;
  top: 3%;
}

.fc-icon-right-triangle:after {
  content: "►";
  font-size: 125%;
  top: 3%;
}

.fc-icon-down-triangle:after {
  content: "▼";
  font-size: 125%;
  top: 2%;
}

.fc-icon-x:after {
  content: "×";
  font-size: 200%;
  top: 6%;
}

/* Buttons (styled <button> tags, normalized to work cross-browser)
--------------------------------------------------------------------------------------------------*/
.fc-toolbar button {
  /* force height to include the border and padding */
  box-sizing: border-box;
  /* dimensions */
  margin: 0;
  height: 2.1em;
  padding: 0 0.6em;
  /* text & cursor */
  font-size: 1em; /* normalize */
  white-space: nowrap;
  cursor: pointer;
}

/* Firefox has an annoying inner border */
.fc button::-moz-focus-inner {
  margin: 0;
  padding: 0;
}

.fc-state-default { /* non-theme */ }

.fc-state-default.fc-corner-left { /* non-theme */
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.fc-state-default.fc-corner-right { /* non-theme */
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* icons in buttons */
.fc button .fc-icon { /* non-theme */
  position: relative;
  vertical-align: middle;
}

/*
  button states
  borrowed from twitter bootstrap (http://twitter.github.com/bootstrap/)
*/
.fc-state-default {
  transition: color 400ms;
}

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  color: #A32035;
}

.fc-state-disabled {
  cursor: default;
}

/* Buttons Groups
--------------------------------------------------------------------------------------------------*/
.fc-button-group {
  display: inline-block;
}

/*
every button that is not first in a button group should scootch over one pixel and cover the
previous button's border...
*/
.fc .fc-button-group > * { /* extra precedence b/c buttons have margin set to zero */
  float: left;
  margin: 0 0 0 -1px;
}

.fc .fc-button-group > :first-child { /* same */
  margin-left: 0;
}

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-popover {
  position: absolute;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.fc-popover .fc-header { /* TODO: be more consistent with fc-head/fc-body */
  padding: 2px 4px;
}

.fc-popover .fc-header .fc-title {
  margin: 0 2px;
}

.fc-popover .fc-header .fc-close {
  cursor: pointer;
}

.fc-ltr .fc-popover .fc-header .fc-title,
.fc-rtl .fc-popover .fc-header .fc-close {
  float: left;
}

.fc-rtl .fc-popover .fc-header .fc-title,
.fc-ltr .fc-popover .fc-header .fc-close {
  float: right;
}

/* unthemed */
.fc-unthemed .fc-popover {
  border-width: 1px;
  border-style: solid;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
  font-size: 0.9em;
  margin-top: 2px;
}

/* jqui themed */
.fc-popover > .ui-widget-header + .ui-widget-content {
  border-top: 0; /* where they meet, let the header have the border */
}

/* Misc Reusable Components
--------------------------------------------------------------------------------------------------*/
.fc-divider {
  border-style: solid;
  border-width: 1px;
}

hr.fc-divider {
  height: 0;
  margin: 0;
  padding: 0 0 2px; /* height is unreliable across browsers, so use padding */
  border-width: 1px 0;
}

.fc-clear {
  clear: both;
}

.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton {
  /* these element should always cling to top-left/right corners */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.fc-bg {
  bottom: 0; /* strech bg to bottom edge */
}

.fc-bg table {
  height: 100%; /* strech bg to bottom edge */
}

/* Tables
--------------------------------------------------------------------------------------------------*/
.fc table {
  width: 100%;
  box-sizing: border-box; /* fix scrollbar issue in firefox */
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1.4rem;
}

.fc th {
  text-align: center;
}

.fc th,
.fc td {
  border: none;
  padding: 0;
  vertical-align: middle;
}

.fc-day-header {
  color: #707070;
  font-size: 1rem;
  font-weight: normal;
  text-transform: uppercase;
}

/* Internal Nav Links
--------------------------------------------------------------------------------------------------*/
a[data-goto] {
  cursor: pointer;
}

a[data-goto]:hover {
  text-decoration: underline;
}

/* Fake Table Rows
--------------------------------------------------------------------------------------------------*/
.fc .fc-row { /* extra precedence to overcome themes w/ .ui-widget-content forcing a 1px border */
  /* no visible border by default. but make available if need be (scrollbar width compensation) */
  border-style: solid;
  border-width: 0;
}

/* Day Row (used within the header and the DayGrid)
--------------------------------------------------------------------------------------------------*/
.fc-row {
  position: relative;
}

.fc-row .fc-bg {
  z-index: 1;
}

/* highlighting cells & background event skeleton */
.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
  bottom: 0; /* stretch skeleton to bottom of row */
}

.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
  height: 100%; /* stretch skeleton to bottom of row */
}

.fc-row .fc-highlight-skeleton td,
.fc-row .fc-bgevent-skeleton td {
  border-color: transparent;
}

.fc-row .fc-bgevent-skeleton {
  z-index: 2;
}

.fc-row .fc-highlight-skeleton {
  z-index: 3;
}

/*
row content (which contains day/week numbers and events) as well as "helper" (which contains
temporary rendered events).
*/
.fc-row .fc-content-skeleton {
  position: relative;
  z-index: 4;
  padding-bottom: 2px; /* matches the space above the events */
}

.fc-row .fc-helper-skeleton {
  z-index: 5;
}

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  /* see-through to the background below */
  background: none; /* in case <td>s are globally styled */
  border-color: transparent;
  /* don't put a border between events and/or the day number */
  border-bottom: 0;
}

.fc-row .fc-content-skeleton tbody td,
.fc-row .fc-helper-skeleton tbody td {
  /* don't put a border between event cells */
  border-top: 0;
}

/* Scrolling Container
--------------------------------------------------------------------------------------------------*/
.fc-scroller {
  -webkit-overflow-scrolling: touch;
}

/* TODO: move to agenda/basic */
.fc-scroller > .fc-day-grid,
.fc-scroller > .fc-time-grid {
  position: relative; /* re-scope all positions */
  width: 100%; /* hack to force re-sizing this inner element when scrollbars appear/disappear */
}

/* Global Event Styles
--------------------------------------------------------------------------------------------------*/
.fc-event {
  position: relative; /* for resize handle and other inner positioning */
  display: block; /* make the <a> tag block */
  font-size: 0.85em;
  line-height: 1.3;
  border-radius: 3px;
  border: 1px solid #3a87ad; /* default BORDER color */
  font-weight: normal; /* undo jqui's ui-widget-header bold */
}

.fc-event,
.fc-event-dot {
  background-color: #3a87ad; /* default BACKGROUND color */
}

/* overpower some of bootstrap's and jqui's styles on <a> tags */
.fc-event,
.fc-event:hover,
.ui-widget .fc-event {
  color: #fff; /* default TEXT color */
  text-decoration: none; /* if <a> has an href */
}

.fc-event[href],
.fc-event.fc-draggable {
  cursor: pointer; /* give events with links and draggable events a hand mouse pointer */
}

.fc-not-allowed,
.fc-not-allowed .fc-event { /* to override an event's custom cursor */
  cursor: not-allowed;
}

.fc-event .fc-bg { /* the generic .fc-bg already does position */
  z-index: 1;
  background: #fff;
  opacity: 0.25;
}

.fc-event .fc-content {
  position: relative;
  z-index: 2;
}

/* resizer (cursor AND touch devices) */
.fc-event .fc-resizer {
  position: absolute;
  z-index: 4;
}

/* resizer (touch devices) */
.fc-event .fc-resizer {
  display: none;
}

.fc-event.fc-allow-mouse-resize .fc-resizer,
.fc-event.fc-selected .fc-resizer {
  /* only show when hovering or selected (with touch) */
  display: block;
}

/* hit area */
.fc-event.fc-selected .fc-resizer:before {
  /* 40x40 touch area */
  content: "";
  position: absolute;
  z-index: 9999; /* user of this util can scope within a lower z-index */
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
}

/* Event Selection (only for touch devices)
--------------------------------------------------------------------------------------------------*/
.fc-event.fc-selected {
  z-index: 9999 !important; /* overcomes inline z-index */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.fc-event.fc-selected.fc-dragging {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
}

/* Horizontal Events
--------------------------------------------------------------------------------------------------*/
/* bigger touch area when selected */
.fc-h-event.fc-selected:before {
  content: "";
  position: absolute;
  z-index: 3; /* below resizers */
  top: -10px;
  bottom: -10px;
  left: 0;
  right: 0;
}

/* events that are continuing to/from another week. kill rounded corners and butt up against edge */
.fc-ltr .fc-h-event.fc-not-start,
.fc-rtl .fc-h-event.fc-not-end {
  margin-left: 0;
  border-left-width: 0;
  padding-left: 1px; /* replace the border with padding */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
  margin-right: 0;
  border-right-width: 0;
  padding-right: 1px; /* replace the border with padding */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* resizer (cursor AND touch devices) */
/* left resizer  */
.fc-ltr .fc-h-event .fc-start-resizer,
.fc-rtl .fc-h-event .fc-end-resizer {
  cursor: w-resize;
  left: -1px; /* overcome border */
}

/* right resizer */
.fc-ltr .fc-h-event .fc-end-resizer,
.fc-rtl .fc-h-event .fc-start-resizer {
  cursor: e-resize;
  right: -1px; /* overcome border */
}

/* resizer (mouse devices) */
.fc-h-event.fc-allow-mouse-resize .fc-resizer {
  width: 7px;
  top: -1px; /* overcome top border */
  bottom: -1px; /* overcome bottom border */
}

/* resizer (touch devices) */
.fc-h-event.fc-selected .fc-resizer {
  /* 8x8 little dot */
  border-radius: 4px;
  border-width: 1px;
  width: 6px;
  height: 6px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  /* vertically center */
  top: 50%;
  margin-top: -4px;
}

/* left resizer  */
.fc-ltr .fc-h-event.fc-selected .fc-start-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-end-resizer {
  margin-left: -4px; /* centers the 8x8 dot on the left edge */
}

/* right resizer */
.fc-ltr .fc-h-event.fc-selected .fc-end-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-start-resizer {
  margin-right: -4px; /* centers the 8x8 dot on the right edge */
}

/* DayGrid events
----------------------------------------------------------------------------------------------------
We use the full "fc-day-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
.fc-day-grid-event {
  margin: 1px 2px 0; /* spacing between events and edges */
  padding: 0 1px;
}

tr:first-child > td > .fc-day-grid-event {
  margin-top: 2px; /* a little bit more space before the first event */
}

.fc-day-grid-event.fc-selected:after {
  content: "";
  position: absolute;
  z-index: 1; /* same z-index as fc-bg, behind text */
  /* overcome the borders */
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  /* darkening effect */
  background: #000;
  opacity: 0.25;
}

.fc-day-grid-event .fc-content { /* force events to be one-line tall */
  white-space: nowrap;
  overflow: hidden;
}

.fc-day-grid-event .fc-time {
  font-weight: bold;
}

/* resizer (cursor devices) */
/* left resizer  */
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
  margin-left: -2px; /* to the day cell's edge */
}

/* right resizer */
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer {
  margin-right: -2px; /* to the day cell's edge */
}

/* Event Limiting
--------------------------------------------------------------------------------------------------*/
/* "more" link that represents hidden events */
a.fc-more {
  margin: 1px 3px;
  font-size: 0.85em;
  cursor: pointer;
  text-decoration: none;
}

a.fc-more:hover {
  text-decoration: underline;
}

.fc-limited { /* rows and cells that are hidden because of a "more" link */
  display: none;
}

/* popover that appears when "more" link is clicked */
.fc-day-grid .fc-row {
  z-index: 1; /* make the "more" popover one higher than this */
}

.fc-more-popover {
  z-index: 2;
  width: 220px;
}

.fc-more-popover .fc-event-container {
  padding: 10px;
}

/* Now Indicator
--------------------------------------------------------------------------------------------------*/
.fc-now-indicator {
  position: absolute;
  border: 0 solid red;
}

/* Utilities
--------------------------------------------------------------------------------------------------*/
.fc-unselectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Toolbar
--------------------------------------------------------------------------------------------------*/
.fc-toolbar {
  text-align: center;
  position: relative;
}

.fc-toolbar.fc-header-toolbar {
  background-color: #fafafa;
  padding: 12px 0;
}

.fc-toolbar.fc-footer-toolbar {
  margin-top: 1em;
}

.fc-toolbar .fc-left {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 36px;
}
.fc-toolbar .fc-left .fc-button {
  height: auto;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.fc-toolbar .fc-left .fc-button.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.fc-toolbar .fc-right {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 36px;
}
.fc-toolbar .fc-right .fc-button {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.fc-toolbar .fc-right .fc-button.disabled {
  opacity: 0.25;
  pointer-events: none;
}

.fc-toolbar .fc-center {
  display: inline-block;
  padding: 3px 0;
}

/* the things within each left/right/center section */
.fc .fc-toolbar > * > * { /* extra precedence to override button border margins */ }

/* the first thing within each left/center/right section */
.fc .fc-toolbar > * > :first-child { /* extra precedence to override button border margins */
  margin-left: 0;
}

/* title text */
.fc-toolbar h2, .fc-toolbar .h2 {
  display: block;
  margin: 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.6rem;
  line-height: 30px;
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
}

.fc button.fc-weekClick-button {
  display: block;
  font-size: 1.2rem;
  line-height: 2;
  color: #A32035;
  text-decoration: underline;
}

.week-view button.fc-weekClick-button {
  display: none;
}
.week-view .fc-current-week {
  background-color: #fafafa;
}
.week-view .fc-current-week .fc-day-top.fc-other-month {
  opacity: 0.3;
  height: auto;
  visibility: visible;
}
.week-view .fc-today .fc-day-number {
  border: 2px solid #FFCE00;
  line-height: 31px;
}

.day-view .fc-day-top.selected .fc-day-number {
  font-weight: bold;
  background-color: #FFCE00;
}

/* button layering (for border precedence) */
.fc-toolbar button {
  position: relative;
}

.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
  z-index: 2;
}

.fc-toolbar .fc-state-down {
  z-index: 3;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
  z-index: 4;
}

.fc-toolbar button:focus {
  z-index: 5;
}

/* View Structure
--------------------------------------------------------------------------------------------------*/
/* undo twitter bootstrap's box-sizing rules. normalizes positioning techniques */
/* don't do this for the toolbar because we'll want bootstrap to style those buttons as some pt */
.fc-view-container {
  background-color: #fff;
  padding: 24px 18px 18px;
}

.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after {
  box-sizing: content-box;
}

.fc-view,
.fc-view > table { /* so dragged elements can be above the view's main element */
  position: relative;
  z-index: 1;
}

.fc-scroller {
  overflow: visible !important;
  height: auto !important;
}

/* BasicView
--------------------------------------------------------------------------------------------------*/
/* day row structure */
.fc-basicWeek-view .fc-content-skeleton,
.fc-basicDay-view .fc-content-skeleton {
  /* there may be week numbers in these views, so no padding-top */
  padding-bottom: 1em; /* ensure a space at bottom of cell for user selecting/clicking */
}

/* a "rigid" row will take up a constant amount of height because content-skeleton is absolute */
.fc-row.fc-rigid {
  overflow: hidden;
}

.fc-row.fc-rigid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

/* week and day number styling */
.fc-day-top.fc-other-month {
  opacity: 0.3;
  visibility: hidden;
  height: 1px;
}

.fc-day-number {
  display: block;
}

.fc-basic-view .fc-week {
  margin: 8px 0;
  height: 35px;
}

.fc-basic-view th.fc-week-number,
.fc-basic-view th.fc-day-number {
  padding: 0 2px; /* column headers can't have as much v space */
}

/*.fc-ltr .fc-basic-view .fc-day-top .fc-day-number { float: right; }
.fc-rtl .fc-basic-view .fc-day-top .fc-day-number { float: left; }

.fc-ltr .fc-basic-view .fc-day-top .fc-week-number { float: left; border-radius: 0 0 3px 0; }
.fc-rtl .fc-basic-view .fc-day-top .fc-week-number { float: right; border-radius: 0 0 0 3px; }*/
.fc-day-number {
  display: block;
  text-align: center;
  font-size: 1.4rem;
  line-height: 2.5;
  height: 35px;
  width: 35px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

.fc-future .fc-day-number,
.fc-today .fc-day-number {
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
  transition: box-shadow 400ms;
}
.fc-future .fc-day-number:hover,
.fc-today .fc-day-number:hover {
  text-decoration: none;
}
.fc-future .fc-day-number:hover,
.fc-today .fc-day-number:hover {
  background-color: #fff;
}

.fc-basic-view .fc-day-top .fc-week-number {
  min-width: 1.5em;
  text-align: center;
  background-color: #eeeeee;
  color: #808080;
}

/* when week/day number have own column */
.fc-basic-view td.fc-week-number {
  text-align: center;
}

.fc-basic-view td.fc-week-number > * {
  /* work around the way we do column resizing and ensure a minimum width */
  display: inline-block;
  min-width: 1.25em;
}

/* AgendaView all-day area
--------------------------------------------------------------------------------------------------*/
.fc-agenda-view .fc-day-grid {
  position: relative;
  z-index: 2; /* so the "more.." popover will be over the time grid */
}

.fc-agenda-view .fc-day-grid .fc-row {
  min-height: 3em; /* all-day section will never get shorter than this */
}

.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-bottom: 1em; /* give space underneath events for clicking/selecting days */
}

/* TimeGrid axis running down the side (for both the all-day area and the slot area)
--------------------------------------------------------------------------------------------------*/
.fc .fc-axis { /* .fc to overcome default cell styles */
  vertical-align: middle;
  padding: 0 4px;
  white-space: nowrap;
}

.fc-ltr .fc-axis {
  text-align: right;
}

.fc-rtl .fc-axis {
  text-align: left;
}

.ui-widget td.fc-axis {
  font-weight: normal; /* overcome jqui theme making it bold */
}

/* TimeGrid Structure
--------------------------------------------------------------------------------------------------*/
.fc-time-grid-container,
.fc-time-grid { /* so slats/bg/content/etc positions get scoped within here */
  position: relative;
  z-index: 1;
}

.fc-time-grid {
  min-height: 100%; /* so if height setting is 'auto', .fc-bg stretches to fill height */
}

.fc-time-grid table { /* don't put outer borders on slats/bg/content/etc */
  border: 0 hidden transparent;
}

.fc-time-grid > .fc-bg {
  z-index: 1;
}

.fc-time-grid .fc-slats,
.fc-time-grid > hr { /* the <hr> AgendaView injects when grid is shorter than scroller */
  position: relative;
  z-index: 2;
}

.fc-time-grid .fc-content-col {
  position: relative; /* because now-indicator lives directly inside */
}

.fc-time-grid .fc-content-skeleton {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
}

/* divs within a cell within the fc-content-skeleton */
.fc-time-grid .fc-business-container {
  position: relative;
  z-index: 1;
}

.fc-time-grid .fc-bgevent-container {
  position: relative;
  z-index: 2;
}

.fc-time-grid .fc-highlight-container {
  position: relative;
  z-index: 3;
}

.fc-time-grid .fc-event-container {
  position: relative;
  z-index: 4;
}

.fc-time-grid .fc-now-indicator-line {
  z-index: 5;
}

.fc-time-grid .fc-helper-container { /* also is fc-event-container */
  position: relative;
  z-index: 6;
}

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-slats td {
  height: 1.5em;
  border-bottom: 0; /* each cell is responsible for its top border */
}

.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: dotted;
}

.fc-time-grid .fc-slats .ui-widget-content { /* for jqui theme */
  background: none; /* see through to fc-bg */
}

/* TimeGrid Highlighting Slots
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-highlight-container { /* a div within a cell within the fc-highlight-skeleton */
  position: relative; /* scopes the left/right of the fc-highlight to be in the column */
}

.fc-time-grid .fc-highlight {
  position: absolute;
  left: 0;
  right: 0;
  /* top and bottom will be in by JS */
}

/* TimeGrid Event Containment
--------------------------------------------------------------------------------------------------*/
.fc-ltr .fc-time-grid .fc-event-container { /* space on the sides of events for LTR (default) */
  margin: 0 2.5% 0 2px;
}

.fc-rtl .fc-time-grid .fc-event-container { /* space on the sides of events for RTL */
  margin: 0 2px 0 2.5%;
}

.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
  position: absolute;
  z-index: 1; /* scope inner z-index's */
}

.fc-time-grid .fc-bgevent {
  /* background events always span full width */
  left: 0;
  right: 0;
}

/* Generic Vertical Event
--------------------------------------------------------------------------------------------------*/
.fc-v-event.fc-not-start { /* events that are continuing from another day */
  /* replace space made by the top border with padding */
  border-top-width: 0;
  padding-top: 1px;
  /* remove top rounded corners */
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.fc-v-event.fc-not-end {
  /* replace space made by the top border with padding */
  border-bottom-width: 0;
  padding-bottom: 1px;
  /* remove bottom rounded corners */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* TimeGrid Event Styling
----------------------------------------------------------------------------------------------------
We use the full "fc-time-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
.fc-time-grid-event {
  overflow: hidden; /* don't let the bg flow over rounded corners */
}

.fc-time-grid-event.fc-selected {
  /* need to allow touch resizers to extend outside event's bounding box */
  /* common fc-selected styles hide the fc-bg, so don't need this anyway */
  overflow: visible;
}

.fc-time-grid-event.fc-selected .fc-bg {
  display: none; /* hide semi-white background, to appear darker */
}

.fc-time-grid-event .fc-content {
  overflow: hidden; /* for when .fc-selected */
}

.fc-time-grid-event .fc-time,
.fc-time-grid-event .fc-title {
  padding: 0 1px;
}

.fc-time-grid-event .fc-time {
  font-size: 0.85em;
  white-space: nowrap;
}

/* short mode, where time and title are on the same line */
.fc-time-grid-event.fc-short .fc-content {
  /* don't wrap to second line (now that contents will be inline) */
  white-space: nowrap;
}

.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
  /* put the time and title on the same line */
  display: inline-block;
  vertical-align: top;
}

.fc-time-grid-event.fc-short .fc-time span {
  display: none; /* don't display the full time text... */
}

.fc-time-grid-event.fc-short .fc-time:before {
  content: attr(data-start); /* ...instead, display only the start time */
}

.fc-time-grid-event.fc-short .fc-time:after {
  content: " - "; /* seperate with a dash, wrapped in nbsp's */
}

.fc-time-grid-event.fc-short .fc-title {
  font-size: 0.85em; /* make the title text the same size as the time */
  padding: 0; /* undo padding from above */
}

/* resizer (cursor device) */
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
  overflow: hidden;
  line-height: 1;
  font-size: 1.2rem;
  font-family: monospace;
  text-align: center;
  cursor: s-resize;
}

.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after {
  content: "=";
}

/* resizer (touch device) */
.fc-time-grid-event.fc-selected .fc-resizer {
  /* 10x10 dot */
  border-radius: 5px;
  border-width: 1px;
  width: 8px;
  height: 8px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  /* horizontally center */
  left: 50%;
  margin-left: -5px;
  /* center on the bottom edge */
  bottom: -5px;
}

/* Now Indicator
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-now-indicator-line {
  border-top-width: 1px;
  left: 0;
  right: 0;
}

/* arrow on axis */
.fc-time-grid .fc-now-indicator-arrow {
  margin-top: -5px; /* vertically center on top coordinate */
}

.fc-ltr .fc-time-grid .fc-now-indicator-arrow {
  left: 0;
  /* triangle pointing right... */
  border-width: 5px 0 5px 6px;
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.fc-rtl .fc-time-grid .fc-now-indicator-arrow {
  right: 0;
  /* triangle pointing left... */
  border-width: 5px 6px 5px 0;
  border-top-color: transparent;
  border-bottom-color: transparent;
}

/* List View
--------------------------------------------------------------------------------------------------*/
/* possibly reusable */
.fc-event-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

/* view wrapper */
.fc-rtl .fc-list-view {
  direction: rtl; /* unlike core views, leverage browser RTL */
}

.fc-list-view {
  border-width: 1px;
  border-style: solid;
}

/* table resets */
.fc .fc-list-table {
  table-layout: auto; /* for shrinkwrapping cell content */
}

.fc-list-table td {
  border-width: 1px 0 0;
  padding: 8px 14px;
}

.fc-list-table tr:first-child td {
  border-top-width: 0;
}

/* day headings with the list */
.fc-list-heading {
  border-bottom-width: 1px;
}

.fc-list-heading td {
  font-weight: bold;
}

.fc-ltr .fc-list-heading-main {
  float: left;
}

.fc-ltr .fc-list-heading-alt {
  float: right;
}

.fc-rtl .fc-list-heading-main {
  float: right;
}

.fc-rtl .fc-list-heading-alt {
  float: left;
}

/* event list items */
.fc-list-item.fc-has-url {
  cursor: pointer; /* whole row will be clickable */
}

.fc-list-item:hover td {
  background-color: #f5f5f5;
}

.fc-list-item-marker,
.fc-list-item-time {
  white-space: nowrap;
  width: 1px;
}

/* make the dot closer to the event title */
.fc-ltr .fc-list-item-marker {
  padding-right: 0;
}

.fc-rtl .fc-list-item-marker {
  padding-left: 0;
}

.fc-list-item-title a {
  /* every event title cell has an <a> tag */
  text-decoration: none;
  color: inherit;
}

.fc-list-item-title a[href]:hover {
  /* hover effect only on titles with hrefs */
  text-decoration: underline;
}

/* message when no events */
.fc-list-empty-wrap2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fc-list-empty-wrap1 {
  width: 100%;
  height: 100%;
  display: table;
}

.fc-list-empty {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.fc-unthemed .fc-list-empty { /* theme will provide own background */
  background-color: #eee;
}

.degree-finder__filter-container {
  position: relative;
  z-index: 2;
}
.degree-finder__filter-container div#dropdownCalendar {
  box-shadow: 2px 2px 5px 2px rgba(101, 101, 101, 0.4196078431);
}

.degree-finder__results-container {
  position: relative;
  z-index: 0;
}

/* AddEvent theme CSS
---------------------------------------------------------------------- */
/* The button */
.addeventatc {
  cursor: pointer;
  color: #A32035;
  border-radius: 0;
  text-decoration: underline;
  white-space: normal;
  text-align: left;
  vertical-align: baseline;
  transition: color 400ms;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.3;
  display: inline-block;
  padding: 0 0 0 30px;
  position: relative;
}
.addeventatc .icon {
  transition: color 400ms;
  color: #005EB8;
  font-size: 2.1rem;
  line-height: 0.8;
  position: absolute;
  left: 0;
  text-decoration: none;
}

.addeventatc:hover {
  color: #821a2a;
}

.addeventatc .start,
.addeventatc .end,
.addeventatc .timezone,
.addeventatc .title,
.addeventatc .description,
.addeventatc .location,
.addeventatc .organizer,
.addeventatc .organizer_email,
.addeventatc .facebook_event,
.addeventatc .all_day_event,
.addeventatc .date_format,
.addeventatc .alarm_reminder,
.addeventatc .recurring,
.addeventatc .attendees,
.addeventatc .calname,
.addeventatc .uid,
.addeventatc .status,
.addeventatc .method,
.addeventatc .client {
  display: none !important;
}

/* The drop down */
.addeventatc_dropdown {
  width: 200px;
  position: absolute;
  z-index: 99999;
  padding: 6px 0 0 0;
  background: #fff;
  text-align: left;
  display: none;
  margin-top: 4px;
  margin-left: -1px;
  border: 1px solid #eeeeee;
  border-radius: 2px;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
}

.addeventatc_dropdown span {
  display: block;
  line-height: 100%;
  background: #fff;
  text-decoration: none;
  font-size: 1.4rem;
  color: #333;
  padding: 9px 10px 9px 40px;
}

.addeventatc_dropdown span:hover {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
  font-size: 1.4rem;
}

.addeventatc_dropdown em {
  color: #b8b8b8;
  font-size: 1.2rem;
}

.addeventatc_dropdown .frs {
  background: #fff;
  color: #dbdbdb !important;
  cursor: pointer;
  font-size: 1rem;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 110% !important;
  padding-left: 10px;
  position: absolute;
  right: 10px;
  text-align: right;
  text-decoration: none;
  top: 5px;
  z-index: 101;
}

.addeventatc_dropdown .frs:hover {
  color: #999 !important;
}

.addeventatc_dropdown .ateappleical {
  background-image: url(/images/gfx/icon-apple-t1.svg);
  background-position: 13px 50%;
  background-repeat: no-repeat;
  background-size: 14px auto;
}

.addeventatc_dropdown .ateoutlook {
  background-image: url(/images/gfx/icon-outlook-t1.svg);
  background-position: 12px 50%;
  background-repeat: no-repeat;
  background-size: 16px auto;
}

.addeventatc_dropdown .ateoutlookcom {
  background-image: url(/images/gfx/icon-outlook-t1.svg);
  background-position: 12px 50%;
  background-repeat: no-repeat;
  background-size: 16px auto;
}

.addeventatc_dropdown .ategoogle {
  background-image: url(/images/gfx/icon-google-t1.svg);
  background-position: 12px 50%;
  background-repeat: no-repeat;
  background-size: 16px auto;
}

.addeventatc_dropdown .ateyahoo {
  background-image: url(/images/gfx/icon-yahoo-t1.svg);
  background-position: 12px 50%;
  background-repeat: no-repeat;
  background-size: 16px auto;
}

.addeventatc_dropdown .atefacebook {
  background-image: url(/images/gfx/icon-facebook-t1.svg);
  background-position: 12px 50%;
  background-repeat: no-repeat;
  background-size: 16px auto;
}

.addeventatc_dropdown .copyx {
  height: 21px;
  display: block;
  position: relative;
  cursor: default;
}

.addeventatc_dropdown .brx {
  height: 1px;
  overflow: hidden;
  background: #e0e0e0;
  position: absolute;
  z-index: 100;
  left: 10px;
  right: 10px;
  top: 9px;
}

:root {
  --font-size-h1: 2.6rem;
  --font-size-h2: 2rem;
  --font-size-h3: 1.8rem;
  --font-size-p: 1.4rem;
  --border-color: black;
}

@media print {
  #onetrust-banner-sdk,
  #onetrust-consent-sdk,
  br {
    display: none;
  }
  body {
    margin: 0.1rem !important;
    padding: 0px !important;
    background: transparent;
    font-family: Arial, sans-serif;
    line-height: 1 !important;
    font-size: var(--font-size-p) !important;
  }
  h1, .h1,
  h2,
  .h2,
  h3,
  .h3,
  p,
  a,
  ul,
  li,
  span {
    padding: 0px !important;
    margin: 0px !important;
  }
  h1, .h1 {
    font-size: var(--font-size-h1) !important;
  }
  h2, .h2 {
    font-size: var(--font-size-h2) !important;
  }
  h3, .h3 {
    font-size: var(--font-size-h3) !important;
  }
  p,
  ul,
  em,
  a,
  div {
    font-size: var(--font-size-p) !important;
  }
  a {
    color: #000;
    text-decoration: none !important;
  }
  .section__darkgrayback,
  .section__grayback {
    background-color: white;
  }
  .container {
    margin: 0px !important;
    padding: 0px !important;
  }
  section,
  .container {
    max-width: 100% !important;
  }
  div.ivy-bot,
  section.faqs,
  section.related-information,
  section.faculty,
  section.career-impact,
  section.bubbles,
  .nav__topnav-wrapper,
  #navbarSupportedContent,
  .navbar-toggler,
  .readmore__button,
  section.steps,
  section.p-0 {
    display: none !important;
  }
  .header__global {
    position: fixed !important;
    top: 0;
  }
  .header {
    padding: 0px !important;
  }
  #hero-wrap {
    margin-top: 65px !important;
  }
  #hero-wrap #heroInterior .hero__background picture > source {
    display: none;
  }
  #hero-wrap #heroInterior .hero__background picture > source[media="(max-width:959px)"] {
    display: block !important;
  }
  .hero {
    min-height: 0 !important;
    border-top: none !important;
  }
  .hero__background {
    position: static;
  }
  .hero__background--overlay,
  .hero__background picture {
    height: 100% !important;
    background: none !important;
  }
  #heroInterior {
    padding: 0px !important;
    clip-path: none !important;
  }
  .hero__container {
    max-width: 100% !important;
    display: grid;
  }
  .hero__description {
    color: rgba(0, 0, 0, 0.8);
    text-shadow: none !important;
    margin-bottom: 0px !important;
  }
  .hero__description p {
    font-size: 1.4rem !important;
    line-height: 1 !important;
  }
  .hero__description p:empty {
    display: none;
  }
  .hero__description div {
    display: none;
  }
  #heroInterior .container .hero__container .hero__header {
    order: 1 !important;
    position: fixed;
    width: 75%;
    margin-top: -220px;
    justify-self: end;
  }
  #heroInterior .container .hero__container .hero__header h1, #heroInterior .container .hero__container .hero__header .h1 {
    margin-bottom: 0px !important;
    color: #A32035 !important;
    text-shadow: none !important;
    text-align: end;
    line-height: 1;
  }
  #heroInterior .hero__background {
    order: 2 !important;
    margin-top: 10px;
    height: 150px;
    max-height: 175px;
  }
  #heroInterior .container .hero__container .hero__description {
    order: 3 !important;
    margin-top: 10px;
  }
  section.program-detail .row {
    flex-direction: column-reverse !important;
  }
  section.program-detail .row > div {
    width: 100% !important;
    padding: 0px 8px !important;
  }
  section.program-detail .row .program-detail__detail-window {
    padding: 0px !important;
    margin: var(--margin-padding-0) !important;
    border-top: 2px solid var(--border-color);
    border-bottom: 2px solid var(--border-color);
    border-left: none;
    border-right: none;
    margin-top: 5px !important;
  }
  section.program-detail .row .program-detail__detail-window .row {
    display: grid;
    grid-template-areas: "left right";
  }
  section.program-detail .row .program-detail__detail-window .row .program-detail__detail {
    margin: 0px !important;
  }
  section.program-detail .row .program-detail__detail-window .row .program-detail__info {
    grid-area: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  section.program-detail .row .program-detail__detail-window .row .program-detail__info a {
    display: none;
  }
  section.program-detail .row .program-detail__detail-window .row .program-detail__info .program-detail__detail {
    margin-bottom: 0px !important;
  }
  section.program-detail .row .program-detail__detail-window .row .program-detail__info .program-detail__detail .heading__type {
    margin-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 8px !important;
  }
  section.program-detail .row .program-detail__detail-window .row .program-detail__info .program-detail__detail .ways__value {
    margin-bottom: 0px !important;
  }
  section.program-detail .row .program-detail__detail-window .row .program-detail__ways {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    grid-area: right;
  }
  section.program-detail .row .program-detail__detail-window .row .program-detail__ways .program-detail__detail {
    margin-bottom: 0px !important;
  }
  section.program-detail .row .program-detail__detail-window .row .program-detail__ways .program-detail__detail .heading__type {
    margin-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 2px !important;
  }
  section.program-detail .row .program-detail__detail-window .row .program-detail__ways .program-detail__detail .ways__value {
    margin-bottom: 0px !important;
  }
  section.program-detail .program-detail__value > .icon,
  section.program-detail .ways__value > .icon {
    font-size: 2.2rem !important;
  }
  section.program-detail .section__description {
    margin: 15px 0;
  }
  section.program-detail .section__description p {
    margin-bottom: 15pt !important;
  }
  section.program-detail .section__description ul > li {
    margin: 0px 0px 15px 30px !important;
    list-style: circle !important;
  }
  .section__lightgrayback {
    background-color: transparent !important;
    margin: 0px;
    border-top: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
    padding: 0px !important;
    position: fixed;
    bottom: 75px;
    width: 100% !important;
    max-width: 100% !important;
  }
  .tabs__wrapper {
    margin: 0px !important;
  }
  .tabs__container .tabs__content .row {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    width: 75% !important;
    max-width: 75% !important;
  }
  .tabs__container .tabs__content .row > div {
    width: 45% !important;
    max-width: 45% !important;
  }
  .columns__content {
    padding: 10px 0px !important;
    width: 100%;
    max-width: 100%;
  }
  .columns__content p {
    font-size: 0.8rem !important;
    line-height: 1;
    padding: 0px !important;
  }
  .tabs__content .row img,
  .tabs__content .row a > img {
    width: 35% !important;
    max-width: 35% !important;
  }
  section.testimonials {
    background-image: none !important;
    padding: 0px !important;
  }
  .testimonials .container {
    padding-bottom: 0px;
    padding-top: 0px;
    background-color: white;
  }
  .testimonials__continer {
    padding: 0px !important;
  }
  .testimonials__previous,
  .testimonials__next,
  .testimonials__image,
  .testimonials__slide .row .col-md-4 {
    display: none !important;
  }
  .testimonials__slide {
    display: none !important;
    padding: 0px !important;
  }
  .testimonials__slide:first-child {
    display: block !important;
    padding: 0px !important;
  }
  .testimonials__quote {
    margin: 0;
    margin-top: 10px !important;
    padding: 0px 56px;
  }
  .testimonials blockquote {
    font-style: normal !important;
    line-height: 1.1 !important;
    font-size: 1.4rem !important;
    margin: 0px !important;
  }
  .testimonials__name {
    margin-top: 0 !important;
  }
  .section.admission-req {
    page-break-before: always !important;
    margin-top: 55px !important;
  }
  .section.admission-req .section__description .row {
    display: var(--display-none) !important;
  }
  .admission-req__additional {
    width: 100% !important;
    margin: 0px !important;
  }
  .admission-req__additional-container {
    background: none !important;
    margin: 0px !important;
  }
  .admission-req__additional-container .container p > strong {
    font-size: var(--font-size-h3);
  }
  .admission-req__additional-container .container p > strong .section__description p strong {
    font-size: var(--font-size-h3);
  }
  .admission-req__additional-container p,
  .admission-req__additional-container ul {
    margin-bottom: 10pt !important;
  }
  .section__description .admission-req__requirements li,
  .section__description ul li {
    margin: 0px 0px 15px 30px !important;
    list-style: circle !important;
  }
  .admission-req__additional-text {
    padding: var(--padding-admission);
    font-size: var(--font-size-additional-text);
    height: fit-content !important;
    border: none;
  }
  .admission-req__additional-text .admission-req__title {
    margin-bottom: 0px;
    font-size: var(--font-size-h3);
    color: inherit !important;
  }
  .admission-req__additional-container p[style="text-align: center;"] {
    display: none;
  }
  .accordion__section-content > p:nth-of-type(1),
  .accordion__section-content > p:nth-of-type(2),
  .accordion__section-content + .accordion__section-header,
  .accordion__section-header,
  .icon__downarrow {
    display: none !important;
  }
  .section.curriculum {
    page-break-before: always !important;
    margin-top: 50px !important;
  }
  .accordionV2__section {
    margin-top: 5px !important;
  }
  .accordionV2__section .accordionV2__section-wrapper {
    padding: 0px;
  }
  .accordionV2__section .accordion__section-content + p {
    display: none;
  }
  .accordionV2__section:nth-child(3) {
    page-break-before: always;
    margin-top: 50px !important;
  }
  .accordionV2__section:nth-child(5) {
    page-break-before: always;
    margin-top: 50px !important;
  }
  .accordionV2 > li button {
    order: 0 !important;
    border-top-width: 0px;
    border-top-style: none;
    border-top-color: currentcolor;
    border-top: 1px solid #000 !important;
    pointer-events: none !important;
    cursor: not-allowed !important;
    padding: 5px 0px !important;
    border-radius: 0px !important;
    background: white !important;
  }
  .accordion__section-content {
    display: block !important;
  }
  .curriculum__wrapper {
    max-width: 100% !important;
    width: 100% !important;
  }
  .curriculum__wrapper table {
    width: 100% !important;
  }
  .curriculum__wrapper table th {
    padding: 0px !important;
  }
  .accordionV2 > li,
  .list-group-item {
    border: none !important;
  }
  table.sc_courselist {
    width: 100%;
    border-collapse: collapse;
  }
  table.sc_courselist td {
    padding: 0px !important;
    border: none;
  }
  table.sc_courselist {
    border-collapse: collapse;
  }
  table.sc_courselist tbody tr {
    border-top: none;
    border-bottom: none;
  }
  table.sc_courselist thead tr {
    border-top: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
  }
  table.sc_courselist tbody th:first-child,
  table.sc_courselist tbody td:first-child,
  table.sc_courselist tbody th:last-child,
  table.sc_courselist tbody td:last-child {
    font-family: "Noto Mono", monospace;
  }
  table.sc_courselist tbody th:first-child,
  table.sc_courselist tbody td:first-child {
    width: 25%;
  }
  table.sc_courselist tbody th:last-child,
  table.sc_courselist tbody td:last-child {
    width: 5%;
  }
  .sc_courselist caption.hidden,
  .program-detail__info .ways__value a {
    display: none;
  }
  table > span,
  .codecol span,
  td p span {
    line-height: 0 !important;
  }
  .section.cta-snippet {
    display: none !important;
  }
  .section.cta-snippet.section__dark-text[aria-label="Ways to get started"] {
    display: block !important;
    background-color: white !important;
    position: fixed;
    border-bottom: 1px solid #000 !important;
    bottom: 0 !important;
    width: 100%;
    padding-bottom: 5px;
  }
  .section.cta-snippet.section__dark-text[aria-label="Ways to get started"] .section__header > h2, .section.cta-snippet.section__dark-text[aria-label="Ways to get started"] .section__header > .h2,
  .section.cta-snippet.section__dark-text[aria-label="Ways to get started"] .section__header > p,
  .section.cta-snippet.section__dark-text[aria-label="Ways to get started"] .steps__links li {
    display: none;
  }
  .section.cta-snippet.section__dark-text[aria-label="Ways to get started"] .container,
  .section.cta-snippet.section__dark-text[aria-label="Ways to get started"] .steps__links,
  .section.cta-snippet.section__dark-text[aria-label="Ways to get started"] .section__header {
    display: block;
    text-align: left !important;
  }
  .section.cta-snippet.section__dark-text[aria-label="Ways to get started"] .container::before {
    content: "Become the Teacher Who Inspired You";
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 1.5px;
  }
  .section.cta-snippet.section__dark-text[aria-label="Ways to get started"] .container .section__header::after {
    content: "✉ Request Info: https://www.umassglobal.edu";
    font-size: 1.4rem;
    line-height: 0;
  }
  .section.cta-snippet.section__dark-text[aria-label="Ways to get started"] .container .steps__links::before {
    content: "✓ Apply Now: https://www.umassglobal.edu/apply";
    font-size: 1.4rem;
    line-height: 0;
  }
  .section.cta-snippet.section__dark-text[aria-label="Ways to get started"] .container::after {
    content: "☎ Call us at: 800.746.0082";
    font-size: 1.4rem;
    line-height: 0;
  }
}
:root {
  --exp-active-tab-bg-color: #005eb8;
  --exp-active-tab-border-color: #005eb8;
  /* ### Umass New Colors ### */
  --umgWarningRed-dark: hsla(0, 95%, 22%, 1);
  --umgWarningRed: hsla(0, 95%, 45%, 1);
  --umgWarningRed-light: hsla(0, 95%, 75%, 1);
  --umgWarningRed-extraLight: hsla(0, 95%, 95%, 1);
  --umgSuccessGreen-dark: hsla(121, 100%, 13%, 1);
  --umgSuccessGreen: hsla(121, 100%, 26%, 1);
  --umgSuccessGreen-light: hsla(121, 100%, 75%, 1);
  --umgSuccessGreen-extraLight: hsla(121, 100%, 95%, 1);
  --umgBrandBlue-dark: hsla(209, 100%, 18%, 1);
  --umgBrandBlue: hsla(209, 100%, 36%, 1);
  --umgBrandBlue-light: hsla(209, 100%, 75%, 1);
  --umgBrandBlue-extraLight: hsla(209, 100%, 95%, 1);
  --umgFormAreaBackground: rgba(240, 240, 240, 0.8);
  --umgDisabledText: hsla(0, 0%, 33%, 1);
  --umgUserEnteredText: hsla(0, 0%, 18%, 1);
  /* ### Icons ### */
  --infoIcon: "";
  --errorIcon: "";
  --successIcon: "";
}

.exp-leads {
  position: relative;
  /* When description does not exists */
}
.exp-leads .iti {
  width: 100%;
}
.exp-leads .iti input {
  height: auto;
}
.exp-leads .background-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}
.exp-leads .trapezoid-left {
  position: absolute;
  right: 0;
  background-color: rgba(0, 94, 184, 0.95);
  height: 734px;
  z-index: -1;
}
.exp-leads .container {
  position: relative;
  background-color: transparent;
  z-index: 1;
  max-width: 100%;
  padding: 0px !important;
}
.exp-leads .exp-leads-container {
  padding: 0;
  width: 100%;
  max-width: 100%;
}
.exp-leads .left-content,
.exp-leads .right-content {
  display: flex;
  flex-direction: column;
}
.exp-leads .left-content {
  justify-content: space-between;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-right: 60px;
  padding-right: 0;
  padding-left: 80px;
  height: 100%;
}
.exp-leads .right-content {
  padding-top: 175px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-left: 0;
}
.exp-leads .exp-leads-heading {
  line-height: 48px;
  margin-top: 0;
  font-family: "Noto Sans", sans-serif;
  font-size: 36px;
  font-weight: 700;
}
.exp-leads .exp-leads-heading-expanded {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 70px;
  line-height: 110%;
}
.exp-leads .exp-leads-paragraph {
  font-family: "Noto Sans", sans-serif;
  margin: 0;
  font-size: 17px;
  line-height: 27px;
}
.exp-leads .logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.exp-leads .logo {
  width: 92px;
  height: auto;
  object-fit: contain;
}
.exp-leads .exp-leads-nav-tabs {
  display: flex;
  justify-content: center;
  border: none;
  user-select: none;
  pointer-events: none;
}
.exp-leads .exp-leads-nav-tabs li {
  user-select: none;
  pointer-events: none;
}
.exp-leads .exp-leads-nav-tabs li a {
  user-select: none;
  pointer-events: none;
}
.exp-leads .nav-item {
  display: flex;
  align-items: center;
}
.exp-leads .nav-item:not(:last-child) {
  margin-right: 44px;
}
.exp-leads .nav-item .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  padding: 0;
  box-sizing: content-box;
  border-radius: 50%;
  border: 3px solid #d9d9d9;
  border-style: solid;
  background: transparent;
  text-align: center;
  pointer-events: none;
}
.exp-leads .nav-item .nav-link.active {
  background: var(--exp-active-tab-bg-color);
  border-color: var(--exp-active-tab-border-color);
}
.exp-leads .exp-leads-select {
  margin-top: 16px;
  height: 56px;
  border-radius: 0%;
  width: 100%;
}
.exp-leads .exp-leads-select:first-child {
  margin-top: 40px;
}
.exp-leads .exp-leads-input {
  width: 100%;
  height: 56px;
  margin-top: 16px;
  border: none;
}
.exp-leads .exp-leads-input:first-child {
  margin-top: 40px;
}
.exp-leads .button-container {
  margin-top: 46px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.exp-leads #exp-continue-btn {
  margin-left: auto;
  background-color: transparent;
}
.exp-leads .exp-leads-btn {
  border-radius: 0;
  border-style: none;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 12px;
  height: 36px;
  min-width: auto;
  text-transform: capitalize;
  line-height: normal;
}
.exp-leads .exp-leads-back-btn {
  border-radius: 50%;
  padding: 4px;
  text-align: center;
  width: 40px;
  height: 40px;
  background-color: #333333;
  border: none;
  color: #fff !important;
  min-width: auto;
}
.exp-leads #tab1,
.exp-leads #tab2 {
  margin-bottom: 8px;
}
.exp-leads .exp-leads-arrow {
  margin-left: 12px;
}
@media (max-width: 1600px) {
  .exp-leads .exp-leads-heading-expanded {
    font-size: calc(20px + 3.6vw);
  }
}
@media (min-width: 992px) {
  .exp-leads .right-content {
    padding-right: 80px;
    padding-bottom: 55px;
    padding-top: 78px;
  }
}
@media (max-width: 991px) {
  .exp-leads .exp-leads-row {
    flex-direction: column-reverse;
    height: auto;
  }
  .exp-leads .exp-leads-heading {
    margin-bottom: 40px;
  }
  .exp-leads .left-content,
  .exp-leads .right-content {
    height: auto;
    padding: 40px;
  }
  .exp-leads .left-content {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .exp-leads .left-content,
  .exp-leads .right-content {
    padding: 40px;
  }
  .exp-leads .exp-leads-heading {
    font-size: calc(2.9rem + 3.7vw);
  }
}
@media (min-width: 480px) {
  .exp-leads .row {
    flex-direction: row;
    justify-content: space-around;
  }
  .exp-leads .logo {
    max-width: none;
  }
}
@media (max-width: 479px) {
  .exp-leads .left-content {
    padding: 5.5rem 0px 0px 0px;
  }
  .exp-leads .right-content {
    padding: 3rem 0px 5.5rem 0px;
  }
  .exp-leads .logos {
    flex-direction: column;
    align-items: center;
  }
  .exp-leads .logo {
    width: 105px;
    margin: 15px auto;
  }
}
form.uxAbleForms {
  margin: 10px;
  display: flex;
  flex-direction: column;
  flex: 1 1 90%;
  width: calc(100% - 20px);
  justify-content: space-between;
}
form.uxAbleForms > div:first-child {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
}
form.uxAbleForms .fieldValidatorUIGroup,
form.uxAbleForms .uxAbleForms--inputContainer {
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  font-size: 1.6rem;
  line-height: 1.25;
}
form.uxAbleForms .validationIndicator,
form.uxAbleForms .validationMessage,
form.uxAbleForms .uxAbleForms--validationIndicator,
form.uxAbleForms .uxAbleForms--validationMessage {
  display: inline-block;
  font-family: bootstrap-icons; /*important*/
  font-style: normal;
  font-weight: normal; /*important*/
  font-variant: normal;
  text-transform: none;
  vertical-align: text-bottom;
  -webkit-font-smoothing: antialiased;
  font-size: 2rem;
  line-height: 1;
  transition: color 0.2s ease-in-out;
}
form.uxAbleForms input[type=text],
form.uxAbleForms input[type=email],
form.uxAbleForms input[type=tel],
form.uxAbleForms select {
  font-family: "Noto Sans", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  font-size: 1.6rem;
  line-height: 1.25;
  vertical-align: -0.2rem;
  background-color: transparent;
  color: #333;
}
form.uxAbleForms input[type=tel] {
  padding-left: 52px;
}
form.uxAbleForms .inputLabelGroup,
form.uxAbleForms .uxAbleForms--field {
  width: 100%;
  border-bottom: 3px solid var(--umgDisabledText);
  background-color: var(--umgFormAreaBackground);
  padding: 0.6rem 1rem 0.3rem 1rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}
form.uxAbleForms .inputLabelGroup.error,
form.uxAbleForms .uxAbleForms--field.errorState {
  border-bottom: 3px solid var(--umgWarningRed);
}
form.uxAbleForms .validationMessage.error:before,
form.uxAbleForms .uxAbleForms--validationMessage:before {
  content: var(--infoIcon);
  color: var(--umgWarningRed);
}
form.uxAbleForms .inputLabelGroup.error .validationIndicator:before,
form.uxAbleForms .uxAbleForms--field.errorState .uxAbleForms--validationIndicator:before {
  content: var(--errorIcon);
  color: var(--umgWarningRed);
}
form.uxAbleForms .inputLabelGroup.success,
form.uxAbleForms .uxAbleForms--field.successState {
  border-bottom: 3px solid var(--umgSuccessGreen);
}
form.uxAbleForms .inputLabelGroup.success .validationIndicator:before,
form.uxAbleForms .uxAbleForms--field.successState .uxAbleForms--validationIndicator:before {
  content: var(--successIcon);
  color: var(--umgSuccessGreen);
}
form.uxAbleForms .fieldGroup,
form.uxAbleForms .uxAbleForms--fieldContainer {
  width: 100%;
  margin: 16px 0 0 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 3;
  flex-basis: 100%;
}
form.uxAbleForms .inputLabelGroup label:not(.btn),
form.uxAbleForms .uxAbleForms--field label:not(.btn),
form.uxAbleForms .uxAbleForms--field legend {
  width: 100%;
  font-size: 1.3rem;
  line-height: 1.2;
  font-family: "Noto Sans", Helvetica, sans-serif;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.03rem;
  padding-bottom: 0.3rem;
  font-style: normal;
  font-weight: 400;
  color: var(--umgBrandBlue-dark) !important;
  flex: 1 1 100%;
}
form.uxAbleForms .uxAbleForms--field abbr {
  text-decoration: none;
}
form.uxAbleForms div.errorMessageContainer,
form.uxAbleForms .uxAbleForms--infoContainer {
  font-size: 1.6rem;
  line-height: 1.4;
  flex-direction: row;
  width: 100%;
  padding: 0.4rem 1rem 0.4rem 1rem;
  margin: 0 0 0 0;
  flex-shrink: 1;
  flex-grow: 0;
  flex-basis: 100%;
  background: var(--umgWarningRed-extraLight);
  display: none;
}
form.uxAbleForms .inputLabelGroup.error + div.errorMessageContainer,
form.uxAbleForms .uxAbleForms--field.errorState + .uxAbleForms--infoContainer {
  display: flex;
}
form.uxAbleForms .uxAbleForms--infoContainer p {
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.5;
  font-stretch: semi-condensed;
  font-family: "Noto Sans", Helvetica, sans-serif;
  display: inline-block;
  padding: 0 0 0 1rem;
  font-style: normal;
  font-weight: 500;
  color: black;
  flex: 1 1 100%;
}
form.uxAbleForms div.inputLabelGroup:has(input[type=text]:focus),
form.uxAbleForms .uxAbleForms--field:has(input[type=text]:focus),
form.uxAbleForms div.inputLabelGroup:has(input[type=email]:focus),
form.uxAbleForms .uxAbleForms--field:has(input[type=email]:focus),
form.uxAbleForms div.inputLabelGroup:has(input[type=tel]:focus),
form.uxAbleForms .uxAbleForms--field:has(input[type=tel]:focus) {
  outline: 2px var(--umgBrandBlue-light) solid;
  outline-offset: 1px;
  background-color: white;
}
form.uxAbleForms .inputLabelGroup input[type=text]:focus,
form.uxAbleForms .uxAbleForms--field input[type=text]:focus,
form.uxAbleForms .inputLabelGroup input[type=email]:focus,
form.uxAbleForms .uxAbleForms--field input[type=email]:focus,
form.uxAbleForms .inputLabelGroup input[type=tel]:focus,
form.uxAbleForms .uxAbleForms--field input[type=tel]:focus {
  outline: none;
  background-color: var(--umgBrandBlue-extraLight);
}
form.uxAbleForms .inputLabelGroup input::placeholder,
form.uxAbleForms .uxAbleForms--field input::placeholder {
  font-weight: 100;
  font-family: "Noto Sans", Helvetica, sans-serif;
  color: var(--umgDisabledText);
}

.exp-p-search-container {
  width: 100%;
  position: relative;
  padding: 2px 0.4rem 0.3rem;
  height: 30px;
}
.exp-p-search-container.icon-rotate .exp-p-search-icon:after {
  transform: rotate(180deg);
  transition: transform 0.15s;
}
.exp-p-search-container .exp-p-search-icon:after {
  font-family: "standard-icons";
  content: "\f111";
  position: absolute;
  font-size: 0.93rem;
  right: 4px;
  bottom: 0.5rem;
  font-style: normal;
  cursor: pointer;
  font-weight: bold;
}
.exp-p-search-container .exp-p-search-icon:focus {
  outline: none;
  background-color: #e0e0e0;
  box-shadow: 16px 2px 0 7px #5b9dd9;
  font-size: 2px;
}
.exp-p-search-container input {
  border: none;
  background: #dde6ef;
  height: auto;
  padding: 0 10px;
}
.exp-p-search-container input:focus {
  border: 1px solid gray;
}
.exp-p-search-container.has-suggestions .exp-p-suggestions ul {
  display: block;
  list-style-type: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: calc(100% + 3px);
  left: -10px;
  right: 0;
  background: white;
  z-index: 2;
  width: calc(100% + 20px);
}
.exp-p-search-container.has-suggestions .exp-p-suggestions ul * {
  user-select: none;
}
.exp-p-search-container.has-suggestions .exp-p-suggestions ul li {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  font-family: "Noto Sans Condensed", sans-serif;
  font-size: 1.75rem;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
}
.exp-p-search-container.has-suggestions .exp-p-suggestions ul li:hover, .exp-p-search-container.has-suggestions .exp-p-suggestions ul li.active {
  background-color: var(--umgBrandBlue-extraLight);
}
.exp-p-search-container.has-suggestions .exp-p-suggestions ul li strong {
  color: #005eb8;
}
.exp-p-search-container.has-suggestions .exp-p-suggestions ul li .suggestion-pills {
  display: flex;
}
.exp-p-search-container.has-suggestions .exp-p-suggestions ul li .suggestion-pills span,
.exp-p-search-container.has-suggestions .exp-p-suggestions ul li .suggestion-pills abbr {
  border-radius: 8px;
  padding: 0 1rem;
  font-size: 1.5rem;
  line-height: 1.5;
  font-family: "Noto Sans Condensed", sans-serif;
  font-weight: 600;
  color: white;
  display: flex;
  height: 22px;
}
.exp-p-search-container.has-suggestions .exp-p-suggestions ul li .suggestion-pills .suggestion-pill-ins {
  background-color: #005eb8;
  white-space: nowrap;
  margin-right: 1rem;
}

/*! Gray v1.6.0 (https://github.com/karlhorky/gray) | MIT */
.grayscale {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.grayscale.grayscale-fade {
  transition: filter 0.5s;
}

/* Webkit hack until filter is unprefixed */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .grayscale.grayscale-fade {
    -webkit-transition: -webkit-filter 0.5s;
    transition: -webkit-filter 0.5s;
  }
}
.grayscale.grayscale-off,
.grayscale.grayscale-fade:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

/* Background element */
.grayscale.grayscale-replaced {
  -webkit-filter: none;
  filter: none;
}

.grayscale.grayscale-replaced > svg {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 1;
}

.grayscale.grayscale-replaced.grayscale-off > svg,
.grayscale.grayscale-replaced.grayscale-fade:hover > svg {
  opacity: 0;
}

header {
  position: sticky;
  top: 0;
  z-index: 1030;
}
header nav #topNav a {
  color: rgba(0, 0, 0, 0.8);
}
header nav #topNav a:hover {
  color: #A32035;
}
header nav.topnav {
  z-index: 1030;
  padding: 0;
}
header nav a.btn.btn-outline-dark.phone-btn,
header nav .phone-btn {
  font-stretch: condensed;
  font-variant: common-ligatures tabular-nums;
}
header nav .top-ctas > .btn-group {
  max-width: 36rem;
}
header nav .top-ctas > .btn-group > .btn {
  font-size: 1.4rem;
  font-weight: 700;
}
@media (min-width: 768px) {
  header nav .top-ctas > .btn-group > .btn {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  header nav .top-ctas {
    position: fixed;
    right: 0;
    bottom: 1.2rem;
    left: calc(50% - 18rem);
    width: 100%;
  }
  header nav .top-ctas > .btn-group .btn {
    font-stretch: condensed;
    letter-spacing: 0.02em;
    line-height: 1.25;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  header nav .top-ctas > .btn-group .btn:first-child {
    border-top-left-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem;
  }
  header nav .top-ctas > .btn-group .btn:last-child {
    border-top-right-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }
}
header nav .btn-outline-dark {
  background-color: #fff;
}
header nav .btn-outline-dark:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  header nav .navbar-toggler.collapsed + .top-ctas,
  header nav .navbar-collapse.collapsing + .top-ctas,
  header nav .navbar-collapse.show + .top-ctas {
    position: fixed;
    top: 0;
    right: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  header nav button.navbar-toggler {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border-color: #b8b8b8;
  }
}
header .header {
  background: #fff;
  padding: 0.4rem 0;
  position: relative;
}
header .header__container {
  padding: 0 1.5rem;
  align-items: center;
  justify-content: space-between;
}
header .header__container::after, header .header__container::before {
  display: none;
}
@media (max-width: 767.98px) {
  header .header.partner_portal_header .header__container {
    justify-content: center;
  }
}
header .header__logo {
  display: inline-block;
  width: 17.5rem;
}
header .header__logo img {
  height: auto;
  width: 100%;
}
header .navbar ul li a:not(.btn) {
  display: inline-block;
  width: 100%;
  height: calc(100% - 2px);
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
  line-height: 2;
}
header .navbar ul li a:not(.btn):hover, header .navbar ul li a:not(.btn).show {
  color: #A32035;
}
@media (min-width: 768px) {
  header .header {
    padding: 1.6rem 4rem;
  }
  header .header__logo {
    width: 22rem;
  }
}
header .nav__overview-image img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  max-height: 13.5rem !important;
  -webkit-clip-path: polygon(100% 0, 0 0, 0 86%, 50% 100%, 100% 86%);
  clip-path: polygon(100% 0, 0 0, 0 86%, 50% 100%, 100% 86%);
}
header .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
header form .form-floating {
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
header form.searchBar {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1200px) {
  header form.searchBar {
    max-width: 33rem;
    margin-left: auto;
    margin-right: 0;
  }
}

.tcpa_statement {
  font-size: 1.4rem;
  line-height: 1.6;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  color: #00182e;
}

.horizontal_form .form-floating > label {
  left: unset;
}
.horizontal_form .tabs__content.active {
  display: inline-table;
}

.breadcrumb {
  margin-top: 1rem;
}
.breadcrumb a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.4rem;
}
.breadcrumb a:hover {
  color: #A32035;
}
.breadcrumb li:not(:first-child):before {
  content: "| ";
  padding: 0 0.5rem;
  color: #A32035;
}

@counter-style umass-carets {
  system: cyclic;
  symbols: "\f108";
  speak-as: bullets;
  suffix: " ";
}
.admission-req__additional-container ul:not(.checks):not(:has(> li[role=presentation])),
.admission-req__option-container ul:not(.checks):not(:has(> li[role=presentation])),
.section__content ul:not(.checks):not(:has(> li[role=presentation])),
.section__description ul:not(.checks):not(:has(> li[role=presentation])),
.section__text ul:not(.checks):not(:has(> li[role=presentation])) {
  list-style-type: disc;
}
@supports (list-style: symbols("\f108")) {
  .admission-req__additional-container ul:not(.checks):not(:has(> li[role=presentation])),
  .admission-req__option-container ul:not(.checks):not(:has(> li[role=presentation])),
  .section__content ul:not(.checks):not(:has(> li[role=presentation])),
  .section__description ul:not(.checks):not(:has(> li[role=presentation])),
  .section__text ul:not(.checks):not(:has(> li[role=presentation])) {
    list-style: umass-carets;
  }
  .admission-req__additional-container ul:not(.checks):not(:has(> li[role=presentation])) > li::marker,
  .admission-req__option-container ul:not(.checks):not(:has(> li[role=presentation])) > li::marker,
  .section__content ul:not(.checks):not(:has(> li[role=presentation])) > li::marker,
  .section__description ul:not(.checks):not(:has(> li[role=presentation])) > li::marker,
  .section__text ul:not(.checks):not(:has(> li[role=presentation])) > li::marker {
    color: #005EB8;
    font-family: "standard-icons";
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

#ot-sdk-btn-floating {
  bottom: 5.2rem !important;
}

.chevron-out-bottom {
  clip-path: polygon(100% 0, 0 0, 0 86%, 50% 100%, 100% 86%);
}

.chevron-out-top {
  clip-path: polygon(0% 14%, 50% 0%, 100% 14%, 100% 100%, 0% 100%);
}

.chevron-out-right {
  clip-path: polygon(0% 0%, 86% 0%, 100% 50%, 86% 100%, 0% 100%);
}

.chevron-out-left {
  clip-path: polygon(14% 0%, 100% 0%, 100% 100%, 14% 100%, 0% 50%);
}

.chevron-in-bottom {
  clip-path: polygon(100% 0, 0 0, 0 100%, 50% 86%, 100% 100%);
}

.chevron-in-top {
  clip-path: polygon(0% 14%, 50% 0%, 100% 14%, 100% 100%, 0% 100%);
}

.chevron-in-right {
  clip-path: polygon(0% 0%, 100% 0%, 86% 50%, 100% 100%, 0% 100%);
}

.chevron-in-left {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 14% 50%);
}

.schools__image {
  clip-path: polygon(100% 0, 0 0, 0 86%, 50% 100%, 100% 86%);
}

.testimonials {
  background-size: auto;
}
.testimonials blockquote {
  font-style: italic;
  line-height: 3.3rem;
  position: relative;
}
.testimonials blockquote:after, .testimonials blockquote:before {
  color: #005eb8;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 6rem;
  font-style: normal;
  font-weight: 700;
}
.testimonials blockquote:before {
  content: open-quote;
  display: block;
  position: absolute;
  left: -5.5rem;
}
.testimonials blockquote:after {
  content: close-quote;
  display: block;
  margin: 0;
  position: relative;
  right: -100%;
  width: 33px;
  height: 33px;
}

.carousel-control-next,
.carousel-control-prev {
  color: rgba(0, 0, 0, 0.8);
  width: auto;
}

.nav-tabs .nav-link {
  background-color: #dbdbdb;
  color: #383838;
  font-size: 1.3rem;
  margin-right: 1rem;
  line-height: 2;
  border-radius: 0.2rem 0.2rem 0 0;
  padding: 0.3rem 1.8rem;
}
.nav-tabs .nav-link.active {
  background-color: #A32035;
}
.nav-tabs .nav-link.active span {
  color: #fff;
}
.nav-tabs .nav-item a.active {
  background-color: #A32035;
  color: #fff;
}

.leftnav li,
.leftnav a:not(.icon) {
  position: relative;
}
.leftnav .nav__leftnav {
  display: block;
}
.leftnav .nav__leftnav li a,
.leftnav .nav__leftnav li button {
  color: rgba(0, 0, 0, 0.8);
}
.leftnav .nav__leftnav li.selected > a, .leftnav .nav__leftnav li.selected > button {
  color: #A32035;
}

.btn-link,
.btn-link-carat,
.btn-link-leave,
.fullbtn-link-carat,
.fullbtn-link-leave {
  color: rgba(0, 0, 0, 0.8);
  font-weight: 700;
  border-radius: 0;
  padding: 0;
  text-decoration: underline;
  text-decoration-color: #A32035;
  white-space: normal;
  text-align: left;
  vertical-align: baseline;
}

@media (max-width: 991.98px) {
  .container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
.admission-req__additional-container ul li,
.admission-req__option-container ul li,
.section__content ul li,
.section__description ul li,
.section__text ul li,
blockquote {
  margin-bottom: 1.2rem;
}

.degree-finder .badge {
  font-size: 1.6rem;
}

.degree-finder__result {
  box-shadow: none;
}
.degree-finder__result:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0 0.3rem 0.8rem;
}

.people-finder #btn_search {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin-right: 0.1rem;
}

[data-whatinput=keyboard] :focus:focus {
  border-color: #00f;
  outline: 0;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.07), 0 0 0.8rem rgba(0, 0, 255, 0.6);
}

.search-results__result:hover {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.57);
  text-decoration: none;
}

.section__preheader {
  color: #707070;
}

.columns__column .card__image,
.columns__column .card__image--transparent {
  flex: 1 0 auto;
  max-width: 50%;
  margin-top: 0.4rem;
  margin-right: 3rem;
  position: relative;
  width: 23rem;
  height: 16rem;
}

.location-finder__result .card__image {
  height: auto;
  align-self: auto;
}

div.ivy-main.ivy-pos-bottom-right.ivy-t-circle .ivy-circle {
  bottom: 5.2rem;
  right: 1rem;
}

.news-detail__title h1, .news-detail__title .h1 {
  margin-bottom: 3rem;
}

.scrollspy-dynamic {
  overflow-y: auto;
  overflow-x: hidden;
  height: 75vh;
  position: relative;
  padding-top: 13rem;
}
.scrollspy-dynamic h2, .scrollspy-dynamic .h2,
.scrollspy-dynamic h3,
.scrollspy-dynamic .h3 {
  scroll-margin-top: 13rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 7rem;
  height: 4rem;
}
@media (max-width: 767.98px) {
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 4rem;
    height: 6rem;
  }
}

.carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23000" viewBox="0 0 16 16"%3E%3Cpath fill="%23000" d="M11.354 1.646a.5.5 0 0 0-.708 0L4.646 7.646a.5.5 0 0 0 0 .708l6 6a.5.5 0 0 0 .708-.708L5.707 8l5.647-5.646a.5.5 0 0 0 0-.708z"/%3E%3C/svg%3E');
}

.carousel-control-next-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23000" viewBox="0 0 16 16"%3E%3Cpath fill="%23000" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/%3E%3C/svg%3E');
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}