.partnerships {

    &__template {
        display: none;
    }

    &__name {
        display: none;
    }

    &__results {
        padding: 30px 0;
    }

    .tabs__wrapper {
        margin-top: 60px;
        @include media-breakpoint-down(md) {
            margin-top: 30px;
        }
    }

    .tabs__container {
        padding-top: 32px;
    }

    .tabs__content {
        margin: -5px;
    }

    &__result {
        padding: 5px;
        &-image {
            border: 1px solid $table-border-color;
            padding: 0;
            position: relative;

            img {
                height: auto;
                max-width: 65%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
            }

            &:after {
              content: "";
              display: block;
              padding-bottom: 100%;
            }
        }
    }

    &__cta {
        text-align: center;
        margin-top: 32px;
    }
}