.card a {
    display: block;
    text-decoration: none
}

.card h3 {
    text-decoration: none;
}

.card-img-top {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
}

.placeholderb {
    object-fit: contain;
}

.card-body .card-text {
    color: #383838;

}