.video-gallery {
    &__row {
        margin: 0 -8px;
        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: row;
        }
    }
    &__container {
        margin-top: 30px;
    }
    &__video-data {
        top: 0;
        left: -10000px;
        margin-top: 0;
        opacity: 0;
        position: absolute;
        transition: opacity 600ms;
        visibility: hidden;
        &.active {
            top: auto;
            left: auto;
            opacity: 1;
            position: relative;
            visibility: inherit;
        }
        &.last {
            top: auto;
            left: 0;
            opacity: 0;
            position: absolute;
            visibility: inherit;
        }
    }
    &__tabs {
        position: relative;
        margin-top: 30px;
        overflow: hidden;
    }
    &__player {
        &-container {
            padding-left: 8px;
            padding-right: 8px;
        }
        &-wrapper {
            padding-top: 56.25%;
            position: relative;
            @include media-breakpoint-down(md) {
                margin: 0 0 30px;
            }
            iframe {
                display: none;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
            }
            &.playing {
                iframe {
                    display: block;
                }
            }
        }
        &-name {
            margin: 24px 0;
            * {
                font-weight: normal;
                font-size: $font-size-columbian;
                line-height: $line-height-base;
            }
        }
        &-play {
            background-color: #000;
            display: flex;
            align-items: center;
            left: 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            img {
                height: auto;
                width: 100%;
            }
            .playing & {
                display: none;
            }
        }
    }
    &__thumb {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        padding: 8px 0;
        border-bottom: 1px solid $table-border-color;
        width: 100%;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
        &:focus {
            outline: blue;
        }
        &-duration {
            color: $umassglobal-white;
            font-weight: bold;
            font-size: 1rem;
            position: absolute;
            line-height: $line-height-extra;
            padding: 0 4px;
            right: 0;
            bottom: 0;
            background-color: #000;
        }
        &-image {
            width: 38%;
            margin-right: 5%;
            position: relative;
            font-size: $font-size-smallPica;
            font-weight: bold;
            text-transform: uppercase;
            color: $umassglobal-white;
            text-align: center;
            flex: 1 1 36%;
            img {
                height: auto;
                width: 100%;
            }
            .active & {
                &:before {
                    background-color: rgba(0, 0, 0, .5);
                    content: 'Now Playing';
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    left: auto;
                    top: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 3;
                }
            }
        }
        &-name {
            text-align: left;
            flex: 1 1 59%;
            * {
                font-weight: normal;
                font-size: $font-size-smallPica;
                line-height: $line-height-minimal;
                margin: 0;
            }
            .active & {
                * {
                    font-weight: bold;
                }
            }
        }
        &-wrapper {
            &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: rgba(0, 0, 0, .5);
                -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
            }
            padding-left: 8px;
            padding-right: 8px;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            @include media-breakpoint-down(md) {
                height: 340px;
                margin: 50px 0 0;
                padding: 0;

            }
        }
    }
}