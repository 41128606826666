.featured-events {
    overflow: hidden;
    &__cta {
        margin-top: 30px;
        text-align: center;
    }
    .card__eventlisting {
        @include media-breakpoint-down(md) {
            &:first-child {
                padding-top: 20px;
            }
        }
        &:last-child {
            border-bottom: 1px solid $umassglobal-lightest-gray;
        }
    }
}

.featured-news {
    &__cta {
        margin-top: 30px;
        text-align: center;
    }
    .row {
        margin: -8px;
    }
    .col-sm-5,
    .col-sm-7 {
        padding: 0 8px;
    }
    &__container {
        margin-top: 30px;
        @include media-breakpoint-down(md) {
            margin-top: 0;
        }
    }
    &__label {
        background-color: $umassglobal-lightest-gray;
        display: inline-block;
        padding: 0 6px;
        font-size: $font-size-longPrimer;
        font-weight: bold;
        letter-spacing: 0;
        * {
            @include subheading($umassglobal-primary-color, $font-size-tiny);
            line-height: 30px;
            margin: 0;
            text-decoration: none;
        }
    }
    &__main {
        display: block;
        padding: 8px 0;
        @include media-breakpoint-down(md) {
            padding: 12px 0 13px;
        }
        text-decoration: none;
        @include panel-hover-image;
        &-image {
            position: relative;
            .card__image {
                img {
                    height: 494px;
                    top: 0;
                    position: absolute;
                }
            }
        }
        .featured-news__label {
            position: absolute;
            left: 0;
            top: 0;
        }
        &-header {
            * {
                color: $umassglobal-white;
                font-weight: normal;
                text-decoration: none;
            }
        }
        &:hover &-header {
            * {
                color: $umassglobal-white;
                text-decoration: none;
            }
        }
        &:hover {
            text-decoration: none;
            .featured-news__label {
                * {
                    text-decoration: none;
                }
            }
        }
        &-overlay {
            background-image: linear-gradient( to top, rgba(0, 0, 0, 0.8) 30%, rgba(0, 0, 0, 0.1) 55%);
            color: $umassglobal-white;
            display: flex;
            font-size: $font-size-small;
            line-height: $line-height-small;
            align-items: flex-end;
            min-height: 494px;
            height: 100%;
            padding: 30px 40px;
            position: absolute;
            top: 0;
            width: 100%;
            @include media-breakpoint-down(md) {
                background-image: linear-gradient( to top, rgba(0, 0, 0, 0.8) 40%, rgba(0, 0, 0, 0.1) 100%);
                // min-height: 0;
                position: relative;
                padding: 15px;
            }
        }
    }
    &__sub {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        padding: 8px 0;
        text-decoration: none;
        @include panel-hover-image;
        @include media-breakpoint-down(md) {
            padding: 12px 0 13px;
        }
        &:hover {
            text-decoration: none;
            .featured-news__sub {
                &-image {
                    box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
                }
                &-title {
                    color: $umassglobal-primary-color;
                    text-decoration: underline;
                }
                .card__image {
                    img {
                        height: 154px;
                    }
                }
            }
            .featured-news__label {
                * {
                    text-decoration: none;
                }
            }
        }
        &-image {
            flex: 0 0 auto;
            margin-right: 20px;
            width: 56%;
            max-width: 56%;
            @include media-breakpoint-up(xl) {
                max-width: 50%;
            }
            @include media-breakpoint-down(md) {
                width: 135px;
            }
            &.card__image {
                img {
                    height: 90px;
                    object-fit: cover;
                    @include media-breakpoint-up(md) {
                        height: 150px;
                    }
                    @include media-breakpoint-up(lg) {
                        height: 152px;
                    }
                }
            }
        }
        .featured-news__label {
            margin-bottom: 12px;
        }
        &-title {
            transition: color $transition-hover-speed;
            font-size: $font-size-large;
            line-height: 23px;
            text-decoration: none;
            color: $umassglobal-text-gray;
        }
    }
}

.news-detail {
    .container {
        padding-top: 28px;
    }
    &__tags {
        font-size: 0;
        list-style: none;
        margin: 0;
        padding: 0;
        >li {
            display: inline-block;
            margin-right: 12px;
            margin-bottom: 12px;
            &:last-child {
                margin-right: 0;
            }
            @include media-breakpoint-down(md) {
                margin-right: 8px;
                margin-bottom: 8px;
                &:last-child {
                    margin-right: 0;
                }
            }
            a {
                background-color: $umassglobal-off-white-color;
                color: $umassglobal-text-gray;
                display: block;
                font-size: $font-size-small;
                text-decoration: none;
                line-height: 35px;
                padding: 0 10px;
                &:hover {
                    color: $link-hover-color;
                }
                @include media-breakpoint-down(md) {
                    font-size: 1rem;
                    text-decoration: none;
                    line-height: 2.2;
                    padding: 0 8px;
                }
            }
        }
    }
    &__header {
        position: relative;
        &.event-detail__header {
            @include media-breakpoint-up(md) {
                align-items: center;
                display: flex;
                flex-direction: row;
                .news-detail__title-wrapper {
                    flex: 1 1 520px;
                    margin-right: 100px;
                }
                .news-detail__headercta {
                    flex: 1 1 200px;
                    margin: 30px 0;
                    .btn {
                        width: 100%;
                    }
                }
                .news-detail__title * {
                    margin-bottom: 0;
                }
            }
        }
    }
    &__headercta {
        .btn {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }
    &__type {
        color: $umassglobal-primary-color !important;
        font-size: $font-size-smallPica;
        line-height: 1.4;
        letter-spacing: 0;
        &.subheading {
            color: $umassglobal-primary-color;
            letter-spacing: 0;
        }
    }
    &__date-author {
        font-size: $font-size-small;
        line-height: $line-height-small;
        color: $umassglobal-darker-gray;
    }
    &__related {
        &.callout.columns__column {
            align-items: flex-start;
            padding: 30px;
            margin: 30px 0 60px;
            position: relative;
            @include media-breakpoint-down(md) {
                padding: 25px;
            }
            .columns__content {
                padding: 0 0 0 40px;
                @include media-breakpoint-down(md) {
                    padding: 25px 0;
                }
            }
            .subheading {
                margin: 0 0 4px;
            }
            @include media-breakpoint-down(md) {
                &:after {
                    content: "";
                    display: none;
                }
            }
        }
        &-title {
            * {
                font-size: $font-size-h3;
                font-weight: normal;
                line-height: $line-height-h3;
                margin-top: 0;
                margin-bottom: 12px;
            }
        }
    }
    &__audio {
        &-wrapper {
            margin: 24px 0 12px;
        }
    }
    &__title {
        * {
            color: $umassglobal-text-gray;
            font-family: $font-family-sans-serif;
            font-size: $font-size-h2;
            line-height: 40px;
            margin-top: 0;
            margin-bottom: 30px;
            font-weight: normal;
            text-transform: none;
            @include media-breakpoint-down(md) {
                font-size: $font-size-columbian;
                line-height: 1.5;
            }
        }
    }
    &__content {
        margin-top: 30px;
        h2 {
            color: $umassglobal-text-gray;
            font-family: $font-family-sans-serif;
            font-size: $font-size-h3;
            font-weight: bold;
            line-height: $line-height-h3;
        }
    }
    &__print-link {
        @media print {
            display: none;
        }
        @include subheading($umassglobal-primary-color);
        .icon {
            text-decoration: none;
            color: $umassglobal-secondary-color;
        }
        &:hover {
            text-decoration: none;
        }
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}

div.col-md-9.article {
    img {
        display: block;
        max-width: 100%;
        height: auto;
    }
}