.related-information {

    .bn-carousel {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        position: relative;
    }

    .card,
    .card__event,
    .card__program {
        display: none;
        margin-right: auto;
    }

    .card h3,
    .card__event h3,
    .card__program h3 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .card.active,
    .card__event.active,
    .card__program.active {
        display: block;
    }

    .carousel-control-prev .fas,
    .carousel-control-next .fas {
        font-size: 4rem;
        color: $umassglobal-text-gray;
        background-color: red!important;
    }

    .carousel-control-prev {
        left: -5rem;
    }

    .carousel-control-next {
        right: -5rem;
    }

    @media screen and (min-width: 0px) and (max-width: 400px) {

        .card,
        .card__event,
        .card__program {
            height: 620px;
        }
    }

    @media screen and (min-width: 400px) and (max-width: 768px) {

        .card,
        .card__event,
        .card__program {
            height: 600px;
        }
    }

    @media screen and (min-width: 0px) and (max-width: 768px) {

        .card,
        .card__event,
        .card__program {
            width: 100%;
        }

        .carousel-control-prev {
            left: -3rem;
        }

        .carousel-control-next {
            right: -3rem;
        }

        .bn-carousel {
            width: 80%;
        }
    }

    @media screen and (min-width: 769px) and (max-width: 992px) {

        .card,
        .card__event,
        .card__program {
            width: 45%;
            height: 475px;
        }

        .bn-carousel {
            width: 85%;
        }
    }

    @media screen and (min-width: 993px) {

        .card,
        .card__event,
        .card__program {
            height: 505px;
            width: 32.5%;
        }

        .bn-carousel {
            width: 100%;
        }
    }
}