.teaser {
    background-position: 50% 0;
    background-repeat: repeat-y;
    .section__description {
        margin-bottom: 50px;
    }
    &__description {
        text-align: center;
        max-width: 780px;
        margin: 0 auto 45px;
    }
    &__thumb {
        padding-bottom: 10px;
        padding-top: 10px;
    }
    &__image {
        color: $umassglobal-text-gray;
        display: block;
        position: relative;
        text-decoration: none;
        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
            transition: all 0.5s ease;
            @include media-breakpoint-up(lg) {
                height: 370px;
            }
        }
        &-wrapper {
            @include panel-hover;
            position: relative;
            max-height: 350px;
            overflow: hidden;
            img{
                width: 100%;
                max-height: 350px;
                object-fit: cover; 
                max-width: 100%;
            }
        }
    }
    &__text {
        text-decoration: none;
    }
    &__link {
        bottom: 0;
        content: " ";
        border-bottom: 800px solid $umassglobal-tertiary-color;
        border-right: 800px solid transparent;
        height: 0;
        right: 30px;
        opacity: .5;
        position: absolute;
        width: 0;
    }
    &__title {
        * {
            font-size: $font-size-columbian;
            font-weight: 700;
            line-height: $line-height-base;
            margin-top: 32px;
        }
    }
    &__copy {
        color: $umassglobal-darker-gray;
        font-size: $font-size-pica;
        line-height: $line-height-base;
        margin-bottom: 20px;
        text-decoration: none;
    }
}

a.teaser__text,
a.teaser__image {
    &:hover {
        text-decoration: none;
        .teaser__title * {
            color: $umassglobal-primary-color;
            text-decoration: underline;
        }
        .teaser__copy * {
            color: $umassglobal-gray;
        }
    }
}