// FIXME: Do no use px or colors here
// TODO: Rehuse BS5 here
// Start Exposed Leads ***

:root {
  --exp-active-tab-bg-color: #005eb8;
  --exp-active-tab-border-color: #005eb8;

  /* ### Umass New Colors ### */
  --umgWarningRed-dark: hsla(0, 95%, 22%, 1);
  --umgWarningRed: hsla(0, 95%, 45%, 1);
  --umgWarningRed-light: hsla(0, 95%, 75%, 1);
  --umgWarningRed-extraLight: hsla(0, 95%, 95%, 1);
  --umgSuccessGreen-dark: hsla(121, 100%, 13%, 1);
  --umgSuccessGreen: hsla(121, 100%, 26%, 1);
  --umgSuccessGreen-light: hsla(121, 100%, 75%, 1);
  --umgSuccessGreen-extraLight: hsla(121, 100%, 95%, 1);
  --umgBrandBlue-dark: hsla(209, 100%, 18%, 1);
  --umgBrandBlue: hsla(209, 100%, 36%, 1);
  --umgBrandBlue-light: hsla(209, 100%, 75%, 1);
  --umgBrandBlue-extraLight: hsla(209, 100%, 95%, 1);
  --umgFormAreaBackground: rgba(240, 240, 240, 0.8);
  --umgDisabledText: hsla(0, 0%, 33%, 1);
  --umgUserEnteredText: hsla(0, 0%, 18%, 1);

  /* ### Icons ### */

  --infoIcon: '\f410';
  --errorIcon: '\f5e7';
  --successIcon: '\f26b';
}

//Exposed Leads

.exp-leads {
  position: relative;
  .iti {
    width: 100%;
    input {
      height: auto;
    }
  }
  .background-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }

  .trapezoid-left {
    position: absolute;
    right: 0;
    background-color: rgba(0, 94, 184, 0.95);
    height: 734px;
    z-index: -1;
  }

  .container {
    position: relative;
    background-color: transparent;
    z-index: 1;
    max-width: 100%;
    padding: 0px !important;
  }

  .exp-leads-container {
    padding: 0;
    width: 100%;
    max-width: 100%;
  }

  .left-content,
  .right-content {
    display: flex;
    flex-direction: column;
  }

  .left-content {
    justify-content: space-between;
    padding-top: 80px;
    padding-bottom: 80px;
    margin-right: 60px;
    padding-right: 0;
    padding-left: 80px;
    height: 100%;
  }
  .right-content {
    padding-top: 175px;
    padding-bottom: 0px;
    padding-right: 0px;
    padding-left: 0;
  }

  .exp-leads-heading {
    line-height: 48px;
    margin-top: 0;
    font-family: 'Noto Sans', sans-serif;
    font-size: 36px;
    font-weight: 700;
  }

  /* When description does not exists */
  .exp-leads-heading-expanded {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 70px;
    line-height: 110%;
  }

  .exp-leads-paragraph {
    font-family: 'Noto Sans', sans-serif;
    margin: 0;
    font-size: 17px;
    line-height: 27px;
  }

  .logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .logo {
    width: 92px;
    height: auto;
    object-fit: contain;
  }

  .exp-leads-nav-tabs {
    display: flex;
    justify-content: center;
    border: none;
    user-select: none;
    pointer-events: none;
    li {
      user-select: none;
      pointer-events: none;
      a {
        user-select: none;
        pointer-events: none;
      }
    }
  }

  .nav-item {
    display: flex;
    align-items: center;
  }

  .nav-item:not(:last-child) {
    margin-right: 44px;
  }

  .nav-item .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    padding: 0;
    box-sizing: content-box;
    border-radius: 50%;
    border: 3px solid #d9d9d9;
    border-style: solid;
    background: transparent;
    text-align: center;
    pointer-events: none;
  }

  .nav-item .nav-link.active {
    background: var(--exp-active-tab-bg-color);
    border-color: var(--exp-active-tab-border-color);
  }

  .exp-leads-select {
    margin-top: 16px;
    height: 56px;
    border-radius: 0%;
    width: 100%;
  }

  .exp-leads-select:first-child {
    margin-top: 40px;
  }

  .exp-leads-input {
    width: 100%;
    height: 56px;
    margin-top: 16px;
    border: none;
  }

  .exp-leads-input:first-child {
    margin-top: 40px;
  }

  .button-container {
    margin-top: 46px;
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  #exp-continue-btn {
    margin-left: auto;
    background-color: transparent;
  }

  .exp-leads-btn {
    border-radius: 0;
    border-style: none;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 16px;
    padding-left: 16px;
    font-size: 12px;
    height: 36px;
    min-width: auto;
    text-transform: capitalize;
    line-height: normal;
  }

  .exp-leads-back-btn {
    border-radius: 50%;
    padding: 4px;
    text-align: center;
    width: 40px;
    height: 40px;
    background-color: #333333;
    border: none;
    color: #fff !important;
    min-width: auto;
  }

  #tab1,
  #tab2 {
    margin-bottom: 8px;
  }

  .exp-leads-arrow {
    margin-left: 12px;
  }
  //extra large?
  @media (max-width: 1600px) {
    .exp-leads-heading-expanded {
      font-size: calc(20px + 3.6vw);
    }
  }
  //desktop
  @media (max-width: 1199px) {
  }
  @media (min-width: 992px) {
    .right-content {
      padding-right: 80px;
      padding-bottom: 55px;
      padding-top: 78px;
    }
  }

  //small laptops
  @media (max-width: 991px) {
    .exp-leads-row {
      flex-direction: column-reverse;
      height: auto;
    }

    .exp-leads-heading {
      margin-bottom: 40px;
    }

    .left-content,
    .right-content {
      height: auto;
      padding: 40px;
    }

    .left-content {
      margin-right: 0;
    }
  }
  // tablet
  @media (max-width: 767px) {
    .left-content,
    .right-content {
      padding: 40px;
    }
    .exp-leads-heading {
      font-size: calc(2.9rem + 3.7vw);
    }
  }
  //large mobile
  @media (min-width: 480px) {
    .row {
      flex-direction: row;
      justify-content: space-around;
    }

    .logo {
      max-width: none;
    }
  }

  @media (max-width: 479px) {
    .left-content {
      padding: 5.5rem 0px 0px 0px;
    }
    .right-content {
      padding: 3rem 0px 5.5rem 0px;
    }
    .logos {
      flex-direction: column;
      align-items: center;
    }

    .logo {
      width: 105px;
      // align-self: center;
      margin: 15px auto;
    }
  }

  //small mobile
  @media (max-width: 320px) {
  }
}

form.uxAbleForms {
  margin: 10px;
  display: flex;
  flex-direction: column;
  flex: 1 1 90%;
  width: calc(100% - 20px);
  justify-content: space-between;

  > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
  }

  .fieldValidatorUIGroup,
  .uxAbleForms--inputContainer {
    display: flex;
    flex-direction: row;
    flex: 1 1 100%;
    font-size: 1.6rem;
    line-height: 1.25;
  }

  .validationIndicator,
  .validationMessage,
  .uxAbleForms--validationIndicator,
  .uxAbleForms--validationMessage {
    display: inline-block;
    font-family: bootstrap-icons; /*important*/
    font-style: normal;
    font-weight: normal; /*important*/
    font-variant: normal;
    text-transform: none;
    vertical-align: text-bottom;
    -webkit-font-smoothing: antialiased;
    font-size: 2rem;
    line-height: 1;
    transition: color 0.2s ease-in-out;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  select {
    font-family: 'Noto Sans', Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    box-sizing: border-box;
    font-size: 1.6rem;
    line-height: 1.25;
    vertical-align: -0.2rem;
    background-color: transparent;
    color: #333;
  }

  input[type='tel'] {
    padding-left: 52px;
  }

  .inputLabelGroup,
  .uxAbleForms--field {
    width: 100%;
    border-bottom: 3px solid var(--umgDisabledText);
    background-color: var(--umgFormAreaBackground);
    padding: 0.6rem 1rem 0.3rem 1rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
  }

  .inputLabelGroup.error,
  .uxAbleForms--field.errorState {
    border-bottom: 3px solid var(--umgWarningRed);
  }

  .validationMessage.error:before,
  .uxAbleForms--validationMessage:before {
    content: var(--infoIcon);
    color: var(--umgWarningRed);
  }

  .inputLabelGroup.error .validationIndicator:before,
  .uxAbleForms--field.errorState .uxAbleForms--validationIndicator:before {
    content: var(--errorIcon);
    color: var(--umgWarningRed);
  }

  .inputLabelGroup.success,
  .uxAbleForms--field.successState {
    border-bottom: 3px solid var(--umgSuccessGreen);
  }

  .inputLabelGroup.success .validationIndicator:before,
  .uxAbleForms--field.successState .uxAbleForms--validationIndicator:before {
    content: var(--successIcon);
    color: var(--umgSuccessGreen);
  }

  .fieldGroup,
  .uxAbleForms--fieldContainer {
    width: 100%;
    margin: 16px 0 0 0;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 3;
    flex-basis: 100%;
  }

  .inputLabelGroup label:not(.btn),
  .uxAbleForms--field label:not(.btn),
  .uxAbleForms--field legend {
    width: 100%;
    font-size: 1.3rem;
    line-height: 1.2;
    font-family: 'Noto Sans', Helvetica, sans-serif;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 0.03rem;
    padding-bottom: 0.3rem;
    font-style: normal;
    font-weight: 400;
    color: var(--umgBrandBlue-dark) !important;
    flex: 1 1 100%;
  }

  .uxAbleForms--field abbr {
    text-decoration: none;
  }

  div.errorMessageContainer,
  .uxAbleForms--infoContainer {
    font-size: 1.6rem;
    line-height: 1.4;
    flex-direction: row;
    width: 100%;
    padding: 0.4rem 1rem 0.4rem 1rem;
    margin: 0 0 0 0;
    flex-shrink: 1;
    flex-grow: 0;
    flex-basis: 100%;
    background: var(--umgWarningRed-extraLight);
    display: none;
  }

  .inputLabelGroup.error + div.errorMessageContainer,
  .uxAbleForms--field.errorState + .uxAbleForms--infoContainer {
    display: flex;
  }

  .uxAbleForms--infoContainer p {
    width: 100%;
    font-size: 1.5rem;
    line-height: 1.5;
    font-stretch: semi-condensed;
    font-family: 'Noto Sans', Helvetica, sans-serif;
    display: inline-block;
    padding: 0 0 0 1rem;
    font-style: normal;
    font-weight: 500;
    color: black;
    flex: 1 1 100%;
  }

  div.inputLabelGroup:has(input[type='text']:focus),
  .uxAbleForms--field:has(input[type='text']:focus),
  div.inputLabelGroup:has(input[type='email']:focus),
  .uxAbleForms--field:has(input[type='email']:focus),
  div.inputLabelGroup:has(input[type='tel']:focus),
  .uxAbleForms--field:has(input[type='tel']:focus) {
    outline: 2px var(--umgBrandBlue-light) solid;
    outline-offset: 1px;
    background-color: white;
  }

  .inputLabelGroup input[type='text']:focus,
  .uxAbleForms--field input[type='text']:focus,
  .inputLabelGroup input[type='email']:focus,
  .uxAbleForms--field input[type='email']:focus,
  .inputLabelGroup input[type='tel']:focus,
  .uxAbleForms--field input[type='tel']:focus {
    outline: none;
    background-color: var(--umgBrandBlue-extraLight);
  }

  .inputLabelGroup input::placeholder,
  .uxAbleForms--field input::placeholder {
    font-weight: 100;
    font-family: 'Noto Sans', Helvetica, sans-serif;
    color: var(--umgDisabledText);
  }
}

// Exp Leads Program Autocomplete
.exp-p-search-container {
  width: 100%;
  position: relative;
  padding: 2px 0.4rem 0.3rem;
  height: 30px;

  &.icon-rotate .exp-p-search-icon:after {
    transform: rotate(180deg);
    transition: transform 0.15s;
  }

  .exp-p-search-icon:after {
    font-family: 'standard-icons';
    content: '\f111';
    position: absolute;
    font-size: 0.93rem;
    right: 4px;
    bottom: 0.5rem;
    font-style: normal;
    cursor: pointer;
    font-weight: bold;
  }

  .exp-p-search-icon:focus {
    outline: none;
    background-color: #e0e0e0;
    box-shadow: 16px 2px 0 7px #5b9dd9;
    font-size: 2px;
  }

  input {
    border: none;
    background: #dde6ef;
    height: auto;
    padding: 0 10px;
    &:focus {
      border: 1px solid gray;
    }
  }

  &.has-suggestions .exp-p-suggestions ul {
    display: block;
    list-style-type: none;
    padding: 0;
    margin: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: calc(100% + 3px);
    left: -10px;
    right: 0;
    background: white;
    z-index: 2;
    width: calc(100% + 20px);

    * {
      user-select: none;
    }

    li {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 10px;
      font-family: 'Noto Sans Condensed', sans-serif;
      font-size: 1.75rem;
      cursor: pointer;
      background: rgba(255, 255, 255, 0.2);
      &:hover,
      &.active {
        background-color: var(--umgBrandBlue-extraLight);
      }
      strong {
        color: #005eb8;
      }
      .suggestion-pills {
        display: flex;
        span,
        abbr {
          border-radius: 8px;
          padding: 0 1rem;
          font-size: 1.5rem;
          line-height: 1.5;
          font-family: 'Noto Sans Condensed', sans-serif;
          font-weight: 600;
          color: white;
          display: flex;
          height: 22px;
        }
        .suggestion-pill-ins {
          background-color: #005eb8;
          white-space: nowrap;
          margin-right: 1rem;
        }
      }
    }
  }
}

// FIXME: Do no use px or colors here
// TODO: Rehuse BS5 here
// End Exposed Leads ***
