//TODO: Please add form-select classes to the select type fields on the degree finder template.

.degree-finder {
    //background: $prim-bg url('/images/pattern_back_left--transparent.svg') 50% 100%/100% auto no-repeat;
    @include media-breakpoint-down(md) {
        background-size: initial;
        background-position: 0 100%;
    }
    .container {
        max-width: 1005px;
        @include media-breakpoint-up(lg) {
            padding: 0 30px;
        }
    }
    &__loading {
        opacity: 0;
        position: absolute;
        width: 100%;
        .loading__wrapper {
            display: none;
        }
        &.fadeout {
            transition: opacity 600ms;
            opacity: 0;
        }
        &.fadein {
            transition: opacity 600ms;
            opacity: 1;
            .loading__wrapper {
                display: block;
            }
        }
    }
    &__notfound {
        display: none;
        max-width: 750px;
        margin-bottom: 50px;
        hr {
            margin: 45px 0;
        }
        .degree-finder__header {
            * {
                font-size: 3.2rem;
                font-weight: normal;
                line-height: 1;
                margin-bottom: 50px;
                .searchterm {
                    font-weight: bold;
                }
            }
        }
        opacity: 0;
        position: absolute;
        transform: translateY(30px);
        &.fadeout {
            transition: transform $transition-animate, opacity 600ms;
            opacity: 0;
            transform: translateY(-30px);
        }
        &.fadein {
            display: block;
            transition: transform $transition-animate, opacity 600ms;
            opacity: 1;
            position: relative;
            transform: translateY(0);
        }
    }
    &__header {
        &-container.container {
            padding: 50px 30px 0;
            @include media-breakpoint-down(md) {
                padding: 50px 25px 0;
            }
        }
    }
    &__mobilebutton {
        background-color: $umassglobal-darker-gray;
        color: $umassglobal-white;
        display: none;
        @include media-breakpoint-down(md) {
            display: block;
            position: absolute;
            width: 100%;
        }
    }
    &__filter {
        .select2-container {
            width: 100%;
        }
        @include media-breakpoint-down(md) {
            margin: 15px 0;
            display: none;
            .open & {
                display: block;
            }
        }
        .nav-tabs {
            display: inline-block;
        }
        @include media-breakpoint-up(md) {
            display: inline-block;
            margin-left: 40px;
            &:first-child {
                margin-left: 0;
            }
        }
        @include media-breakpoint-only(md) {
            margin: 10px 20px;
            &:first-child {
                margin: 10px 20px;
            }
        }
        &-container {
            border-bottom: 1px solid $table-border-color;
            margin-bottom: 30px;
            @include media-breakpoint-down(md) {
                border-bottom: none;
                margin-bottom: 5px;
                .container {
                    padding: 25px;
                }
            }
            @include media-breakpoint-only(lg) {
                .container {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
        &-wrapper {
            min-height: 45px;
            position: relative;
            width: 100%;
            margin: 0 auto;
            @include media-breakpoint-down(md) {
                position: relative;
            }
            .container {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        .nav-pills {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            padding: .375rem 0;
            li:not(:first-child) { 
                margin-left: calc(1em/2);
            }
            @include media-breakpoint-up(md) {
                >li {
                    flex: 1 0 auto;
                    text-align: center;
                }
            }
            @include media-breakpoint-down(md) {
                height: auto;
                justify-content: flex-start;
                flex-wrap: wrap;
                li {
                    margin-bottom: 10px;
                }
            }
            @include media-breakpoint-only(md) {
                justify-content: flex-start;
            }
        }
        legend,
        label {
            color: $umassglobal-dark-gray;
            display: block;
            margin-bottom: 12px;
            line-height: 25px;
            letter-spacing: 0;
            font-weight: normal;
        }
        @include media-breakpoint-down(md) {
            margin-right: 0;
        }
        input {
            font-weight: normal;
        }
    }
    &__filters {
        padding: 30px 0;
        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
            flex-direction: row;
        }
        @include media-breakpoint-only(md) {
            margin: 0 -20px;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: 15px 0;
        }
        @include media-breakpoint-down(md) {
            border: 1px solid $umassglobal-lightest-gray;
            background: $body-bg;
            visibility: hidden;
            left: -10000px;
            opacity: 0;
            padding: 15px;
            position: absolute;
            transition: opacity 0.5s;
            width: 100%;
            &.open {
                opacity: 1;
                visibility: visible;
                left: auto;
                position: relative;
            }
        }
    }
    &__name {
        font-size: 2rem;
        margin-top: 10px;
        margin-bottom: 5px;
        line-height: 1.35;
        text-decoration: none;
    }
    &__school {
        margin-top: 0;
        line-height: 30px;
        &.subheading {
            color: $umassglobal-gray;
            font-weight: normal;
            font-size: $font-size-smallPica;
            line-height: 1.6;
            span.icon {
                margin-left: 10px;
                text-decoration: none;
                display: none;
                transition: all 1s;
                @include media-breakpoint-down(md) {
                    display: inline-block;
                }
                &.open {
                    transition: all 0.5s;
                    transform: scale(1, -1);
                }
            }
        }
    }
    &__close {
        display: none;
        @include media-breakpoint-down(md) {
            display: inherit;
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
    &__results {
        opacity: 0;
        transform: translateY(30px);
        &.fadeout {
            transition: transform $transition-animate, opacity 600ms;
            opacity: 0;
            transform: translateY(-30px);
        }
        &.fadein {
            transition: transform $transition-animate, opacity 600ms;
            opacity: 1;
            transform: translateY(0);
        }
        &-container {
            @include media-breakpoint-down(md) {
                background-size: initial;
                background-position: 100% 0;
            }
            .container {
                padding: 0 30px 50px;
                position: relative;
                min-height: 250px;
                @include media-breakpoint-down(md) {
                    padding: 0 25px 50px;
                }
            }
            .degree-finder__results .location-finder__result .card__link:hover {
                box-shadow: 0 0 25px #5b6770;
            }
        }
    }
    &__result {
        text-decoration: none;
        .degree-finder__name {
            text-decoration: none;
        }
        &:hover {
            text-decoration: none;
            .degree-finder__name {
                color: $umassglobal-text-gray;
                text-decoration: none;
            }
        }
        background-color: $umassglobal-white;
        border: 1px solid transparent;
        border-radius: $panel-border-radius;
        @include panel-variant( $umassglobal-extraLight-gray,
        $umassglobal-darker-gray, $umassglobal-off-white-color, $umassglobal-extraLight-gray);
        display: flex;
        flex-direction: row;
        margin-bottom: 30px;
        position: relative;
        overflow: hidden;
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
        @include cards-bottom-colors(1px);
        &-content {
            border-right: 1px solid $umassglobal-lightest-gray;
            height: 100%;
            float: none;
            padding: 30px 30px 200px;
            margin-bottom: -170px;
            @include media-breakpoint-up(md) {
                flex: 1 1 75%;
            }
            @include media-breakpoint-down(md) {
                border-right: none;
                height: auto;
                margin-bottom: 0;
                padding: 24px;
            }
        }
    }
    &__description {
        color: $umassglobal-text-gray;
        display: block;
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
    &__ways {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        padding: 35px 35px 35px 40px;
        float: none;
        @include media-breakpoint-down(md) {
            flex-direction: row;
            flex-wrap: wrap;
            padding: 0 24px 24px;
            justify-content: space-between;
        }
        @include media-breakpoint-up(md) {
            flex: 1 1 25%;
        }
        &-value {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
            @include media-breakpoint-down(md) {
                flex: 1 1 auto;
                height: 31px;
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                .icon {
                    top: 0;
                    transform: none;
                }
            }
        }
    }
    &__template,
    &__loadmore {
        display: none;
    }
}

.degree-finder__result {
    @include panel-hover;
    cursor: pointer;
}


/*Degree finder for paid landing pages*/


.degree-finder__result.no-links {
    margin-bottom: 10px;
    box-shadow: 0 1px 3px 0 #ccc;
    position: relative;
    @include cards-paid-colors(5px);
    @media screen and (max-width: 767px) {
        border-left: none;
    }
    &:hover {
        border: none;
    }
    .degree-finder__result-content {
        margin: 0;
        padding: 0;
        display: block;
        @media screen and (max-width: 767px) {
            padding: 20px
        }
        >div:first-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            h3 {
                margin: 0;
                padding: 0 0 0 30px;
                transition: all 0.5s 0s ease;
            }
            .arrow {
                border-left: 1px solid $umassglobal-extraLight-gray;
                padding: 15px;
                color: $umassglobal-extraLight-gray;
                transition: all 0.5s 0s ease;
                @media screen and (max-width: 767px) {
                    border: none;
                    position: absolute;
                    right: 0;
                    top: -10px;
                }
                .icon__downarrow:after {
                    transition: all 0.5s 0s ease;
                }
            }
        }
        .content-bottom {
            display: none;
            >div {
                display: grid;
                grid-template-columns: 77% 23%;
                transition: all 0.5s 0s ease;
                @media screen and (max-width: 767px) {
                    grid-template-columns: 100%;
                }
                .degree-description {
                    padding: 0 30px 30px 30px;
                    transition: all 0.5s 0s ease;
                }
            }
            a {
                color: #005eb8;
                text-transform: uppercase;
                font-size: $font-size-longPrimer;
                font-weight: bold;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    background: #005eb8;
                    color: white;
                    text-align: center;
                    padding: 20px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    text-decoration: none;
                }
            }
        }
    }
    &.open {
        .degree-finder__description {
            display: block !important;
        }
        .degree-finder__result-content>div:first-child {
            display: grid;
            grid-template-columns: 77% 23%;
            transition: all 0.5s 0s ease;
            h3 {
                padding: 25px 0 0 30px;
                transition: all 0.5s 0s ease;
            }
        }
        .subheading {
            padding-top: 10px;
        }
        .degree-finder__ways {
            border-left: 1px solid $umassglobal-extraLight-gray;
            padding: 0 35px 35px 40px;
            transition: all 0.5s 0s ease;
            @media screen and (max-width: 767px) {
                border-left: none;
                border-top: 1px solid $umassglobal-extraLight-gray;
                padding: 30px 0 50px 0;
            }
        }
        .arrow {
            text-align: end;
            transition: all 0.5s 0s ease;
            .icon__downarrow:after {
                transition: all 0.5s 0s ease;
                transform: rotate(180deg);
            }
        }
    }
}