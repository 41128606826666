.event-finder {
    background-color: $body-bg;
    &.week-view {
        .day-view {
            display: none;
        }
    }
    &.day-view {
        .week-view {
            display: none;
        }
    }
    .container {
        max-width: none;
        @include media-breakpoint-up(lg) {
            padding: 0 30px;
        }
    }
    &__search-filter {
        label {
            text-indent: -10000px;
            @include media-breakpoint-down(md) {
                position: absolute;
            }
        }
        @include media-breakpoint-down(md) {
            margin-top: 25px;
        }
    }
    &__navbar {
        background-color: $umassglobal-gray;
        text-align: center;
        padding: 16px 0;
        @include media-breakpoint-down(md) {
            padding: 10px 0;
            display: flex;
        }
        &-date {
            display: inline-block;
            flex: 1 1 auto;
            * {
                color: $body-bg;
                margin: 0 60px;
                @include media-breakpoint-down(md) {
                    font-size: $font-size-bourgeois;
                    line-height: $line-height-extra;
                    margin: 0;
                }
            }
        }
        button {
            color: $umassglobal-white;
            font-size: $font-size-tiny;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 28px;
            white-space: nowrap;
            @include media-breakpoint-down(md) {
                font-size: .9rem;
                line-height: 2.6;
                padding: 0 10px;
                flex: 1 1 auto;
            }
            .icon {
                &:after {
                    vertical-align: baseline;
                }
            }
            &:hover {
                color: $umassglobal-tertiary-color;
            }
            &.disabled {
                opacity: .5;
                pointer-events: none;
            }
        }
    }
    &__calendar {
        border: 1px solid $umassglobal-lightest-gray;
        margin-top: 50px;
        @include media-breakpoint-only(md) {
            margin-top: 35px;
        }
        @include media-breakpoint-down(md) {
            margin-top: 0;
        }
        &-wrapper {
            .btn {
                width: 100%;
                margin-top: 30px;
                speak-as: literal-punctuation;
            }
            &--mobile {
                padding: 0 25px 25px;
            }
        }
        &-column {
            @include media-breakpoint-up(lg) {
                padding-left: 25px;
            }
        }
    }
    &__result {
        .card__eventlisting {
            &-image {
                width: 170px;
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
                &-fit {
                    margin-bottom: 10px;
                    @include media-breakpoint-up(md) {
                        display: block;
                    }
                    img {
                        width: 100%;
                    }
                }
            }
            &-when {
                width: 30%;
                @include media-breakpoint-down(md) {
                    width: auto;
                }
            }
            &-what {
                width: 69.9%;
                @include media-breakpoint-down(md) {
                    width: auto;
                }
            }
        }
    }
    .degree-finder {
        &__notfound {
            color: $umassglobal-gray;
            padding-top: 50px;
            .degree-finder__header {
                * {
                    color: $umassglobal-gray;
                    margin-top: 0;
                    .searchterm {
                        color: $umassglobal-text-gray;
                    }
                }
            }
            &-thisweek {
                margin-top: 60px;
            }
            hr {
                margin-bottom: 0;
            }
            ul {
                color: $umassglobal-text-gray;
            }
        }
        &__header {
            &-container.container {
                padding: 50px 30px 0;
                @include media-breakpoint-down(md) {
                    padding: 50px 25px 0;
                }
            }
        }
        &__filter {
            margin-left: 0px ;
            @include media-breakpoint-up(lg) {
                
                    margin-left: 10px;
                
            }
            @include media-breakpoint-down(md) {
                margin-left: 0px;
            }
            &-container {
                border: none;
                margin-bottom: 0;
                .container {
                    @include media-breakpoint-down(md) {
                        padding: 35px 25px 25px;
                    }
                }
            }
            &-wrapper {
                max-width: none;
            }
            .input-group-addon,
            .input-group-btn {
                width: 52px;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
                z-index: 4;
            }
            input {
                height: 52px;
                line-height: 38px;
                border: 1px solid $umassglobal-lightest-gray;
                border-radius: 4px;
                @include media-breakpoint-down(md) {
                    min-width: 0;
                }
            }
            button {
                height: 52px;
                line-height: 52px;
                min-width: 0;
                border: none;
                background: none;
                padding: 0;
                width: 52px;
                .icon {
                    color: $umassglobal-text-gray;
                    margin: 0;
                }
                &:hover {
                    .icon {
                        color: $umassglobal-primary-color;
                    }
                }
            }
        }
        &__mobilebutton {
            border-radius: 4px;
            text-transform: none;
            height: 50px;
            font-size: $font-size-pica;
            line-height: 1.75;
            padding: 11px 20px;
        }
        &__results-container {
            padding: 0 30px 50px;
            overflow: hidden;
            @include media-breakpoint-down(md) {
                padding: 0;
            }
            @include media-breakpoint-down(md) {
                padding: 0;
                .container {
                    padding: 0;
                }
            }
        }
        &__results {
            @include media-breakpoint-up(lg) {
                padding-right: 30px;
            }
        }
    }
    .form-group,
    .input-group {
        width: 100%;
        margin-bottom: 0;
        position: relative;
    }
    .input-group-addon {
        background-color: $body-bg;
        border-color: $umassglobal-text-gray;
    }
}