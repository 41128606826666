.leftnav {
    &__content {
        @include media-breakpoint-down(md) {
            margin: 0;
        }
    }
    &__mobile {
        display: none;
        @include media-breakpoint-down(md) {
            display: block;
            .leftnav__nav {
                display: block;
            }
        }
    }
    .container {
        padding-bottom: 50px;
        padding-top: 50px;
        @include media-breakpoint-up(xl) {
            .container {
                padding-bottom: 40px;
                padding-top: 40px;
            }
        }
    }
    &.section--short {
        .container {
            padding-bottom: 0;
            padding-top: 50px;
            @include media-breakpoint-up(xl) {
                .container {
                    padding-bottom: 0;
                    padding-top: 40px;
                }
            }
        }
    }
}

.nav {
    &__leftnav {
        @include media-breakpoint-down(md) {
            /*display: none;*/
            .leftnav__mobile & {
                display: block;
                content: "";
            }
        }
        @media only screen and (max-width: 767px) {}
        margin-top: 6px;
        .leftnav__mobile & {
            margin-top: 0;
        }
        a {
            text-decoration: none;
        }
        border-top: 2px solid $umassglobal-lightest-gray;
        li {
            border: 2px solid $umassglobal-lightest-gray;
            border-top: none;
            display: block;
            min-height: 48px;
            overflow: hidden;
            transition: max-height $transition-hover-speed ease-out;
            @media only screen and (max-width: 767px) {
                min-height: auto;
            }
            a {
                background-color: $umassglobal-off-white-color;
                display: block;
                font-size: $font-size-small;
                font-weight: 700;
                line-height: 16px;
                padding: 15px 25px 15px 20px;
                @media only screen and (max-width: 767px) {
                    line-height: initial;
                    padding: 9px 15px;
                }
            }
            &.open {
                max-height: 10000px;
                >ul {
                    display: block;
                    @media only screen and (max-width: 767px) {
                        display: none;
                    }
                }
                >a {
                    font-weight: bold;
                    color: $umassglobal-primary-color;
                    &:after {
                        top: 50%;
                        content: " ";
                        position: absolute;
                        right: 0;
                        border: 11px solid $umassglobal-primary-color;
                        border-top-color: transparent;
                        border-bottom-color: transparent;
                        border-left-color: transparent;
                        transform: translateY(-11px);
                    }
                }
            }
            &.selected {
                >a {
                    font-weight: bold;
                    color: $umassglobal-primary-color;
                }
            }
        }
        ul {
            border-top: 2px solid $umassglobal-lightest-gray;
            list-style: none;
            margin: 0;
            padding: 0;
            display: none;
            li {
                margin: 0;
                border: none;
                border-bottom: 2px solid $umassglobal-lightest-gray;
                position: relative;
                &:last-child {
                    border-bottom: none;
                }
                >a {
                    background-color: $body-bg;
                    color: $umassglobal-text-gray;
                    font-weight: 400;
                    padding-left: 35px;
                    &:hover {
                        color: $umassglobal-primary-color;
                    }
                }
                &.open {
                    li>a {
                        padding-left: 54px;
                        position: relative;
                        &::before {
                            content: '-';
                            position: absolute;
                            margin-left: -15px;
                        }
                    }
                }
            }
        }
    }
}

.nav__subnav-link.selected {
    @media only screen and (max-width: 767px) {
        a {
            &:after {
                display: none;
            }
        }
    }
}