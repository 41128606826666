.readmore {
  &__image {
    img {
      width: 100%;
      object-fit: cover;
      max-height: 330px;
      height: 300px;
      transition: all 0.5s ease;
    }
    &.v-image {
      zoom: 1;
      img {
        height: auto;
        max-height: 100%;
      }
    }
    @include media-breakpoint-down(md) {
      margin: 25px 0 0;
    }
  }
  .section__header {
    * {
      margin-top: 0;
    }
  }
  .section__description {
    margin-bottom: 0;
    line-height: 24px;
  }
  &__content {
    .btn {
      margin-top: 8px;
    }
  }
  .container {
    @include media-breakpoint-down(md) {
      padding-top: 30px;
    }
  }
}

.readmore__text,
.teaser__copy {
  // display: block;
  ul {
    margin: 0;
    padding: 0;
    li {
      padding-left: 11px;
      margin: 10px 4px;
    }
  }
  &.program-detail__readmore {
    ul {
      padding: 0;
      margin-left: -5px !important;
      li {
        padding-left: 0;
      }
    }
  }}

  .readmore__text,
  .teaser__copy {
    ul:not(.checks) {
      // Fallback
      list-style-type: disc;

      @supports (list-style: symbols('\f108')) {
          // Apply counter style if supported
          list-style: umass-carets;

          & > li::marker {
          color: $umassglobal-secondary-color;
          font-family: "standard-icons";
          }
      }
    }
}