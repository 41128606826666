.benefits{
    
    .surround{
        border: solid 1px $umassglobal-lighter-gray;
        color: $umassglobal-gray;
        font-size: $font-size-smallPica;
        text-align: center;
        @media (min-width: 992px){ 
            max-width: 430px;
            padding: 20px 20px 20px 15%;
        } 
        
    }
    // TODO: Change in template was misspelled and .beneficts__mention
    &__mention{
        font-family: $font-family-serif;
        font-size: $font-size-smallPica;
        text-align: center;
        width: 50%;
        margin-left: 14%;
    }

    .form-group.required>label::after {
        color: $umassglobal-primary-color-d25;
        content: ' *';
    }

    .forms__request-wrapper {
        box-shadow: 0 0 20px rgba(0,0,0,0.25);
        background-color: $umassglobal-white;        
        padding: 30px;
    }

    select.form-control {
        width: 100%;
    }
    // TODO: Change in template was misspelled and .beneficts__mention
    &__logo{
        padding: 50px 0;
        img{
            display: block;
            margin-left: 13%;
            margin-right: auto;
            width: 50%;
        }
    }

}

.partner_portal_main_content{
    .forms__request-header * {
        max-width: 100% !important;
    }

    .section__header:first-child * {
        text-align: left;
    }
    &.center-titles{
        .section__header:first-child * {
            text-align: center;
        }
    }
}




