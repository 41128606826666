
.hero__background--overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hero {
    color: $umassglobal-text-gray;
    min-height: 325px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    .columns__container {
        margin: 0;
    }
    svg polygon {
        display: inherit;
    }
    &__header {
        * {
            font-size: $font-size-h1-alt;
            line-height: $line-height-h1-alt;
            margin-top: 0;
            @include media-breakpoint-down(md) {
                font-size: $font-size-mobile-h1-alt;
                line-height: $line-height-mobile-h1-alt;
            }
        }
    }
    &__description {
        * {
            font-size: $font-size-pica;
            line-height: $line-height-base;
            @include media-breakpoint-down(md) {
                font-size: $font-size-smallPica;
                line-height: $line-height-base;
            }
        }
    }
    .container {
        padding: 50px 25px;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
    &__interior {
        /*display: flex;
          align-items: center;*/
        position: relative;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        clip-path: polygon(100% 0%, 0% 0%, 0% 86%, 50% 100%, 100% 86%);
        padding: 0 0 50px;
        @include media-breakpoint-down(md) {
            clip-path: polygon(100% 0%, 0% 0%, 0% 95%, 50% 100%, 100% 95%);
        }
        .hero-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: rgb(0, 0, 0);
            background: linear-gradient(90deg, #000c 20%, rgba(255, 255, 255, 0) 70%);
            @include media-breakpoint-down(md) {
                background: rgba(0, 0, 0, 0.5);
            }
        }
    }
    &__background {
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: -1;
        background-size: cover;
        background-position: left top;
        &-video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
        #hero-video {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            opacity: 0;
            transition: opacity 0.5s;
            &.active {
                opacity: 1;
            }
        }
        &--cover {
            background-image: linear-gradient( to right, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0) 65%);
            display: none;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            width: 100%;
            .hero--light & {
                display: block;
            }
        }
        &-shape {
            height: 100%;
            width: 100%;
            position: absolute;
            polygon {
                height: 100%;
                width: 100%;
            }
        }
        &-image {
            background-size: cover;
            background-position: center top;
            height: 100%;
            width: 100%;
            display: block;
        }
        .arts-sciences & {
            border-top: 2px solid $umassglobal-arts-and-sciences-color;
        }
        .business & {
            border-top: 2px solid $umassglobal-business-color;
        }
        .education & {
            border-top: 2px solid $umassglobal-education-color;
        }
        .nursing & {
            border-top: 2px solid $umassglobal-nursing-and-health-professions-color;
        }
        .ext-education & {
            border-top: 2px solid $umassglobal-extended-education-color;
        }
    }
    &__video {
        position: relative;
        @include media-breakpoint-down(md) {
            margin-top: 30px;
        }
        img {

            @extend %resImg-2cols;
        }
        @include box-shadow(0 0 20px rgba(0, 0, 0, 0.25));
    }
    &__home {
        position: relative;
        .container {
            align-items: center;
            display: flex;
            flex-direction: row;
            min-height: 490px;
            padding-top: 60px;
            padding-bottom: 40px;
            @include media-breakpoint-down(md) {
                align-items: flex-start;
                min-height: 0;
                flex-direction: column;
                justify-content: center;
            }
        }
        &--alt {
            .hero__background--triangles {
                background-size: cover;
                background-position: center top;
            }
        }
        .hero {
            &__header {
                * {
                    color: white;
                    font-size: $font-size-h1;
                    line-height: $line-height-h1;
                    @include media-breakpoint-down(md) {
                        font-size: $font-size-mobile-h1;
                        line-height: $line-height-mobile-h1;
                    }
                }
            }
            &__description {
                p {
                    color: white;
                }
                * {
                    font-size: $font-size-large;
                    line-height: $line-height-large;
                    vertical-align: inherit;
                    @include media-breakpoint-down(md) {
                        font-size: $font-size-large-mobile;
                        line-height: $line-height-large-mobile;
                    }
                }
            }
            &__container {
                @include media-breakpoint-down(md) {
                    max-width: 100%;
                }
            }
            &__cta {
                @include media-breakpoint-down(md) {
                    max-width: 300px;
                    .btn {
                        font-size: $font-size-smallPica;
                        text-align: center;
                        width: 100%;
                    }
                }
            }
        }
    }
    &__container {
        max-width: 460px;
        margin-right: 60px;
        @include media-breakpoint-only(md) {
            max-width: 330px;
            margin-right: 20px;
        }
        @include media-breakpoint-down(md) {
            margin-right: 0;
        }
    }
    &__cta {
        margin-top: 40px;
        .btn {
            font-size: $font-size-med;
        }
        .btn-primary,
        .btn-secondary,
        .btn-alt {
            padding: 15px 32px;
            height: 60px;
        }
    }
    &--light {
        color: $umassglobal-white;
        .hero {
            &__description,
            &__header {
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
            }
        }
    }
    &__container {
        position: relative;
    }
    &__header {
        * {
            color: $umassglobal-text-gray;
            .hero--light & {
                color: $umassglobal-white;
            }
        }
    }
    &__altcta {
        //border-left: 1px solid #aa4a59;
        color: $umassglobal-white;
        padding: 20px 70px;
        position: relative;
        @include media-breakpoint-down(md) {
            border-left: none;
            border-top: 1px solid $umassglobal-primary-color-s50l25;
            padding: 20px;
        }
        &:first-child {
            border-left: none;
            padding-left: 15px;
            @include media-breakpoint-down(md) {
                border-top: none;
                padding: 20px;
            }
            &::after {
                content: " ";
                position: absolute;
                height: 60%;
                width: 1px;
                background: $umassglobal-white;
                display: block;
                top: 20%;
                right: 0;
            }
        }
        &-container {
            background-color: $umassglobal-primary-color;
            .container {
                padding-top: 0;
                padding-bottom: 0;
                @include media-breakpoint-down(md) {
                    padding: 0;
                }
            }
            @include media-breakpoint-down(md) {
                .row {
                    margin: 0;
                }
            }
        }
        p {
            margin: 0;
        }
        a {
            color: $umassglobal-tertiary-color;
            text-decoration: underline;
            font-weight: 700;
            &:hover {
                color: $umassglobal-white;
            }
        }
    }
    &__landing {
        /*margin-top: -86px;
        padding-top: 86px;*/
        @include media-breakpoint-down(md) {
            margin-top: -64px;
            padding-top: 64px;
            .container {
                padding-top: 15px;
                padding-bottom: 25px;
            }
        }
        .hero__header {
            * {
                @include media-breakpoint-down(md) {
                    font-size: 3.2rem;
                    line-height: 35px;
                }
            }
        }
        .select2-container--classic {
            .select2-selection--single {
                background-color: $body-bg;
            }
            .select2-selection__arrow {
                background-color: $body-bg;
                border-left: 1px solid $umassglobal-text-gray;
                width: 52px;
                .icon__downarrow {
                    font-size: 2rem;
                    color: $umassglobal-text-gray;
                }
            }
        }
        .forms__request {
            &-header {
                border-bottom: 1px solid $umassglobal-lightest-gray;
                margin-bottom: 22px;
                * {
                    color: $umassglobal-gray;
                    font-size: 2rem;
                    line-height: $line-height-halved;
                    max-width: none;
                    margin-bottom: 14px;
                    @include media-breakpoint-up(lg) {
                        white-space: nowrap;
                    }
                    @include media-breakpoint-down(md) {
                        line-height: $line-height-base;
                        margin-bottom: 10px;
                    }
                }
                h3 {
                    color: $umassglobal-text-gray;
                    @include media-breakpoint-up(lg) {
                        white-space: initial;
                    }
                }
                @include media-breakpoint-down(md) {
                    border: none;
                    margin-bottom: 0;
                }
            }
            &-wrapper {
                box-shadow: 0 0 25px #00000090;
                background-color: $body-bg;
                padding: 30px;
                @include media-breakpoint-down(md) {
                    padding: 18px 25px 16px;
                    width: 100%;
                }
                .form-group {
                    margin-bottom: 8px;
                    &.has-error {
                        margin-bottom: 15px;
                    }
                    @include media-breakpoint-down(md) {
                        margin-bottom: 0;
                    }
                }
                button {
                    @include media-breakpoint-down(md) {
                        height: 43px;
                        line-height: 22px;
                    }
                }
                .form-group {
                    &.required {
                        >label {
                            &::after {
                                color: $umassglobal-primary-color;
                                content: " *";
                            }
                        }
                    }
                }
                &.tabs__wrapper ul.form-arrows li {
                    a {
                        color: $umassglobal-text-gray;
                    }
                    &.active {
                        a {
                            color: $umassglobal-white;
                        }
                    }
                }
            }
        }
        .form-arrows.nav-tabs {
            li {
                &.active {
                    span#education, span#about {
                        background-color: $umassglobal-dark-gray;
                        color: $umassglobal-white;
                        font-weight: bold;
                    }
                }
                &:first-child {
                    span#education, span#about {
                        border-top-left-radius: 3px;
                        border-bottom-left-radius: 3px;
                        @include media-breakpoint-down(md) {
                            margin-right: 9px;
                        }
                        &:before {
                            content: none;
                        }
                    }
                }
            }
        }
    }
    &.hero-paid-landing {
        .hero__home::before {
            background: none !important;
        }
        .hero__background {
            position: relative;
            img {
                width: 100%;
                min-height: 330px;
                height: 500px;
                object-fit: cover;
                @include media-breakpoint-down(md) {
                    min-height: 277px;
                    height: 277px;
                }
            }
        }
        .paid-hero-content {
            margin-top: -200px;
            @media screen and (max-width: 767px) {
                margin-top: 0;
                background: $umassglobal-secondary-color;
                color: white;
                padding-top: 30px;
                padding-bottom: 30px;
            }
            .hero__container {
                @media screen and (max-width: 767px) {}
                @media screen and (min-width: 1200px) {
                    margin-right: 0;
                    margin-top: auto;
                    margin-bottom: auto;
                    max-width: 710px;
                    padding-right: 145px;
                }
                .hero__header {
                    h1 {
                        color: $umassglobal-primary-color;
                        @media screen and (max-width: 767px) {
                            color: white;
                            margin-bottom: 19px;
                        }
                    }
                }
                .hero__paragraph {
                    p {
                        font: normal normal normal 28px/35px Noto Sans;
                        letter-spacing: 0;
                        @media screen and (max-width: 767px) {
                            font: normal normal normal 16px/26px Noto Sans;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
            .forms__request-wrapper {
                @media screen and (min-width: 1200px) {
                    position: relative;
                    top: -40px;
                }
            }
        }
    }
}

section.hero.hero__interior.hero--light {
    &.arts-sciences {
        border-top: 4px solid $umassglobal-arts-and-sciences-color;
    }
    &.business {
        border-top: 4px solid $umassglobal-business-color;
    }
    &.education {
        border-top: 4px solid $umassglobal-education-color;
    }
    &.nursing {
        border-top: 4px solid $umassglobal-nursing-and-health-professions-color;
    }
    &.ext-education {
        border-top: 4px solid $umassglobal-extended-education-color;
    }
}

.hero-program-finder {
    .hero__home .container {
        flex-wrap: wrap;
        display: block;
        min-height: auto;
        .degree-finder__filter-container {
            border: none;
            margin: 0;
            .btn {
                height: 52px;
                line-height: 52px;
                margin-top: 5px;
                padding-top: 0;
                width: 100%;
                @media screen and (min-width: 767px) {
                    margin-top: 0;
                    width: initial;
                }
            }
        }
        .degree-finder__filters {
            border: none;
            background: none;
            margin: 0;
            padding: 30px 0;
        }
        .degree-finder__filter-wrapper {
            width: 100%;
            margin: 0;
            .degree-finder__filter {
                width: 100%;
                margin-left: 0;
                @media screen and (min-width: 767px) {
                    width: 30%;
                    margin-right: 30px;
                }
            }
        }
    }
}

/*TO MOVE HERO TEXT*/
.mobile-top-left {
    @media screen and (max-width: 768px) {
      .hero__home {
        min-height: 400px;
        padding: 25px;
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        flex-direction: row;
        .hero__background--overlay {
          background: linear-gradient(to bottom, #0f4c85 20%, #005eb800 65%) no-repeat padding-box !important;
        }
  
        .container {
          padding: 0;
          display: flex;
          .degree-finder {
            //display: none;
            width: 100%;
          }
          .hero__video {
            margin: 0;
          }
          .hero__description p {
            margin: 0;
            font-size: $font-size-english;
            line-height: 1.4;
          }
          .hero__header {
            font-size: 3.5rem;
            line-height: 1;
          }
        }
      }
      //hero home  
  
      #heroInterior {
        .hero__background--overlay {
          background: linear-gradient(to right, rgba(0,0,0,0.8) 20%, rgba(255,255,255,0) 70%) no-repeat padding-box;
        }
        .container {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          min-height: 280px;
          padding: 10px 25px;
        }
      }
      //#hero-wrap
    }
  
  }
  
  .mobile-bottom-left {
    @extend .mobile-top-left;
    @media screen and (max-width: 768px) {
      .hero__home {
        align-items: flex-end;
        .container {
          flex-direction: column-reverse;
          align-content: flex-start;
        }
        .hero__background--overlay {
          background: linear-gradient(to top, #0f4c85 20%, #005eb800 65%) no-repeat padding-box !important;
        }
      }
      #heroInterior {
        .hero__background--overlay {
          background: linear-gradient(to top, rgba(0,0,0,0.8) 25%, rgba(255,255,255,0) 70%) no-repeat padding-box !important;
        }
        .container {
          justify-content: flex-end;
        }
      }
      //#hero-wrap
    }
    //media
  }
  
  .mobile-center-left {
    @extend .mobile-top-left;
    @media screen and (max-width: 768px) {
  
      .hero__home {
        align-items: center;
        .hero__background--overlay {
          background: linear-gradient(to right, #0f4c85 20%, #005eb800 65%) no-repeat padding-box !important;
        }
      }
      //hero-home
      #heroInterior {
        .container {
          justify-content: center;
        }
      }
      //#hero-wrap
  
    }
    //media
  }
  
  .partner_portal_main_content {
    .hero__background--overlay {
      display: none !important;
    }
    .mobile-top-left {
      @media screen and (max-width: 768px) {
        .hero__home {
          .hero__background--cover {
            background-image: linear-gradient(to bottom, rgba(0,0,0,0.8) 25%, rgba(0,0,0,0) 65%);
          }
        }
      }//media
    }//mobile-top/left
    .mobile-bottom-left {
      @extend .mobile-top-left;
      @media screen and (max-width: 768px) {
        .hero__home {
          .hero__background--cover {
            background-image: linear-gradient(to top, rgba(0,0,0,0.8) 25%, rgba(0,0,0,0) 65%);
          }
        }
      }//media
    }//mobile-top/left
    
    .mobile-center-left {
      @extend .mobile-top-left;
      @media screen and (max-width: 768px) {
        .hero__home {
          .hero__background--cover {
            background-image: linear-gradient(to right, rgba(0,0,0,0.8) 25%, rgba(0,0,0,0) 65%);
          }
        }
      }//media
    }//mobile-top/left
    
  }

  /*TO MOVE HERO TEXT*/