.specialbullets {
    counter-increment: specialbullets;
    input {
        color: $umassglobal-primary-color;
        border: 1px solid $umassglobal-primary-color;
        font-weight: 900;
        max-width: 67px;
        text-align: center;
    }
    &:last-child {
        border-bottom: none;
    }
    .section {
        &__header {
            text-align: left;
            padding-left: 44px;
            position: relative;
            * {
                color: $umassglobal-primary-color;
                font-size: $font-size-h3;
                font-family: $font-family-serif;
                font-weight: normal;
                line-height: 35px;
                position: static;
                &:before {
                    background-color: $umassglobal-secondary-color;
                    content: counter(specialbullets);
                    color: $umassglobal-white;
                    font-family: $font-family-sans-serif;
                    font-size: $font-size-large;
                    line-height: 35px;
                    text-align: center;
                    font-weight: bold;
                    height: 35px;
                    width: 35px;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                }
            }
        }
        &__content {
            h4 {
                font: normal normal bold 15px/20px Noto Sans;
                letter-spacing: 0;
                color: $umassglobal-primary-color;
            }
            ul.checks li .list__icon {
                color: $umassglobal-secondary-color;
                &::after {
                    font-weight: bold;
                }
            }
        }
    }
}

.media {
    &__image {
        img {
            height: auto;
            width: 100%;
        }
        @include media-breakpoint-down(md) {
            margin-bottom: 25px;
        }
    }
    .columns__container {
        margin-top: 58px;
        @include media-breakpoint-down(md) {
            margin-top: 30px;
        }
    }
    &__content {
        padding-bottom: 30px;
        border-bottom: 1px solid $umassglobal-lightest-gray;
        margin-bottom: 30px;
        margin-top: -8px;
        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }
        @include media-breakpoint-down(md) {
            margin-top: 0;
        }
        .columns__header {
            font-size: $font-size-columbian;
            line-height: 1.4;
            font-weight: normal;
        }
    }
    &__contacts {
        display: flex;
        flex-direction: row;
        @include media-breakpoint-down(md) {
            display: block;
        }
        margin: 0 -10px;
        &-header {
            * {
                @include subheading($umassglobal-gray, $font-size-small);
                line-height: 30px;
                margin: 0;
            }
        }
        &-phone {
            font-weight: bold;
        }
        a {
            font-weight: bold;
        }
        &-info {
            flex: 1 1 auto;
            line-height: 30px;
            margin: 0 10px;
            @include media-breakpoint-down(md) {
                margin-bottom: 25px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.anchors {
    &__column {
        background-color: $umassglobal-white;
        border-radius: $panel-border-radius;
        color: $umassglobal-darker-gray;
        padding: 20px 30px;
        position: relative;
        @include panel-variant( $umassglobal-extraLight-gray, $umassglobal-darker-gray, $umassglobal-off-white-color, $umassglobal-extraLight-gray);
        &-wrapper {
            counter-increment: section;
            padding-left: 6px;
            padding-right: 6px;
            @include media-breakpoint-down(md) {
                margin-bottom: 25px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            &:after {
                bottom: 0;
                content: " ";
                position: absolute;
                right: 0;
                border-right: 1px solid $umassglobal-lightest-gray;
                height: 100%;
                @media screen and (min-width: 960px) {
                    border-right: 1px solid $umassglobal-lightest-gray;
                }
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
    &__container {
        counter-reset: section;
        margin-left: -6px;
        margin-right: -6px;
        @include media-breakpoint-down(md) {
            margin: 0;
        }
        &.no-border {
            .anchors__column-wrapper {
                &:after {
                    border-right: 0;
                }
            }
        }
    }
    &__header {
        position: relative;
        min-height: 38px;
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        padding-left: 40px;
        h3 {
            font-size: $font-size-small;
            line-height: $line-height-small;
            margin: 0;
            text-align: left;
            >span.icon {
                font-size: 2.3em;
                line-height: 1;
                color: $umassglobal-primary-color;
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        a {
            font-size: $font-size-small;
            line-height: 20px;
            text-decoration-color: $umassglobal-primary-color;
            vertical-align: baseline;
            .icon {
                color: $umassglobal-light-gray;
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                font-size: 2.3em;
                line-height: 1;
                text-decoration: none;
            }
            &:after {
                color: $umassglobal-primary-color;
                display: inline;
                font-size: $font-size-small;
                line-height: 20px;
                vertical-align: baseline;
            }
            &:hover {
                color: $umassglobal-primary-color;
            }
        }
        .icon__increment {
            &:after {
                background-color: $umassglobal-secondary-color;
                content: counter(section);
                color: $umassglobal-white;
                font-family: $font-family-sans-serif;
                font-size: $font-size-base;
                line-height: 30px;
                text-align: center;
                font-weight: bold;
                height: 30px;
                width: 30px;
                border-radius: 50%;
            }
        }
    }
    &__description {
        * {
            font-size: $font-size-small;
            line-height: $line-height-small;
        }
    }
    .section {
        &__header {
            * {
                color: $font-color-h3;
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                font-size: $font-size-h3;
                line-height: 1.4;
                @include media-breakpoint-down(md) {
                    margin-top: 0;
                }
            }
        }
        &__description,
        &__header {
            text-align: left;
            max-width: none;
        }
    }
}

.bulleted-block {
    border-bottom: 1px solid $umassglobal-lightest-gray;
    &:last-child {
        border-bottom: none;
    }
    .section .responsivetable__tagline &,
    .container & {
        .row {
            margin: 0;
        }
    }
    .section {
        &__header {
            margin-top: -10px;
            text-align: left;
            * {
                line-height: 40px;
                margin-top: 0;
                margin-bottom: 24px;
            }
            p {
                margin-bottom: 0;
                font-size: $font-size-h3;
                font-family: $font-family-serif;
            }
        }
        &__description {
            text-align: left;
            @include media-breakpoint-up(lg) {
                padding-right: 90px;
            }
            .btn {
                &:last-child {
                    margin-top: 12px;
                    @include media-breakpoint-down(md) {
                        margin-top: 22px;
                    }
                }
            }
        }
    }
    &__card {
        border: 2px solid $table-border-color;
        overflow: hidden;
        @include media-breakpoint-down(md) {
            margin-top: 35px;
        }
        &-text {
            padding: 30px;
            text-align: center;
            * {
                color: $umassglobal-darker-gray;
                font-size: $font-size-small;
                line-height: $line-height-small;
                margin-top: 0;
                a {
                    color: $umassglobal-primary-color;
                }
            }
        }
        &header {
            //background: $brand-primary url("/images/bulleted_block_header.png") top center;
            background: $umassglobal-secondary-color;
            padding: 50px;
            &--background {
                background-size: cover;
                position: relative;
                &:before {
                    background-color: transparent; // #000;
                    content: " ";
                    position: absolute;
                    opacity: 0.5;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
                * {
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
                }
            }
            * {
                color: $umassglobal-white;
                font-family: $font-family-serif;
                font-size: $font-size-columbian;
                font-weight: normal;
                line-height: 1.2;
                margin: 0;
                position: relative;
                text-align: center;
                z-index: 2;
            }
            &-wrapper {
                position: relative;
                &:before,
                &:after {
                    bottom: 0;
                    content: " ";
                    position: absolute;
                    border-bottom: 16px solid $umassglobal-white;
                    border-top: 16px solid transparent;
                    z-index: 3;
                }
                &:before {
                    right: 50%;
                    border-left: 90px solid $umassglobal-white;
                    border-right: 90px solid transparent;
                    @media (min-width: 240px) and (max-width: $screen-xs-max) {
                        border-bottom: 30px solid $umassglobal-white;
                        border-top: 30px solid transparent;
                        border-left: 180px solid $umassglobal-white;
                        border-right: 180px solid transparent;
                    }
                }
                &:after {
                    left: 50%;
                    border-left: 90px solid transparent;
                    border-right: 90px solid $umassglobal-white;
                    @media (min-width: 240px) and (max-width: $screen-xs-max) {
                        border-bottom: 30px solid $umassglobal-white;
                        border-top: 30px solid transparent;
                        border-right: 180px solid $umassglobal-white;
                        border-left: 180px solid transparent;
                    }
                }
            }
        }
    }
}

.steps,
.section__blueback {
    background: $umassglobal-secondary-color;
    p {
        color: white;
    }
    .section__header {
        * {
            margin-bottom: 12px;
        }
        h2 {
            color: $umassglobal-tertiary-color;
        }
        p,
        li {
            color: white;
        }
    }
    &__links {
        font-size: 0;
        padding: 0;
        text-align: center;
        li {
            display: block;
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
            @include media-breakpoint-down(md) {
                margin-right: 0;
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            @include media-breakpoint-up(md) {
                display: inline-block;
            }
            .btn {
                width: auto;
                height: auto;
                white-space: normal;
                line-height: 24px;
                padding: 15px 10px;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    text-align: left;
                }
                @include media-breakpoint-only(md) {
                    width: auto;
                }
            }
        }
    }
}

.linkcollection {
    border: 1px solid $umassglobal-lightest-gray;
    background-color: $umassglobal-lightest-gray;
    padding: 18px 30px 0;
    margin-top: 30px;
    text-align: left;
    position: relative;
    .section__description &,
    .section__content & {
        margin-bottom: 24px;
        margin-top: 24px;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .addcalendar-button {
        font-size: $font-size-smallPica;
        line-height: 1.6;
        font-weight: bold;
        .icon {
            text-decoration: none;
            color: $umassglobal-light-gray;
            font-size: 1.4em;
            line-height: 1.6;
            margin-right: 4px;
            &:after {
                vertical-align: bottom;
            }
        }
    }
    &.section__lightgrayback,
    &.section__grayback,
    &.section__darkgrayback {
        border: none;
    }
    @include media-breakpoint-down(md) {
        padding: 30px 25px 10px;
        margin: 25px 0;
    }
    &.section .container {
        padding: 0;
    }
    &__dark {
        border: 2px solid $table-border-color;
    }
    &__select {
        background: none;
        border: none;
        padding: 0;
        &:after {
            content: "";
            display: none;
        }
        .btn,
        .select2 {
            width: 100%;
        }
    }
    address {
        font-size: $font-size-small;
        line-height: $line-height-small;
        text-decoration: underline;
        @include media-breakpoint-down(md) {
            text-decoration: none;
        }
    }
    .btn {
        margin-right: 30px;
        margin-bottom: 20px;
        width: 280px;
        @include media-breakpoint-down(md) {
            display: block;
            width: auto;
            margin-right: 0;
        }
        &-link-carat,
        &-link-leave,
        &-link {
            color: $umassglobal-text-gray;
            width: auto;
            margin-right: 20px;
            &:after {
                color: $umassglobal-primary-color;
            }
            .icon {
                color: $umassglobal-light-gray;
                font-size: $font-size-maximus;
            }
            &:hover {
                color: $umassglobal-primary-color;
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
    &__side {
        padding: 8px 20px;
        .btn {
            margin-right: 0;
            margin-bottom: 10px;
            &-link-carat,
            &-link-leave,
            &-link {
                margin-right: 0;
            }
        }
    }
    &__header {
        margin-bottom: 10px;
        * {
            color: $umassglobal-gray;
            line-height: 21px;
            margin: 3px 0;
        }
        .subheading {
            color: $umassglobal-dark-gray !important;
            font-size: $font-size-pica;
            line-height: 1.3;
            letter-spacing: 0;
        }
        .linkcollection__columns & {
            @include media-breakpoint-down(md) {
                border-bottom: 1px solid $umassglobal-lightest-gray;
                .subheading {
                    font-weight: normal;
                }
            }
        }
    }
    .linkcollection__header {
        .subheading {
            color: $umassglobal-primary-color !important;
        }
    }
    .linkcollection__links {
        span.icon {
            color: $umassglobal-secondary-color;
            display: inline;
        }
        a {
            color: $umassglobal-text-gray;
            font-weight: bold;
            text-decoration: none;
            border-bottom: 1px solid $umassglobal-primary-color;
        }
    }
    p {
        font-size: $font-size-small;
        line-height: $line-height-small;
        margin-bottom: 0;
        &.phone {
            margin-bottom: 12px;
        }
    }
    &__column {
        margin: 0 10px 20px;
        flex: 1 1 25%;
        address {
            margin-bottom: 0;
        }
        a {
            font-weight: bold;
        }
        @include media-breakpoint-down(md) {
            flex: 1 1 auto;
            margin-bottom: 30px;
        }
    }
    &__columns {
        display: flex;
        flex-direction: row;
        margin: 0 -12px;
        justify-content: stretch;
        @include media-breakpoint-up(xl) {
            flex-wrap: wrap;
        }
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }
    &__connect {
        font-size: 4rem;
        line-height: .9;
        position: relative;
        .trademark {
            bottom: 0;
            color: $umassglobal-darker-gray;
            font-size: $font-size-minion;
            position: absolute;
        }
        .icon__linkedinfull {
            transition: color $transition-hover-speed;
            color: #0077b5;
            text-decoration: none;
            &:after {
                text-decoration: underline;
            }
            &:after {
                text-decoration: none;
            }
        }
        &:hover {
            .icon__linkedinfull {
                color: darken(#0077b5, 5%);
            }
        }
    }
    &__dropdown {
        margin-top: 12px;
        &__columns {
            display: flex;
            flex-direction: row;
            margin: 0 -12px;
            justify-content: stretch;
            @include media-breakpoint-up(xl) {
                flex-wrap: wrap;
            }
            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
        }
        &__connect {
            font-size: 4rem;
            line-height: .9;
            position: relative;
            .trademark {
                bottom: 0;
                color: $umassglobal-darker-gray;
                font-size: $font-size-minion;
                position: absolute;
            }
            .icon__linkedinfull {
                transition: color $transition-hover-speed;
                color: #0077b5;
                text-decoration: none;
                &:after {
                    text-decoration: underline;
                }
                &:after {
                    text-decoration: none;
                }
            }
            &:hover {
                .icon__linkedinfull {
                    color: darken(#0077b5, 5%);
                }
            }
        }
        select {
            width: 100%;
        }
        .select2-selection__rendered {
            color: $umassglobal-darker-gray;
        }
    }
    &__callout {
        background-color: $umassglobal-off-white-color;
        border: none;
        padding: 30px 30px 0;
        .container &.section {
            .row {
                margin: 0 -30px;
            }
            .linkcollection__column {
                padding: 0 20px 0 30px;
                &-noheader {
                    padding-top: 34px;
                    @include media-breakpoint-down(md) {
                        padding-top: 0;
                    }
                }
            }
        }
        .readmore {
            margin-bottom: 30px;
        }
        .linkcollection {
            &__header {
                margin-bottom: 6px;
                @include media-breakpoint-down(md) {
                    border-bottom: 1px solid $umassglobal-lightest-gray;
                }
            }
            &__column {
                margin: 0 0 30px;
                border-left: 1px solid $umassglobal-lightest-gray;
                &:first-child {
                    border-left: none;
                }
            }
        }
        p {
            font-size: $font-size-base;
            line-height: $line-height-base;
        }
        .linkcollection__column {
            .linkcollection__header {
                font: normal normal bold 1.5rem/1.3 Noto Sans;
                letter-spacing: 0;
                color: $umassglobal-dark-gray;
            }
            p {
                font-size: $font-size-english;
                line-height: 1.3;
            }
            a {
                font: normal normal bold 1.5rem/1.3 Noto Sans;
                letter-spacing: 0;
            }
        }
    }
    .card__eventlisting-date {
        font: normal normal bold 1.8rem/1.3 Noto Sans;
        letter-spacing: 0;
        margin-bottom: 5px;
    }
    .card__eventlisting-time {
        font: normal normal normal 1.8rem/1.3 Noto Sans;
        letter-spacing: 0;
        margin-bottom: 7px;
    }
    .card__addlink {
        .addeventatc {
            font-size: $font-size-pica;
            font-weight: 700;
            line-height: 1.3;
        }
    }
}

.cta-snippet {
    .btn {
        height: 60px;
        padding: 15px 25px;
        @include media-breakpoint-down(md) {
            min-width: 225px;
            width: auto;
        }
    }
    &__cta {
        margin-top: 25px;
    }
    &__emailform {
        margin-top: 25px;
        .icon {
            position: absolute;
            font-size: 3.2rem;
            line-height: 1.3;
            left: 18px;
            top: 0;
            color: $umassglobal-light-gray;
            text-align: left;
            pointer-events: none;
        }
        .form-group {
            margin-bottom: 15px;
            position: relative;
            display: inline-flex;
            @include media-breakpoint-down(md) {
                flex-direction: column;
                width: 100%;
            }
        }
        label {
            position: absolute;
            left: -1000px;
        }
        input {
            padding: 12px 12px 12px 62px;
            border-radius: 0;
            height: 50px;
            display: inline-block;
            width: 350px;
            flex: 1 1 auto;
            border: none;
            .has-error & {
                border: 1px solid $umassglobal-tertiary-color;
            }
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
        .btn {
            display: inline-block;
            height: 50px;
            padding: 10px 25px;
            flex: 0 1 auto;
            @include media-breakpoint-down(md) {
                margin-top: 12px;
            }
        }
        .forms__message {
            color: $umassglobal-tertiary-color;
            left: 0;
        }
    }
}

.schools {
    &__container {
        &-header {
            * {
                @include subheading;
                margin-bottom: 5px;
            }
            h3 {
                color: $umassglobal-primary-color;
                line-height: 25px;
                font-weight: normal;
                letter-spacing: 0;
            }
        }
    }
    &__image {
        transition: opacity $transition-hover-speed;
        opacity: 1; //0.5;
        overflow: hidden;
        img {
            transition: opacity $transition-hover-speed;
            //opacity: 0.2;
            height: auto;
            width: 100% !important;
            object-fit: cover;
        }
        &-wrapper {
            position: relative;
            background-color: $umassglobal-white;
        }
    }
    &__wrapper {
        margin: 25px -3px 60px;
        &.hover {
            margin-bottom: 3px;
        }
    }
    &__degrees {
        max-width: 900px;
    }
    &__degree {
        font-size: $font-size-pica;
        font-weight: bold;
        line-height: 1.5;
        .btn {
            padding: 12px 0;
            text-transform: none;
        }
    }
    &__school {
        background-color: $body-bg;
        border: 1px solid $umassglobal-lightest-gray;
        overflow: hidden;
        position: relative;
        &.arts-sciences {
            &::before {
                content: "";
                position: absolute;
                display: block;
                height: 3px;
                width: 100%;
                background-color: $umassglobal-arts-and-sciences-color;
                z-index: 1;
            }
            &:hover .schools__image {
                background-color: $umassglobal-arts-and-sciences-color;
            }
        }
        &.business {
            &::before {
                content: "";
                position: absolute;
                display: block;
                height: 3px;
                width: 100%;
                background-color: $umassglobal-business-color;
                z-index: 1;
            }
            &:hover .schools__image {
                background-color: $umassglobal-business-color;
            }
        }
        &.education {
            &::before {
                content: "";
                position: absolute;
                display: block;
                height: 3px;
                width: 100%;
                background-color: $umassglobal-education-color;
                z-index: 1;
            }
            &:hover .schools__image {
                background-color: $umassglobal-education-color;
            }
        }
        &.nursing {
            &::before {
                content: "";
                position: absolute;
                display: block;
                height: 3px;
                width: 100%;
                background-color: $umassglobal-nursing-and-health-professions-color;
                z-index: 1;
            }
            &:hover .schools__image {
                background-color: $umassglobal-nursing-and-health-professions-color;
            }
        }
        &.ext-education {
            &::before {
                content: "";
                position: absolute;
                display: block;
                height: 3px;
                width: 100%;
                background-color: $umassglobal-extended-education-color;
                z-index: 1;
            }
            &:hover .schools__image {
                background-color: $umassglobal-extended-education-color;
            }
        }
        &:hover {
            &::before {
                background-color: transparent;
            }
            .schools {
                &__image {
                    border-top: none;
                    opacity: 1;
                    img {
                        opacity: 0.8; //0.4;
                    }
                }
            }
        }
        &-title {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 82px;
            padding: 15px;
            * {
                color: $umassglobal-text-gray;
                font-size: $font-size-large;
                font-family: $font-family-sans-serif;
                line-height: 1.4;
                font-weight: 700;
                text-align: center;
                margin: 0;
            }
        }
    }
    &__column {
        @include make-sm-column(2.4);
        padding: 0 3px;
        @include media-breakpoint-down(md) {
            margin: 0 auto 15px;
            max-width: 260px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.bubbles {
    &__container {
        margin: 40px auto;
        text-align: center;
        @include media-breakpoint-up(md) {
            display: flex;
            flex-direction: row;
            align-content: stretch;
            justify-content: center;
        }
        @include media-breakpoint-down(md) {
            margin: 0 auto;
        }
    }
    &__bubble {
        padding: 15px 0;
        @include media-breakpoint-up(md) {
            padding: 0 15px;
            flex: 0.5 1 20%;
            max-width: 33%;
        }
        @include media-breakpoint-down(md) {
            padding: 22px 0;
        }
    }
    &__image {
        margin-bottom: 40px;
        img {
            max-width: 100%;
        }
        @include media-breakpoint-down(md) {
            margin-bottom: 10px;
        }
    }
    p {
        font-size: $font-size-small;
        line-height: 1.4;
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        * {
            font-size: $font-size-med;
            line-height: 1.25;
            font-weight: 700;
            margin: 0;
            text-transform: none;
            a {
                color: $umassglobal-text-gray;
                display: inline-block;
                margin: 0 0 20px;
                text-decoration: underline;
                text-decoration-color: $umassglobal-primary-color;
                text-transform: none;
                text-align: center;
                white-space: normal;
                &:hover {
                    color: $umassglobal-primary-color;
                }
                @include media-breakpoint-down(md) {
                    margin-bottom: 12px;
                }
            }
        }
    }
}

.columns {
    p {
        font-size: $font-size-small;
        line-height: 1.4;
    }
    &__header {
        font-size: $font-size-med;
        line-height: 1.25;
        margin-top: 0;
        font-weight: 700;
        text-transform: none;
        a {
            display: inline-block;
            text-transform: none;
            text-align: center;
            white-space: normal;
        }
    }
    &__container {
        margin-top: 30px;
        .container .section .container & {
            margin-left: -15px;
            margin-right: -15px;
        }
        @include media-breakpoint-down(md) {
            margin-top: 0;
        }
        &:last-child {
            .columns__column {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    &__content {
        color: $umassglobal-text-gray;
        font-size: $font-size-longPrimer;
        line-height: 1.5;
        flex: 1 1 auto;
        .col-sm-6 & {
            padding-right: 80px;
            @include media-breakpoint-down(md) {
                padding-right: 0;
            }
            p {
                a {
                    color: black;
                    text-decoration: none;
                    &:hover {
                        color: black;
                        text-decoration: none;
                    }
                }
            }
        }
        .callout & {
            padding: 30px 40px;
            @include media-breakpoint-down(md) {
                padding: 25px;
            }
        }
        .card__title {
            * {
                text-decoration: none;
                font-weight: normal;
                margin: 0 0 12px;
            }
        }
    }
    &__image-link {
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
        @include panel-hover-image;
    }
    &__column {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        max-width: 100%;
        .columns__single & {
            float: none;
            margin: 0 auto;
            @include media-breakpoint-up(md) {
                width: 75%;
            }
        }
        .section__light-text & {
            .card__image {
                a {
                    color: $umassglobal-tertiary-color;
                    &:hover {
                        color: $umassglobal-tertiary-color-d50;
                    }
                }
            }
        }
        &.callout {
            align-items: center;
            padding: 0;
            width: 100%;
            @include media-breakpoint-down(md) {
                margin-bottom: 25px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .card__image {
                margin-right: 0;
            }
            .blog-listing & {
                .subheading {
                    color: $umassglobal-primary-color;
                }
            }
        }
        @include media-breakpoint-down(md) {
            margin-bottom: 15px;
        }
        .card__image--transparent,
        .card__image {
            img {
                height: auto;
                margin-bottom: 1rem;
                max-width: 100%;
                width: auto;
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
            a {
                display: block;
                font-size: $font-size-bourgeois;
                font-weight: bold;
            }
        }
        &.callout {
            background-color: $umassglobal-off-white-color;
            @include media-breakpoint-down(md) {
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
            }
            .card__image {
                border: 1px solid $umassglobal-lightest-gray;
                @include media-breakpoint-down(md) {
                    margin-right: 0;
                    width: 100%;
                    max-width: none;
                    height: auto;
                    &:after {
                        content: "";
                    }
                }
            }
            .columns__content {
                p {
                    flex-wrap: wrap;
                }
                @include media-breakpoint-down(md) {
                    .btn {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.faculty {
    &__container {
        margin-top: 30px;
        margin-bottom: 26px;
        @include media-breakpoint-down(md) {
            margin-bottom: 26px;
        }
    }
    &__cta {
        text-align: center;
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 50px;
        .container {
            padding: 25px 15px;
        }
    }
    &__row--few {
        @include media-breakpoint-up(md) {
            &>[class*="col-"] {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

#sectionFactsStats {
    position: relative;
}

.stats {
    &__background {
        border: 1px solid $umassglobal-gray;
        opacity: 0.79;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        margin-top: 0;
        .section__light-text {}
        .section__afterhero & {
            margin-top: -80px;
            padding-top: 80px;
        }
        background-size: cover;
        background: #002d5d no-repeat center top;
        @include media-breakpoint-down(md) {
            background-size: 1600px auto;
            background-position: 0 50%;
        }
        .section__noback & {
            background: none;
        }
    }
    &__facts {
        max-width: 750px;
        margin: 60px auto 10px;
        overflow: hidden;
        @include media-breakpoint-down(md) {
            margin: 30px auto 0;
        }
    }
    &__fact {
        border-right: 1px solid $table-border-color;
        text-align: center;
        padding: 0 20px;
        @include media-breakpoint-down(md) {
            border-right: 0;
            margin-bottom: 20px;
        }
        &:last-child {
            border-right: 0;
            @include media-breakpoint-down(md) {
                margin-bottom: 0;
            }
        }
        @include media-breakpoint-up(md) {
            padding-bottom: 100px;
            margin-bottom: -100px;
        }
    }
    &__label {
        font-size: 4.2rem;
        top: 8px;
        right: 0;
        position: absolute;
        display: block;
        text-transform: uppercase;
        line-height: 1;
        letter-spacing: 0;
    }
    &__value {
        color: $umassglobal-secondary-color;
        font-size: 8rem;
        line-height: 1;
        padding-right: 34px;
        position: relative;
        display: inline-block;
        .section__light-text & {
            color: $umassglobal-tertiary-color;
        }
    }
    &__description {
        color: #fff;
        font-size: $font-size-longPrimer;
        font-weight: 700;
        line-height: 1.4;
    }
    &.section__afterhero {
        .stats__background {
            display: none;
        }
        .section__light-text {
            color: black;
        }
        .stats__fact {
            .stats__description {
                color: $umassglobal-primary-color !important;
            }
        }
    }
}

.partnerships__name_text {
    position: absolute;
    font-size: 1em;
    @include media-breakpoint-down(md) {
        font-size: 0.8em;
    }
    line-height: 1.2em;
    max-width: 50%;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 40%;
    margin: -20% 0 0 -25%;
}

section.stats.waypoint.section__afterhero {
    .stats__description {
        color: white;
    }
}

// Rich text block CSS
.schools__wrapper {
    span {
        display: block;
    }
}