.curriculum {


    &__container {
        display: flex;
        flex-direction: row;
        max-width: 700px;
        flex-wrap: wrap;
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }

    &__course {
        font-size: $font-size-pica;
        line-height: $line-height-base;
        margin-right: 5%;
        margin-bottom: 30px;
        flex: 0 0 45%;
        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
        }
    }
    &__totals {
        border-top: 1px solid $table-border-color;
        display: block;
        font-size: $font-size-pica;
        font-weight: bold;
        line-height: $line-height-base;
        padding-top: 30px;
    }
    &__name {
        font-weight: bold;
    }
    &__hours {
        color: $umassglobal-darker-gray;
        font-style: italic;
    }
}