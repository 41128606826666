

@font-face {
    font-family: "standard-icons";
    src: url("../../../fonts/standard-icons.eot?c331768050b06dedf63880bf151d6dde");
    src: url("../../../fonts/standard-icons.eot?#iefix") format("embedded-opentype"), url("../../../fonts/standard-icons.woff2?c331768050b06dedf63880bf151d6dde") format("woff2"), url("../../../fonts/standard-icons.woff?c331768050b06dedf63880bf151d6dde") format("woff"), url("../../../fonts/standard-icons.ttf?c331768050b06dedf63880bf151d6dde") format("truetype"), url("../../../fonts/standard-icons.svg?c331768050b06dedf63880bf151d6dde#standard-icons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

%icon-base-after {
    &:after{
        font-family: "standard-icons";
        display: inline-block;
        vertical-align: middle;
        font-weight: normal;
        font-style: normal;
        speak: none;
        text-decoration: inherit;
        text-transform: none;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

%icon-base-before {
    &:before {
        font-family: "standard-icons";
        display: inline-block;
        vertical-align: middle;
        font-weight: normal;
        font-style: normal;
        speak: none;
        text-decoration: inherit;
        text-transform: none;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

@mixin standard-icons($icon,
$psuedo: after) {
    @if ($psuedo=="after") {
        @extend %icon-base-after;
        @if ($icon=="blackboard") {
            &:after {
                content: "\f101";
            }
        }
        @else if ($icon=="board") {
            &:after {
                content: "\f102";
            }
        }
        @else if ($icon=="book") {
            &:after {
                content: "\f103";
            }
        }
        @else if ($icon=="boxarrow") {
            &:after {
                content: "\f104";
            }
        }
        @else if ($icon=="brochure") {
            &:after {
                content: "\f105";
            }
        }
        @else if ($icon=="calculator") {
            &:after {
                content: "\f106";
            }
        }
        @else if ($icon=="calendar") {
            &:after {
                content: "\f107";
            }
        }
        @else if ($icon=="carat") {
            &:after {
                content: "\f108";
            }
        }
        @else if ($icon=="chat") {
            &:after {
                content: "\f109";
            }
        }
        @else if ($icon=="check") {
            &:after {
                content: "\f10a";
            }
        }
        @else if ($icon=="checkbox") {
            &:after {
                content: "\f10b";
            }
        }
        @else if ($icon=="checkoutline") {
            &:after {
                content: "\f10c";
            }
        }
        @else if ($icon=="close") {
            &:after {
                content: "\f10d";
            }
        }
        @else if ($icon=="cost") {
            &:after {
                content: "\f10e";
            }
        }
        @else if ($icon=="credit") {
            &:after {
                content: "\f10f";
            }
        }
        @else if ($icon=="curvearrow") {
            &:after {
                content: "\f110";
            }
        }
        @else if ($icon=="downarrow") {
            &:after {
                content: "\f111";
            }
        }
        @else if ($icon=="downchevrons") {
            &:after {
                content: "\f112";
            }
        }
        @else if ($icon=="download") {
            &:after {
                content: "\f113";
            }
        }
        @else if ($icon=="email") {
            &:after {
                content: "\f114";
            }
        }
        @else if ($icon=="facebook") {
            &:after {
                content: "\f115";
            }
        }
        @else if ($icon=="faculty") {
            &:after {
                content: "\f116";
            }
        }
        @else if ($icon=="growth") {
            &:after {
                content: "\f117";
            }
        }
        @else if ($icon=="hybrid") {
            &:after {
                content: "\f118";
            }
        }
        @else if ($icon=="inperson") {
            &:after {
                content: "\f119";
            }
        }
        @else if ($icon=="inpersonutility") {
            &:after {
                content: "\f11a";
            }
        }
        @else if ($icon=="instagram") {
            &:after {
                content: "\f11b";
            }
        }
        @else if ($icon=="largeleftarrow") {
            &:after {
                content: "\f11c";
            }
        }
        @else if ($icon=="largerightarrow") {
            &:after {
                content: "\f11d";
            }
        }
        @else if ($icon=="leadership") {
            &:after {
                content: "\f11e";
            }
        }
        @else if ($icon=="leftarrow") {
            &:after {
                content: "\f11f";
            }
        }
        @else if ($icon=="linkedin") {
            &:after {
                content: "\f120";
            }
        }
        @else if ($icon=="linkedinfull") {
            &:after {
                content: "\f121";
            }
        }
        @else if ($icon=="mybrandman") {
            &:after {
                content: "\f122";
            }
        }
        @else if ($icon=="online") {
            &:after {
                content: "\f123";
            }
        }
        @else if ($icon=="onlineself") {
            &:after {
                content: "\f124";
            }
        }
        @else if ($icon=="pencil") {
            &:after {
                content: "\f125";
            }
        }
        @else if ($icon=="personcheck") {
            &:after {
                content: "\f126";
            }
        }
        @else if ($icon=="phone") {
            &:after {
                content: "\f127";
            }
        }
        @else if ($icon=="pin") {
            &:after {
                content: "\f128";
            }
        }
        @else if ($icon=="play") {
            &:after {
                content: "\f129";
            }
        }
        @else if ($icon=="podcast") {
            &:after {
                content: "\f12a";
            }
        }
        @else if ($icon=="print") {
            &:after {
                content: "\f12b";
            }
        }
        @else if ($icon=="request") {
            &:after {
                content: "\f12c";
            }
        }
        @else if ($icon=="rightarrow") {
            &:after {
                content: "\f12d";
            }
        }
        @else if ($icon=="rightmorearrow") {
            &:after {
                content: "\f12e";
            }
        }
        @else if ($icon=="search") {
            &:after {
                content: "\f12f";
            }
        }
        @else if ($icon=="selfservice") {
            &:after {
                content: "\f130";
            }
        }
        @else if ($icon=="staff") {
            &:after {
                content: "\f131";
            }
        }
        @else if ($icon=="twitter") {
            &:after {
                content: "\f132";
            }
        }
        @else if ($icon=="uparrow") {
            &:after {
                content: "\f133";
            }
        }
        @else if ($icon=="video") {
            &:after {
                content: "\f134";
            }
        }
        @else if ($icon=="youtube") {
            &:after {
                content: "\f135";
            }
        }
    }
    @else {
        @extend %icon-base-before;
        @if ($icon=="blackboard") {
            &:before {
                content: "\f101";
            }
        }
        @else if ($icon=="board") {
            &:before {
                content: "\f102";
            }
        }
        @else if ($icon=="book") {
            &:before {
                content: "\f103";
            }
        }
        @else if ($icon=="boxarrow") {
            &:before {
                content: "\f104";
            }
        }
        @else if ($icon=="brochure") {
            &:before {
                content: "\f105";
            }
        }
        @else if ($icon=="calculator") {
            &:before {
                content: "\f106";
            }
        }
        @else if ($icon=="calendar") {
            &:before {
                content: "\f107";
            }
        }
        @else if ($icon=="carat") {
            &:before {
                content: "\f108";
            }
        }
        @else if ($icon=="chat") {
            &:before {
                content: "\f109";
            }
        }
        @else if ($icon=="check") {
            &:before {
                content: "\f10a";
            }
        }
        @else if ($icon=="checkbox") {
            &:before {
                content: "\f10b";
            }
        }
        @else if ($icon=="checkoutline") {
            &:before {
                content: "\f10c";
            }
        }
        @else if ($icon=="close") {
            &:before {
                content: "\f10d";
            }
        }
        @else if ($icon=="cost") {
            &:before {
                content: "\f10e";
            }
        }
        @else if ($icon=="credit") {
            &:before {
                content: "\f10f";
            }
        }
        @else if ($icon=="curvearrow") {
            &:before {
                content: "\f110";
            }
        }
        @else if ($icon=="downarrow") {
            &:before {
                content: "\f111";
            }
        }
        @else if ($icon=="downchevrons") {
            &:before {
                content: "\f112";
            }
        }
        @else if ($icon=="download") {
            &:before {
                content: "\f113";
            }
        }
        @else if ($icon=="email") {
            &:before {
                content: "\f114";
            }
        }
        @else if ($icon=="facebook") {
            &:before {
                content: "\f115";
            }
        }
        @else if ($icon=="faculty") {
            &:before {
                content: "\f116";
            }
        }
        @else if ($icon=="growth") {
            &:before {
                content: "\f117";
            }
        }
        @else if ($icon=="hybrid") {
            &:before {
                content: "\f118";
            }
        }
        @else if ($icon=="inperson") {
            &:before {
                content: "\f119";
            }
        }
        @else if ($icon=="inpersonutility") {
            &:before {
                content: "\f11a";
            }
        }
        @else if ($icon=="instagram") {
            &:before {
                content: "\f11b";
            }
        }
        @else if ($icon=="largeleftarrow") {
            &:before {
                content: "\f11c";
            }
        }
        @else if ($icon=="largerightarrow") {
            &:before {
                content: "\f11d";
            }
        }
        @else if ($icon=="leadership") {
            &:before {
                content: "\f11e";
            }
        }
        @else if ($icon=="leftarrow") {
            &:before {
                content: "\f11f";
            }
        }
        @else if ($icon=="linkedin") {
            &:before {
                content: "\f120";
            }
        }
        @else if ($icon=="linkedinfull") {
            &:before {
                content: "\f121";
            }
        }
        @else if ($icon=="mybrandman") {
            &:before {
                content: "\f122";
            }
        }
        @else if ($icon=="online") {
            &:before {
                content: "\f123";
            }
        }
        @else if ($icon=="onlineself") {
            &:before {
                content: "\f124";
            }
        }
        @else if ($icon=="pencil") {
            &:before {
                content: "\f125";
            }
        }
        @else if ($icon=="personcheck") {
            &:before {
                content: "\f126";
            }
        }
        @else if ($icon=="phone") {
            &:before {
                content: "\f127";
            }
        }
        @else if ($icon=="pin") {
            &:before {
                content: "\f128";
            }
        }
        @else if ($icon=="play") {
            &:before {
                content: "\f129";
            }
        }
        @else if ($icon=="podcast") {
            &:before {
                content: "\f12a";
            }
        }
        @else if ($icon=="print") {
            &:before {
                content: "\f12b";
            }
        }
        @else if ($icon=="request") {
            &:before {
                content: "\f12c";
            }
        }
        @else if ($icon=="rightarrow") {
            &:before {
                content: "\f12d";
            }
        }
        @else if ($icon=="rightmorearrow") {
            &:before {
                content: "\f12e";
            }
        }
        @else if ($icon=="search") {
            &:before {
                content: "\f12f";
            }
        }
        @else if ($icon=="selfservice") {
            &:before {
                content: "\f130";
            }
        }
        @else if ($icon=="staff") {
            &:before {
                content: "\f131";
            }
        }
        @else if ($icon=="twitter") {
            &:before {
                content: "\f132";
            }
        }
        @else if ($icon=="uparrow") {
            &:before {
                content: "\f133";
            }
        }
        @else if ($icon=="video") {
            &:before {
                content: "\f134";
            }
        }
        @else if ($icon=="youtube") {
            &:before {
                content: "\f135";
            }
        }
    }
}

.icon {
    &:after {
        font-family: "standard-icons";
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        font-weight: normal;
        font-style: normal;
        speak: none;
        text-decoration: inherit;
        text-transform: none;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

// Icons
.icon__blackboard:after {
    content: "\f101";
}

.icon__board:after {
    content: "\f102";
}

.icon__book:after {
    content: "\f103";
}

.icon__boxarrow:after {
    content: "\f104";
}

.icon__brochure:after {
    content: "\f105";
}

.icon__calculator:after {
    content: "\f106";
}

.icon__calendar:after {
    content: "\f107";
}

.icon__carat:after {
    content: "\f108";
}

.icon__chat:after {
    content: "\f109";
}

.icon__check:after {
    content: "\f10a";
}

.icon__checkbox:after {
    content: "\f10b";
}

.icon__checkoutline:after {
    content: "\f10c";
}

.icon__close:after {
    content: "\f10d";
}

.icon__cost:after {
    content: "\f10e";
}

.icon__credit:after {
    content: "\f10f";
}

.icon__curvearrow:after {
    content: "\f110";
}

.icon__downarrow:after {
    content: "\f111";
}

.icon__downchevrons:after {
    content: "\f112";
}

.icon__download:after {
    content: "\f113";
}

.icon__email:after {
    content: "\f114";
}

.icon__facebook:after {
    content: "\f115";
}

.icon__faculty:after {
    content: "\f116";
}

.icon__growth:after {
    content: "\f117";
}

.icon__growth.down:after {
    color: $umassglobal-primary-color;
    transform: rotate(180deg);
}

.icon__hybrid:after {
    content: "\f118";
}

.icon__inperson:after {
    content: "\f119";
}

.icon__inpersonutility:after {
    content: "\f11a";
}

.icon__instagram:after {
    content: "\f11b";
}

.icon__largeleftarrow:after {
    content: "\f11c";
}

.icon__largerightarrow:after {
    content: "\f11d";
}

.icon__leadership:after {
    content: "\f11e";
}

.icon__leftarrow:after {
    content: "\f11f";
}

.icon__linkedin:after {
    content: "\f120";
}

.icon__linkedinfull:after {
    content: "\f121";
}

.icon__mybrandman:after {
    content: "\f122";
}

.icon__online:after {
    content: "\f123";
}

.icon__onlineself:after {
    content: "\f124";
}

.icon__pencil:after {
    content: "\f125";
}

.icon__personcheck:after {
    content: "\f126";
}

.icon__phone:after {
    content: "\f127";
}

.icon__pin:after {
    content: "\f128";
}

.icon__play:after {
    content: "\f129";
}

.icon__podcast:after {
    content: "\f12a";
}

.icon__print:after {
    content: "\f12b";
}

.icon__request:after {
    content: "";
    background-image: url('../../../fonts/refresh.svg');
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
}

.icon__rightarrow:after {
    content: "\f12d";
}

.icon__rightmorearrow:after {
    content: "\f12e";
}

.icon__search:after {
    content: "\f12f";
}

.icon__selfservice:after {
    content: "\f130";
}

.icon__staff:after {
    content: "\f131";
}

.icon__twitter:after {
    content: "\f132";
}

.icon__uparrow:after {
    content: "\f133";
}

.icon__video:after {
    content: "\f134";
}

.icon__youtube:after {
    content: "\f135";
}