.header {
    background: $body-bg;
    padding: 16px 0;
    position: relative;
    &__global {
        .keyboard & {
            top: -138px;
            @include media-breakpoint-up(xl) {
                top: -119px;
            }
        }
        @media print {
            position: relative;
            left: auto;
            top: auto;
        }
        &.sticky {
            @include box-shadow(0 0 6px rgba(0, 0, 0, .25));
        }
        .mobile {
            display: none;
            @include media-breakpoint-up(xl) {
                display: initial;
            }
        }
        .desktop {
            display: none;
            @include media-breakpoint-up(lg) {
                display: initial;
            }
        }
    }
    @include media-breakpoint-down(md) {
        padding: 16px 25px;
    }
    @include media-breakpoint-up(md) {
        padding: 16px 40px;
    }
    &__logo {
        display: inline-block;
        width: 250px;
        @include media-breakpoint-down(md) {
            width: 180px;
        }
        @media print {
            &:after {
                display: none;
            }
        }
    }
    &__container {
        &:after,
        &:before {
            display: none;
        }
        @include media-breakpoint-up(xl) {
            position: static;
            padding: 0;
        }
    }
    &__landing {
        .header__logo {
            display: block;
            margin-right: 1.5rem;
            max-width: 6rem;
        }
    }
}

.breadcrumb {
    &__nav {
        @media print {
            display: none;
        }
        border-top: 1px solid $umassglobal-lightest-gray;
        border-bottom: 1px solid $umassglobal-lightest-gray;
        .container {
            @include media-breakpoint-up(xl) {
                padding: 0 25px;
            }
        }
    }
    nav {
        background: none;
    }
}

.partner__logo {
    float: left;
    margin-left: 1.5rem;
    padding-left: 1.5rem;
    border-left: 1px solid $umassglobal-extraLight-gray;
    color: #737373;
    font-size: 16pt;
    text-decoration: none;
    text-align: center;
    height: 75px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    h5 {
        display: table-cell;
        vertical-align: middle;
        color: #737373;
        @include media-breakpoint-up(md) {
            font-size: 16pt;
            font-weight: 500;
            line-height: 1.2;
        }
    }
    img {
        max-height: 100%;
        width: auto;
        margin-top: 0;
        height: auto;
        max-width: 100%;
        @media (min-width: 960px) {
            /*height: 60px;
            width: auto;
            max-width: 20vw;
            margin-top: 8px;*/
            width: auto;
            margin-top: 0;
            height: auto;
            max-width: 100%;
        }
    }
    @media (max-width: 960px) {
        //height: 50px;
        width: 100%;
        /*padding: 0 28px;
		margin: 12px 0;
		font-size: 8pt;
		width: 100%;
		max-width: 100%;*/
        p {
            margin: auto;
        }
        img {
            /*width: auto;
            margin-top: 3px;
            height: 90%;
            max-width: 100%;*/
        }
    }
}


/*.partner_portal_logo {
    //padding: 15px 20px 0;
    //padding-top: 25px !important;
    max-height: 90px;
    @include media-breakpoint-down(md) {
        width: 111%;
        padding: 0;
        margin: 0 45px;
    }
    @include media-breakpoint-only(md) {
        width: 100%;
        margin-right: auto !important;
        margin-left: auto !important;
        margin: 0 auto;
        text-align: center;
        display: block;
    }
}*/

.left-bar {
    border-left: 1px solid $umassglobal-extraLight-gray;
    @media (min-width: 992px) {
        width: 12%!important;
    }
}

.pre_header {
    font-size: 1rem;
    text-align: center;
    background-color: $umassglobal-off-white-color;
    height: 38px;
    padding: 10px;
    color: $umassglobal-dark-gray;
    .link {
        font-weight: bold;
        color: $umassglobal-dark-gray;
    }
}

.no_padding {
    @media (max-width: 992px) {
        padding: 0!important;
        margin: 0!important;
        //height: 43px;
    }
}

.btn-grey {
    background-color: #b3b3b3!important;
    color: black;
    width: 100%;
}

.btn-light {
    width: 100%;
    background-color: $umassglobal-off-white-color!important;
}

.no_fixed {
    @media (max-width: 960px) {
        //position: relative!important;  
        //height: 150px!important;
    }
}

.no_border {
    border: none;
}

@media only screen and (min-device-width: 960px) and (max-device-width: 1200px) and (orientation: landscape) {}