.testimonials {
    background-position: 0 50%;
    @include media-breakpoint-down(md) {
        padding: 50px 0;
    }
    
    .container {
        padding-bottom: 35px;
        padding-top: 35px;
        background-color: $umassglobal-lightest-gray;
    }
    blockquote {
        padding: 0;
        margin: 0 0 20px;
        footer {
            position: absolute;
        }
        @include media-breakpoint-down(md) {
            font-size: $font-size-english;
            &:before {
                left: -40px;
                top: 17px;
            }
        }
    }
    &__image {
        text-align: center;
        img {
            //max-width: 100%; This is nonsense. TODO: Check what was intended here.
            margin: 0 auto;
            @extend %resImg-2cols;
            width: 100%;
            height: 215px;
            object-fit: cover;
            @include media-breakpoint-down(md) {
                //width: auto;
                height: 150px;
            }
        }
    }
    &__name {
        font-size: $font-size-bourgeois;
    }
    &__quote {
        padding: 30px;
        margin: 30px;
        @include media-breakpoint-down(md) {
            padding: 30px 40px;
            margin: 0;
        }
    }
    &__continer {
        position: relative;
        padding: 0 50px;
        @include media-breakpoint-down(md) {
            padding: 0 5px;
        }
    }
    &__previous,
    &__next {
        color: $umassglobal-dark-gray;
        font-size: 5rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @include media-breakpoint-down(md) {
            font-size: 3.5rem;
            top: 75px;
            transform: none;
        }
        &:hover {
            color: #000;
        }
    }
    &__previous {
        left: 0;
    }
    &__next {
        right: 0;
    }
    &__slide {
        padding: 0 50px;
        .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
        }
    }
}