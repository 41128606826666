.admission-req {
    .section__description {
        width: 100%;
        margin: 0;
    }
    &__title {
        @include subheading($umassglobal-gray, 14px);
        margin-bottom: 9px;
    }
    &__additional {
        margin: 15px 0;
        &-container {
            background-position: 50% 50%;
            background-size: 100% auto;
            background-image: none;
            background-color: $umassglobal-lightest-gray;
            @include media-breakpoint-down(md) {
                background-size: initial;
                background-position: 0 0;
            }
        }
        &-text {
            background-color: $body-bg;
            border: 1px solid $umassglobal-secondary-color;
            padding: 30px;
            color: $umassglobal-text-gray;
            font-size: $font-size-pica;
            line-height: $line-height-base;
            position: relative;
        }
    }
    &__disclaimer {
        font-size: $font-size-smallPica;
        font-style: italic;
        margin-top: 45px;
    }
    &__option {
        margin: 15px 0;
        &-link {
            display: block;
            font-style: italic;
            a {
                font-weight: 700;
                text-decoration: underline;
            }
        }
        &-header {
            * {
                font-size: $font-size-english;
            }
        }
        &-container {
            background: url("/images/pattern_back--transparent.svg") no-repeat 50% 50%;
            background-size: 100% auto;
            @include media-breakpoint-down(md) {
                background-size: initial;
                background-position: 100% 0;
            }
        }
        &-text {
            background-color: $body-bg;
            border: 1px solid $umassglobal-lightest-gray;
            padding: 30px;
            color: $umassglobal-text-gray;
            font-size: $font-size-pica;
            line-height: $line-height-base;
            position: relative;
            p {
                margin: 0 0 7px;
            }
            .admission-req {
                &__title {
                    color: $umassglobal-primary-color;
                }
            }
        }
    }
}