@use "sass:math";
$border-radius-base: 0; //4
$border-radius-large: 0; //6
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-xs-max: ($screen-sm-min - 1);
$navbar-height: 5rem;
$navbar-padding-vertical: math.div(($navbar-height - $line-height-computed) , 2);
$nav-link-padding: 6px 13px;
$panel-border-radius: $border-radius-large;


button {
  background: none;
  border: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
}