//PLACEHOLDERS
%resImg-2cols {
    width: 100%;
    height: 300px;
    object-fit: cover;
    transition: all 0.5s ease;
    @include media-breakpoint-up(md) {
        height: 400px;
        transition: all 0.5s ease;
    }
    @include media-breakpoint-up(lg) {
        height: 410px;
        transition: all 0.5s ease;
    }
}

//PLACEHOLDERS
.grid-results {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    @include media-breakpoint-only(md) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-down(md) {
        grid-template-columns: repeat(1, 1fr);
    }
    &.grid-4 {
        grid-template-columns: repeat(4, 1fr);
        @include media-breakpoint-only(md) {
            grid-template-columns: repeat(3, 1fr);
        }
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

.two-col-img {
    img {
        @extend %resImg-2cols;
        height: 160px;
        @include media-breakpoint-up(md) {
            height: 160px;
        }
        @include media-breakpoint-up(lg) {
            height: 160px;
        }
    }
}