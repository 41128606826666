// Please try to add utilty classes in layouts cshtml files instead of here
// Unique file to override bs5 and add custom styles
// Things dont working properly in bs5

header {
  position: sticky;
  top: 0;
  z-index: 1030;

  // Navbar
  nav {
    #topNav a {
      color: $umassglobal-text-gray;

      &:hover {
        color: $umassglobal-red;
      }
    }

    &.topnav {
      z-index: 1030;
      padding: 0;
    }

    a.btn.btn-outline-dark.phone-btn,
    .phone-btn {
      font-stretch: condensed;
      font-variant: common-ligatures tabular-nums;
    }

    .top-ctas {
      > .btn-group {
        max-width: 36rem;

        > .btn {
          font-size: $font-size-sm;
          font-weight: 700;

          @include media-breakpoint-up(md) {
            border-radius: 0;
          }
        }
      }

      @include media-breakpoint-down(md) {
        position: fixed;
        right: 0;
        bottom: 1.2rem;
        left: calc(50% - 36rem / 2);
        width: 100%;

        > .btn-group .btn {
          font-stretch: condensed;
          letter-spacing: 0.02em;
          line-height: $line-height-sm;
          padding-top: 0.8rem;
          padding-bottom: 0.8rem;

          &:first-child {
            border-top-left-radius: 0.6rem;
            border-bottom-left-radius: 0.6rem;
          }

          &:last-child {
            border-top-right-radius: 0.6rem;
            border-bottom-right-radius: 0.6rem;
          }
        }
      }
    }

    .btn-outline-dark {
      background-color: $umassglobal-white;

      &:hover {
        background-color: $umassglobal-text-gray;
      }
    }

    @include media-breakpoint-between(md, xl) {
      .navbar-toggler.collapsed + .top-ctas,
      .navbar-collapse.collapsing + .top-ctas,
      .navbar-collapse.show + .top-ctas {
        position: fixed;
        top: 0;
        right: 1.5rem;
      }
    }

    @include media-breakpoint-down(md) {
      button {
        &.navbar-toggler {
          padding-top: 0.6rem;
          padding-bottom: 0.6rem;
          border-color: $umassglobal-lighter-gray;
        }
      }
    }
  }

  // Header
  .header {
    background: $umassglobal-white;
    padding: 0.4rem 0;
    position: relative;

    &__container {
      padding: 0 1.5rem;
      align-items: center;
      justify-content: space-between;

      &::after,
      &::before {
        display: none;
      }
    }

    &.partner_portal_header {
      .header__container {
        @include media-breakpoint-down(md) {
          justify-content: center;
        }
      }
    }

    &__logo {
      display: inline-block;
      width: 17.5rem;

      img {
        height: auto;
        width: 100%;
      }
    }
  }

  .navbar {
    ul li {
      a:not(.btn) {
        display: inline-block;
        width: 100%;
        height: calc(100% - 2px);
        color: $umassglobal-text-gray;
        text-decoration: none;
        line-height: $line-height-lg;

        &:hover,
        &.show {
          color: $umassglobal-red;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .header {
      padding: 1.6rem 4rem;

      &__logo {
        width: 22rem;
      }
    }
  }

  .nav__overview-image img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    max-height: 13.5rem !important;
    -webkit-clip-path: polygon(100% 0, 0 0, 0 86%, 50% 100%, 100% 86%);
    clip-path: polygon(100% 0, 0 0, 0 86%, 50% 100%, 100% 86%);
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  form {
    .form-floating {
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
    }

    &.searchBar {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @include media-breakpoint-up(xl) {
    form.searchBar {
      max-width: 33rem;
      margin-left: auto;
      margin-right: 0;
    }
  }
}

// Forms
.tcpa_statement {
  font-size: $font-size-smallPica;
  line-height: 1.6;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  color: $umassglobal-secondary-color-d75;
}

// Horizontal form
.horizontal_form {
  .form-floating > label {
    left: unset;
  }

  .tabs__content.active {
    display: inline-table;
  }
}

// Breadcumbs
.breadcrumb {
  margin-top: 1rem;

  a {
    text-decoration: none;
    color: $umassglobal-text-gray;
    font-size: $font-size-sm;

    &:hover {
      color: $umassglobal-primary-color;
    }
  }

  li:not(:first-child):before {
    content: '| ';
    padding: 0 0.5rem;
    color: $umassglobal-primary-color;
  }
}

// List carets counter style
// Define counter style
@counter-style umass-carets {
  system: cyclic;
  symbols: '\f108';
  speak-as: bullets;
  suffix: ' ';
}

.admission-req__additional-container,
.admission-req__option-container,
.section__content,
.section__description,
.section__text {
  ul:not(.checks):not(:has(> li[role='presentation'])) {
    // Fallback
    list-style-type: disc;

    @supports (list-style: symbols('\f108')) {
      // Apply counter style if supported
      list-style: umass-carets;

      & > li::marker {
        color: $umassglobal-blue;
        font-family: 'standard-icons';
      }
    }
  }
}

// Recaptcha badge
.grecaptcha-badge {
  visibility: hidden;
}

// One trust badge
#ot-sdk-btn-floating {
  bottom: 5.2rem !important;
}

// Empty Chevrons clasess for content
.chevron-out-bottom {
  @include chevron('out-bottom');
}

.chevron-out-top {
  @include chevron('out-top');
}

.chevron-out-right {
  @include chevron('out-right');
}

.chevron-out-left {
  @include chevron('out-left');
}

.chevron-in-bottom {
  @include chevron('in-bottom');
}

.chevron-in-top {
  @include chevron('in-top');
}

.chevron-in-right {
  @include chevron('in-right');
}

.chevron-in-left {
  @include chevron('in-left');
}

// Chevrons
.schools__image {
  @include chevron('out-bottom');
}

// Testimonials
.testimonials {
  background-size: auto;

  blockquote {
    font-style: italic;
    line-height: 3.3rem;
    position: relative;

    &:after,
    &:before {
      color: #005eb8;
      font-family: Georgia, 'Times New Roman', Times, serif;
      font-size: 6rem;
      font-style: normal;
      font-weight: $font-weight-bold;
    }

    &:before {
      content: open-quote;
      display: block;
      position: absolute;
      left: -5.5rem;
    }

    &:after {
      content: close-quote;
      display: block;
      margin: 0;
      position: relative;
      right: -100%;
      width: 33px;
      height: 33px;
    }
  }
}

// Carousel indicators
.carousel-control-next,
.carousel-control-prev {
  color: $umassglobal-text-gray;
  width: auto;
}

// Tabs
.nav-tabs {
  .nav-link {
    background-color: $umassglobal-extraLight-gray;
    color: $umassglobal-darker-gray;
    font-size: $font-size-longPrimer;
    margin-right: 1rem;
    line-height: $line-height-lg;
    border-radius: 0.2rem 0.2rem 0 0;
    padding: 0.3rem 1.8rem;

    &.active {
      background-color: $umassglobal-red;

      span {
        color: $umassglobal-white;
      }
    }
  }

  .nav-item {
    a {
      &.active {
        background-color: $umassglobal-red;
        color: $umassglobal-white;
      }
    }
  }
}

// Left Nav
.leftnav {
  li,
  a:not(.icon) {
    position: relative;
  }

  .nav__leftnav {
    display: block;

    li {
      a,
      button {
        color: $umassglobal-text-gray;
      }
    }

    li.selected {
      & > a,
      & > button {
        color: $umassglobal-red;
      }
    }
  }
}

// Button link
.btn-link,
.btn-link-carat,
.btn-link-leave,
.fullbtn-link-carat,
.fullbtn-link-leave {
  color: $umassglobal-text-gray;
  font-weight: $font-weight-bold;
  border-radius: 0;
  padding: 0;
  text-decoration: underline;
  text-decoration-color: $umassglobal-red;
  white-space: normal;
  text-align: left;
  vertical-align: baseline;
}

// FIXME: Do no use fixed padding on all components and containers
// TODO: Use BS5 padding classes on needed and do not override container padding
// Start Old Container Fixed Padding ***

@include media-breakpoint-down(lg) {
  .container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

// FIXME: Do no use fixed padding on all components and containers
// TODO: Use BS5 padding classes on needed and do not override container padding
// End Old Container Fixed Padding ***

// Others
.admission-req__additional-container ul li,
.admission-req__option-container ul li,
.section__content ul li,
.section__description ul li,
.section__text ul li,
blockquote {
  margin-bottom: 1.2rem;
}

.degree-finder .badge {
  font-size: $font-size-base;
}

.degree-finder__result {
  box-shadow: none;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0 0.3rem 0.8rem;
  }
}

.people-finder #btn_search {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin-right: 0.1rem;
}

[data-whatinput='keyboard'] :focus:focus {
  border-color: #00f;
  outline: 0;
  box-shadow:
    inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.07),
    0 0 0.8rem rgba(0, 0, 255, 0.6);
}

.search-results__result:hover {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.57);
  text-decoration: none;
}

.section__preheader {
  color: $umassglobal-gray;
}

.columns__column .card__image,
.columns__column .card__image--transparent {
  flex: 1 0 auto;
  max-width: 50%;
  margin-top: 0.4rem;
  margin-right: 3rem;
  position: relative;
  width: 23rem;
  height: 16rem;
}

.location-finder__result .card__image {
  height: auto;
  align-self: auto;
}

div.ivy-main.ivy-pos-bottom-right.ivy-t-circle .ivy-circle {
  bottom: 5.2rem;
  right: 1rem;
}

.news-detail__title h1 {
  margin-bottom: 3rem;
}

.scrollspy-dynamic {
  overflow-y: auto;
  overflow-x: hidden;
  height: 75vh;
  position: relative;
  padding-top: 13rem;

  h2,
  h3 {
    scroll-margin-top: 13rem;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 7rem;
  height: 4rem;

  @include media-breakpoint-down(md) {
    width: 4rem;
    height: 6rem;
  }
}

.carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23000" viewBox="0 0 16 16"%3E%3Cpath fill="%23000" d="M11.354 1.646a.5.5 0 0 0-.708 0L4.646 7.646a.5.5 0 0 0 0 .708l6 6a.5.5 0 0 0 .708-.708L5.707 8l5.647-5.646a.5.5 0 0 0 0-.708z"/%3E%3C/svg%3E');
}

.carousel-control-next-icon {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23000" viewBox="0 0 16 16"%3E%3Cpath fill="%23000" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/%3E%3C/svg%3E');
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}
