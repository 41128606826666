$chevron-offset: 14% !default;
$chevron-location: 50% !default;

@mixin chevron($direction) {
  @if $direction == 'out-bottom' {
    clip-path: polygon(100% 0, 0 0, 0 calc(100% - $chevron-offset), $chevron-location 100%, 100% calc(100% - $chevron-offset));
  } @else if $direction == 'out-top' {
    clip-path: polygon(0% $chevron-offset, $chevron-location 0%, 100% $chevron-offset, 100% 100%, 0% 100%);
  } @else if $direction == 'out-right' {
    clip-path: polygon(0% 0%, calc(100% - $chevron-offset) 0%, 100% $chevron-location, calc(100% - $chevron-offset) 100%, 0% 100%);
  } @else if $direction == 'out-left' {
    clip-path: polygon($chevron-offset 0%, 100% 0%, 100% 100%, $chevron-offset 100%, 0% $chevron-location);
  } @else if $direction == 'in-bottom' {
    clip-path: polygon(100% 0, 0 0, 0 100%, $chevron-location calc(100% - $chevron-offset), 100% 100%);
  } @else if $direction == 'in-top' {
    clip-path: polygon(0% $chevron-offset, $chevron-location 0%, 100% $chevron-offset, 100% 100%, 0% 100%);
  } @else if $direction == 'in-right' {
    clip-path: polygon(0% 0%, 100% 0%, calc(100% - $chevron-offset) $chevron-location, 100% 100%, 0% 100%);
  } @else if $direction == 'in-left' {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, $chevron-offset $chevron-location);
  }
}